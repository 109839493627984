import React, { Component } from "react";
import PropTypes from "prop-types";
import "./css/dropdown.css";

class DropDown extends Component {
    static propTypes = {
        isOpen: PropTypes.bool.isRequired,
        label: PropTypes.string.isRequired,
        children: PropTypes.node,
        onClick: PropTypes.func
    };

    static defaultProps = {
        isOpen: false,
        label: "Download",
        children: null
    };



    constructor(props) {
        super(props);
        this.state = {
            isOpen: this.props.isOpen,
            disabled: this.props.disabled
        };
    }

    componentDidMount() {
        //Assign click handler to listen the click to close the dropdown when clicked outside
        document.addEventListener("click", this.handleClickOutside);
    }

    componentWillUnmount() {
        //Remove the listener
        document.removeEventListener("click", this.handleClickOutside);
    }

    //If click is outside the dropdown button or display area
    //Close the dropdown
    handleClickOutside = event => {
        const path = event.path || (event.composedPath && event.composedPath());
        const { onClick } = this.props;

        if (
            !path.includes(this.displayAreaRef) &&
            !path.includes(this.dropTogglerRef)
        ) {
            this.setState({
                isOpen: false
            });

            onClick && onClick(false);
        }
    };

    //DropDown toggler
    toggleDropDown = () => {
        const { onClick } = this.props;
        const { isOpen } = this.state;
        this.setState({
            isOpen: !isOpen
        });

        onClick && onClick(!isOpen);
    };

    //To control component
    componentDidUpdate(prevProps, prevState, snapshot) {

        // console.warn("Dropdown - DidUpdate", this.state, this.props.isOpen)
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({
                isOpen: this.props.isOpen,
            });
        }

    }

    render() {
        const { label } = this.props;
        const { children } = this.props;
        const { isOpen } = this.state;

        const { disabled } = this.state;

        // console.log("Render Dropdown", "Disabled: "+disabled, this.state)
        return (
            <div className="wrapper" disabled={disabled}>
                <div
                    style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}
                    className="dropToggler"
                    onClick={this.toggleDropDown}
                    ref={ref => (this.dropTogglerRef = ref)}
                >
                    <div className="label">{label}</div>
                    <span className="arrow" disabled={disabled}>{isOpen ? "\u25B2" : "\u25BC"}</span>
                </div>
                <div className="displayArea">
                    {isOpen && (
                        <div
                            className="children"
                            ref={ref => (this.displayAreaRef = ref)}
                        >
                            {children}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default DropDown;