
import {useAPI} from "./api/v2/apiV2";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    ErrorDiv,
    LoginBodyDiv,
    LoginButtonDiv,
    LoginContent,
    LoginHeaderDiv,
    LoginMain, TwoFactorAuthDiv,
    VersionContent
} from "./login.styles";
import logo from "../images/newLogoColored.png";
import project from "../../package.json";
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import AuthService from "./AuthService";
import styles from "./login.module.css";
import {Button} from "@mui/material";
import LoadingSpinner from "./uiElements/LoadingSpinner";
import {useUserService} from "./services/userService/UserServiceProvider";
import {LANG, useLanguageService} from "./language/LanguageProvider";
import {useLocation, useNavigate} from "react-router-dom";

const LoginPageTabTypes = ["Login_Old", "Reset", "2FA"]
function LoginPage(props){

    // const [cookie, setCookie] = useCookie("jwt", "");
    const api = useAPI();
    const userService = useUserService();
    const {changeLanguage, strings} = useLanguageService();
    const location = useLocation();
    const navigate = useNavigate();


    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [activeTab, setActiveTab] = useState(LoginPageTabTypes[0])

    const [totpRequired, setTotpRequired] = useState(false)

    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState("");

    const redirectRef = useRef(null);

    const resendTotp = () => {
        api.authenticate(email, password).then((result) => {
            console.log("Result: ", result)
            console.log("Checking AuthService", AuthService.getUser())
        }).catch((err) => {
            //Nothing to do, Error-Msg already handles by ApiProvider
            console.table(err)
        })
    }

    const handleChange = (event) => {
        switch (event.target.id){
            case "email": setEmail(event.target.value); break;
            case "password": setPassword(event.target.value); break;
            default:
        }
    }

    const handleSubmit = (event) => {

        setLoadingText("Authenticate...");
        setLoading(true);
        event.preventDefault();

        //LoginForm
        if(event.target.name === "loginForm"){
            let email = event.target.email.value;
            let password = event.target.password.value;

            api.authenticate(email, password, event).then((result) => {
                // console.log("Result: ", result)
                if(result.message && result.message.totpRequired) {
                    setLoading(false);  //Need to set is false
                    setTotpRequired(true);
                }
            }).catch((err) => {
                //Nothing to do, Error-Msg already handles by ApiProvider
                console.table(err)
                setLoading(false);
                if(err.code === "ERR_NETWORK")
                    toast.warn("Could not connect to Server")
                // else
                //     toast.warn(err.message)
            })
        }
        //2FA Form
        if(event.target.name === "twoFactorAuthForm"){
            api.totp.verifyTotp(event).then((result) => {
                // console.log(result)
                setLoading(false);
            }).catch((error) => {
                console.error(error)
            })
        }
        //Reset PAssword
        if(event.target.name === "forgotPasswordForm"){
            console.log(event.target)
            setLoadingText(strings.SENDING_EMAIL)

            api.forgotPassword(email, event)
            .then((result) => {
                // console.table(result)
                setLoading(false);
                setActiveTab(LoginPageTabTypes[0])
                toast.info(result.message)
            })
            .catch((error) => {
                console.table(error)
                // toast.error(error.response.data.message)
            })
        }

    }

    // useEffect(() => {
    //
    //     if(userService.isUserAuthenticated()){
    //         console.log("USER IS AUTHENTICATED", userService)
    //         console.log("Login..............")
    //         console.log(location)
    //         // navigate("/dashboard")
    //     }
    //
    // }, [userService.isUserAuthenticated(), userService.finalInit])

    useEffect(() => {

        if(redirectRef.current){
            setTimeout(()=>{
                navigate("/dashboard")
            }, 500)
        }

    }, [redirectRef.current]);

    console.log(redirectRef)

    if(userService.isUserAuthenticated()) {

        // console.log("Is LoggedIn? ", userService.isLoggedIn(), userService.getUser(), userService.getToken())
        // api.login(userService.getToken());
        // navigate("/dashboard");
        return(

            <div ref={redirectRef} style={{height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
                <LoadingSpinner title={strings.REDIRECTING} />
            </div>
        )
    }else{
        return(
            <LoginMain>
                <LoginContent>
                    <LoginHeaderDiv>
                        <img src={logo} alt="qgate_logo"/>
                        {/*<img src={"./images/newLogoColored.png"} alt="qgate_logo"/>*/}
                    </LoginHeaderDiv>
                    <LoginBodyDiv>

                        {
                            loading
                                ?
                                <LoadingSpinner title={loadingText}/>
                                :
                                <LoginTabGroup
                                    activeTab={activeTab}
                                    setActiveTab={setActiveTab}
                                    totpRequired={totpRequired}

                                    handleSubmit={handleSubmit}
                                    handleChange={handleChange}
                                    resendTotp={resendTotp}
                                >
                                </LoginTabGroup>
                        }
                    </LoginBodyDiv>

                </LoginContent>
                <VersionContent>
                    <div>
                        <select
                            defaultValue={LANG.en_US}
                            onChange={(elem) => {changeLanguage(elem.target.value)}}>
                            <option value={LANG.en_US}>EN</option>
                            <option value={LANG.de_DE}>DE</option>
                        </select>
                    </div>
                    {
                        (process.env.NODE_ENV !== 'production')
                            ?
                            <a href={"http://" + window.location.hostname + ":8080/beta/docs"}>API-Documentation</a>
                            // <></>
                            :
                            // <a href={"https://" + loc.hostname + ":/api"}>API-Documentation</a>
                            <></>
                    }
                    {/*<p>API-Version: {version}</p>*/}
                </VersionContent>
            </LoginMain>
        )
    }



}

function LoginTabGroup(props){

    const api = useAPI();

    const setLoginTab = () => { props.setActiveTab(LoginPageTabTypes[0]) }
    const setResetTab = () => { props.setActiveTab(LoginPageTabTypes[1]) }
    const set2FactorAuthTab = () => { props.setActiveTab(LoginPageTabTypes[2]) }

    useEffect(()=> {
        if(props.totpRequired){
            set2FactorAuthTab()
        }
    }, [props.totpRequired])

    return(
        <>
            {
                (props.activeTab === LoginPageTabTypes[0])
                    ?
                    <LoginTab
                        // handleSubmit={""}
                        setTab={setResetTab}
                        handleSubmit={props.handleSubmit}
                        handleChange={props.handleChange}
                        // errorMessage={props.errorMessage}
                    />
                    :
                    <></>
            }
            {
                (props.activeTab === LoginPageTabTypes[1])
                    ?
                    <ForgotPasswordTab
                        setTab={setLoginTab}
                        handleSubmit={props.handleSubmit}
                        handleChange={props.handleChange}
                    />
                    :
                    <></>
            }
            {
                (props.activeTab === LoginPageTabTypes[2])
                    ?
                    <TwoFactorAuthTab
                        setTab={() => {
                            setLoginTab();
                            // api.totp.setTotp(false);
                        }}
                        resendTotp={props.resendTotp}
                        handleSubmit={props.handleSubmit}
                        handleChange={props.handleChange}
                    />
                    :
                    <></>
            }
        </>
    )

}

function LoginTab(props){
    const {strings} = useLanguageService();
    return(
        <>
            <div style={{color: 'grey', textAlign: 'center', paddingTop: '5%'}}>
                <p>Login RE:QGate - v{project.version}</p>
            </div>

            <Form onSubmit={props.handleSubmit} name="loginForm">
                <input id="email" type="text" placeholder="E-Mail" onChange={props.handleChange} required/>
                <input id="password" type="password" placeholder={strings.PASSWORD} onChange={props.handleChange} required/>

                {/*<div className={"rememberMeDiv"}>*/}
                {/*    <span>Remember me </span>*/}
                {/*    <input type="checkbox" name="remember" value="remember"/>*/}
                {/*</div>*/}
                <div style={{textAlign: 'right'}}>
                    <a href="#" onClick={() => props.setTab()}>{strings.FORGOT_PASSWORD}</a>
                </div>

                {/*<ErrorDiv>*/}
                {/*    {*/}
                {/*        (props.errorMessage)*/}
                {/*            ? <p>{props.errorMessage}</p>*/}
                {/*            : ""*/}
                {/*    }*/}
                {/*</ErrorDiv>*/}

                <LoginButtonDiv>
                    <input type="submit" value={strings.LOGIN_TEXT}/>
                </LoginButtonDiv>
            </Form>
        </>
    )

}

function TwoFactorAuthTab(props){

    const {strings} = useLanguageService();


    const charField1 = useRef(null);
    const charField2 = useRef(null);
    const charField3 = useRef(null);
    const charField4 = useRef(null);
    const charField5 = useRef(null);
    const charField6 = useRef(null);

    const twoAuthCode = useRef(null);

    const setValue = () => {
        twoAuthCode.current.value =
            charField1.current.value +
            charField2.current.value +
            charField3.current.value +
            charField4.current.value +
            charField5.current.value +
            charField6.current.value;

        twoAuthCode.current.value = twoAuthCode.current.value.toUpperCase();
    }

    const setFocus = (event) => {

        event.target.value = event.target.value.toUpperCase();

        if(event.target.value.length >= 1){
            switch(event.target.id){
                case "char1": charField2.current.focus(); break;
                case "char2": charField3.current.focus(); break;
                case "char3": charField4.current.focus(); break;
                case "char4": charField5.current.focus(); break;
                case "char5": charField6.current.focus(); break;
                case "char6": break;
            }
        }else{
            switch(event.target.id){
                case "char1": break;
                case "char2": charField1.current.focus(); charField1.current.setSelectionRange(0,1); break;
                case "char3": charField2.current.focus(); charField2.current.setSelectionRange(0,1); break;
                case "char4": charField3.current.focus(); charField3.current.setSelectionRange(0,1); break;
                case "char5": charField4.current.focus(); charField4.current.setSelectionRange(0,1); break;
                case "char6": charField5.current.focus(); charField5.current.setSelectionRange(0,1); break;
            }
        }

    }

    const pasteCodeEvent = (event) => {
        let charCode = String.fromCharCode(event.which).toLowerCase();
        if(event.ctrlKey && charCode === 'v') {
            navigator.clipboard.readText().then((result) => {
                pasteCode(result)
            })
        }
        // For MAC we can use metaKey to detect cmd key
        if(event.metaKey && charCode === 'v') {
            navigator.clipboard.readText().then((result) => {
                pasteCode(result)
            })
        }
    }

    const pasteCode = (code) => {

        if(code.length >= 6){
            charField1.current.value = code[0]
            charField2.current.value = code[1]
            charField3.current.value = code[2]
            charField4.current.value = code[3]
            charField5.current.value = code[4]
            charField6.current.value = code[5]
            charField6.current.focus();
        }
        setValue();
    }

    return(
        <React.Fragment>

            <div style={{color: 'grey', textAlign: 'center', paddingTop: '5%'}}>
                {/*<img src={mailSend} width={40} height={40} style={{paddingRight: "10px"}}/>*/}
                <span>{strings.FA_ENABLED_DESCRIPTION}</span>
                <p>{strings.CHECK_MAILBOX}</p>
            </div>
            <Form onSubmit={props.handleSubmit} name={"twoFactorAuthForm"} autoComplete="off">
                <input id="twoAuthCode" ref={twoAuthCode} style={{display: "none"}} />
                <TwoFactorAuthDiv>
                    <input id="char1" type="text" ref={charField1} maxLength={1} onKeyDown={pasteCodeEvent} onChange={setFocus} onInput={setValue} autoFocus={true} required/>
                    <input id="char2" type="text" ref={charField2} maxLength={1} onChange={setFocus} onInput={setValue} required/>
                    <input id="char3" type="text" ref={charField3} maxLength={1} onChange={setFocus} onInput={setValue} required/>
                    <input id="char4" type="text" ref={charField4} maxLength={1} onChange={setFocus} onInput={setValue} required/>
                    <input id="char5" type="text" ref={charField5} maxLength={1} onChange={setFocus} onInput={setValue} required/>
                    <input id="char6" type="text" ref={charField6} maxLength={1} onChange={setFocus} onInput={setValue} required/>
                </TwoFactorAuthDiv>

                <Button onClick={props.resendTotp}>{strings.RESEND_CODE}</Button>

                <LoginButtonDiv>

                    <div
                        className={styles.backButton}
                        onClick={() => props.setTab()}
                    >
                        <i className="fas fa-arrow-left"></i>
                        <a href="#" >Back</a>
                    </div>

                    <input type="submit" value={strings.SUBMIT_CODE}/>
                </LoginButtonDiv>

            </Form>

        </React.Fragment>
    )

}

function ForgotPasswordTab(props){

    const {strings} = useLanguageService();


    return(
        <React.Fragment>

            <div style={{color: 'grey', textAlign: 'center', paddingTop: '5%'}}>
                <p>{strings.FORGOT_PASSWORD_DESCRIPTION}</p>
            </div>
            <Form onSubmit={props.handleSubmit} name={"forgotPasswordForm"}>
                <input id="email" type="email" placeholder="E-Mail" onChange={props.handleChange} required/>

                <LoginButtonDiv>

                    <div className={styles.backButton} onClick={() => props.setTab()}>
                        <i className="fas fa-arrow-left"></i>
                        <a href="#" >Login</a>
                    </div>

                    <input type="submit" value={strings.FORGOT_PASSWORD_CONFIRM}/>
                </LoginButtonDiv>

            </Form>

        </React.Fragment>
    )

}

export default LoginPage;