import React, {Component, useEffect, useMemo, useState} from "react";
import API from "../../../api/api";
import styled from "styled-components";
import moment from "moment";
import {useLocation, useNavigate} from "react-router-dom";
import styles from "./RuleDashboard.module.css"
import classNames from "classnames";
import RulesPage from "./components/RulesPage";
import EnumPage from "./components/EnumPage";

const QualityPage = styled.div`
  width: 100%;
  text-align: center;
  padding: 8px;
  min-height: calc(100vh - 50px);
  p{
    color: grey;
  }
`
const RuleDashboardBottomBar = styled.div`
  margin:16px;

  bottom: 0;
  z-index: 1000;
  a{
    background-color: #4a3d3d;
    color:white;
    margin: 0 8px;
  }
`

export const PAGE = {
    ENUM_PAGE: "ENUM_PAGE",
    RULES_PAGE: "RULES_PAGE"
}

function RuleDashboard(){

    const navigate = useNavigate();
    const location = useLocation();

    const [selectedPage, setSelectedPage] = useState(<EnumPage/>);

    const changePage = (elem) => {
        setSelectedPage(elem)
    }

    //DidMount
    useMemo(() => {
        console.log()
    }, [])

    //ShouldUpdate
    useEffect(() => {
        console.log()
    }, [selectedPage])

    return(
        <>
            {selectedPage}
            <BottomBar changePage={changePage}></BottomBar>
        </>
    )

}





function BottomBar(props){

    const click = (elem) => {
        props.changePage(elem);
    }

    return(
        <RuleDashboardBottomBar className="w3-white w3-padding w3-card"
             >
            <div>
                <a onClick={ () => {click(<RulesPage/>)} }
                   className="w3-bar-item w3-button rulesNavButton pageNavButton"><span>Rules</span></a>
                <a onClick={() => {click(<EnumPage/>)} }
                   className="w3-bar-item w3-button enumNavButton pageNavButton selected"><span>Enumeration</span></a>
            </div>
        </RuleDashboardBottomBar>
    )
}

export default RuleDashboard;