import React, {useEffect, useMemo, useState} from 'react';
import TemplateDiv from "./StyledPages";

import {Form} from "react-bootstrap";
import styled from "styled-components";
import Switch from "react-switch";

import API from "../../api/api";
import async from "async";
import {toast} from "react-toastify";
import {useUserService} from "../../services/userService/UserServiceProvider";
import {useAPI} from "../../api/v2/apiV2";

const Content = styled.div`
  
  display: flex;
  flex-direction: column;
  align-items: center;
  
  div{
    display: flex;
    justify-content: flex-end;
    
    //padding: 10px;
    
  }
`;
const ServiceContent = styled.div`
  padding: 10px;
  
  width: 300px;
  
  span{
    padding-left: 10px;
    padding-right: 10px;
  }
  Switch{
    padding: 10px;
  }
`;
const SettingsDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 12% 8px 12%;  
`;
const ClientSettingDiv = styled.div`
  padding: 0px 0px 0px 0px;
  //margin: 2px 0px 2px 0px;
  border: 1px solid grey;
  
  display: grid;
  grid-template-columns: auto 40%;
    
  div[class="clientInfo"]{
    padding: 8px;
    display: grid;
    grid-template-columns: 40% auto;
    
    label{
      //font-weight: bold;
      width: 200px;
    }
    
  }
  
  div[class="flag_content"]{
    display: flex;
    flex-direction: column;
    background-color: lightgrey;
    padding: 8px;
    max-height: 250px;
    overflow-y: auto;
    
    ::-webkit-scrollbar{
      //display: none;
      display: block;
      
      width: 5px;
      height: 8px;
      background-color: #aaa; /* or add it to the track */
    }
    ::-webkit-scrollbar-thumb {
      background: #000;
    }
    
  }
  
  div[class="labeledSwitch"]{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
`;
const ClientHeader = styled.div`
  width: 150px;
  border-top: 1px solid grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  margin: 10px 0 0px 0;
  padding-left: 8px;
  font-weight: bolder;
`;
function Console(props){

    const {activeRole, user} = useUserService();
    const api = useAPI();

    const [storageService, setStorageService] = useState(false);
    const [monitorService, setMonitorService] = useState(false);
    const [overdueService, setOverdueService] = useState(false);

    const toggleSwitchService = async(checked, event, id) => {

        let data = {};
        switch (id){
            case "storageService": data = {storageService: !storageService, monitorService: monitorService, overdueService: overdueService}; break;
            case "monitorService": data = {storageService: storageService, monitorService: !monitorService, overdueService: overdueService}; break;
            case "overdueService": data = {storageService: storageService, monitorService: monitorService, overdueService: !overdueService}; break;
        }

        api.admin.updateService(data).then(res => {
            setStorageService(res.message.storage.enabled);
            setMonitorService(res.message.monitor.enabled);
            setOverdueService(res.message.overdue.enabled);
        })

        // if(id === "storageService"){
        //     api.admin.updateService({storageService: !storageService, monitorService: monitorService}).then(res => {
        //         setStorageService(res.message.storage.enabled);
        //         setMonitorService(res.message.monitor.enabled);
        //         setOverdueService(res.message.overdue.enabled);
        //     })
        // }else if(id === "monitorService"){
        //     api.admin.updateService({storageService: storageService, monitorService: !monitorService}).then(res => {
        //         setStorageService(res.message.storage.enabled);
        //         setMonitorService(res.message.monitor.enabled);
        //         setOverdueService(res.message.overdue.enabled);
        //
        //     })
        // }else if(id === "overdueService"){
        //     api.admin.updateService({storageService: storageService, monitorService: monitorService, overdueService: !overdueService}).then(res => {
        //         setStorageService(res.message.storage.enabled);
        //         setMonitorService(res.message.monitor.enabled);
        //         setOverdueService(res.message.overdue.enabled);
        //     })
        // }
    }

    const deleteUploads = async() => {
        api.admin.deleteUploads().then(res => {
            if(res.status === 200)
                toast.success(res.data.message, {autoClose: 500})
        })
    }

    /**
     * Fetches the Services Monitor and Storage
     */
    const getFeatures = () => {
        api.admin.getServices().then(res => {
            if(res.status === 200) {
                setStorageService(res.message.storage.enabled);
                setMonitorService(res.message.monitor.enabled);
                setOverdueService(res.message.overdue.enabled);
            }else{
                toast.warn("Error in requesting Services")
            }
        }).catch(err => {
            console.error(err)
        })
    }

    useMemo(()=>{
        getFeatures();
    }, [])

    // useEffect(() => {
    //     return () => {}
    // }, [])

    return(
        <TemplateDiv>
            <h1>Admin Console</h1>
            <p>A Service Console to enable or disable Services</p>

            <Content>
                <ServiceContent>
                    <span>Storage Service</span>
                    <div>
                        <Switch
                            checked={storageService}
                            onChange={toggleSwitchService}
                            onColor="#86d3ff"
                            onHandleColor="#ffffff"
                            handleDiameter={16}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={24}
                            width={40}
                            className="react-switch"
                            id="storageService"
                        />
                        <span>{(storageService) ? "Enabled" : "Disabled"}</span>
                    </div>
                </ServiceContent>
                <ServiceContent>
                    <span>Monitor Service</span>
                    <div>
                        <Switch
                            checked={monitorService}
                            onChange={toggleSwitchService}
                            onColor="#86d3ff"
                            onHandleColor="#ffffff"
                            handleDiameter={16}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={24}
                            width={40}
                            className="react-switch"
                            id="monitorService"
                        />
                        <span>{(monitorService) ? "Enabled" : "Disabled"}</span>
                    </div>
                </ServiceContent>
                <ServiceContent>
                    <span>Overdue file deletion</span>
                    <div>
                        <Switch
                            checked={overdueService}
                            onChange={toggleSwitchService}
                            onColor="#86d3ff"
                            onHandleColor="#ffffff"
                            handleDiameter={16}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={24}
                            width={40}
                            className="react-switch"
                            id="overdueService"
                        />
                        <span>{(overdueService) ? "Enabled" : "Disabled"}</span>
                    </div>
                </ServiceContent>
                <ServiceContent>
                    <span>Delete all uploaded files</span>
                    <button onClick={deleteUploads}>Delete Files</button>
                </ServiceContent>
            </Content>

            {
                (activeRole.role === "SUPERUSER") ? <ClientSettingsPanel/> : <></>
            }
        </TemplateDiv>

    )

}
function ClientSettingsPanel(props){

    const {activeRole} = useUserService();
    const {admin} = useAPI();

    const [clientSettings, setClientSettings] = useState([]);

    const getInstanceSettings = () =>  {
        admin.getSettings().then(res => {
            if(res.status === 200)
                setClientSettings(res.message)
        }).catch(err => {
            console.error(err)
        })
    }

    useMemo(()=>{
        if(activeRole.role === "SUPERUSER")
            getInstanceSettings()

    }, [])

    return(
        <SettingsDiv>
            <hr/>
            <h4>Client Instance Settings</h4>
            {
                (clientSettings)
                    ?
                    clientSettings.map((client, key) => (
                        <ClientSettings key={key} client={client}/>
                    ))
                    :
                    <></>
            }
        </SettingsDiv>
    )

}
function ClientSettings(props){

    const [client, setClient] = useState(props.client);
    const {admin} = useAPI();
    const extractLabelTextFromKey = (key) => {
        key = key.charAt(0).toUpperCase() + key.slice(1);
        key = key.split('_')
        key = key.join(' ')
        return key;
    }

    const toggleSwitchSetting = (clientId, settings, field_name, value, bla) => {

        settings[field_name] = value;
        admin.updateSettings(settings).then((res) => {
            if(res.status === 200){
                setClient({...client, instance_settings:res.message})
            }
        }).catch((error) => {
            console.error(error)
        })
    }

    return(
        <>
            <ClientHeader className="clientHeader">{client.name}</ClientHeader>
            <ClientSettingDiv>
                <div className="clientInfo">
                    <label>Client Name</label><span>{client.id}</span>
                    <label>Contract Person</label><span>{client.contact_person}</span>
                    <label>Contract Person Email</label><span>{client.contact_person_email}</span>
                    <label>Base Table</label><span>{client.instance_settings.base_table}</span>
                    <label>Enabled Featues</label>
                    <ul style={{paddingInlineStart: "20px"}}>
                        {
                            client.instance_settings.enabled_features.map((featureName, key) => {
                                return <li key={key}>{featureName}</li>
                            })
                        }
                    </ul>

                </div>
                <div className="flag_content">

                    {
                        Object.entries(client.instance_settings).map((entry) => {
                            let key = entry[0];
                            let value = entry[1];
                            if(typeof value === 'boolean'){
                                return(
                                    <div className="labeledSwitch" key={key}>
                                        <label>{extractLabelTextFromKey(key)}</label>
                                        <Switch
                                            checked={value}
                                            onChange={() => toggleSwitchSetting(client.id, client.instance_settings, key, !value)}
                                            onColor="#86d3ff"
                                            onHandleColor="#ffffff"
                                            handleDiameter={16}
                                            uncheckedIcon={false}
                                            checkedIcon={false}
                                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                            height={24}
                                            width={40}
                                            className="react-switch"
                                            id="storageService"
                                        />
                                        {/*<span>{(storageService) ? "Enabled" : "Disabled"}</span>*/}

                                    </div>
                                )
                            }
                        })
                    }
                </div>

            </ClientSettingDiv>
        </>


    )

}

export default Console;