import styled from "styled-components";
import {FilterDiv, FilterSelectionDiv} from "../../../../uiElements/filter/Filter.styles";

export const ExceptionActionButtons = styled.div`

  display: flex;
  justify-content: flex-start;
  gap: 20px;

  > button {
    border: unset;
    display: inline-block;
    padding: 6px 12px;
    width: 120px;
    color: #fff !important;
    transition: 0.2s;
    cursor: pointer;

    i {
      //padding-right: 6px;
    }

    span {
      padding-left: 6px;
    }
  }

  button[class*="disable"]{
    //pointer-events: none;
    opacity: 0.2;
  }
  button:disabled:hover{
    opacity: 1 !important;
  }
  
  button[class*="exception"] {
    background-color: unset !important;
    //color: rgba(76, 175, 80, 1) !important;
    width: max-content;
  }

  button[class*="approve exception"] {
    color: rgba(76, 175, 80, 1) !important;
  }

  button[class*="approve exception"]:hover {
    //color: rgba(76, 175, 80, 0.5) !important;
    opacity: 0.6;
  }

  button[class*="reject exception"] {
    color: rgba(244, 67, 54, 1) !important;
  }

  button[class*="reject exception"]:hover {
    //color: rgba(244, 67, 54, 0.5) !important;
    opacity: 0.6;
  }

  button[class^="approve"] {
    background-color: rgba(76, 175, 80, 1);
  }

  button[class^="approve"]:hover {
    background-color: rgba(76, 175, 80, 0.5);
  }

  button[class^="reject"] {
    background-color: rgba(244, 67, 54, 1);
  }

  button[class^="reject"]:hover {
    background-color: rgba(244, 67, 54, 0.5);
  }
`;

export const VertHr = styled.hr`
  height: 100%;
  border-left: thick solid #ff0000;
`;

export const OverdueStatusSpan = styled.span`
      content: '${props => (props.daysLeft)}';
      background-color: ${props => (props.status === "NOT_OVERDUE" ? "green" : (props.daysLeft === 0) ? "orange" : "red")};
      // background-color: ${props => (props.daysLeft === 0) ? "orange" :  (props.daysLeft > 0) ? "red" : "green"};
      color: white;
      font-weight: bold;
      padding: 4px;
      border-radius: 5px;

    `;

export const StatusDiv = styled.div`
      background-color: ${(props) => props.backgroundColor};
      padding: 4px;
      font-weight: 550;
      border-radius: 5px;
      color: white;
      text-align: center;
      display: flex;
      flex-direction: column;
    `;

FilterDiv.FilterSelectionDiv = FilterSelectionDiv;

export const ManagerContainer = styled.div`
  height: 2em;
  widht: 200px;
  position: relative;
  transition: all 1s;

  &:has(.clicked) {
    z-index: 10
  }

  .label {
    padding: 0px 16px 16px 16px;
    border: 1px solid #dddddd;
    border-radius: 32px;
    //text-align: center;
    max-width: 200px;

    max-height: 1.5em;
    overflow-y: hidden;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    background-color: white;


  }

  .label::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  .label:hover {
    border: 1px solid #aaaaaa;
    cursor: pointer;
  }

  .label.clicked {
    position: absolute;
  }

  .clicked {
    max-width: unset;
    max-height: unset;
    transition: all 1s;
    z-index: 100
  }

  .transition {
    transition: all 1s;
  }
`

export const TableSortContainerHead = styled.span`
  cursor: pointer;
  white-space: nowrap;

  &.rotate {

  }

  & > i {
    margin-left: 4px;
    transition: 0.4s;
    opacity: 0;
  }

  &:hover > i {
    opacity: 1;
  }

  & > i.selected {
    opacity: 1;
  }

  & > i.rotate {
    transform: rotate(180deg)
  }
`

export const PropertyOverviewContentDiv = styled.div`

  width: 100%;
  padding: 8px;
  
  h1 {
    text-align: center;
  }

`;

export const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 10000;
  
  background-color: transparent;
`;