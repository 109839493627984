import React, {useState} from 'react';
import {EventComponent} from "../../utils/EventComponent";
import {saveAs} from "file-saver";

import ExcelJS from 'exceljs';


class ChangeReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            changeReportData: null,

            events: EventComponent.getInstance()
        }

    }

    componentDidMount() {
        this.state.events.subscribe(this.state.events.Topics.changeReport.CHANGE_REPORT_DATA, this.changeReportData);
    }

    componentWillUnmount() {
        this.state.events.unsubscribe(this.state.events.Topics.changeReport.CHANGE_REPORT_DATA, this.changeReportData);
    }

    async changeReportData(data) {
        let file_names = [...new Set(data.map(item => item.file_name))]
        for (const fileName of file_names) {
            let workbook = new ExcelJS.Workbook();
            let tableNames = [...new Set(data.filter(x => x.file_name == fileName).map(item => item.table_name))]
            tableNames.forEach(tableName => {
                let tdata = data.filter(x => x.table_name == tableName && x.file_name == fileName)
                let td = []
                td[0] = []


                tdata.forEach(x => {
                    if (td[x.line_number] == undefined)
                        td[x.line_number] = []
                    td[x.line_number][x.column_number - 1] = !x.change_detected? x.current_value:x.current_value+" | Change detected - Previous Value: "+x.previous_value
                    td[0][x.column_number - 1] = x.column_name
                })
                td = td.filter(n => n)
                let worksheet = workbook.addWorksheet(tableName);
                worksheet.addRows(td)
                //let worksheet = XLSX.utils.aoa_to_sheet(td)
                //XLSX.utils.book_append_sheet(workbook, worksheet, tableName);

            })
            workbook.eachSheet((ws)=>{
                ws.eachRow(r=>{
                    r.eachCell(c=>{
                        if(typeof c === 'string')
                            if(c.value.indexOf("| Change detected - Previous Value:")>0)
                                c.fill = {
                                    type: 'pattern',
                                    pattern:'solid',
                                    fgColor:{argb:'FFFFFF00'}
                                };
                    })
                })
            })
            workbook.eachSheet(sheet=> {
                let cols_to_delete = []
                sheet.columns.forEach(column=>{
                    let column_empty = true;
                    column.eachCell(cell=>{
                        if(cell.value != null)
                            column_empty = false;
                    })
                    if(column_empty)
                        cols_to_delete.push(column.number);
                })
                cols_to_delete.forEach((ci,i)=>{
                    sheet.spliceColumns(ci-i,1)
                })
            })




            // console.log(workbook)
            const buffer = await workbook.xlsx.writeBuffer();
            const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            const fileExtension = '.xlsx';

            const blob = new Blob([buffer], {type: fileType});

            saveAs(blob, fileName.replaceAll('.csv',fileExtension));

        }
    }

    render() {
        return <></>
    }

}

export default ChangeReport;