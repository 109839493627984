import TemplateDiv from "../StyledPages";
import project from "../../../../../package.json";
import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { ocean } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// import {IconButton} from "@mui/material";
// import TextField from '@material-ui/core/TextField';
import IconButton from '@mui/material/IconButton';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {toast} from "react-toastify";
import {TextField} from "@mui/material";
import {useAPI} from "../../../api/v2/apiV2";
import {useUserService} from "../../../services/userService/UserServiceProvider";

/**
 * Page for API-User
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function KeyPage(props){

    // const {token} = useAPI();

    const userService = useUserService();

    // let authService = AuthService;

    let apiKey = userService.getUser().keys[0].apikey;

    const views = {
	Python: 	<CodeSample 	apiKey={apiKey} language={"Python"} />,
	// JavaScript: 	<CodeSample 	apiKey={apiKey} language={"JavaScript"} />,
	// Php: 		<CodeSample 	apiKey={apiKey} language={"Php"} />,
    };

    const [selectedKey, setSelectedKey] = useState('Python');

    // console.log(props)
    // console.log(authService.getUser());

    const setView = (key) => {
	console.log(key)
	setSelectedKey(key)
    }

    return(
	<TemplateDiv style={{textAlign: 'unset', display: 'flex', flexDirection: 'column', padding: "10px"}}>

	    <label>You API-Key:</label>
	    <TextField value={apiKey} size="small" variant="outlined"></TextField>
	    <br/>
	    <p style={{color: "grey"}}>This Token is only valid for the current Client. This Token was created and signed by RE:QGate</p>
	    <br/>

	    {/*<div style={{display: "flex", flexDirection:'row', gap: "20px", backgroundColor: "grey", width: "max-content", padding: "10px"}}>*/}
		{/*{Object.keys(views).map( (key) => (*/}
		{/*    <IconButton style={{color: "white", fontSize: "16px !important"}} key={key} onClick={() => setView(key)}>*/}
		{/*    	<span>{key}</span>*/}
		{/*    </IconButton>*/}
		{/*))}*/}
	    {/*</div>*/}
	    {views[selectedKey]}

	    {/*<CodeSample apiKey={authService.getUser().keys[0].apikey} language={"Python"}/>*/}
	    {/*<div style={{display: "block"}}>*/}

	    {/*</div>*/}


	</TemplateDiv>
    )
}

function CodeSample(props){
    // console.log(props)

    let sampleCode;
    switch (props.language) {
	case "Python" : sampleCode = pythonCode(props.apiKey); break;
	case "JavaScript": sampleCode = javascriptCode(props.apiKey); break;
	case "Php": sampleCode = phpCode(props.apiKey); break;
	default: pythonCode(props.apiKey);
    }

    const [code, setCode] = useState(sampleCode);

    const handleCopy = () => {
	navigator.clipboard.writeText(code);
	toast.info("Code Copied")
    };

    return (
	<div style={{maxWidth: "calc(100vw - 250px - 20px)"}}>

	    <div style={{backgroundColor: "grey", display: "flex", alignItems: "center", justifyContent: "space-between"}}>
		<IconButton size="medium" style={{color: "white"}}>
		    <span>{props.language}</span>
		</IconButton>
		<IconButton size="medium" onClick={handleCopy} style={{color: "white", gap: "10px", alignSelf: "right"}}>
		    <FileCopyIcon />
		    <span>Copy code</span>
		</IconButton>
	    </div>
	    <SyntaxHighlighter language={props.language.toLowerCase()} style={ocean}>
		{code}
	    </SyntaxHighlighter>
	</div>
    );
}

let url = window.location.origin+project.backendURI+"/v1/checkdata";

let pythonCode = (apiKey) =>
    `
import requests
import json

url = "${url}"

#Example data
payload = json.dumps({
        "Period": [
            {
                "identifier": "A-1",
                "from": "2021-09-01T00:00:00.000Z",
                "to": "2021-09-30T00:00:00.000Z",
                "period_type": "XXX",
                "value_type": "dgfgdsg",
                "value_type_name": "monatliche Datenlieferung"
            }
        ]
    })
    
#Setting headers with Bearer Auth-Token
#Setting content-type with application/json, later application/xml will be possible too
headers = {
  'Authorization': 'Bearer ${apiKey}',	
  'Content-Type': 'application/json',
  'Format': 'GIFSTANDARD'
}

response = requests.request("POST", url, headers=headers, data=payload)

print(response.text)

    `;

let javascriptCode = (apiKey) => `
const JWT = "${apiKey}"; // Your ApiKey
const API_URL = "${url}";

const requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    "Authorization": "Bearer ${apiKey}"
  }
};

fetch(API_URL, requestOptions)
  .then(response => response.json())
  .then(data => console.log(data))
  .catch(error => console.error(error));
`;

let phpCode = (apiKey) =>
    `
    <?php

	$jwt = "${apiKey}"; // Your ApiKey
	$apiUrl = "${url}";
	
	$options = [
	  "http" => [
	    "method" => "POST",
	    "header" => [
	      "Content-Type: application/json",
	      "Authorization: Bearer " . $jwt
	    ]
	  ]
	];
	
	$context = stream_context_create($options);
	
	$response = file_get_contents($apiUrl, false, $context);
	$responseData = json_decode($response);
	
	print_r($responseData);
    ?>
    `;

export default KeyPage;