//Tab-Stylings
import styled from "styled-components";

export const Tab = styled.button`
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
  opacity: 0.6;
  background: white;
  border: 0;
  outline: 0;
  width: 100%;
  ${({ active }) =>
    active &&
    `
    border-bottom: 2px solid black;
    opacity: 1;
  `};
`;

const TabDescription = styled.div`
  padding: 20px;
`;
Tab.Description = TabDescription;

export const FeatureCards = styled.div`
`;

export const FeatureCardsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  //width: 100%;
`;


FeatureCards.Grid = FeatureCardsGrid;

export const FeatureCard = styled.div`
  
  //background-color: transparent;
  border: 2px solid ${props =>{return props.theme.palette.primary}};
  border-radius: 6px;
  
  :hover{
    //border: 2px solid ${props => {return props.theme.selection}};
    box-shadow: 2px 2px 10px;
  }
  
  h2{
    top: 0;
    left: 0;
    font-size: 20px;
  }
`;

const FeatureCardContent = styled.div`

  height: 100px; 
  width: 100%;
  padding: 18px;
  
  display: flex;
  align-items: center;
  
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  
`;
FeatureCard.Content = FeatureCardContent; //Adds the Content styled Div to FeatureCard

export const ButtonGroup = styled.div`
  
  display: flex;
  border-bottom: 1px solid lightgrey;
  height: 80px;
  
  @media (max-width: 1050px) {
    button{
      font-size: 14px;
    }
  }
  
`;