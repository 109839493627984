import React from "react";
import moment from "moment/moment";

import {
    StepContainer,
    StepCount,
    StepsLabelContainer,
    StepStyle,
    StepWrapper,
    StepLabel,
    OverlayDiv, UploadWarningsDiv, Fieldset
} from "./UIElements.styles";

import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {useNavigate} from "react-router-dom";
import {convertDate} from "../../../utils/GlobalUtils";
import {Button} from "@mui/material";
import {StyledExportButton} from "../../../pages/data_export/DataExport.styles";
import {useAPI} from "../../../../api/v2/apiV2";
import {StreamType} from "../../../utils/Constants";

/**
 * PROPERTY_AWAIT_EXCEPTION_APPROVAL - Notification UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function AwaitApprovalNotification(props){

    const navigate = useNavigate();

    const notification = props.notification;
    const property = notification.data.property;
    const property_status = notification.data.property_status;

    const openLink = (notification) => {
        if(notification.data.search_query){
            navigate({
                pathname: "/dashboard/overview",
                search: "?property="+notification.data.search_query
            })
        }else{
            navigate("/dashboard/overview")
        }
    }

    const getMessage = (notification) => {
        let property = notification.data.property;
        switch (notification.data.property_status){
            case "AWAIT_EXCEPTION_APPROVAL":
                return <label>Property manager <span style={{fontWeight: "bold"}}>{property.changed_by}</span> has set property for review</label>
            case "REJECTED_BY_USER":
                return <label>Asset manager <span style={{fontWeight: "bold"}}>{property.changed_by}</span> rejected property</label>
            case "REJECTED_BY_SYSTEM":
                return <label>Our system rejected a property</label>
            case "APPROVED_BY_USER":
                return <label>Asset manager <span style={{fontWeight: "bold"}}>{property.changed_by}</span> approved property</label>
            case "APPROVED_BY_SYSTEM":
                return <label>Our system approved a property</label>
        }
    }

    return(
        <div style={{display: "flex", flexDirection: "column", gap: "6px"}}>
            <div style={{textAlign: "center"}}>{notification.message}</div>
            <div style={{display: "flex", flexDirection: "column", fontSize: "0.9em", gap: "6px"}} >
                {getMessage(notification)}
                <Fieldset status={property_status}>
                    <legend>Infos</legend>
                    <table style={{width: "100%"}}>
                        <colgroup>
                            <col style={{width: "33%"}}/>
                            <col style={{width: "33%"}}/>
                            <col style={{width: "33%"}}/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Property</th>
                            <th>Period</th>
                            <th style={{textAlign: "center"}}>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{property.name}</td>
                            <td>{convertDate(property.period)}</td>
                            <td>
                                <button
                                    className={"review"}
                                    style={{textAlign: "center"}}
                                    onClick={() => openLink(notification) }
                                >Review</button></td>
                        </tr>
                        </tbody>
                    </table>
                </Fieldset>
            </div>

        </div>
    );
}

/**
 * UPLOAD - Notification UI
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function UploadNotification(props){

    const notification = props.notification;

    const STATUS = [
        {status: "UPLOADED"},
        {status: "PROCESSING"},
        {status: "DONE"}
    ]
    for(const [index, [, value]] of Object.entries(notification.data.status_history).entries()){
        if(value.status === "READY_FOR_PROCESS") value.status = "UPLOADED";
        STATUS[index] = value;
    }

    const width = `${(100 / (STATUS.length - 1)) * (notification.data.status_history.length-1)}%`

    const currentStatus = STATUS[notification.data.status_history.length-1];
    const currentStep = notification.data.status_history.length-1;



    return(
        <>
            <div style={{textAlign: "center"}}>{notification.message}</div>

            <UploadWarnings report_warnings={notification.data.report_warnings ? notification.data.report_warnings : []} />

            <StepContainer width={width}>
                {
                    STATUS.map((status_entry, step) => (
                        <StepWrapper key={step}>
                            <StepStyle
                                step={currentStep >= step ? 'completed' : 'incomplete'}
                                status={status_entry.status}
                            >
                                {
                                    (currentStep > step || currentStep+1 === 3 & status_entry.status === "DONE") ? ( <CheckIcon /> ) :
                                        (currentStep > step || currentStep+1 === 3 & status_entry.status === "ERROR") ? (<PriorityHighIcon />) :
                                            ( <StepCount>{step}</StepCount>)
                                }
                            </StepStyle>
                            <StepsLabelContainer>
                                <StepLabel>{status_entry.status.toLowerCase()}</StepLabel>
                                {
                                    status_entry.timestamp
                                        ? <StepLabel style={{fontSize: "0.7em"}}>{moment(status_entry.timestamp).format("DD.MM / HH:mm:ss")}</StepLabel>
                                        : <StepLabel> </StepLabel>
                                }
                            </StepsLabelContainer>
                        </StepWrapper>
                    ))
                }
            </StepContainer>

        </>
    )
}

export function ExportNotification(props){

    const notification = props.notification;

    let latest_status_history = notification.data.status_history[notification.data.status_history.length-1];
    // console.log("Notification", notification)


    let notificationMessageText = "Generating Export"
    if(latest_status_history.status === "DONE") {
        notificationMessageText = "Export ready for download"
    }
    if(latest_status_history.status === "ERROR") {
        notificationMessageText = "Export failed"
    }
    //            <UploadWarnings report_warnings={notification.data.uploadWarningText ? notification.data.report_warnings : []} />
    let exportId = notification.data.export_id;
    const api = useAPI()
    //make Download button do request and download export by exportId
    return(
        <>
            <h5 style={{textAlign: "left"}}>{notificationMessageText}</h5>
            {
                (latest_status_history.status === "DONE")
                    ?
                    <StyledExportButton onClick={() => {api.download(exportId, StreamType.FILE_EXPORT)}}>
                        Download
                    </StyledExportButton>:<></>
            }
        </>
    )
}

export function UploadWarnings(props){

    let report_upload_warnings = props.report_warnings;
    // report_upload_warnings = [];    //TODO: Uncomment when want to show Upload-Warngings in Content
    // return <></>

    if(report_upload_warnings.length > 0){

        return(
            <UploadWarningsDiv>
                {report_upload_warnings.map(warning => (
                    <label>{warning}</label>
                ))}
            </UploadWarningsDiv>
        )

    }else{
        return <></>
    }

}

export function RedirectToReportOverlay(props){

    const navigate = useNavigate();
    const reportUploadId = props.notification.data?.uploadId;

    const navigateToReport = (reportUploadId) => {
        navigate("./inspect?upload="+reportUploadId)
    }

    return(
        <OverlayDiv style={{display: (props.showOverlay) ? "flex" : "none"}} onClick={() => {navigateToReport(reportUploadId)}}>
            <p>Open Report</p>
            <p>ID: {reportUploadId}</p>
        </OverlayDiv>
    )

}