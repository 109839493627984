/* eslint-disable */

import React, {Component} from "react";
import moment from "moment";
import Chart from "chart.js/auto";
import {numberWithCommas, sameObjectArrayByPropertyId} from "../functions";
import API from "../../../../api/api";

class ESGIntensities extends Component{
    constructor(props) {
        super(props);
        this.api = new API();

        this.state = {
            title:"",
            data: this.props.data,
            settings :"",
            unit:this.props.unit,
            value:0,
            cardSide:0
        }

        Object.assign(this.state.settings, JSON.parse(JSON.stringify(props.settings)))
        if(this.props.title!=undefined)
            this.state.title = this.props.title;
    }

    render(){
        return(
            <div className={"w3-col "+this.props.colSetup}>

                    {
                        this.generateContent()
                    }
            </div>
        )
    }
    componentWillUpdate(nextProps, nextState, nextContext) {
        if (
            this.state.data == null || nextState.data == null || nextProps.data == null ||
            !(Object.keys(nextState.data).length == Object.keys(this.state.data).length)
        ||
            this.state.data.buildingData == null || nextProps.data.buildingData == null||
            !(Object.keys(nextProps.data.buildingData).length == Object.keys(this.state.data.buildingData).length)
            ||
            (!sameObjectArrayByPropertyId(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings) && nextProps.settings.selectedBuildings !== undefined)||nextProps.settings.selectedTimestamp !==this.state.settings.selectedTimestamp){
            this.setState({data:this.props.data, settings:  JSON.parse(JSON.stringify(nextProps.settings))})
        }
    }
    content(){
        if(this.state.data === undefined)
            this.state.data = []
        if(this.state.data.buildingData == undefined)
            return ""
        let buildingData = Object.values(this.state.data.buildingData).sort((a,b )=>a.this_year_total_per_sqm>b.this_year_total_per_sqm)
        let buildingData_reversed = Object.values(this.state.data.buildingData).sort((a,b )=>a.this_year_total_per_sqm>b.this_year_total_per_sqm).reverse()

        return <div className="w3-round-xxlarge w3-white w3-container" style={{border:"1px solid" ,borderColor:this.props.color, minHeight:"195px",height:"200px"}}>
            <div className="w3-row-padding intensitiesContainer w3-display-container" style={{height:"200px"}}>
                <div style={{display:this.state.cardSide%2 == 0? "block":"none"}}>
                    <div className={"w3-row-padding"}>
                        <div className="w3-col w3-cell l3 m12 s12 w3-center" style={{color:this.props.color, margin:"55px 0"}}>
                            <i className={this.props.icon+""}  style={{fontSize:"3.2vw"}}></i><br />
                                <span className="w3-xlarge" style={{marginLeft: "-100%", marginRight: "-100%", textAlign: "center"}}>{this.props.title}</span>
                        </div>

                        <div className="w3-col w3-cell l9 m12 s12 w3-center" style={{paddingTop:"30px",height:"195px", position:"relative", color:this.props.color}}>
                            <div>
                                <div><span className={""} style={{fontSize:"3vw", whiteSpace:"nowrap"}} id="waterIntensities">{
                                    <i title ={""+(this.state.data.this_year_total_per_sqm - this.state.data.last_year_total_per_sqm).toFixed(2)+" "+this.state.unit+"/m²"} className={this.state.data.last_year_total_per_sqm>this.state.data.last_year_total_per_sqm?"fa fa-caret-up":"fa fa-caret-down"}></i>
                                } {numberWithCommas(this.state.data.this_year_total_per_sqm.toFixed(2))}</span><br stlye={{lineHeight:"1.2"}} />
                                        <span className="w3-large">{this.state.unit}/m²</span><br /><span>last 12 months</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{display:this.state.cardSide%2 != 0? "block":"none", height:"200px"}}>
                    <table className="w3-table" style={{marginTop:"20px"}}>
                        <thead>
                        <tr style={{color:this.props.color}}>
                            <th className={"w3-right w3-hide-small w3-hide-medium"}></th>
                            <th>Top</th>
                            <th className={"w3-right w3-hide-small w3-hide-medium"}></th>
                            <th>Flop</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={"w3-right w3-hide-small w3-hide-medium"}>{""+((buildingData.length>0)?(""+(buildingData[0].current_year_per_sqm).toFixed(2)+" "+this.state.unit+"/m²"):"")}</td>
                            <td style={{overflowX:"hidden", textOverflow:"ellipsis", maxWidth:"80px", whiteSpace:"nowrap"}}><span>{(buildingData.length>0)?(buildingData[0].building.label?buildingData[0].building.label:buildingData[0].building.address_street):""} </span></td>
                            <td className={"w3-right w3-hide-small w3-hide-medium"}>{""+((buildingData.length>0)?(""+(buildingData_reversed[0].current_year_per_sqm).toFixed(2)+" "+this.state.unit+"/m²"):"")}</td>
                            <td style={{overflowX:"hidden", textOverflow:"ellipsis", maxWidth:"80px", whiteSpace:"nowrap"}}><span>{(buildingData.length>0)?(buildingData_reversed[0].building.label?buildingData_reversed[0].building.label:buildingData_reversed[0].building.address_street):""}</span></td>
                        </tr>
                        <tr>
                            <td className={"w3-right w3-hide-small w3-hide-medium"}>{""+((buildingData.length>1)?(""+(buildingData[1].current_year_per_sqm).toFixed(2)+" "+this.state.unit+"/m²"):"")}</td>
                            <td style={{overflowX:"hidden", textOverflow:"ellipsis", maxWidth:"80px", whiteSpace:"nowrap"}}><span>{(buildingData.length>1)?(buildingData[1].building.label?buildingData[1].building.label:buildingData[1].building.address_street):""}</span></td>
                            <td className={"w3-right w3-hide-small w3-hide-medium"}>{""+((buildingData.length>1)?(""+(buildingData_reversed[1].current_year_per_sqm).toFixed(2)+" "+this.state.unit+"/m²"):"")}</td>
                            <td style={{overflowX:"hidden", textOverflow:"ellipsis", maxWidth:"80px", whiteSpace:"nowrap"}}><span>{(buildingData.length>1)?(buildingData_reversed[1].building.label?buildingData_reversed[1].building.label:buildingData_reversed[1].building.address_street):""}</span></td>
                        </tr>
                        <tr>
                            <td className={"w3-right w3-hide-small w3-hide-medium"}>{""+((buildingData.length>2)?(""+(buildingData[2].current_year_per_sqm).toFixed(2)+" "+this.state.unit+"/m²"):"")}</td>
                            <td style={{overflowX:"hidden", textOverflow:"ellipsis", maxWidth:"80px", whiteSpace:"nowrap"}}><span>{(buildingData.length>2)?(buildingData[2].building.label?buildingData[2].building.label:buildingData[2].building.address_street):""}</span></td>
                            <td className={"w3-right w3-hide-small w3-hide-medium"}>{""+((buildingData.length>2)?(""+(buildingData_reversed[2].current_year_per_sqm).toFixed(2)+" "+this.state.unit+"/m²"):"")}</td>
                            <td style={{overflowX:"hidden", textOverflow:"ellipsis", maxWidth:"80px", whiteSpace:"nowrap"}}><span>{(buildingData.length>2)?(buildingData_reversed[2].building.label?buildingData_reversed[2].building.label:buildingData_reversed[2].building.address_street):""}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class={"w3-display-topright"} style={{paddingTop:"16px", paddingLeft:"16px"}}>
                    <button onClick={()=>{this.setState({cardSide:this.state.cardSide+1})}} class={"w3-button w3-round"} style={{color:"white", backgroundColor:this.props.color}}><i className="fa fa-table" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    }

    generateContent() {
        return <div className = {"w3-container"} style={{minHeight:"100px"}}>
            {this.content()}
        </div>
    }
};
export default ESGIntensities;