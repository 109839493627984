import React, {Component, useEffect, useState} from "react";
import API from "../../../api/api";

import TemplateDiv from "../StyledPages";
import styles from "../../global/css/profile.module.css";

import Switch from "react-switch";
import {toast} from "react-toastify";
import classNames from "classnames";
import {ButtonGroup, FeatureCard, FeatureCards, RoleSelect, Tab} from "./profile.styles";
import {useTheme} from "styled-components";
import {useUserService} from "../../../services/userService/UserServiceProvider";
import {useAPI} from "../../../api/v2/apiV2";
import {deepCompare} from "../../utils/GlobalUtils";
import {Button} from "@mui/material";
import Select from "react-select";

// import roleStyles from '../../global/css/navbar.module.css';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShieldIcon from '@mui/icons-material/Shield';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ExtensionIcon from '@mui/icons-material/Extension';

const ProfileTabTypes_ = [
    {
        name: "Details",
        icon: <AccountCircleIcon/>
    },
    {
        name: "Security",
        icon: <ShieldIcon/>
    },
    {
        name: "Notifications",
        icon: <NotificationsIcon/>
    },
    {
        name: "Features",
        icon: <ExtensionIcon/>
    }
]

const ProfileTabTypes =
    [
        "Details",
        "Security",
        "Notifications",
        "Features",
        // "Settings"
    ]
function Profile(props){

    return(
        <TemplateDiv>
            {/*<h1>Profile</h1>*/}
            <div className={styles.content}>
                <ProfileTabGroup/>
                {/*    <AccountTab user={this.state.user} tokenInputType/>*/}
            </div>

        </TemplateDiv>
    )

}

function ProfileTabGroup(props){

    const [active, setActive] = useState(ProfileTabTypes_[0])
    console.log("active", active)
    return(
        <>
            <ButtonGroup>
                {ProfileTabTypes_.map(category => (
                    <Tab
                        key={category.name}
                        active={active === category}
                        onClick={() => setActive(category)}
                    >
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", cursor: "pointer"}}>
                            {category.icon}
                            <label style={{pointerEvents: "none"}}>{category.name}</label>
                        </div>
                    </Tab>
                ))}
            </ButtonGroup>
            { (active === ProfileTabTypes_[0]) ? <DetailsTab /> : <></> }
            { (active === ProfileTabTypes_[1]) ? <SecurityTab/> : <></> }
            { (active === ProfileTabTypes_[2]) ? <NotificationsTab/> : <></> }
            { (active === ProfileTabTypes_[3]) ? <FeaturesTab/> : <></> }
            {
                (active !== ProfileTabTypes_[3] && active !== ProfileTabTypes_[2])
                ?
                <input form={"profile-form"} type="submit" className={styles.saveChangesButton} value={"Save changes"}></input>
                :
                <></>
            }
        </>
    )
}

function DetailsTab(props){

    const {user, activeRole, storedRole, setDefaultRole, updateDefaultRole} = useUserService();

    const {profile} = useAPI();

    let [tokenInputType, setTokenInputType] = useState("password")
    let [changedUser, setChangedUser] = useState(user)

    console.log(storedRole, activeRole)

    let [selectedRole, setSelectedRole] = useState((storedRole !== null) ? storedRole : activeRole);

    console.log("SelectedRole", selectedRole)

    const togglePassword = (elem) => {
        let typeInput = tokenInputType === 'password' ? 'text' : 'password';
        elem.target.classList.toggle("fa-eye-slash")
        // inputType = typeInput;
        setTokenInputType(typeInput);
    }

    const onChange = (elem) => {
        let fieldToChange = elem.target.getAttribute("name");
        let value = elem.target.value;
        let changedUser_temp = Object.assign({}, changedUser);
        changedUser_temp[fieldToChange] = value;
        setChangedUser(changedUser_temp)
    }

    const onSubmit = event => {
        event.preventDefault();

        if(deepCompare(user, changedUser)){
            toast.info("No changes made to account", {autoClose: 500})
        }else{
            const data = new FormData(event.target);
            let user = {
                email: data.get("email"),
                first_name: data.get("first_name"),
                last_name: data.get("last_name"),
                phonenumber: data.get("phonenumber"),
                new_password: data.get("new_password"),
                confirm_password: data.get("confirm_password"),
            }

            profile.updateDetails(user).then((res) => {
                console.table(res)
                if(res.status === 200)
                    toast.success(res.message)
            }).catch((err) => {
                console.error(err.data.message)
            })
        }
    }

    const handleSelectedRole = (newRole) => {
        setSelectedRole({...selectedRole, role: newRole.role, roleName: newRole.roleName})
    }

    const setNewDefaultRole = () => {
        console.log("Set new Default role")
        if(storedRole)
            updateDefaultRole(selectedRole)
        else
            setDefaultRole(selectedRole)
    }

    return(
        <>

        <form id="profile-form" className={classNames(styles.tabContent, styles.formContent, styles.detailsContent)} onSubmit={onSubmit}>
            {/*<div className={styles.profileContent}>*/}
            <label>Access Token</label>
            <div className={styles.secretInput+ " form-group"}>
                <input type={tokenInputType} value={user.apikey} onChange={() => {}} placeholder={"token"} name={"access_token"} className={"form-control"} style={{display: "initial"}}/>
                <i onClick={togglePassword} className="far fa-eye" name="newPassword"></i>
            </div>

            <label>E-Mail</label>
            <input className={"form-control"} placeholder="E-Mail" type="email" name="email" value={changedUser.email} onChange={onChange}/>

            <label>Name</label>
            <input className={"form-control"} placeholder="Name" name="first_name" value={changedUser.first_name} onChange={onChange}/>

            <label>Last Name</label>
            <input className={"form-control"} placeholder="Last Name" name="last_name" value={changedUser.last_name} onChange={onChange}/>

            <label>Phonenumber</label>
            <input
                className={"form-control"}
                // pattern={"/^\\+?([0-9]{2})\\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/"}
                placeholder="Phonenumber"
                name="phonenumber"
                value={changedUser.phonenumber}
                onChange={onChange}
            />

        </form>

        <div className={classNames(styles.formContent, styles.tabContent)} style={{gridTemplateColumns: "25% 55% auto"}}>
            <label htmlFor={"roles"}>Default Role:</label>
            <div >
                <Select
                    styles={{
                        option: (defaultStyle, state) => ({
                            ...defaultStyle,
                            textAlign: "left"
                        })
                    }}
                    // className={roleStyles.roleSelect}
                    isSearchable={false}
                    isDisabled={(user.roles.length > 1) ? false : true}
                    // value={roleOptions.value}
                    options={user.roles}
                    defaultValue={user.roles.filter((role) => role.role === selectedRole.role)}
                    name="Role Selection"
                    placeholder={(user.roles.length > 0) ? "" : "No Roles available"}
                    onChange={handleSelectedRole}

                    // options={user.roles}
                    // // defaultValue={ (user.roles.) }
                    getOptionLabel={(role) => (role.role ===  (storedRole ? storedRole.role : activeRole.role)) ? role.roleName+" (Current)" : role.roleName}
                    getOptionValue={(role) => role.role}
                ></Select>
            </div>
            {
                (storedRole)
                    ?
                    <Button variant="outlined" disabled={(storedRole.role === selectedRole.role)} onClick={setNewDefaultRole}>Save</Button>
                    :
                    <Button variant="outlined" onClick={setNewDefaultRole}>Set</Button>

            }
        </div>

        </>
    )

}

function SecurityTab(props){

    const {user} = useUserService();
    const api = useAPI();

    let [tokenInputType, setTokenInputType] = useState("password")

    let [oldPassword, setOldPassword] = useState("")
    let [newPassword, setNewPassword] = useState("")
    let [confirmPassword, setConfirmPassword] = useState("")

    let [errorOldPasswordMessage, setOldPasswordMessage] = useState(null)
    let [errorMessage, setErrorMessage] = useState(null);

    let [twoFactorAuthEnabled, setTwoFactorAuthEnabled] = useState(user.twoFactorAuthEnabled)

    const changePassword = (elem) => {

        switch (elem.target.getAttribute("name")) {
            case "password_old": setOldPassword(elem.target.value); break;
            case "password_new": setNewPassword(elem.target.value); break;
            case "password_confirm": setConfirmPassword(elem.target.value); break;
            default: break;
        }
    }

    const showErrorMessage = (error) => {
        let {errorType, message} = error.message;
        // toast.error(error)

        if(errorType === "length"){
            toast.error(message)
        }

        if(errorType === "confirm"){
            setErrorMessage(message)
            setOldPasswordMessage(null)

            setTimeout(()=>{
                setErrorMessage(null)
            }, 2000)

        }else if(errorType === "old"){
            setErrorMessage(null)
            setOldPasswordMessage(message)

            setTimeout(()=>{
                setOldPasswordMessage(null)
            }, 2000)
        }
    }

    const onSubmit = event => {
        const data = new FormData(event.target);
        let passwordChanged = {
            twoFactorAuthEnabled: twoFactorAuthEnabled,
            password_old: data.get("password_old"),
            password_new: data.get("password_new"),
            password_confirm: data.get("password_confirm"),
        }

        event.preventDefault();

        // let api = new API();

        if(
            twoFactorAuthEnabled === user.twoFactorAuthEnabled
            &&
            oldPassword === ""
            &&
            newPassword === ""
            &&
            confirmPassword === ""
        ) {
            return true;
        }else{
            if(
                twoFactorAuthEnabled != user.twoFactorAuthEnabled
                && oldPassword !== ""
                && newPassword === ""
                && confirmPassword === ""
            ) {
                api.profile.update2FA(passwordChanged).then((res) => {
                        if(res.status === 200){
                            toast.success(res.message, {position: toast.POSITION.TOP_RIGHT})
                            // if(response.data.message.twoFactorAuthEnabled)
                            api.logout();
                        }
                    })
                    .catch((error) => {
                        let err = JSON.parse(error.message)
                        showErrorMessage(err)
                    })
            }else{
                api.profile.changePassword(passwordChanged).then((res) => {
                    setErrorMessage(null)
                    setOldPasswordMessage(null)
                    if(res.message.errorType === "none"){
                        toast.success(res.message.message)
                    }else{
                        toast.success(res.message.message)
                    }

                }).catch((error) => {
                    let err = JSON.parse(error.message)
                    showErrorMessage(err)
                })
            }
        }

    }

    const setTwoFactorAuth = (event) => {
        setTwoFactorAuthEnabled(event.target.checked)
    }
    
    return(
        <>
            <form id="profile-form" className={ classNames(styles.tabContent, styles.secretContent) } onSubmit={onSubmit}>

                <div className={classNames(styles.formContent, styles.twoFactorAuth)}>
                    <label>2-FA per Mail</label>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start'}}>
                        <input
                            type={"checkbox"}
                            name={"twoFactorAuthEnabled"}
                            defaultChecked={(user.twoFactorAuthClientEnabled) ? user.twoFactorAuthClientEnabled: twoFactorAuthEnabled}
                            onChange={setTwoFactorAuth}
                            disabled={(props.twoFactorAuthClientEnabled)}/>
                        {
                            (props.twoFactorAuthClientEnabled) ? <span style={{fontSize: 14, color: "lightgrey"}}>Client enabled 2-FA. You cannot disable it.</span> : <></>
                        }
                    </div>

                </div>

                <div className={classNames(styles.oldPasswordContent, styles.formContent)}>
                    <label>Current Password</label>
                    <SecretPasswordInput
                        changePassword={changePassword}
                        placeholder={"Password"}
                        name={"password_old"}
                        value={oldPassword}
                        error={errorOldPasswordMessage}
                        required={(twoFactorAuthEnabled !== user.twoFactorAuthEnabled)}
                    />
                </div>
                <br/>
                <hr/>
                <div className={classNames(styles.changePasswordContent, styles.formContent)}>
                    <label>Password</label>
                    <SecretPasswordInput changePassword={changePassword} placeholder={"New Password"} name={"password_new"} value={newPassword}/>
                    <label>Confirm Password</label>
                    <SecretPasswordInput changePassword={changePassword} placeholder={"Confirm Password"} name={"password_confirm"} value={confirmPassword} error={errorMessage}/>
                </div>
            </form>
        </>

    )

}

function NotificationsTab(props){

    const {user} = useUserService();
    const api = useAPI();
    //TODO: Old profile_settings wont be updated! -> Update or request new profile_settings!!!
    const [notificationSettings, setNotificationSettings] = useState(user.profile_settings);

    useEffect(() => {

        console.log("New Notification-Settings", notificationSettings)

    }, [notificationSettings])

    const updateNotificationType = (notificationType, type, data) => {

        let updateNotificationSettings = Object.assign({}, notificationSettings);
        updateNotificationSettings.notification_types[notificationType][type] = data;

        api.profile.updateSettings(updateNotificationSettings).then(response => {
            console.log(response, response.message)
            setNotificationSettings({...notificationSettings, ...response.message})
        }).catch(err => {
            console.table(err)
            toast.error(err.message)
        })
    }

    const getNotificationTypeHeader = (notificationType) => {
        switch(notificationType){
            case "ACTION_USER_ACTIVATION": return "Account activation";
            case "ACTION_PROPERTY": return "Property status changes";
            case "ACTION_REPORT_UPLOAD": return "Report upload";
            case "ACTION_FILE_EXPORT": return "File export";
        }
    }

    if(notificationSettings) {
        let notificationType
        return (
            <div className={styles.tabContent}>

                {
                    Object.entries(notificationSettings.notification_types).map((notificationType,key) => (
                        <div key={key}>
                            <h3 style={{
                                textTransform: 'capitalize',
                                textAlign: 'left'
                            }}>{getNotificationTypeHeader(notificationType[0]).toLowerCase()}</h3>
                            <div key={notificationType[0]} className={styles.profileContent} style={{
                                display: "grid",
                                gridTemplateColumns: "auto auto",
                                gap: "10px",
                                justifyItems: "center"
                            }}>
                                {
                                    Object.keys(notificationType[1]).map((type, key) => (

                                        <SettingEntry
                                            key={key}
                                            type={type}
                                            notificationType={notificationType}
                                            updateNotificationType={updateNotificationType}
                                        />
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }


            </div>
        )
    }else{
        return(
            <div className={styles.tabContent}>
                <Tab.Description>
                    <p>No notification-settings for this User found.</p>
                </Tab.Description>
            </div>
        )
    }

    //Internal Switch with label
    function SettingEntry(props){

        const [checked, setChecked] = useState(props.notificationType[1][props.type]);

        useEffect(() => {

            let check = props.notificationType[1][props.type];
            // setChecked(check);

        }, [props.notificationType[1][props.type]])

        const updateChecked = (data) => {

            // let settings = [...props.notificationType];
            props.updateNotificationType(props.notificationType[0], props.type, data)
            setChecked(data);
        }

        const extractLabelTextFromKey = (key) => {
            key = key.charAt(0).toUpperCase() + key.slice(1);
            key = key.split('_')
            key = key.join(' ')
            return key;
        }

        return(
            <>
                <label key={props.type}>{extractLabelTextFromKey(props.type.toLowerCase())}</label>
                {/*<input value={props.notificationType[1][props.type]}/>*/}
                <Switch
                    checked={checked}
                    onChange={updateChecked}
                    onColor="#86d3ff"
                    onHandleColor="#ffffff"
                    handleDiameter={16}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={24}
                    width={40}
                    className="react-switch"
                    id="monitorService"
                />
            </>
        )

    }
}

function FeaturesTab(props){

    const theme = useTheme();
    const {activeClient} = useUserService();
    // let theme = {
    //     color: props.roleColor,
    //     selection: "rgba(250,0,7,0.25)"
    // }

    if(activeClient !== null){
        return(
            <div className={styles.tabContent}>
                <Tab.Description>
                    <p>This is a list of all features which are enabled for your account regading your client</p>
                </Tab.Description>
                <FeatureCards.Grid>
                    {
                        (activeClient.instance_settings.enabled_features.map((feature,key) => {
                            return (
                                <FeatureCard key={key}>
                                    <h2>{feature}</h2>
                                    <hr></hr>
                                    {
                                        (feature === "QUALITY")
                                            ?
                                            <FeatureCard.Content>
                                                <p>The Quality Dashboard is enabled for your account</p>
                                            </FeatureCard.Content>
                                            :
                                            (feature === "ANALYZE")
                                            ?
                                            <FeatureCard.Content>
                                                <p>The Analyze Dashboard is enabled for your account</p>
                                            </FeatureCard.Content>
                                            :
                                            (feature === "CHANGE_REPORTING")
                                            ?
                                            <FeatureCard.Content>
                                                <p>You are able to download a Report of changes of previous uploaded reports</p>
                                            </FeatureCard.Content>
                                            :
                                            (feature === "ESG_DASHBOARD")
                                            ?
                                            <FeatureCard.Content>
                                                <p>Dashboard for ESG related topics is actived</p>
                                            </FeatureCard.Content>
                                            :
                                            (feature === "DEMO_CLIENT")
                                            ?
                                            <FeatureCard.Content>
                                                <p>Demo Client activated</p>
                                            </FeatureCard.Content>
                                            :
                                            <></>
                                    }
                                </FeatureCard>


                            )
                        }))
                    }
                </FeatureCards.Grid>
            </div>
        )
    }else{
        return(
            <div className={styles.tabContent}>
                <p>No Features enabled</p>
            </div>
        )
    }



}

/**
 * Customized Secret Password Input Form
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function SecretPasswordInput(props){

    let [inputType, setInputType] = useState("password")
    let [newPassword, setNewPassword] = useState("");

    const togglePassword = (elem) => {
        let typeInput = inputType === 'password' ? 'text' : 'password';
        elem.target.classList.toggle("fa-eye-slash")
        setInputType(typeInput);
    }

    const onChange = (value, input) => {
        setNewPassword(value.target.value)
        props.changePassword(value.target.value)
    }

    let styling = styles.secretInput + " form-group";

    return(
        <div className={styling}>
            <input
                type={inputType}
                value={props.value}
                onChange={props.changePassword}
                placeholder={props.placeholder}
                name={props.name}
                className={"form-control "+ ((props.error) ? styles.error : "") }
                style={{display: "initial"}}
                minLength={8}
                required={props.required}
            />
            <i onClick={togglePassword} className="far fa-eye" name="newPassword"></i>
            {
                props.error && (
                    <span className={"invalid-feedback"} style={{display: "block", textAlign: "left"}}>{props.error}</span>
                )
            }
        </div>
    )
}



export default Profile;