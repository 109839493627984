/* eslint-disable */

import React, {Component} from "react";
import moment from "moment";
import Chart from "chart.js/auto";
import {numberWithCommas, sameObjectArrayByPropertyId} from "../functions";
import API from "../../../../api/api";
import ESGIntensities from "./ESGIntensities";

class ESGIntensityComponsite extends Component{
    constructor(props) {
        super(props);
        this.api = new API();

        this.state = {
            data:{
            },
            title:"",
            settings :"",
            unit:"",
            value:0,
            cardSide:0

        }

        Object.assign(this.state.settings, JSON.parse(JSON.stringify(props.settings)))
        if(this.props.title!=undefined)
            this.state.title = this.props.title;
        this.getIntensitiesData()

    }
    getIntensitiesData(){
        let d = {
            intensityTypes: ["ENERGIE","WASSER", "CO2"],
            selectedBuildings: (this.props.settings.buildings!==undefined)?this.props.settings.selectedBuildings.map(x=>x.propertyId):[],
            timestamp:this.props.settings.selectedTimestamp,
            role:this.props.settings.role
        }
        if(d.selectedBuildings.length==0)
            return ""

        this.api.esg.getIntensitiesData.get(this.api.getCookie("jwt"),d).then(response=>{
            this.setState({data:response.data})
            //this.fillIntensities(response.data,d)
                //this.calculateIntensitiesByYearAndBuilding(response.data.filter(x=>x.meter_type === "CONSUMPTION"),d)
                console.log(response.data)
        })
    }

    render(){
        return(
            <React.Fragment>
            <ESGIntensities color={"#2196F3"} icon = "fas fa-tint" unit={"m³"} type={"WASSER"} colSetup = {"s12 m4 l4"} data = {this.state.data["WASSER"]} settings = {this.props.settings} title = "WATER"></ESGIntensities>
        <ESGIntensities color={"#4CAF50"} icon = "fas fa-leaf" unit={"kg³"} type={"CO2"}  colSetup = {"s12 m4 l4"} data = {this.state.data["CO2"]} settings = {this.props.settings} title = "CO²"></ESGIntensities>
        <ESGIntensities color={"#ffc107"} icon = "fas fa-bolt" unit={"KWH"} type={"ENERGIE"} colSetup = {"s12 m4 l4"} data = {this.state.data.ENERGIE} settings = {this.props.settings} title = "ENERGY"></ESGIntensities>
                </React.Fragment>
    )
    }
    componentWillUpdate(nextProps, nextState, nextContext) {
        console.log(this.state.data, nextState.data)
        if (
            this.state.data == null || nextState.data == null ||
            !(Object.keys(nextState.data).length == Object.keys(this.state.data).length)
            ||
            (
                !sameObjectArrayByPropertyId(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings)
                &&
                nextProps.settings.selectedBuildings !== undefined
            )||
            nextProps.settings.selectedTimestamp !==this.state.settings.selectedTimestamp
        ){
            console.log("here")
            this.setState({settings:  JSON.parse(JSON.stringify(nextProps.settings))})
            this.getIntensitiesData()
        }
    }
};
export default ESGIntensityComponsite;