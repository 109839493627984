import Dexie from 'dexie';

export const browserDB = new Dexie('qgate');

browserDB.version(1).stores({
    // roles: 'id, role, roleName',
    defaultRole: 'id, role, roleName'
})


