import React, {Component} from "react";
import API from "../../../api/api";

// import styled from "styled-components";

import TemplateDiv from "../StyledPages";

class Support extends Component {

    constructor(props) {
        super(props);

        this.api = new API();
    }

    render() {
        return(
            <TemplateDiv>
                <h1>Support</h1>



            </TemplateDiv>
        );
    }
}

export default Support;