import React, {Component} from "react";
import moment from "moment";
import Chart from "chart.js/auto";
import {getColorByType, getIconByType, numberWithCommas} from "../functions";

class ESGConsumptionTile extends Component{
    constructor(props) {
        super(props);
        this.state = {
            title:"",
            settings :""
        }
        this.getGraphData = this.getGraphData.bind(this);
        this.drawGraph = this.drawGraph.bind(this);

        Object.assign(this.state.settings, JSON.parse(JSON.stringify(props.settings)))
        if(this.props.title!=undefined)
            this.state.title = this.props.title;

    }

    getGraphData(data){
        console.log(data,"chartdata")

        let chartData =[]
        return {labels:[0,1,2,3,4,5,6,7,8,9,10,11],datasets:[{

                pointRadius:0,
            lineTension: 0.2,
            borderWidth:1.5,
            data: data.graph
    }]}
    }
    drawGraph(data){
        if(Object.keys(data).length === 0)
            return 0
        let chartData = this.getGraphData(data);
        console.log("getGraphData in:", data)
        console.log("getGraphData out:", chartData)

        let tailingZeroRemaining = false;
        /*while(tailingZeroRemaining){
            if(chartData.datasets[0].data[chartData.datasets[0].data.length-1]===0){
                chartData.datasets[0].data.pop();
                chartData.labels.pop();
            }
            else
                tailingZeroRemaining = false;
        }*/
        let config = {
            type: 'line',
            data: chartData,
            options:{
                maintainAspectRatio: false,
                responsive: true,
                borderColor: getColorByType(this.props.title),
                plugins:{
                    legend:{
                        display: false,
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true,

                        display: false,
                    },

                    x: {
                        ticks: {
                        },
                        display: false,
                    },

                }
            }
        }
        if(document.getElementById("chart"+this.props.title) == undefined) return 0
        this.lineChartCtx = document.getElementById("chart"+this.props.title).getContext('2d')
        if(this.lineChart!== undefined)
            this.lineChart.destroy()

        this.lineChart = new Chart(this.lineChartCtx, config)

        document.getElementById("this_month"+this.props.title).innerText = numberWithCommas(data.currentMonth.toFixed(2))
        document.getElementsByClassName("unit"+this.props.title).forEach(e=>{
            e.innerText = data.unit
        })
        document.getElementById("this_month_date"+this.props.title).innerText = moment.utc(data.currentMonthDate).format("YYYY-MM")
        document.getElementById("ytd"+this.props.title).innerText = numberWithCommas(data.thisYearToDate.toFixed(2))
        document.getElementById("lytd"+this.props.title).innerText = numberWithCommas(data.lastYearToDate.toFixed(2))
        document.getElementById("ytd_production"+this.props.title).innerText = numberWithCommas(data.production.toFixed(2))
        document.getElementById("ytd_production_ratio"+this.props.title).innerText = (data.productionRatio*100).toFixed(0)

    }
componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.prop !== prevProps){

            this.drawGraph(this.props.data)
        }
}

    render(){
        if(this.props.data === undefined)
            return <div>Loading...</div>
        return(
            <div className={"w3-col "+this.props.colSetup}>
                <div style={{position:"relative", height:"316px"}}>
                    <div className={"w3-white w3-card"} style={{width:"70%", borderLeft:"6px solid", borderColor:getColorByType(this.props.title), zIndex:90-(this.props.seq*2), position:"absolute", top:0, paddingBottom:"8px"}}>
                        {
                            this.generateTitle()
                        }

                        {
                            this.generateContent()
                        }
                    </div>
                    <div className={"w3-white w3-card"} onMouseOver={(e)=>{e.target.style.left="25%"}} onMouseOut={(e)=>{e.target.style.left="0%"}} style={{width:"75%", margin:"16px 16px 16px calc(25% - 16px)", position:"absolute", top:0, left:"0%", padding:"16px", height:"216px",transition: "all 0.7s ease-out", zIndex:90-(this.props.seq*2+1)}}>
                        <canvas onMouseOver={(e)=>{e.target.parentElement.style.left="25%"}} onMouseOut={(e)=>{e.target.parentElement.style.left="0%"}} id={"chart"+this.props.title}></canvas>
                    </div>
                </div>
            </div>
        )
    }
    content(){
        window.setTimeout(()=>{
            this.drawGraph(this.props.data)
        },100)

        return                            <div className="w3-row-padding w3-light-grey w3-round-large" style={{padding:"2px"}}>
            <div className="w3-center w3-border w3-border-light-grey w3-white w3-half" style={{height:"100px"}}>
                <div className={"w3-tiny"}><span className="w3-xlarge" id={"this_month"+this.props.title}></span>
                    <br/>
                    <span className={"w3-tiny unit"+this.props.title}></span></div>
                <div>current month</div>
                <div className="w3-tiny"><span id={"this_month_date"+this.props.title}></span></div>
            </div>
            <div className="w3-center w3-border w3-border-light-grey w3-white w3-half" style={{height:"100px"}}>
                <div className={"w3-tiny"}><span className="w3-xlarge" id={"ytd"+this.props.title}></span>
                    <br/>
                    <span className={"w3-tiny unit"+this.props.title}></span></div>
                <div>this year to date</div>
            </div>
            <div className="w3-center w3-border w3-border-light-grey w3-white w3-half" style={{height:"100px"}}>
                <div className={"w3-tiny"}><span className="w3-xlarge" id={"ytd_production"+this.props.title}></span>
                    <br/>
                    <span className={"w3-tiny unit"+this.props.title}></span></div>
                <div>production</div>
                <div className="w3-tiny"><span id={"ytd_production_ratio"+this.props.title}>0</span>% of consumption</div>
            </div>
            <div className="w3-center w3-border w3-border-light-grey w3-white w3-half" style={{height:"100px"}}>
                <div className={"w3-tiny"}><span className="w3-xlarge" id={"lytd"+this.props.title}></span>
                    <br/>
                    <span className={"w3-tiny unit"+this.props.title}></span></div>
                <div>last year to date</div>
            </div>
        </div>
    }
    generateTitle() {
        if(this.props.title !="")
            return <div className = {"w3-panel w3-border-bottom"}>
                <h4><i style={{color:getColorByType(this.props.title)}} className={getIconByType(this.props.title)}></i> {this.props.title}</h4>
            </div>
        return <div></div>
    }
    generateContent() {
        return <div className = {"w3-container"} style={{minHeight:"100px"}}>
            {this.content()}
        </div>
    }
}
export default ESGConsumptionTile;