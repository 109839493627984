/* eslint-disable */

import API from "../../../../api/api";
import React from "react";
import ESGTile from "./ESGTile";
import moment from "moment";
import Chart from "chart.js/auto";
import {getColorBySeqNr, sameObjectArrayById, sameObjectArrayByPropertyId} from "../functions";


class ESGZertifizierungen extends ESGTile{
    constructor(props) {
        super(props);
        this.api = new API();


        //window.setTimeout(this.getCarbonData,500)
    }

    content(){

        if(!this.hasBuildingData() || !this.hasCertificatesData())

            return <div style={{"height": "400px"}} class={"w3-display-container"}>
                <div class={"w3-display-middle"}>
                    <h5>No Certificate Data uploaded.</h5>
                </div>
            </div>
        //if(this.isMultipleBuildings()){
        if(true){
            return <div>
                <div style={{"height":"396px"}} className="w3-container" id={"ZertifizierungenContainer"}>
                    <canvas id={"zertifizierungen"}></canvas>
                </div>
                <div style={{"display":"none","height":"396px"}} className={"w3-center"} id={"EnergieratingContainer"}>
                    <canvas id="Energierating" style={{width:"100%", height:"100%"}}></canvas>
                </div>
                <span className={"w3-tiny w3-right"} style={{visibility: "hidden"}}>Bubble Size represents Building m²</span>

            </div>
        }
    }
    isMultipleBuildings(){
        if(!this.hasBuildingData())
            return false
        return this.props.settings.selectedBuildings.length > 1
    }
    hasBuildingData(){
        return this.props.settings.selectedBuildings !== undefined
    }
    hasCertificatesData(){
        return this.props.settings.selectedBuildings.map(b=>{
            return b.energy_rating_heating_energy_demand_rating!=null||
            b.energy_rating_heating_energy_demand!=null||
            b.energy_rating_primary_energy_consumption_rating!=null||
            b.energy_rating_primary_energy_consumption!=null||
            b.energy_rating_co2_emissions_rating!=null||
            b.energy_rating_co2_emissions!=null||
            b.energy_rating_total_energy_factor_rating!=null||
            b.energy_rating_total_energy_factor!=null||
                b.energy_rating_efficiency_class != null
        }).reduce((p,c)=>p||c,false)
    }

    drawEnergyrating(id,building){
        function drawHorizontalLines(ctx,ratings, settings){
            let segment_height = (settings.height - settings.margin_top) / Object.keys(ratings).length
            ctx.strokeStyle="#e5e9eb"
            ctx.lineWidth = 3;
            Object.keys(ratings).forEach((rating, i)=>{
                ctx.beginPath()
                ctx.moveTo(0, i*segment_height + settings.margin_top)
                ctx.lineTo(settings.width, i*segment_height + settings.margin_top)
                ctx.stroke();
                ctx.moveTo(0, i*segment_height + settings.margin_top + (segment_height/2))
                ctx.lineTo(settings.width, i*segment_height + settings.margin_top + (segment_height/2))
                ctx.stroke();
            })
        }
        function drawVertictalLines(ctx,ratings, settings){

            let remaining_start_x = settings.label_min_width + Object.keys(ratings).length * settings.label_width_increase + ((settings.height - settings.margin_top) / Object.keys(ratings).length / 3)
            let remaining_width = settings.width - remaining_start_x;

            Object.keys(settings.categories).forEach((c,i)=>{
                let cat_count = Object.keys(settings.categories).length;
                let x = remaining_start_x + i * remaining_width / cat_count
                ctx.beginPath()
                ctx.moveTo(x,0)
                ctx.lineTo(x,settings.height)
                ctx.stroke();
            })
            let cat_count = Object.keys(settings.categories).length;

            // draw categories title
            Object.keys(settings.categories).forEach((c,i)=>{
                let x = remaining_start_x + (i + 0.5) * remaining_width / cat_count
                ctx.textAlign = "center";
                ctx.fillStyle = "black";
                ctx.font = "bold 16px Arial";
                ctx.fillText(settings.categories[c].title, x, 24);

                ctx.font = "14px Arial";
                ctx.fillText(settings.building[settings.categories[c].value_key], x, 45);

            })

            // draw rating arrows

            Object.keys(settings.categories).forEach((c,i)=>{
                let x = remaining_start_x + i * remaining_width / cat_count
                let segment_height = (settings.height - settings.margin_top) / Object.keys(ratings).length
                let key = settings.categories[c].rating_key
                let rating_label = settings.building[key]
                let building = settings.building
                let a = i*segment_height + settings.margin_top
                if(Object.keys(settings.ratings).findIndex(x=>x==rating_label)!=-1)
                    roundRectLeft(ctx, x + 5, settings.margin_top+ Object.keys(settings.ratings).findIndex(x=>x==rating_label)*segment_height, remaining_width / cat_count - 10, segment_height, 8, "black", rating_label)

                ctx.textAlign = "right";
                ctx.fillStyle = "white";
                ctx.font = "30px Arial";
                ctx.fillText(rating_label, x + remaining_width / cat_count -10, settings.margin_top+ Object.keys(settings.ratings).findIndex(x=>x==rating_label)*segment_height + 30);

            })


        }
        function roundRect(ctx, x, y, width, height, radius, color, text) {

            if (typeof radius === 'undefined') {
                radius = 5;
            }
            if (typeof radius === 'number') {
                radius = {tl: radius, tr: radius, br: radius, bl: radius};
            } else {
                var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
                for (var side in defaultRadius) {
                    radius[side] = radius[side] || defaultRadius[side];
                }
            }
            ctx.beginPath();
            ctx.moveTo(x + radius.tl, y);
            ctx.lineTo(x + width, y);
            ctx.lineTo(x + width + height/3, y + height/2);

            ctx.lineTo(x + width, y + height);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
            ctx.lineTo(x + radius.bl, y + height);
            ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
            ctx.lineTo(x, y + radius.tl);
            ctx.quadraticCurveTo(x, y, x + radius.tl, y);
            ctx.closePath();

            ctx.fillStyle = color;
            ctx.fill();
            ctx.fillStyle = "white";
            ctx.textAlign = "start";

            ctx.font = "30px Arial";
            ctx.fillText(text, 20, y + height*0.75);


        }

        function roundRectLeft(ctx, x, y, width, height, radius, color, text) {
            let oldwidth = width;
            x = x + oldwidth - width


            if (typeof radius === 'undefined') {
                radius = 5;
            }
            if (typeof radius === 'number') {
                radius = {tl: radius, tr: radius, br: radius, bl: radius};
            } else {
                var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
                for (var side in defaultRadius) {
                    radius[side] = radius[side] || defaultRadius[side];
                }
            }
            radius.bl = 20
            radius.tl = 20
            ctx.beginPath();
            ctx.moveTo(x + radius.tl, y);
            ctx.lineTo(x + width - radius.tr, y);
            ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
            ctx.lineTo(x + width, y + height - radius.br);
            ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
            ctx.lineTo(x + radius.bl, y + height);

            ctx.lineTo(x - oldwidth + width, y + height/2);
            ctx.closePath();

            ctx.fillStyle = color;
            ctx.fill();


        }
        function drawCategories(ctx, ratings, settings){
            let segment_height = (settings.height - settings.margin_top) / Object.keys(ratings).length
            Object.keys(ratings).forEach((rating, i)=>{
                roundRect(ctx, settings.label_margin, i*segment_height + settings.margin_top+1, settings.label_min_width + i * settings.label_width_increase, segment_height-2, 8, ratings[rating], rating)
            })
        }
        let ratings = {
            //"A++": "#5fb959",
            "A+": "#7fc356",
            "A": "#b2d44c",
            "B": "#e2dd37",
            "C": "#f9db21",
            "D": "#f9ba2f",
            "E": "#f57f35",
            "F": "#ef3b2d",
            "G": "#eb2830",
            "H": "#b71f25",
        }
        let canvas = document.getElementById(id)
        let ctx = canvas.getContext("2d");
        canvas.width = canvas.clientWidth;
        canvas.height = canvas.clientHeight;
        let settings = {
            building: building,
            ratings :ratings,
            width: canvas.width,
            height: canvas.height,
            label_margin: 10,
            label_min_width:80,
            label_width_increase: 20,
            categories:{
                HED:{
                    title:"HED",
                    subtitle: "asdf",
                    rating_key: "energy_rating_heating_energy_demand_rating",
                    value_key:"energy_rating_heating_energy_demand"
                },
                PEC:{
                    title:"PEC",
                    subtitle: "asdf",
                    rating_key:"energy_rating_primary_energy_consumption_rating",
                    value_key:"energy_rating_primary_energy_consumption"
                },
                CO2:{
                    title:"CO2",
                    subtitle: "asdf",
                    rating_key:"energy_rating_co2_emissions_rating",
                    value_key:"energy_rating_co2_emissions"
                },
                TEF:{
                    title:"TEF",
                    subtitle: "asdf",
                    rating_key:"energy_rating_total_energy_factor_rating",
                    value_key:"energy_rating_total_energy_factor"
                }
            },
            seperator_width: 2,
            margin_top: 50

        }
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        drawHorizontalLines(ctx, ratings, settings)
        drawVertictalLines(ctx, ratings, settings)
        drawCategories(ctx, ratings, settings)

    }


    fillCert(selectedBuildings, timeout = 0){
        if(selectedBuildings.length !== 0){
            let labels = ['A+','A','B','C','D','E','F','G','H']
            selectedBuildings.forEach(x=>(x.energy_rating_efficiency_class=="A_1PLUS"?x.energy_rating_efficiency_class="A+":""))
            let types = [...new Set(selectedBuildings.map(x=>x.use_type_primary))]
            let datasets = types.map((x,i)=>{
                let data = selectedBuildings.filter(y=>y.use_type_primary===x).map(y=>y.energy_rating_efficiency_class)
                return {
                    label: x,
                    data: labels.map(y=>data.filter(z=>z===y).length),
                    backgroundColor: getColorBySeqNr(i)
                }
            })

            let data = {
                labels: labels,
                datasets: datasets


            };
            if(document.getElementById('zertifizierungen') != undefined){
                if(selectedBuildings.length == 1){
                    document.getElementById("EnergieratingContainer").style.display = "block"
                    document.getElementById("ZertifizierungenContainer").style.display = "none"
                    this.drawEnergyrating("Energierating",selectedBuildings[0])
                }
                else{
                    document.getElementById("EnergieratingContainer").style.display = "none"
                    document.getElementById("ZertifizierungenContainer").style.display = "block"
                    let ctx = document.getElementById('zertifizierungen').getContext('2d');
                    if(this.certChart !== undefined)    this.certChart.destroy()
                    this.certChart = new Chart(ctx, {
                        type: 'bar',
                        data: data,
                        options: {

                            responsive: true,
                            maintainAspectRatio: false,
                        }
                    });
                }

            }
            else{
                window.setTimeout(()=>{this.fillCert(selectedBuildings, timeout + 1)}, timeout*200)
            }
        }
        if(selectedBuildings.length  == 1){

        }
    }
    componentWillUnmount(){
        if(this.certChart !== undefined)
            this.certChart.destroy()
    }
    componentDidMount() {
        if(this.props.settings !== undefined)
            if(this.props.settings.selectedBuildings !== undefined)
                this.fillCert(this.props.settings.selectedBuildings);

    }


    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.settings.selectedBuildings == undefined)
            return 0
        if (this.state.settings == undefined || !sameObjectArrayByPropertyId(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings)){
            this.setState({settings:  JSON.parse(JSON.stringify(nextProps.settings))})
            this.fillCert(nextProps.settings.selectedBuildings);


        }

    }
}

export default ESGZertifizierungen;