import {useState} from "react";

/**
 * ReactHooks * LocalStorage
 * @param keyName
 * @param defaultValue
 * @returns {[unknown,setValue]}
 */
const useLocalStorage = (keyName, defaultValue) => {
    const [storedValue, setStoredValue] = useState(() => {
	try{
	    const value = window.localStorage.getItem(keyName);

	    if(value){
		return JSON.parse(value);
	    }else{
		window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
		return defaultValue;
	    }
	}catch (e) {
	    return defaultValue;
	}
    })

    const setValue = (newValue) => {
	try{
	    window.localStorage.setItem(keyName, JSON.stringify(newValue));
	}catch (e) {
		console.error(e)
	}
	setStoredValue(newValue);
    }
    return [storedValue, setValue];
}

/**
 * ReactHooks * CookieStorage
 * @param cookieName
 * @param defaultCookie
 * @returns {[unknown,setCookie]}
 */
export const useCookie = (keyName, defaultCookie) => {

    const [storedCookie, setStoredCookie] = useState(() => {

	try{
	    let cookieName = keyName + "=";
	    const cookies = document.cookie.split(";");
	    for(let cookie of cookies){
		cookie = (cookie.charAt(0) === ' ') ? cookie.substring(1,cookie.length) : cookie;
		if(cookie.indexOf(cookieName) === 0) return cookie.substring(cookieName.length, cookie.length);
	    }
	}catch (e) {
	    return defaultCookie;
	}
    })
    const setCookie = (value, expireInDays) => {
	let expires = "";
	if(expireInDays !== undefined){
	    let date = new Date();
	    date.setTime(date.getTime() + (expireInDays*24*60*60*1000));
	    if(expireInDays === 0){
		date.setTime(date.getTime() - 1)
	    }

	    expires = "; expires=" + date.toUTCString();
	}
	document.cookie = keyName + "=" + (value || "")  + expires + "; path=/";

	// try{
	//     window.localStorage.setItem(cookieName, JSON.stringify(newCookie));
	// }catch (e) {}
	setStoredCookie(value);
    }

    const getCookie = () => {
	let cookieName = keyName + "=";
	let value;
	const cookies = document.cookie.split(";");
	for(let cookie of cookies){
	    cookie = (cookie.charAt(0) === ' ') ? cookie.substring(1,cookie.length) : cookie;
	    if(cookie.indexOf(cookieName) === 0) {
		value = cookie.substring(cookieName.length, cookie.length);
	    	return value;
	    }
	}
	return undefined;
    }

    return [getCookie(), setCookie, getCookie];
}