import EnumPage from "./EnumPage";
import {useAPI} from "../../../../api/v2/apiV2";
import React, {useEffect, useMemo, useState} from "react";


function EnumForm(props){

    console.log("enum form",props)

    const api = useAPI();


    return(
        <div className={props.className+" w3-container"}>
            {props.selectedEnum?
        <div className="w3-display-container enumContainer" style={{borderRadius: '16px', marginTop: '16px', width: '100%'}}>
            <div className="w3-display-topright w3-margin-right w3-margin-top">
                <button className="w3-button w3-border w3-round w3-small" style={{marginTop: '-4px', padding: '2px 6px'}}><i className="fa fa-pen"></i></button>
                <button className="w3-button w3-border w3-round w3-small transition" style={{marginTop: '-4px', padding: '2px 6px', opacity: '0'}}><i className="fa fa-plus"></i></button>
            </div>
            <div className="w3-container w3-left" style={{width: '100%'}}>
                <h4 style={{lineHeight: '2', textAlign: 'left'}}><b><input value={props.selectedEnum} className="w3-input w3-border" style={{maxWidth: '600px', opacity: '1', color: '#333'}} disabled /></b></h4>
            </div>
            <div className="w3-row-padding w3-border-bottom" style={{width: '100%'}}>
                <div className="w3-third">
                    <div className="w3-container">
                        <span style={{textAlign: 'left'}}><b>Technical Name</b></span>
                    </div>
                </div>
                <div className="w3-third">
                    <div className="w3-container">
                        <span style={{textAlign: 'left'}}><b>EN</b></span>
                    </div>
                </div>
                <div className="w3-third">
                    <div className="w3-container">
                        <span style={{textAlign: 'left'}}><b>DE</b></span>
                    </div>
                </div>
            </div>
            {props.data[props.selectedEnum].values.map((enumLine, index) => (
                <div key={index} className="w3-row-padding w3-border-bottom" style={{width: '100%'}}>
            <div className="w3-third">
                <div className="w3-container">
                    <input style={{backgroundColor:"#fff", color:"#222", border:0}} value={enumLine.technical_name} className="w3-input " disabled />
                </div>
            </div>
            <div className="w3-third">
                <div className="w3-container">
                    <input style={{backgroundColor:"#fff", color:"#222", border:0}} value={enumLine.en} className="w3-input " disabled />
                </div>
            </div>
            <div className="w3-third">
                <div className="w3-container">
                    <input style={{backgroundColor:"#fff", color:"#222", border:0}} value={enumLine.de} className="w3-input " disabled />
                </div>
            </div>
        </div>
    ))}
</div>:""}
        </div>
    )
}

export default EnumForm;
