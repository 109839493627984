import styled from "styled-components";


export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-top: 10px;
  position: relative;
  
  margin: 0px 10%;
  margin-bottom: 20px;
  
  height: 80px;
  
  :before {
    content: '';
    position: absolute;
    background: #f3e7f3;
    height: 4px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: ${(props) => props.theme.palette.primary};
    height: 4px;
    width: ${(props) => props.width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`

export const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
`

export const StepStyle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  border: 3px solid ${(props) => props.step === 'completed' ? props.theme.palette.primary : '#F3E7F3'};
  background-color: ${({ step, status }) => status !== "ERROR" ? (step === 'completed' ? '#3abe00' : '#F3E7F3') : "#ff0000"};
  color: white;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StepCount = styled.span`
  font-size: 1em;
  color: lightgrey !important;
`

export const StepsLabelContainer = styled.div`
  position: absolute;
  display: grid;
  grid-template-rows: auto auto;
  
  top: 50px;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const StepLabel = styled.span`
  text-transform: capitalize;
  text-align: center;
  width: 80px;
  font-size: 0.7em;
  //color: ${(props) => props.theme.palette.primary};
`

export const OverlayDiv = styled.div`
  position: absolute;
  display: none;
  
  flex-direction: column;
  
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  justify-content: center;
  align-items: center;
  
  background-color: rgba(211, 211, 211, 0.6);
  z-index: 1;
  backdrop-filter: blur(2px);

  font-weight: bold;
  
  cursor: pointer;
  
`;

export const UploadWarningsDiv = styled.div`
  display: grid;
  padding: 6px;
  margin: 6px;
  gap: 4px;
  //background-color: #ffecb5;
  font-size: 0.8em;
  
  label{
    background: #ffecb5;
    //color: #ffecb5;
    border-bottom: 1px solid grey;
  }
  
`;

export const Fieldset = styled.fieldset`
  display: block;
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border-color: rgb(192, 192, 192);
  border-image: initial;

  legend {
    display: block;
    padding-inline-start: 2px;
    padding-inline-end: 2px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
    float: unset;
    width: unset;
    padding: unset;
    margin-bottom: unset;
    font-size: unset;
    line-height: unset;
  }

  button[class="review"] {
    background-color: ${props => (props.status) ? props.theme.global.status[props.status]+"a4" : "lightgrey"};
    //color: white;

    :hover {
      background-color: rgba(31, 119, 180, 0.7);
    }
  }

`;