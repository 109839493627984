import styled from "styled-components";

export const UploadPage = styled.div`
  width: 100%;
  text-align: center;
  padding: 8px;
  min-height: calc(100vh - 50px);
  p{
    color: grey;
  }

`;
export const FileList = styled.div`
  text-align: left;
`

export const UploadDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  //border-top: 1px solid black;
  width: 100%;
  
  //padding: 8px;
  padding-top: 16px;
  padding-left: 0%;
  padding-right: 0%;
  
  form{
    //align-self: center;
    //max-width: 600px;
    
    //padding-left: 20%;
    //padding-right: 20%;
    display: grid;
    grid-template-columns: auto 50%;
    grid-gap: 20px;
    
    align-items: center;
    
    .uploadButton{
      grid-column-start: 1;
      grid-column-end: 3;

      background-color: rgb(6, 119, 161);
      color: white;
      border: unset;
      height: 35px;
    }

    
    .period{
      border-radius: 4px;
      border: 1px solid lightgrey;
      height: 35px;
      padding-left: 6px;
    }
  }
  
`;
