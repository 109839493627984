import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {useAPI} from "../../../api/v2/apiV2";
import {
    LoadingOverlay,
    PropertyOverviewContentDiv
} from "./styles/PropertiesOverview.styles";
import {CardDiv} from "../inspect_report/InspectReport.styles";
import {useUserService} from "../../../services/userService/UserServiceProvider";
import {getCurrentDate} from "../../utils/GlobalUtils";
import Select from "react-select";
import {toast} from "react-toastify";
import LoadingSpinner from "../../../uiElements/LoadingSpinner";
// import {Tab, Tabs} from "react-bootstrap";
import {Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";

import "../../global/css/ErrorReport.css";
import {useTheme} from "styled-components";
import {TableSortContainer} from "./PropertiesOverview";
import PropertyContent from "./content/PropertyContent";
import PropTypes from "prop-types";

import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import './styles/PropertyOverviewTabs.css';
import {ServerEventComponent} from "../../utils/ServerEventComponent";
import {FilterPeriod} from "../../../uiElements/filter/PeriodFilter";
import {FilterDiv, FilterSelectionDiv, getSelectStyling} from "../../../uiElements/filter/Filter.styles";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";

export const TABS = {
    APPROVED:       0,
    AWAITING:       1,
    REJECTED:       2,
    OVERDUE:        3,
    NOT_UPLOADED:   4
}


/**
 * PropertyOverview-Page
 * @returns {JSX.Element}
 * @constructor
 */
function PropertiesOverview(props){

    const location = useLocation();
    const [searchParams] = useSearchParams();   //Params over URL decoded. Includes base64-String JSON for Property-Search

    const api = useAPI();
    const {activeRole} = useUserService();

    const [selectedTab, setSelectedTab] = useState(TABS.APPROVED);

    const [baseProperties, setBaseProperties] = useState([]); //Base_Properties

    const [selectedProperties, setSelectedProperties] = useState([]);

    const [period, setPeriod] = useState(getCurrentDate());
    const [availablePeriods, setAvailablePeriods] = useState([]);

    const [loading, setLoading] = useState(false);

    useMemo(() => {
        // // console.log("Initial")
        // setLoading(true);
        // api.inspect.availablePeriods({role: activeRole.role}).then((responsePeriods) => {
        //     // console.log(responsePeriods)
        //     setAvailablePeriods(responsePeriods);
        //     toast.info("Fetched Periods")
        //     // setLoading(false);
        // }).catch((error) => {
        //     console.warn(error)
        // })

    }, [])

    useMemo(() => {

        if(searchParams.has("property")){
            let searchPropertyBase64 = searchParams.get("property");
            // let obj = Buffer.from(searchPropertyBase64, 'base64').toJSON();
            // console.log(obj)
            try{
                let searchProperty = JSON.parse(atob(searchPropertyBase64));
                //Setting Period
                if(Object.keys(searchProperty).includes("period")){
                    setLoading(true);
                    setPeriod(searchProperty.period);
                    api.inspect.availablePeriods({role: activeRole.role}).then((responsePeriods) => {
                        // console.log(responsePeriods)
                        setAvailablePeriods(responsePeriods);
                        // setLoading(false);
                    }).catch((error) => {
                        console.warn(error)
                    })

                    switch (searchProperty.property_status){
                        case "AWAIT_EXCEPTION_APPROVAL": setSelectedTab(TABS.AWAITING); break;
                        case "REJECTED_BY_USER": setSelectedTab(TABS.REJECTED); break;
                        case "REJECTED_BY_SYSTEM": setSelectedTab(TABS.REJECTED); break;
                        case "APPROVED_BY_USER": setSelectedTab(TABS.APPROVED); break;
                        case "APPROVED_BY_SYSTEM": setSelectedTab(TABS.APPROVED); break;
                        case "NOT_UPLOADED": setSelectedTab(TABS.NOT_UPLOADED); break;
                        default: setSelectedTab(TABS.APPROVED);
                    }
                }
            }catch(e) {
                toast.warn("Error on searchquery. Continue on default")
                setLoading(true);
                api.inspect.availablePeriods({role: activeRole.role}).then((responsePeriods) => {
                    // console.log(responsePeriods)
                    setAvailablePeriods(responsePeriods);
                    // setLoading(false);
                }).catch((error) => {
                    console.warn(error)
                })
            }
        }else{
            setLoading(true);
            api.inspect.availablePeriods({role: activeRole.role}).then((responsePeriods) => {
                // console.log(responsePeriods)
                setAvailablePeriods(responsePeriods);
                // setLoading(false);
            }).catch((error) => {
                console.warn(error)
            })
        }

    }, [searchParams])

    useLayoutEffect(() => {
        return () => {
            // console.log("Unsubscribe")
            api.events.unsubscribe(ServerEventComponent.Topics.updates.PROPERTY, refreshProperty);
        }
    }, [])

    //If Period changes from a Filter
    useEffect(() => {
        // (async() => {
            console.log("Change Period per Filter", period, availablePeriods)
            if(period && availablePeriods.length > 0){
                setLoading(true);
                let data = (period === "none") ? {role: activeRole.role} : {period: period, role: activeRole.role};
                api.inspect.uploadedProperties(data).then((response) => {
                    if(response.status === 200) {
                        setBaseProperties(response.message)
                        // setSelectedProperties(response.message)
                        toast.info("Received "+response.message.length+" items")
                        setLoading(false);
                    }
                    // setLoading(false)
                }).catch((error) => {
                    toast.warn(error.message)
                    // setLoading(false)
                })
            }
        // })();
    }, [period, availablePeriods])

    //When Tabs has changed
    useEffect(() => {
        // console.log("SelectedTab", selectedTab)
        refreshSelectedProperties();
    }, [selectedTab])

    useEffect(() => {
        // console.log("Properties", baseProperties)
        refreshSelectedProperties();

        if(baseProperties.length > 0){ //Need to unsub and sub again, because the state wont go through if subscribe on INIT
            api.events.unsubscribe(ServerEventComponent.Topics.updates.PROPERTY, refreshProperty);
            api.events.subscribe(ServerEventComponent.Topics.updates.PROPERTY, refreshProperty);
        }
        // if(!forwardedLoading)
        // setLoading(false);
    }, [baseProperties])

    const refreshProperty = (updatedProperty) => {
        // console.log("Refreshing Property delivered by EventComponent");
        // console.log(updatedProperty)
        // console.log(updatedProperty, baseProperties)

        let updatedBaseProperties = baseProperties.map((property) => (property.id === updatedProperty.id) ? updatedProperty : property )
        // console.log(updatedBaseProperties)

        setBaseProperties(updatedBaseProperties);
    }

    const refreshSelectedProperties = () => {

        // console.log("RefreshSelectedProperties", selectedTab, baseProperties)

        let properties_current_view;
        switch(selectedTab){
            case TABS.APPROVED: properties_current_view = properties_APPROVED; break;
            case TABS.REJECTED: properties_current_view = properties_REJECTED; break;
            case TABS.AWAITING: properties_current_view = properties_AWAITING; break;
            case TABS.OVERDUE:  properties_current_view = properties_OVERDUE;  break;
            case TABS.NOT_UPLOADED: properties_current_view = properties_NOT_UPLOADED; break;
        }
        setSelectedProperties(properties_current_view);     //Just triggers and sets the current Properties for the View
    }

    let loadingStyle = {
        opacity: (loading ? "0.7" : "1"),
        pointerEvents: (loading ? "none" : "auto")
    }

    let properties_APPROVED = baseProperties.filter((property) => property.status.includes("APPROVED"));
    let properties_AWAITING = baseProperties.filter((property) => property.status.includes("AWAIT"));
    let properties_REJECTED = baseProperties.filter((property) => property.status.includes("REJECTED"));
    let properties_OVERDUE = baseProperties.filter((property) => property.statusOverdue !== null && property.statusOverdue === "OVERDUE");
    let properties_NOT_UPLOADED = baseProperties.filter((property) => property.status.includes("NOT_UPLOADED"));

    return(

        <>
            <LoadingOverlay style={{display: (loading ? "block" : "none")}}>
                <LoadingSpinner title={"Loading..."}/>
            </LoadingOverlay>

            <PropertyOverviewContentDiv style={loadingStyle}>
                <h1>Property Overview</h1>
                <CardDiv>
                    <FilterPeriod
                        availablePeriods={availablePeriods}
                        setSelectedPeriod={setPeriod}
                        selectedPeriod={period}
                        // defaultSelectCurrentPeriod={searchParams.has("property")}
                        // properties={baseProperties}
                    />

                    <Tabs selectedIndex={selectedTab} onSelect={(index) => setSelectedTab(index)}>
                        <TabList>
                            <Tab name={"APPROVED"}>{"Approved (" + properties_APPROVED.length + ")" }</Tab>
                            <Tab name={"AWAITING"}>{"In Review (" + properties_AWAITING.length + ")" }</Tab>
                            <Tab name={"REJECTED"}>{"Rejected (" + properties_REJECTED.length + ")" }</Tab>
                            <Tab name={"OVERDUE"}>{"Overdue (" + properties_OVERDUE.length + ")" }</Tab>
                            <Tab name={"NOT_UPLOADED"}>{"Not Uploaded (" + properties_NOT_UPLOADED.length + ")" }</Tab>
                        </TabList>

                        <TabPanel>
                            <TabContent tab={TABS.APPROVED} properties={properties_APPROVED} />
                        </TabPanel>
                        <TabPanel>
                            <TabContent tab={TABS.AWAITING} properties={properties_AWAITING} />
                        </TabPanel>
                        <TabPanel>
                            <TabContent tab={TABS.REJECTED} properties={properties_REJECTED} />
                        </TabPanel>
                        <TabPanel>
                            <TabContent tab={TABS.OVERDUE} properties={properties_OVERDUE} />
                        </TabPanel>
                        <TabPanel>
                            <TabContent tab={TABS.NOT_UPLOADED} properties={properties_NOT_UPLOADED} />
                        </TabPanel>
                    </Tabs>

                    {/*<Tabs*/}
                    {/*    id="statusTabs"*/}
                    {/*    activeKey={selectedTab}*/}
                    {/*    onSelect={(tab) => setSelectedTab(tab)}*/}
                    {/*    className="statusTabs"*/}
                    {/*    style={{width: "100%"}}*/}
                    {/*>*/}
                    {/*    <Tab eventKey={TABS.APPROVED} title={"Approved (" + properties_APPROVED.length + ")" }>*/}
                    {/*        <TabContent tab={selectedTab} properties={properties_APPROVED}/>*/}
                    {/*    </Tab>*/}

                    {/*    <Tab eventKey={TABS.AWAITING} title={"In Review (" + properties_AWAITING.length + ")" }>*/}
                    {/*        <TabContent tab={selectedTab} properties={properties_AWAITING}/>*/}
                    {/*    </Tab>*/}
                    {/*    <Tab eventKey={TABS.REJECTED} title={"Rejected (" + properties_REJECTED.length + ")" }>*/}
                    {/*        <TabContent tab={selectedTab} properties={properties_REJECTED}/>*/}
                    {/*    </Tab>*/}
                    {/*    <Tab eventKey={TABS.OVERDUE} title={"Overdue (" + properties_OVERDUE.length + ")" }>*/}
                    {/*        <TabContent tab={selectedTab} properties={properties_OVERDUE}/>*/}
                    {/*    </Tab>*/}
                    {/*    <Tab eventKey={TABS.NOT_UPLOADED} title={"Not Uploaded (" + properties_NOT_UPLOADED.length + ")" }>*/}
                    {/*        <TabContent tab={selectedTab} properties={properties_NOT_UPLOADED}/>*/}
                    {/*    </Tab>*/}

                    {/*</Tabs>*/}

                </CardDiv>

            </PropertyOverviewContentDiv>
        </>


    )
}

/**
 * Content-Element for Properties and Errors
 * @param props
 * @returns {*}
 * @constructor
 */
function TabContent(props) {

    let baseProperties = props.properties;
    const [filteredProperties, setFilteredProperties] = useState([]);
    let statusType = props.tab;

    const [filterActive, setFilterActive] = useState(false);

    // console.log("TabContent", statusType, baseProperties, filteredProperties);

    useEffect(() => {
        // console.log("Properties of TOP have changed", props.properties);
        setFilteredProperties([])
    }, [props.properties])

    useEffect(() => {
        // console.log("FilteredProperties", filteredProperties)
    }, [filteredProperties])

    const setFilteredProps = (isFilterActive, filteredProperties) => {
        if(isFilterActive){
            setFilteredProperties(filteredProperties);
        }else{
            setFilteredProperties(props.properties);
        }
        setFilterActive(isFilterActive);
    }

    let properties = (filterActive) ? filteredProperties : baseProperties;

    //Renders the APPROVED-Tab
    const render_APPROVED = () => {
        return(
            <>
                <Filter
                    properties={baseProperties}
                    setProperties={setFilteredProps}
                    tab={props.tab}
                />
                <Table>
                    {/*{this.renderTableHeader("APPROVED")}*/}
                    <TableHeader type={statusType}/>
                    <TableBody>
                        {
                            // // report.properties.length == 0
                            properties.length === 0
                                ?
                                <TableRow>
                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={9}> No approved properties found! </TableCell>
                                </TableRow>
                                : properties.map((propertyInfos, key) => {
                                    return(
                                        <PropertyContent
                                            number={key}
                                            key={key}
                                            property={propertyInfos}
                                            type={statusType}
                                        />
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </>

        )
    }

    //Renders the AWAITING-Tab
    const render_AWAITING = () => {
        return(
            <>
                <Filter
                    properties={baseProperties}
                    setProperties={setFilteredProps}
                    tab={props.tab}
                />
                <Table>
                    <TableHeader type={statusType}></TableHeader>
                    <TableBody>
                        {
                            properties.length === 0
                                ?
                                <TableRow>
                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={9}> No properties for exception approval found! </TableCell>
                                </TableRow>
                                : properties.map((propertyInfos, key) => {
                                    return(
                                        <PropertyContent
                                            number={key}
                                            key={key}
                                            property={propertyInfos}
                                            type={statusType}
                                        />
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </>

        )
    }

    //Renders the REJECTED-Tab
    const render_REJECTED = () => {
        return(
            <>
                <Filter
                    properties={baseProperties}
                    setProperties={setFilteredProps}
                    tab={props.tab}
                />
                <Table>
                    {/*{this.renderTableHeader("REJECTED")}*/}
                    <TableHeader type={statusType}/>
                    <TableBody>
                        {
                            properties.length === 0
                                ?
                                <TableRow>
                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={8}> No rejected properties found! </TableCell>
                                </TableRow>
                                : properties.map((propertyInfos, key) => {
                                    return(
                                        <PropertyContent
                                            number={key}
                                            key={key}
                                            property={propertyInfos}
                                            type={statusType}
                                        />
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </>

        )
    }

    //Renders the OVERDUE-Tab
    const render_OVERDUE = () => {
        return(
            <>
                <Filter
                    properties={baseProperties}
                    setProperties={setFilteredProps}
                    tab={props.tab}
                />
                <Table>
                    <TableHeader type={statusType}/>
                    <TableBody>
                        {
                            properties.length === 0
                                ?
                                <TableRow>
                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={9}> No Overdue properties found! </TableCell>
                                </TableRow>
                                : properties.map((propertyInfos, key) => {
                                    return(
                                        <PropertyContent
                                            number={key}
                                            key={key}
                                            property={propertyInfos}
                                            type={statusType}
                                        />
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </>

        )
    }

    //Renders the NOT_UPLOADED-Tab
    const render_NOT_UPLOADED = () => {
        return(
            <>
                <Filter
                    properties={baseProperties}
                    setProperties={setFilteredProps}
                    tab={props.tab}
                />
                <Table>
                    <TableHeader type={statusType}/>
                    <TableBody>
                        {
                            properties.length === 0
                                ?
                                <TableRow>
                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={7}> No Uploaded properties found! </TableCell>
                                </TableRow>
                                : properties.map((propertyInfos, key) => {
                                    return(
                                        <PropertyContent
                                            number={key}
                                            key={key}
                                            property={propertyInfos}
                                            type={statusType}
                                        />
                                    );
                                })
                        }
                    </TableBody>
                </Table>
            </>
        )
    }

    const render = () => {
        switch (props.tab) {
            case TABS.APPROVED: return render_APPROVED();
            case TABS.AWAITING: return render_AWAITING();
            case TABS.REJECTED: return render_REJECTED();
            case TABS.OVERDUE:  return render_OVERDUE();
            case TABS.NOT_UPLOADED: return render_NOT_UPLOADED();
        }
    }
    return render();
}


/**
 * Filter for Properties in Overview
 * FilterTypes[Type, PropertyIdReceiver, Status, Overdue]
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function Filter(props){

    let properties = props.properties;

    const typeFilter = [...new Set(properties.map(property => {return property.type}) )];
    const propertyFilter = [...new Set(properties.map(property => {return property.property.property_id_receiver}))];
    // const datesFilter = [...new Set(properties.map(property => {return property.period}))];
    const statusFilter = [...new Set(properties.map(property => {return property.status}))];
    const overdueFilter = [...new Set(properties.map(property => {return property.statusOverdue}))];

    const [typesToFilter, setTypesToFilter] = useState({
        TYPE: "none",
        PROPERTY: "none",
        PERIOD: "none",
        STATUS: "none",
        OVERDUE: "none"
    })

    const setFilter = (filter, filterType) => {
        // console.log(filter)
        setTypesToFilter({...typesToFilter, [filterType]: filter.value})
    }

    const setFilteredProperties = () => {

        let filteredProps = properties;
        for(let typeOfFilter in typesToFilter){
            let filterValue = typesToFilter[typeOfFilter];

            if(typeOfFilter === "TYPE" && filterValue !== "none")
                filteredProps = filteredProps.filter(property => {return property.type === filterValue});

            if(typeOfFilter === "PROPERTY" && filterValue !== "none")
                filteredProps = filteredProps.filter(property => {return property.property.property_id_receiver === filterValue});

            if(typeOfFilter === "PERIOD" && filterValue !== "none")
                filteredProps = filteredProps.filter(property => {return property.period === filterValue});

            if(typeOfFilter === "STATUS" && filterValue !== "none")
                filteredProps = filteredProps.filter(property => {return property.status === filterValue});

            if(typeOfFilter === "OVERDUE" && filterValue !== "none")
                filteredProps = filteredProps.filter(property => {return property.statusOverdue === filterValue});

        }
        let isFilterActive = Object.values(typesToFilter).some((filter) => {return filter !== "none";})
        props.setProperties(isFilterActive ,filteredProps);
    }

    //If something in the Filter want to have a Icon, put it here
    const getIconForOption = (optionName) => {
        let labeledIcon;
        switch(optionName){
            case "FINANCIAL":   labeledIcon = <i className="fas fa-coins"/>;    break;
            case "RENTROLL":    labeledIcon = <i className="fas fa-home"/>;     break;
            case "ESG":         labeledIcon = <i className="fas fa-leaf"/>;     break;
            default:            labeledIcon = "";
        }
        return labeledIcon;
    }

    const getOptions = (filterItems, filterType) => {
        let filterOptions = filterItems.map((option) => {
            let label = option;
            // console.log("FILTER", filterItems)
            if(filterType === "OVERDUE"){
                label = (option === "NOT_OVERDUE" ? "In Time" : "Overdue");
            }
            return {value: option, label: <>{getIconForOption(option)} <span>{label}</span> </> }
        });
        filterOptions.unshift({value: "none", label: "--- All ---"})
        // console.log(filterOptions, filterItems, filterType)
        return filterOptions;
    }

    useEffect(() => {
        setFilteredProperties();
    }, [typesToFilter])

    return(
        <FilterDiv>
            <FilterSelectionDiv>
                <div style={{padding: "8px 16px", color: "white"}}>
                    <i className="fas fa-filter"></i>
                </div>
                <div>
                    <label>Type</label>
                    <Select
                        name={"Filter Type"}
                        isSearchable={false}
                        placeholder={"Type Filter"}
                        isDisabled={typeFilter.length === 0}
                        options={getOptions(typeFilter, "TYPE")}
                        styles={getSelectStyling(typeFilter)}
                        onChange={(target) => {setFilter(target, "TYPE")}}
                    />


                    {/*<select*/}
                    {/*    defaultValue={"default"}*/}
                    {/*    disabled={typeFilter.length === 0}*/}
                    {/*    onChange={(target) => {setFilter(target, "TYPE")}}*/}
                    {/*>*/}
                    {/*    <option disabled hidden value={"default"}>Filter Type</option>*/}
                    {/*    <option value={"none"}>All</option>*/}
                    {/*    {*/}
                    {/*        typeFilter?.length === 0*/}
                    {/*            ?*/}
                    {/*            <option disabled>No Props available</option>*/}
                    {/*            :*/}
                    {/*            typeFilter.map((type, key) => {*/}
                    {/*                return <option value={type} key={key}>{type}</option>*/}
                    {/*            })*/}
                    {/*    }*/}
                    {/*</select>*/}
                </div>
                <div>
                    <label>Property</label>
                    <Select
                        name={"Property Type"}
                        isSearchable={false}
                        placeholder={"Property Filter"}
                        isDisabled={typeFilter.length === 0}
                        options={getOptions(propertyFilter, "PROPERTY")}
                        styles={getSelectStyling(propertyFilter)}
                        onChange={(target) => {setFilter(target, "PROPERTY")}}
                    />


                    {/*<select*/}
                    {/*    defaultValue={"default"}*/}
                    {/*    disabled={(propertyFilter.length === 0)}*/}
                    {/*    onChange={(target) => setFilter(target, "PROPERTY")}*/}
                    {/*>*/}
                    {/*    <option disabled hidden value="default">Filter Property</option>*/}
                    {/*    <option value="none">All</option>*/}
                    {/*    {*/}
                    {/*        propertyFilter?.length === 0*/}
                    {/*            ?*/}
                    {/*            <option disabled>No Props available</option>*/}
                    {/*            :*/}
                    {/*            propertyFilter.map((property, key) => {*/}
                    {/*                return <option value={property} key={key}>{property}</option>*/}
                    {/*            })*/}
                    {/*    }*/}
                    {/*</select>*/}
                </div>
                <div>
                    <label>Status</label>
                    <Select
                        name={"Status Type"}
                        isSearchable={false}
                        placeholder={"Status Filter"}
                        isDisabled={typeFilter.length === 0}
                        options={getOptions(statusFilter, "STATUS")}
                        styles={getSelectStyling(statusFilter)}
                        onChange={(target) => {setFilter(target, "STATUS")}}
                    />
                    {/*<select*/}
                    {/*    defaultValue={"default"}*/}
                    {/*    // value={typesToFilter.STATUS !== "none" ? typesToFilter.STATUS : "default"}*/}
                    {/*    disabled={(statusFilter.length === 0)}*/}
                    {/*    onChange={(target) => setFilter(target, "STATUS")}*/}
                    {/*>*/}
                    {/*    <option disabled hidden value="default">Filter Status</option>*/}
                    {/*    <option value="none">All</option>*/}
                    {/*    {*/}
                    {/*        statusFilter?.length === 0*/}
                    {/*            ?*/}
                    {/*            <option disabled>No Status available</option>*/}
                    {/*            :*/}
                    {/*            statusFilter.map((status, key) => {*/}
                    {/*                return <option value={status} key={key}>{status}</option>*/}
                    {/*            })*/}
                    {/*    }*/}
                    {/*</select>*/}
                </div>
                {
                    // (props.tab === TABS.NOT_UPLOADED) ?
                        <div>
                            <label>Overdue</label>
                            <Select
                                name={"Overdue Type"}
                                isSearchable={false}
                                placeholder={"Overdue Filter"}
                                isDisabled={overdueFilter.length === 0}
                                options={getOptions(overdueFilter, "OVERDUE")}
                                styles={getSelectStyling(overdueFilter)}
                                onChange={(target) => {setFilter(target, "OVERDUE")}}
                            />
                            {/*<select*/}
                            {/*    defaultValue={"default"}*/}
                            {/*    disabled={(overdueFilter.length === 0)}*/}
                            {/*    onChange={(target) => setFilter(target, "OVERDUE")}*/}
                            {/*>*/}
                            {/*    <option disabled hidden value="default">Filter Status</option>*/}
                            {/*    <option value="none">All</option>*/}
                            {/*    {*/}
                            {/*        overdueFilter?.length === 0*/}
                            {/*            ?*/}
                            {/*            <option disabled>No Overdue Props available</option>*/}
                            {/*            :*/}
                            {/*            overdueFilter.map((status, key) => {*/}
                            {/*                return (*/}
                            {/*                    <option value={status} key={key}>*/}
                            {/*                        {*/}
                            {/*                            (status === "NOT_OVERDUE") ? "On Time" : "Overdue"*/}
                            {/*                        }*/}
                            {/*                    </option>*/}
                            {/*                )*/}
                            {/*            })*/}
                            {/*    }*/}
                            {/*</select>*/}
                        </div>
                        // :
                        // <div></div>
                }
            </FilterSelectionDiv>
        </FilterDiv>
    )

}
Filter.propTypes = {
    properties: PropTypes.array,
    setProperties: PropTypes.func,
    tab: PropTypes.string
}
/**
 * Renders the specific Headers for the PropertyOverview StatusType (APPROVED, REJECTED, AWAITING, OVERDUE, NOT_UPLOADED)
 * @param props
 * @returns {*}
 * @constructor
 */
function TableHeader(props){

    const theme = useTheme()

    const header_APPROVED = () => {
        let type = TABS.APPROVED;
        return(
            <TableHead>
                <TableRow className="tableRowHeader" style={{backgroundColor: theme.palette.primary}}>
                    <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Uploaded by</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Approved by</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Days Overdue</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}></TableSortContainer></TableCell>
                </TableRow>
            </TableHead>
        )
    }
    const header_REJECTED = () => {
        let type = TABS.REJECTED;
        return(
            <TableHead>
                <TableRow className="tableRowHeader" style={{backgroundColor: theme.palette.primary}}>
                    {/*<TableCell></TableCell>*/}
                    <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Uploaded by</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Property Manager</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Rejected by</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Days Overdue</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}></TableSortContainer></TableCell>
                </TableRow>
            </TableHead>
        )
    }
    const header_AWAITING = () => {
        let type = TABS.AWAITING;
        return(
            <TableHead>
                <TableRow className="tableRowHeader" style={{backgroundColor: theme.palette.primary}}>
                    <TableCell><TableSortContainer tab={type}></TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Uploaded by</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>In Review since</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Exception count</TableSortContainer></TableCell>
                    {/*<TableCell>Status</TableCell>*/}
                    <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Days Overdue</TableSortContainer></TableCell>
                    {/*<TableCell>Action</TableCell>*/}
                </TableRow>
            </TableHead>
        )
    }

    const header_OVERDUE = () => {
        let type = TABS.OVERDUE;
        return(
            <TableHead>
                <TableRow className="tableRowHeader" style={{backgroundColor: theme.palette.primary}}>
                    <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Uploaded by</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Property Manager</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Last change</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Status</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Days Overdue</TableSortContainer></TableCell>
                </TableRow>
            </TableHead>
        )
    }
    const header_NOTUPLOADED = () => {
        let type = TABS.NOT_UPLOADED;
        return(
            <TableHead>
                <TableRow className="tableRowHeader" style={{backgroundColor: theme.palette.primary}}>
                    <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Property Manager</TableSortContainer></TableCell>
                    <TableCell><TableSortContainer tab={type}>Last change</TableSortContainer></TableCell>
                    {/*<TableCell>< Status</TableCellTableSortContainer>*/}
                    <TableCell><TableSortContainer tab={type}>Overdue</TableSortContainer></TableCell>
                </TableRow>
            </TableHead>
        )
    }

    const renderHeader = () => {
        switch(props.type){
            case TABS.APPROVED: return header_APPROVED();
            case TABS.REJECTED: return header_REJECTED();
            case TABS.AWAITING: return header_AWAITING();
            case TABS.OVERDUE:  return header_OVERDUE();
            case TABS.NOT_UPLOADED: return header_NOTUPLOADED();

        }
    }
    return(renderHeader())
}

export default PropertiesOverview;