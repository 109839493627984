import API from "../../../../api/api";
import React from "react";
import ESGTile from "./ESGTile";
import moment from "moment";
import Chart from "chart.js/auto";
import {sameObjectArrayById, sameObjectArrayByPropertyId} from "../functions";


class ESGCarbonBubble extends ESGTile{
    constructor(props) {
        super(props);
        this.api = new API();
        this.getCarbonData = this.getCarbonData.bind(this);

        this.state = {
            noData: false
        }

        //window.setTimeout(this.getCarbonData,500)
    }
    getCarbonData(){
        let d = {

            selectedBuildings: (this.props.settings.buildings!==undefined)?this.props.settings.selectedBuildings.map(x=>x.propertyId):[],
            role:this.props.settings.role,
            timestamp:this.props.settings.selectedTimestamp

        }
        if(d.selectedBuildings.length === 0)
            return ""
        this.api.esg.getCarbonBubbleData.get(this.api.getCookie("jwt"),d).then(response=>{
            this.drawCarbonBubble(response.data,d)
        })

    }
    drawCarbonBubble(data,request_settings){

        let max_color_per_side = Math.min((data.buildingData.length - data.buildingData.length%2)/2,3)
       let datapoints = {
            datasets:   data.buildingData.sort((a,b)=>a.distance-b.distance).map((d,i)=>{
                let color = "rgba(0,0,0,0.5)"
                if(i<max_color_per_side){
                    color = "hsla(119, 94%, "+(50 + i*10)+"%,0.5)"
                }
                else if(i>=data.buildingData.length-max_color_per_side){
                    color = "hsla(3, 100%, "+(50 - i*10)+"%,0.5)"
                }
                return {
                    label: d.building.label?d.building.label:d.building.address_street,
                    data: [
                        {
                            x: d.energy_intensity,
                            y: d.carbon_intensity,
                            r: d.sqm/100
                        }
                    ],
                    backgroundColor: color,
                    borderColor: color,
                    borderWidth: 1
                }
            })


        }

        if(document.getElementById(this.props.id + "carbonBubble") == null)
            return 0;
        let ctx = document.getElementById(this.props.id + "carbonBubble").getContext('2d');
        if(window.myScatter !== undefined)
            window.myScatter.destroy();
        let showChart = datapoints.datasets.map(d=>{
            return d.data[0].x!=0 || d.data[0].y!=0
        }).reduce((p,c)=>p||c,false)
        if(showChart)
            window.myScatter = new Chart(ctx, {
            type: 'bubble',

            data: datapoints,
            options: {

                title: {
                    display: true,
                    text: ''
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label = data.labels[tooltipItem.index];
                            return "Objekt "+ Math.floor(Math.random() * 10+1) + ': ' + tooltipItem.xLabel + ' m², ' + tooltipItem.yLabel + 't CO2';
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Energy intensity (kWh/m² )"
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value+ "";
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Carbon intensity (kgCO2e/m²)"
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value;
                            }
                        }
                    }
                }
            }
        });
        this.setState({noData:!showChart})

    }
    componentWillUnmount(){
        if(window.myScatter!== undefined)
            window.myScatter.destroy()
    }
    componentDidMount() {

        this.getCarbonData()
    }
    content(){
        return <React.Fragment>
            <div style={{"height": "400px", display: this.state.noData?"block":"none"}} className={"w3-display-container"}>
                <div className={"w3-display-middle"}>
                    <h5>No CO2 Equivalents uploaded.</h5>
                </div>
            </div>

            <div id="ESGCarbonBubbleContainer" style={{"height":"400px", display: this.state.noData?"none":"block"}} className={"w3-container"}>
                <canvas id={this.props.id + "carbonBubble"} style={{height:"210px", width:"700px"}}></canvas>
                <span className={"w3-tiny w3-right"}>Bubble Size represents Building m²</span>
            </div>
        </React.Fragment>

    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.settings.selectedBuildings == undefined){
            this.setState({noData:true})
            return 0

        }
        if (this.state.settings == undefined || !sameObjectArrayByPropertyId(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings)|| nextProps.settings.selectedTimestamp !==this.state.settings.selectedTimestamp){
            this.setState({settings:  JSON.parse(JSON.stringify(nextProps.settings))})
            this.getCarbonData()
        }

    }
}

export default ESGCarbonBubble;