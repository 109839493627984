import React from 'react';
import API from "../../../api/api";
import {Form} from "react-bootstrap";
import styled from "styled-components";
import BaseSelect from "react-select";

import '../../global/css/Upload.css';
import FixRequiredSelect from "../../utils/FixRequiredSelect";
import {toast} from "react-toastify";
import {ServerEventComponent} from "../../utils/ServerEventComponent";
import {FileList, UploadDiv, UploadPage} from "./Upload.styles";
import {useUserService} from "../../../services/userService/UserServiceProvider";


const Select = props => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

// toast.configure()

function Upload(props){

    const {activeClient} = useUserService();

    return(
        <UploadOld activeClient={activeClient}/>
    )

}

class UploadOld extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            formData: {
                client: this.props.activeClient,
                period: "",
                platform: ""
            },

            selectedFiles: undefined,
            currentFile: undefined,
            fileInfos: [],
            uploadedReports: [],

            platformOptions: this.getSelectionOptions(),
            instanceSettings: this.props.activeClient.instance_settings,

            is_dragging: false,
            is_dragging_site: false,


            serverEvents: ServerEventComponent.getInstance(),
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handlePlatformSelections = this.handlePlatformSelections.bind(this);
        this.api = new API();


    }

    componentDidMount() {
        this.state.serverEvents.subscribe(ServerEventComponent.Topics.updates.UPLOAD_ERROR, this.showUploadError)
    }

    componentWillUnmount() {
        this.state.serverEvents.unsubscribe(ServerEventComponent.Topics.updates.UPLOAD_ERROR, this.showUploadError)

    }

    showUploadError = (data) => {
        toast.error(data.message.message, {position: toast.POSITION.BOTTOM_CENTER})
    }

    handlePlatformSelections = (event) => {
        let formData = this.state.formData;
        formData.platform = event.value;
        formData.client = this.props.activeClient;
        this.setState({formData: formData});

    }

    handleSubmit = (event) => {

        let client = this.props.activeClient;
        let formData = this.state.formData;
        formData.client = client;

        this.setState({formData: formData});

//        console.log(event.target.files, formData);
        event.preventDefault();

        this.api.uploads.newUpload(this.api.getCookie("jwt"), formData, this.state.selectedFiles)
        // this.api.uploads.newUpload(this.api.getCookie("jwt"), formData, this.state.currentFile)
            .then((response) => {
                // console.log(response)
                if(response.status === 200) {
                    toast.success("Successfully uploaded Report", {position: toast.POSITION.BOTTOM_CENTER})
                    event.target.reset();
                }
            })
            .catch((error) => {
                toast.error(error.response.data.message, {position: toast.POSITION.BOTTOM_CENTER})
            })

        let state = this.state;
        state.selectedFiles = undefined;
        state.currentFile = undefined;
        this.setState(state);
    }

    selectFile = (event) => {

        //console.log("File Selected!", event.target.files);

        this.setState({
            selectedFiles: event.target.files
        })
        this.setState({
            currentFile: event.target.files[0]
        })
    }

    selectFileDragDrop = (event) =>{
        event.preventDefault()
        /*for (let i = 0; i < event.dataTransfer.files.length; i++) {
            console.log(event.dataTransfer.files[i].type)
        }*/

        this.setState({
            selectedFiles: event.dataTransfer.files
        })
        this.setState({
            currentFile: event.dataTransfer.files[0]
        })
        document.getElementById("uploadInputField").files = event.dataTransfer.files
    }
    getFileLine = ()=>{
        if(this.state.selectedFiles === undefined)
            return ""
        let returnLines = []
        // console.log(this.state.selectedFiles)
        for(let i = 0; i<this.state.selectedFiles.length;i++){
            let f = this.state.selectedFiles[i]
            // console.log(f)

            // eslint-disable-next-line react/no-unknown-property
            returnLines.push(<div fileNum = {i} >{f.name}</div>)
        }
        return returnLines
    }

    setPeriod = (event) => {
        let date = new Date(event.target.value);

        let dateInfos = date.toISOString().split("T")[0].split("-")
        let year = dateInfos[0];
        let month = dateInfos[1];

        let formData = this.state.formData;
        formData.period = year+month;

        this.setState({formData: formData})

    }

    getSelectionOptions = () =>{

        let options = []

        // console.log(this.props.activeClient.instance_settings.allowed_platforms)

        for(let platform of this.props.activeClient.instance_settings.allowed_platforms){

            options.push(
                {
                    value: platform,
                    label: platform
                }
            )

        }

        return options
    }


    render() {

        let platformOptions = this.getSelectionOptions();

        return (
            <UploadPage
                        onDrop={(e)=>this.selectFileDragDrop(e)}
                        onDragOver={(e) => { this.setState({is_dragging_site:true});e.preventDefault();}}
                        onDragEnter={(e)=>{this.setState({is_dragging_site:true})}}
                        onPointerLeave={(e)=>{this.setState({is_dragging_site:false})}}
                        // onDrop={(e)=>{this.setState({is_dragging_site:false})}}

            >

                <div id="id01" className={this.state.is_dragging_site?"w3-modal w3-show":"w3-modal"}
                     onDrop={(e)=>this.selectFileDragDrop(e)}
                     onDragOver={(e) => {e.preventDefault(); this.setState({is_dragging_site:true})}}
                     onDragEnter={(e)=>{this.setState({is_dragging_site:true})}}
                     onClick={()=>{this.setState({is_dragging_site:false})}}

                >
                    <div className="w3-modal-content w3-round dropZoneContainer">

                        <div className="w3-container w3-round dropZone">
                            <p><i className={"fa fa-upload dropZoneIcon"}></i></p>
                            <p className={"w3-xlarge"}>Drop File here</p>
                        </div>

                    </div>
                </div>


                <h1>Upload File</h1>
                <hr/>
                <UploadDiv>
                    <p>Please upload your Reports for the RuleEngine Checks</p>
                    <div>

                    </div>
                    <Form onSubmit={this.handleSubmit}>

                        {
                            (this.state.instanceSettings.check_period === false)
                                ?
                            <React.Fragment>
                                <label>Period</label>
                                <input type="date" name="check_period" className="period" onChange={this.setPeriod} required/>
                            </React.Fragment>
                                :
                                <></>
                        }


                        <label>Report Standard</label>
                        <Select
                            options={platformOptions}
                            onChange={this.handlePlatformSelections}
                            //defaultValue={platformOptions[0]}
                            required />

                        {/*Title: <input type="text" name="title" value={this.state.title} onChange={this.handleChange}/>*/}
                        <label>Report:</label>
                        <input type="file" id={"uploadInputField"} name="report" accept="application/vnd.ms-excel, .xlsx, .csv, .json" onChange={this.selectFile} multiple="multiple" required/>
                        <label style={{display:(this.state.selectedFiles === undefined)?"none":"block"}}>Selected Files:</label>
                        <FileList style={{display:(this.state.selectedFiles === undefined)?"none":"block"}}>{
                           this.getFileLine()
                        }</FileList>
                        <input className="uploadButton" type="submit" value="Upload"/>

                    </Form>
                </UploadDiv>
            </UploadPage>
        )
    }

}

export default Upload;