import styled from "styled-components";

export const InspectReportDiv = styled.div` 
  width: 100%;
  text-align: center;
  padding: 8px;
`;


export const UploadOverviewDiv = styled.div`
  //padding-left: 10%;
  //padding-right: 10%;
  //padding: 10px;
  //border: 1px solid black;
`;


export const UploadedReportDiv = styled.div`
  
  display: flex;
  text-align: left;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 26px;
  justify-content: space-around;
  
  button{
    border-radius: 0px;
  }
`;


export const DownloadReportDiv = styled.div`
  padding: 10px;
  display: block;
  //background-color: white;
`;


export const ReportInfos = styled.div`
  display: flex;
  flex-direction: row;
  //border: 1px solid black;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background-color: white;
  width: 100%;
  
  //padding: 10px;
  
  //div[class="buttonDiv"]{
  //  
  //  >button{
  //    font-size: 10px;
  //  }
  //}
  
  > div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    //margin: 10px;
    padding: 10px;

    //width: 49%;
    div{

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span[class="READY_FOR_PROCESS"]{
        color: green;
        font-weight: bold;
      }
      span[class="NOT_READY_FOR_PROCESS"]{
        color: red;
        font-weight: bold;
      }

    }
    
  }
`;

export const CardDiv = styled.div`
  padding: 12px;
  color: black;
  background-color: white;
  box-shadow: 0 2px 5px rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
`;


export const InspectButton = styled.button`
  
  cursor: pointer;
  border: unset;
  line-height: 2;
  width: 200px;
  
  margin-left: 6px;
  
  :hover{
    background-color: #3498DB;
    color: white;
  }
  
  >span{
    padding-left: 6px;
  }
  
`;