import API from "./api/api";
import {toast} from "react-toastify";
import async from "async";

import Cookies from 'js-cookie';

/**
 * @deprecated
 */
class AuthService{

    constructor() {

        if(AuthService._instance){
            return AuthService._instance;
        }
        AuthService._instance = this;

        this.api = new API();

        this.user = null;
        // this.isUserAuthenticated()
    }

    isUserAuthenticated = () => {
        return (this.user !== null);
    }

    getUser = () => {return this.user}

    setUser = (user) => {
        console.log(user)
        this.user = user;
        console.log("AuthService has User", user)
    }

    authenticate = async(username, password, eventForm) => {

        return await this.api.authenticate(username, password, eventForm).then(result => {

            if(result.status === 200){
                // toast.success("Authentication successful", {position: toast.POSITION.TOP_RIGHT});
                if(result.data.hasOwnProperty("totpRequired") && result.data.totpRequired === true){
                    return result;
                }else {
                    // console.log("Auth success, forward to login", result.data.apikey)
                    // console.log("Get JSESSIONID", Cookies.get("JSESSIONID"))
                    // let jSessionId = Cookies.get("JSESSIONID");
                    return this.login(result.data.apikey);
                }
            }else{
                toast.error("Authentication Error: Got no API-Key from Server", {position: toast.POSITION.BOTTOM_CENTER})
            }

        }).catch(error => {

            if(error.response)
                toast.error("Authentication Error: " + error.response.data.message, {position: toast.POSITION.BOTTOM_CENTER})
            else{
                error = error.toJSON();
                toast.error(error.message+": No response from server", {position: toast.POSITION.BOTTOM_CENTER})
            }
        })

    }

    login = async(jwtToken, jSessionId) => {

        console.log("JSESSIONID", jSessionId)
        // toast.info("LOL");
        return await this.api.login(jwtToken, jSessionId).then((response) => {

            if(response.status === 200) {
                if(response.data){
                    //Necessary storage of user-Infos for PrivateRoutes
                    this.user = {
                        apikey: response.data.apikey,
                        roles: response.data.roles,
                        clients: response.data.clients,
                        email: response.data.email,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        phonenumber: response.data.phonenumber,
                        profile_settings: response.data.profile_settings,
                        twoFactorAuthEnabled: response.data.twoFactorAuthEnabled,
                        twoFactorAuthClientEnabled: response.data.twoFactorAuthClientEnabled,
                        keys: response.data.keys
                        // redirect: true,
                    }
                    return response;
                }else{
                    throw Error("Received no Content from Server")
                }
            }else{
                toast.warn(response.status);
            }
        }).catch((error) => {
            // window.loginerror = error
            console.table(error)
            if(error.response) {
                toast.error("Login Error: " + error.response.data.message, {position: toast.POSITION.BOTTOM_CENTER})
                console.error(error.response)
                throw new Error(error);
            }else{
                // error = error.toJSON();
                toast.error(error.message, {position: toast.POSITION.BOTTOM_CENTER})
                throw new Error(error);
            }
            // throw new Error(error)
            // return error;
        })
    }

    verifyTotp = async(form) => {
        return this.api.verifyTotp(form)
    }

}

export default new AuthService();