import React from 'react';
import {CircularProgress} from "@mui/material";

function LoadingSpinner(props){
    return(
	<div style={{display: "flex", flexDirection: "column", justifyContent: 'center', alignItems: "center", height: "90%", fontSize: "1.2em"}}>
	    <CircularProgress size={"3em"}/>
	    <br/>
	    <label>{props.title}</label>
	</div>
    )
}

export default LoadingSpinner;