import React from "react";

export const getIconForOption = (optionName) => {
    let labeledIcon;
    switch(optionName){
        case "FINANCIAL":   labeledIcon = <i className="fas fa-coins"/>;    break;
        case "RENTROLL":    labeledIcon = <i className="fas fa-home"/>;     break;
        case "ESG":         labeledIcon = <i className="fas fa-leaf"/>;     break;
        case "XLSX":        labeledIcon = <i className="fas fa-file-excel"/>; break;
        case "CSV":         labeledIcon = <i className="fas fa-file-csv"/>; break;
        case "JSON":        labeledIcon = <i className="fas fa-file-code"/>; break;
        default:            labeledIcon = "";
    }
    return labeledIcon;
}