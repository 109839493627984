import React, {Component} from "react";
import Chart from "chart.js/auto";
import * as Gauge from 'chartjs-gauge';
class CO2Dashboard extends Component {

    constructor(props) {
        super(props);



    }
    getFilteredBuildings=()=>{
        return this.props.data.buildings.filter(b=>Object.keys(b.resources).includes("Electricity"))
    }
    getEnergyByYearMonth=(b,year,month)=>{
        return b.map(x=>{return {
            [x.id]: x.resources.Electricity.values.filter(y=>{return y.date.getUTCFullYear()==year && y.date.getUTCMonth()==month}).map(z=>z.value).reduce((a,b)=>{return a+b})
        }})
    }


    onlyUnique=(value, index, self) =>{
        return self.indexOf(value) === index;
    }

    getRandomColor=() =>{
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    inputConvertAbsolute=(input)=>{
        let datasets = [];
        let labels = [];
        input.data.values.forEach(x=>{labels.push(x.timestamp)})
        labels = labels.filter(this.onlyUnique);
        labels = labels.sort();
        input.data.topics.forEach(x=>{
            let ds = {};
            ds.label = x;
            ds.backgroundColor = this.getRandomColor();
            ds.data = []
            labels.forEach(y=>{
                input.data.values.forEach(z=>{
                    if(z.timestamp == y && z.topic == x)
                        ds.data.push(z.value);
                })
            })
            datasets.push(ds)

        })
        return {"labels":labels, "datasets":datasets};
    }
    drawGauge=()=>{

        const data = {
            labels: ['AAA','AA','A','B'],
            datasets: [{
                label: 'Housing',
                data: [3,2,3,1],
                borderColor: "rgba(89,255,0,0.8)",
                backgroundColor: "rgba(89,255,0,0.8)",
            },{
                label: 'Office',
                data: [1,1,1,0],
                borderColor: "rgba(25,0,255,0.8)",
                backgroundColor: "rgba(25,0,255,0.8)",
            },{
                label: 'Retail',
                data: [1,0,1,0],
                borderColor: "rgba(255,200,0,0.8)",
                backgroundColor: "rgba(255,200,0,0.8)",
            }]


        };
        let ctx = document.getElementById('gauge').getContext('2d');
        window.myGauge = new Gauge(ctx,{
                type: 'gauge',
                data: {
                    datasets: [{
                        data: [20,50,80,100],
                        value: 75,
                        backgroundColor: ['red', 'orange', 'yellow', 'green'],
                        borderWidth: 2
                    }]
                },
                options: {
                    responsive: false,
                    maintainAspectRatio: true,

                    title: {
                        display: false,
                        text: 'Gauge chart'
                    },
                    layout: {
                        padding: {
                            bottom: 30
                        }
                    },
                    needle: {
                        // Needle circle radius as the percentage of the chart area width
                        radiusPercentage: 2,
                        // Needle width as the percentage of the chart area width
                        widthPercentage: 3.2,
                        // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
                        lengthPercentage: 80,
                        // The color of the needle
                        color: 'rgba(0, 0, 0, 1)'
                    },
                    valueLabel: {
                        formatter: Math.round
                    }
                }
            }
        );
        window.myGauge.canvas.style.display ="inline-block"
    };

    drawPieEnergie=()=>{

        const data = {
            datasets: [
                {
                    labels: [ 'Portfolio-1 Verbrauch','Portfolio-2 Verbrauch','Portfolio-3 Verbrauch','Portfolio-1 Erzeugung','Portfolio-2 Erzeugung','Portfolio-3 Erzeugung'],
                    data: [30,45,15,3,5,2],
                    backgroundColor: [
                        "rgba(255,102,0,0.9)",
                        "rgba(255,102,0,0.6)",
                        "rgba(255,102,0,0.3)",

                        "rgba(62,128,0,0.9)",
                        "rgba(62,128,0,0.6)",
                        "rgba(62,128,0,0.3)",
                    ],
                },
                {
                    labels: ['Verbrauch', 'Erzeugung'],
                    data: [90,10],
                    backgroundColor: ["rgba(255,102,0,0.8)","rgba(62,128,0,0.8)"],
                }
            ]
        };
        let ctx = document.getElementById('pieEnergie').getContext('2d');
        window.myDonut = new Chart(ctx,{
                type: 'pie',
                data: data,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            display: true,
                            position: 'left',
                            labels:{
                                display:true
                            }
                        },
                        title: {
                            display: false,
                            text: 'Chart.js Doughnut Chart'
                        },
                        tooltip: {
                            callbacks: {
                                label: function(context) {
                                    const labelIndex = context.dataIndex;
                                    return context.dataset.labels[labelIndex] + ': ' + context.formattedValue;
                                }
                            }
                        }
                    }
                },
            }
        );
    };
    drawBar=()=>{
        let barChartData ={
            labels: ["Portfolio-1","Portfolio-2", "Portfolio-3"],
            datasets: [
                {
                    label:'Geothermie',
                    data : [1.7,2.3,2.2],
                    backgroundColor: 'rgba(255,89,0,0.8)'
                },
                {
                    label:'Biomasse',
                    data : [1.7,2.3,2.2],
                    backgroundColor: 'rgba(0,102,12,0.8)'
                },
                {
                    label:'Wasserkraft',
                    data : [1.2,2.8,1.5],
                    backgroundColor: 'rgba(0,55,255,0.8)'
                },
                {
                    label:'Windkraft',
                    data : [1.5,2.2, 1],
                    backgroundColor: 'rgba(0,115,255,0.8)'
                },
                {
                    label:'Erdöl',
                    data : [1.5,1, 2.2],
                    backgroundColor: 'rgba(108,42,0,0.8)'
                },
                {
                    label:'Erdgas',
                    data : [1.5,1, 2.2],
                    backgroundColor: 'rgba(147,63,0,0.8)'
                },
            ]
        }
        let ctx = document.getElementById('canvas').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                },
                barPercentage: 0.6,
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                        title: {
                            display: true,
                            text: "Energy consumed GwH/Jahr"
                        },
                    }
                }
            }
        });
    }

    drawLineTemp=()=>{
        let chartData ={
            labels: ["2019-01","2019-02","2019-03","2019-04","2019-05","2019-06","2019-07","2019-08","2019-09","2019-10","2019-11","2019-12","2020-01","2020-02","2020-03","2020-04","2020-05","2020-06","2020-07","2020-08","2020-09","2020-10","2020-11","2020-12","2021-01","2021-02","2021-03","2021-04","2021-05","2021-06","2021-07","2021-08","2021-09"],
            datasets: [

                {
                    label:'Energy Consumption',
                    data : [720,780,750,720,780,750,620,680,650,620,680,550,520,580,550,520,580,550,420,480,450,420,480,450,420,380,350,320,380,350,320,380,250,],
                    backgroundColor: 'darkSlateGrey',
                    yAxisID: 'y',

                },
                {
                    label:'Temperatur',
                    data : [0,2,6,11,15,19,21,21,16,11,5,1,0,2,6,11,15,19,21,21,16,11,5,1,0,2,6,11,15,19,21,21,16],
                    backgroundColor: 'rgba(255,200,0,0.8)',
                    yAxisID: 'y1'
                },
            ]
        }
        let ctx = document.getElementById('temperatureLine').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'line',
            data: chartData,
            options: {
                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Energy Consumption Mwh"
                        },


                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',

                        // grid line settings
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        ticks:{
                            callback: function(value) {if (value % 1 === 0) {return value + "°C";}}
                        }
                    },

                }
            }
        });
    }


    componentDidMount(){
        this.drawBar()
        this.drawGauge()
        this.drawLineTemp()
        this.drawPieEnergie()
    }

    render() {
        return(<div>


            <div className="w3-row-padding w3-padding">
                <div className="w3-white w3-card w3-panel" style={{"marginTop":"0px"}}>
                    <div className="w3-dropdown-hover w3-white "  style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Jahr</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} defaultChecked={true} type="radio"/> 2020</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2019</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2018</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2017</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white"  style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Land</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> Nord</a>
                            <a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> Mitte</a>
                            <a className="w3-bar-item w3-button"><input type="checkbox"/> Süd</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white"  style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Nutzungsart
                        </button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            {Array.from(new Set(this.props.data.buildings.map(x=>{return x.type}))).map(x=>{return(<a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> {x}</a>)})}

                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white"  style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Objekte</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            {this.props.data.buildings.map(x=>{return(<a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> {x.id}</a>)})}
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white "  style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Portfolio</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-1</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-2</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-3</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-4</a>
                        </div>
                    </div>
                </div>

            </div>

            <div className="w3-row-padding ">
                <div className="w3-twothird">
                    <div className={"w3-row-padding"}>
                        <div className={"w3-third"}>
                            <div className="w3-card sideChangeBar w3-container w3-white"
                                 style={{"margin":"0px 0px 16px 0px", "borderColor":"#ffd747"}}>
                                <h3><i className="fa fa-bolt" aria-hidden="true"  style={{"color":"#ffd747"}} ></i> Strom</h3>
                                <span className="w3-tiny">Vergleich Vorjahr</span>
                                <ul className={"w3-ul hideScrollbarY"} style={{"height":"212px","overflowY":"scroll","width":"100%"}}>
                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P1</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 7%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-1</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P2</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 8%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-2</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P3</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 4%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-3</span><br/>

                                        </div>
                                    </li>


                                </ul>
                            </div>

                        </div>
                        <div className={"w3-third"}>

                            <div className="w3-card sideChangeBar w3-container w3-white"
                                 style={{"margin":"0px 0px 16px 0px", "borderColor":"#ff9a6b"}}>
                                <h3><i className="fa fa-tint"  style={{"color":"#ff9a6b"}}  aria-hidden="true"></i> Warmwasser</h3>
                                <span className="w3-tiny">Vergleich Vorjahr</span>
                                <ul className={"w3-ul hideScrollbarY"} style={{"height":"212px","overflowY":"scroll","width":"100%"}}>
                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P1</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 7%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-1</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P2</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 8%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-2</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P3</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 4%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-3</span><br/>

                                        </div>
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <div className={"w3-third"}>

                            <div className="w3-card sideChangeBar w3-container w3-white"
                                 style={{"margin":"0px 0px 16px 0px", "borderColor":"#6b7fff"}}>
                                <h3><i className="fa fa-tint"  style={{"color":"#6b7fff"}}  aria-hidden="true"></i> Wasser</h3>
                                <span className="w3-tiny">Vergleich Vorjahr</span>
                                <ul className={"w3-ul hideScrollbarY"} style={{"height":"212px","overflowY":"scroll","width":"100%"}}>
                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P1</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 7%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-1</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P2</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 8%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-2</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P3</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 4%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-3</span><br/>

                                        </div>
                                    </li>


                                </ul>
                            </div>
                        </div>
                        <div className={"w3-third"}>
                            <div className="w3-card sideChangeBar w3-container w3-white"
                                 style={{"margin":"0px 0px 16px 0px", "borderColor":"#ff4f23"}}>
                                <h3><i className="fa fa-fire" style={{"color":"#ff4f23"}} aria-hidden="true"></i> Fernwärme</h3>
                                <span className="w3-tiny">Vergleich Vorjahr</span>
                                <ul className={"w3-ul hideScrollbarY"} style={{"height":"212px","overflowY":"scroll","width":"100%"}}>
                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P1</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 7%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-1</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P2</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 8%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-2</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P3</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 4%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-3</span><br/>

                                        </div>
                                    </li>


                                </ul>

                            </div>

                        </div>
                        <div className={"w3-third"}>
                            <div className="w3-card sideChangeBar w3-container w3-white"
                                 style={{"margin":"0px 0px 16px 0px", "borderColor":"#23d3ff"}}>
                                <h3><i className="fa fa-burn" style={{"color":"#23d3ff"}} aria-hidden="true"></i> Gas</h3>
                                <span className="w3-tiny">Vergleich Vorjahr</span>
                                <ul className={"w3-ul hideScrollbarY"} style={{"height":"212px","overflowY":"scroll","width":"100%"}}>
                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P1</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 7%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-1</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P2</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 8%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-2</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P3</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 4%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-3</span><br/>

                                        </div>
                                    </li>


                                </ul>

                            </div>

                        </div>
                        <div className={"w3-third"}>
                            <div className="w3-card sideChangeBar w3-container w3-white"
                                 style={{"margin":"0px 0px 16px 0px", "borderColor":"#1f1b00"}}>
                                <h3><i className="fa fa-trash" style={{"color":"#1f1b00"}} aria-hidden="true"></i> Müll</h3>
                                <span className="w3-tiny">Vergleich Vorjahr</span>
                                <ul className={"w3-ul hideScrollbarY"} style={{"height":"212px","overflowY":"scroll","width":"100%"}}>
                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P1</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 7%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-1</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P2</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 8%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-2</span><br/>

                                        </div>
                                    </li>

                                    <li className="w3-bar">
                                        <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px","marginTop":"8px"}}>
                                            <span style={{"verticalAlign":"middle"}}>P3</span>
                                        </div>
                                        <div className="w3-center w3-bar-item" style={{"width":"70px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 4%</div>

                                        <div className="w3-bar-item" style={{"marginTop":"16px"}}>
                                            <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-3</span><br/>

                                        </div>
                                    </li>


                                </ul>

                            </div>

                        </div>

                    </div>

                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"height":"480px"}}>
                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-chart-bar"></i> Portfoliovergleich - Energiemix</h4></div>
                        </div>
                        <div style={{"height":"380px"}} className="w3-padding">
                            <canvas id="canvas" style={{"height":"210px", "width":"700px"}}></canvas>
                        </div>

                    </div>

                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"height":"400px"}}>
                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-chart-line"></i> Verlauf Energieverbrauch & Temperatur</h4></div>
                        </div>
                        <div style={{"maxHeight":"300px"}} className="w3-padding">
                            <canvas id="temperatureLine" style={{"height":"210px", "width":"700px"}}></canvas>
                        </div>

                    </div>


                </div>



                <div className="w3-third" style={{"paddingRight":"0px"}}>
                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"paddingBottom":"8px"}}>
                        <div className="w3-right w3-panel w3-border-bottom"  style={{"width":"100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-bullseye"></i> Ziele - Verbrauch 2021</h4></div>
                        </div>
                        <div className={"w3-container w3-center"}>
                            <div className={"w3-center"}>
                                <canvas id="gauge"></canvas>
                            </div>
                        </div>

                        <div className={"w3-row-padding w3-left-align"}>
                            <div className={"w3-half"}>
                                <div className={"w3-container w3-large w3-padding-large"}>
                                    <p><span className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-red"}>&#11044;</span> Wasser</p>
                                    <p><span className={"w3-text-green"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> Wärme</p>
                                </div>
                            </div>
                            <div className={"w3-half"}>
                                <div className={"w3-container w3-large w3-padding-large"}>
                                    <p><span className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-yellow"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> Strom</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"paddingBottom":"8px"}}>
                        <div className="w3-right w3-panel w3-border-bottom"  style={{"width":"100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-chart-pie"></i> Energie Verbrauch & Erzeugung</h4></div>
                        </div>
                        <div className={"w3-container w3-center"}>
                            <div className={"w3-center"}>
                                <canvas id="pieEnergie"></canvas>
                            </div>
                        </div>

                    </div>





                </div>
            </div>

        </div>)
    }
}

export default CO2Dashboard;