import React, {Component, createElement, useState} from 'react';
import API from "../../../api/api";
import {ErrorReportContentDiv, renderErrorTypeIcon} from "../inspect_report/ErrorReport";

import {Tab, Tabs} from "react-bootstrap";

// import Table from "@material-ui/core/Table";
// import {Box, Collapse, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
// import IconButton from "@mui/icons-material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {EventComponent} from "../../utils/EventComponent";
import {toast} from "react-toastify";
// import TableFooter from "@material-ui/core/TableFooter";
// import TablePagination from "@material-ui/core/TablePagination";
// import TablePaginationActions from "@material-ui/core/TablePagination/TablePaginationActions";
import Modal from "../../modal/Modal";
import ChangeReport from "./ChangeReport";
import ReactDOM from "react-dom";
import Select from "react-select";
import moment from "moment";

import {CardDiv} from "../inspect_report/InspectReport.styles";
import {getCurrentDate, convertDate, renderCategoryTypeIcon} from "../../utils/GlobalUtils";
import * as PropTypes from "prop-types";

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableFooter,
    TablePagination,
    Collapse,
    Box,
    Button,
    ButtonGroup,
    Typography,
    makeStyles, useTheme,

    IconButton,
    // Tabs, Tab,

} from "@mui/material";

import {
    ExceptionActionButtons,
    ManagerContainer,
    OverdueStatusSpan, StatusDiv,
    VertHr,
    TableSortContainerHead
} from "./styles/PropertiesOverview.styles";
import {Filter, FilterPeriod} from "./filter/Filter";
import {TablePaginationActions} from "../inspect_report/UploadHistoryTable";
import {useUserService} from "../../../services/userService/UserServiceProvider";
import {FilterDiv, FilterPeriodDiv, FilterSelectionDiv} from "../../../uiElements/filter/Filter.styles";


export class TableSortContainer extends Component {
    constructor(props) {
        super(props);
        // create a ref to store the textInput DOM element
        this.ref = React.createRef();
        this.state={
            set: false,
            order:1,
            title : this.props.children,
            events: EventComponent.getInstance(),

        }
        //this.focusTextInput = this.focusTextInput.bind(this);
    }
    clickHandler = ()=>{
        let order = this.state.order;
        if(this.state.set)
            order = order * -1
        this.state.events.publish(this.state.events.Topics.filter.PROPERTY_OVERVIEW, this.ref)

        this.set()
        this.sort(order)
        this.setState({order:order})
    }
    componentDidMount(){
        this.state.events.subscribe(this.state.events.Topics.filter.PROPERTY_OVERVIEW,this.unset)
    }
    componentWillUnmount(){
        this.state.events.unsubscribe(this.state.events.Topics.filter.PROPERTY_OVERVIEW,this.unset)
    }
    unset = (ref)=>{
        if(ref === this.ref)
            this.setState({set:false})
        else{
            this.setState({set:false, order:-1})
        }
    }
    set = ()=>{
        this.setState({set:true})
    }
    sort = (order)=>{
        function rowCompare(a,b, order, type){

            let a_value = ""
            if(a.children[col_index].attributes.value)
                a_value =a.children[col_index].attributes.value.value
            else
                a_value =a.children[col_index].innerText

            let b_value = ""
            if(b.children[col_index].attributes.value)
                b_value =b.children[col_index].attributes.value.value
            else
                b_value =b.children[col_index].innerText


            if(type === "date_by"){
                a_value = a_value.split("\nby")[0]
                b_value = b_value.split("\nby")[0]
            }
            if(!isNaN(a_value))
                a_value = parseFloat(a_value)

            if(!isNaN(b_value))
                b_value = parseFloat(b_value)
            if(typeof a_value == "string")
                return a_value.localeCompare(b_value)*order

            if(a_value==b_value){
                return 0
            }
            if(a_value < b_value){
                return (-1*order)
            }
            else{
                return order
            }

        }

        let col_index = this.ref.current.parentElement.parentElement.children.findIndex(x=>x==this.ref.current.parentElement)
        let table = this.ref.current.parentElement.parentElement.parentElement.parentElement.children[1]
        let rows = table.children
        let new_table_content = []
        if(this.props.tab === "AWAIT"){
            rows.forEach(row =>new_table_content.push(row))

            new_table_content = new_table_content.reduce(function(result, value, index, array) {
                if (index % 2 === 0)
                    result.push(array.slice(index, index + 2));
                return result;
            }, []);

            new_table_content = new_table_content.sort((a,b)=>{
                let type = undefined
                if(a[0].children[col_index].attributes.type)
                    type = a[0].children[col_index].attributes.type.value
                return rowCompare(a[0],b[0],order, type)
            })
            while(table.firstChild)
                table.removeChild(table.lastChild)
            new_table_content.forEach(r=>{
                table.appendChild(r[0])
                table.appendChild(r[1])
            })

        }
        else{
            rows.forEach(row =>new_table_content.push(row))

            new_table_content = new_table_content.sort((a,b)=>{
                let type = undefined
                if(a.children[col_index].attributes.type)
                    type = a.children[col_index].attributes.type.value
                return rowCompare(a,b,order, type)
            })
            while(table.firstChild)
                table.removeChild(table.lastChild)

            new_table_content.forEach(r=>
                table.appendChild(r))
        }


    }

    render() {
        return <TableSortContainerHead ref={this.ref} onClick={this.clickHandler}>
            {this.props.children}
             <i className={"fa-sharp fa-solid fa-arrow-up "+(this.state.order==1?"":"rotate ")+(this.state.set?" selected ":"")}></i>
        </TableSortContainerHead>
    }
}
TableSortContainer.propTypes = {children: PropTypes.node};

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Box sx={{ p: 3 }}>
                //     <Typography>{children}</Typography>
                // </Box>
                <>{children}</>
            )}
        </div>
    );
}

function PropertiesOverviewOld(props){

    const {activeRole} = useUserService();
    const theme = useTheme();

    return(
        <PropertiesOverview_Old roleColor={theme.palette.primary} role={activeRole.role} />
    )

}

class PropertiesOverview_Old extends React.Component {

    constructor(props) {
        super(props)

        console.log("PropertyOverview", props)

        this.state = {
            role: this.props.role,
            roleColor: this.props.roleColor,

            selectedTab: "approved",
            tabs: {
                APPROVED: "approved",
                AWAITING: "awaiting",
                REJECTED: "rejected",
                OVERDUE:  "overdue",
                NOT_UPLOADED:  "not_uploaded"
            },

            selectedFilteredProperties: [],
            selectedProperties: [],
            properties: [],

            availablePeriods: [],

            lastSelectedPeriod: getCurrentDate(),

            events: EventComponent.getInstance(),

        }

        this.api = new API();

        this.filterRef = React.createRef();

        let period = getCurrentDate();
        // let period = "202110";

        this.api.inspect.property.getPeriods(this.api.getCookie("jwt"), {role: this.props.role}).then((responsePeriod) => {
            // this.setState({availablePeriods: responsePeriod.data})

            this.api.inspect.property.getUploadedProperties(this.api.getCookie("jwt"), {period: period, role: this.props.role}).then((responseProperties) => {
                // console.log(response)

                // this.setState({properties: responseProperties.data})
                // this.setState({selectedFilteredProperties: this.getSelectedProperties()})

                console.log("responseProperties", responseProperties.data)
                console.table(responseProperties)

                let state = this.state;
                state.availablePeriods = responsePeriod.data;
                state.properties = responseProperties.data.message;
                state.selectedFilteredProperties = this.getSelectedProperties(responseProperties.data.message)

                this.setState(state);

            }).catch((error) => {
                console.error(error)
            })
        })
    }

    componentDidMount() {
        this.state.events.subscribe(this.state.events.Topics.exception.APPROVE_EXCEPTION, this.approveException);
        this.state.events.subscribe(this.state.events.Topics.exception.REJECT_EXCEPTION, this.rejectException);
    }
    componentWillUnmount() {
        this.state.events.unsubscribe(this.state.events.Topics.exception.APPROVE_EXCEPTION, this.approveException);
        this.state.events.unsubscribe(this.state.events.Topics.exception.REJECT_EXCEPTION, this.rejectException);
    }

    /**
     * Approves an exception, calls the callback-function which was dilvered by event.publish
     * @param error
     * @param callback
     */
    approveException = (error, callback) => {
        // console.log("Approve Error", error)
        let exceptionObject = {}

        exceptionObject.property_id = error.property.id;
        exceptionObject.property_type = error.property.type;
        exceptionObject.property_period = error.property.period;
        exceptionObject.property_status = error.property.status;
        exceptionObject.exception_status = error.exception.exception_status;
        exceptionObject.error_id = error.error_id;
        // exceptionObject.exception_note_pm = error.exception.exception_note_pm;
        exceptionObject.exception_note_am = "approved";

        // console.log(callback)

        this.api.inspect.property.exception.approveException(this.api.getCookie("jwt"), exceptionObject).then((response) => {
            // console.log(response.response)
            if(response.status === 200) {
                callback(response.data)
                toast.info("Exception Approved")
            }else{
                toast.error("Error on approving exception")
            }
        }).catch((error) => {
            console.error(error)
            // toast.error(error.response.data.message)
        })
    }
    rejectException = (error, callback) => {
        // console.log("REJECTING ", error)

        let exceptionObject = {}

        exceptionObject.property_id = error.property.id;
        exceptionObject.property_type = error.property.type;
        exceptionObject.property_period = error.property.period;
        exceptionObject.property_status = error.property.status;
        exceptionObject.exception_status = error.exception.exception_status;
        exceptionObject.error_id = error.error_id;
        // exceptionObject.exception_note_pm = error.exception.exception_note_pm;
        exceptionObject.exception_note_am = error.exception.exception_note_am;

        // console.log(exceptionObject)

        this.api.inspect.property.exception.rejectException(this.api.getCookie("jwt"), exceptionObject).then((response) => {
            // console.log(response.response)

            if(response.status === 200) {
                callback(response.data)
                toast.info("Exception Rejected")
            }else{
                toast.error("Error on rejecting exception")
            }
        }).catch((error) => {
            console.error(error)
            // toast.error(error.response.data.message)
        })


    }

    renderTableHeader = (type) => {

        if(type === "APPROVED")
            return(
                <TableHead>
                    <TableRow className="tableRowHeader" style={{backgroundColor: this.state.roleColor}}>
                        <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Uploaded by</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Approved by</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Days Overdue</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}></TableSortContainer></TableCell>
                    </TableRow>
                </TableHead>
            )
        if(type === "REJECTED")
            return(
                <TableHead>
                    <TableRow className="tableRowHeader" style={{backgroundColor: this.state.roleColor}}>
                        {/*<TableCell></TableCell>*/}
                        <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Uploaded by</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Property Manager</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Rejected by</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Days Overdue</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}></TableSortContainer></TableCell>
                    </TableRow>
                </TableHead>
            )
        if(type === "AWAIT")
            return(
                <TableHead>
                    <TableRow className="tableRowHeader" style={{backgroundColor: this.state.roleColor}}>
                        <TableCell><TableSortContainer tab={type}></TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Uploaded by</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>In Review since</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Exception count</TableSortContainer></TableCell>
                        {/*<TableCell>Status</TableCell>*/}
                        <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Days Overdue</TableSortContainer></TableCell>
                        {/*<TableCell>Action</TableCell>*/}
                    </TableRow>
                </TableHead>
            )
        if(type === "OVERDUE")
            return(
                <TableHead>
                    <TableRow className="tableRowHeader" style={{backgroundColor: this.state.roleColor}}>
                        <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Uploaded by</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Property Manager</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Last change</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Status</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Days Overdue</TableSortContainer></TableCell>
                    </TableRow>
                </TableHead>
            )
        if(type === "NOT_UPLOADED")
            return(
                <TableHead>
                    <TableRow className="tableRowHeader" style={{backgroundColor: this.state.roleColor}}>
                        <TableCell><TableSortContainer tab={type}>Type</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Property</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Period</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Delivery Deadline</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Property Manager</TableSortContainer></TableCell>
                        <TableCell><TableSortContainer tab={type}>Last change</TableSortContainer></TableCell>
                        {/*<TableCell>< Status</TableCellTableSortContainer>*/}
                        <TableCell><TableSortContainer tab={type}>Overdue</TableSortContainer></TableCell>
                    </TableRow>
                </TableHead>
            )

    }
    refreshProperty = () => {
        this.api.inspect.property.getUploadedProperties(this.api.getCookie("jwt"), null).then((response) => {
            // console.log(response.data)
            let state = this.state;
            state.properties = response.data.message;
            this.setState(state);
        }).catch((error) => {

        })
    }

    refreshPropertyOverview = () => {

        console.log(this.state.lastSelectedPeriod)

        let data = {
            role: this.props.role
        }
        if(this.state.lastSelectedPeriod !== "none")
            data["period"] = this.state.lastSelectedPeriod;

        this.api.inspect.property.getUploadedProperties(this.api.getCookie("jwt"), data).then((response) => {
            // console.log(response.data)
            let state = this.state;
            state.properties = response.data.message;
            this.setState(state);
        }).catch((error) => {

        })
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        if(!this.state.filterPlaced) {
            this.utils.insertAfterNode(this.filterRef, "statusTabs")
            this.setState({filterPlaced: true})
        }
        return true;
    }

    getPropertiesWithPeriod = (period) => {

        console.log("GetPropertiesWithPeriod", period)

        let data = {role: this.props.role};

        if(period !== "none") {
            data["period"] = period;
        }
        this.setState({lastSelectedPeriod: period})

        this.api.inspect.property.getUploadedProperties(this.api.getCookie("jwt"), data).then((response) => {
            console.log("GetPropsWithPeriod", response)
            console.table(response.data.message)
            this.setState({properties: response.data.message})
            let selectedProperties = this.getSelectedProperties(response.data.message);
            console.log("SelectedProperties", selectedProperties)
            this.setState({selectedFilteredProperties: selectedProperties})
        }).catch((error) => {
            console.error(error)
        })
    }

    /**
     * Returns the an Array with all Properties regarding which tab was selected
     * @returns {undefined}
     */
    getSelectedProperties(properties){
        // let {properties} = this.state;

        let properties_APPROVED = properties.filter((property) => property.status.includes("APPROVED"));
        let properties_REJECTED = properties.filter((property) => property.status.includes("REJECTED"));
        let properties_AWAITING = properties.filter((property) => property.status.includes("AWAIT"));
        let properties_NOT_UPLOADED = properties.filter((property) => property.status.includes("NOT_UPLOADED"));
        // let properties_OVERDUE = properties.filter((property) => property.status.includes("OVERDUE"));
        let properties_OVERDUE = properties.filter((property) => property.statusOverdue != null && property.statusOverdue.includes("OVERDUE") && property.status !== "NOT_UPLOADED");
        // console.log(properties_OVERDUE_2)

        let selectedProperties;
        switch (this.state.selectedTab) {
            case this.state.tabs.APPROVED: selectedProperties = properties_APPROVED; break;
            case this.state.tabs.REJECTED: selectedProperties = properties_REJECTED; break;
            case this.state.tabs.AWAITING: selectedProperties = properties_AWAITING; break;
            case this.state.tabs.OVERDUE: selectedProperties = properties_OVERDUE; break;
            case this.state.tabs.NOT_UPLOADED: selectedProperties = properties_NOT_UPLOADED; break;
            default: selectedProperties = undefined;
        }
        return selectedProperties;
    }
    setFilteredProperties = (filteredProps) => {
        console.log("Set FilteredProperties", filteredProps)
        let state = this.state;
        // state.selectedProperties = filteredProps;
        // this.setState(state);
        this.setState({selectedFilteredProperties: filteredProps});
    }
    // setFilteredDates = (filteredProps) => {
    //     this.setState({selectedFilteredProperties: filteredProps});
    // }

    setTab = (tab) => {
        let state = this.state;
        state.selectedTab = tab;
        state.selectedFilteredProperties = this.getSelectedProperties(this.state.properties);
        state.selectedProperties = [];
        this.setState(state)
    }

    utils = {

        insertAfterNode(elementRef, elementClassName){
            let element = document.getElementsByClassName(elementClassName)[0];
            try{
                //eslint-disable-next-line
                let filterNode = ReactDOM.findDOMNode(elementRef.current)

                //eslint-disable-next-line
                let node = ReactDOM.findDOMNode(element)

                node.parentNode.insertBefore(filterNode, node.nextSibling)
                filterNode.style.visibility = "visible";

            }catch (e) {
                console.error("Error on inserting Filter: ", e)
            }

            // ReactDOM.findDOMNode()
            // console.log(node)
        }

    }

    getTabProps = (index) => {
        return {
            id: index,
            'aria-controls': index,
        }
    }

    render() {

        let {properties, selectedFilteredProperties} = this.state;
        //
        let properties_APPROVED = properties.filter((property) => property.status.includes("APPROVED"));
        let properties_REJECTED = properties.filter((property) => property.status.includes("REJECTED"));
        let properties_AWAITING = properties.filter((property) => property.status.includes("AWAIT"));
        let properties_NOT_UPLOADED = properties.filter((property) => property.status.includes("NOT_UPLOADED"));
        // let properties_OVERDUE = properties.filter((property) => property.status.includes("OVERDUE"));
        let properties_OVERDUE = properties.filter((property) => property.statusOverdue != null && property.statusOverdue.includes("OVERDUE") && property.status !== "NOT_UPLOADED");

        let selectedProperties = this.getSelectedProperties(this.state.selectedFilteredProperties);

        // let selectedFilteredProps = [];

        // console.log(selectedProperties, selectedFilteredProperties);
	// console.log(properties_APPROVED, properties_AWAITING, properties_NOT_UPLOADED)
        //
        let selectedFilteredProps = selectedFilteredProperties;
        // if(selectedFilteredProperties.length === 0)
        //     selectedFilteredProps = selectedProperties;
        // else{
        //     selectedFilteredProps = selectedFilteredProperties;
        // }
        // // let selectedFilteredProps = (selectedProperties.length >= selectedFilteredProperties.length) ? selectedFilteredProperties : selectedProperties;
        //
        console.log("SelectedProperties", selectedProperties)
        console.log("SelectedFilteredProps", selectedFilteredProps)

        return(
                <ErrorReportContentDiv>
                    <h1>Property Overview</h1>

                    <CardDiv style={{padding: 12}}>
                        <FilterPeriod
                            // filterRef={this.filterRef}
                            availablePeriods={this.state.availablePeriods}
                            properties={selectedProperties}
                            filteredProperties={selectedFilteredProperties}
                            setFilteredProperties={this.setFilteredProperties}
                            getPropertiesWithPeriod={this.getPropertiesWithPeriod}
                        />
                        <Filter
                            filterRef={this.filterRef}
                            properties={properties}
                            filteredProperties={selectedFilteredProperties}
                            setFilteredProperties={this.setFilteredProperties}
                            selectedTab={this.state.selectedTab}
                        />

                        <Tabs
                            id="statusTabs"
                            activeKey={this.state.selectedTab}
                            onSelect={(tab) => this.setTab(tab)}
                            className="statusTabs"
                            style={{width: "100%"}}
                        >
                            <Tab eventKey={this.state.tabs.APPROVED} title={"Approved (" + properties_APPROVED.length + ")" }>
                                <Table>
                                    {this.renderTableHeader("APPROVED")}
                                    <TableBody>
                                        {
                                            // report.properties.length == 0
                                            selectedProperties.length === 0
                                                ?
                                                <TableRow>
                                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={9}> No approved properties found! </TableCell>
                                                </TableRow>
                                                : selectedProperties.map((propertyInfos, key) => {
                                                    return(
                                                        <PropertyContent
                                                            history={this.props.history}
                                                            key={key}
                                                            property={propertyInfos}
                                                            role={this.props.role}
                                                            contentToRenderForTab={"APPROVED"}
                                                            roleColor = {this.props.roleColor}
                                                        ></PropertyContent>
                                                    );
                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </Tab>
                            <Tab eventKey={this.state.tabs.AWAITING} title={"In Review (" + properties_AWAITING.length + ")" }>
                                <Table>
                                    {this.renderTableHeader("AWAIT")}
                                    <TableBody>
                                        {
                                            selectedProperties.length === 0
                                                ?
                                                <TableRow>
                                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={9}> No properties for exception approval found! </TableCell>
                                                </TableRow>
                                                : selectedProperties.map((propertyInfos, key) => {
                                                    return(
                                                        <PropertyContent
                                                            property={propertyInfos}
                                                            key={key}
                                                            role={this.props.role}
                                                            refreshProperty={this.refreshPropertyOverview}
                                                            contentToRenderForTab={"AWAIT"}
                                                            roleColor = {this.props.roleColor}
                                                        />
                                                    );
                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </Tab>
                            <Tab eventKey={this.state.tabs.REJECTED} title={"Rejected (" + properties_REJECTED.length + ")" }>
                                <Table>
                                    {this.renderTableHeader("REJECTED")}
                                    <TableBody>
                                        {
                                            selectedProperties.length === 0
                                                ?
                                                <TableRow>
                                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={8}> No rejected properties found! </TableCell>
                                                </TableRow>
                                                : selectedProperties.map((propertyInfos, key) => {
                                                    return(
                                                        <PropertyContent
                                                            property={propertyInfos}
                                                            key={key}
                                                            role={this.props.role}
                                                            contentToRenderForTab={"REJECTED"}
                                                            roleColor = {this.props.roleColor}
                                                        />
                                                    );
                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </Tab>
                            <Tab eventKey={this.state.tabs.OVERDUE} title={"Overdue (" + properties_OVERDUE.length + ")" }>
                                <Table>
                                    {this.renderTableHeader("OVERDUE")}
                                    <TableBody>
                                        {
                                            selectedProperties.length === 0
                                                ?
                                                <TableRow>
                                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={9}> No Overdue properties found! </TableCell>
                                                </TableRow>
                                                : selectedProperties.map((propertyInfos, key) => {
                                                    return(
                                                        <PropertyContent
                                                            property={propertyInfos}
                                                            key={key}
                                                            role={this.props.role}
                                                            contentToRenderForTab={"OVERDUE"}
                                                            roleColor = {this.props.roleColor}
                                                        />
                                                    );
                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </Tab>
                            <Tab eventKey={this.state.tabs.NOT_UPLOADED} title={"Not Uploaded (" + properties_NOT_UPLOADED.length + ")" }>
                                <Table>
                                    {this.renderTableHeader("NOT_UPLOADED")}
                                    <TableBody>
                                        {
                                            selectedProperties.length === 0
                                                ?
                                                <TableRow>
                                                    <TableCell style={{width: "100%", color: "gray"}} align="center" colSpan={7}> No Uploaded properties found! </TableCell>
                                                </TableRow>
                                                : selectedProperties.map((propertyInfos, key) => {
                                                    return(
                                                        <PropertyContent
                                                            property={propertyInfos}
                                                            key={key}
                                                            role={this.props.role}
                                                            contentToRenderForTab={"NOT_UPLOADED"}
                                                            roleColor = {this.props.roleColor}
                                                        />
                                                    );
                                                })
                                        }
                                    </TableBody>
                                </Table>
                            </Tab>
                        </Tabs>

                    </CardDiv>

                    <ChangeReport/>
                </ErrorReportContentDiv>
        )
    }

}
class PropertyContent extends React.Component{

    constructor(props){
        super(props)
        // console.log(props)
        this.state = {
            property: this.props.property,
            refreshProperty: this.props.refreshProperty,
            api: new API(),
            open: false,
            onlyRejectable: false,

            dateFormat: "YYYY/MM/DD",

            events: EventComponent.getInstance(),
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
	// console.log(this.props, nextProps)
        if(this.props.property.exceptions !== []){
            //Check if any exception is rejected
            let rejectedExceptions = this.props.property.exceptions.filter((exception) => exception.exception !== null && exception.exception.exception_status.includes("REJECTED"));
            // console.log(rejectedExceptions)
            if(rejectedExceptions.length > 0){
                // let state = this.state;
                // state.onlyRejectable = true;
                // this.setState(state)
                nextState.onlyRejectable = true;
            }
        }
	return true;
    }

    setOnlyRejectable = (onlyRejectable) => {
        let state = this.state;
        state.onlyRejectable = onlyRejectable;
        this.setState(state)
    }

    openCollapse = (open) => {
	console.log(this.props.property)
        this.setState({open: !open})
    }

    render() {

        if(this.props.contentToRenderForTab.includes("APPROVED"))       return this.render_APPROVED(this.props.property);
        if(this.props.contentToRenderForTab.includes("AWAIT"))          return this.render_AWAITING(this.props.property);
        if(this.props.contentToRenderForTab.includes("REJECTED"))       return this.render_REJECTED(this.props.property);
        if(this.props.contentToRenderForTab.includes("OVERDUE"))        return this.render_OVERDUE(this.props.property);
        if(this.props.contentToRenderForTab.includes("NOT_UPLOADED"))   return this.render_NOT_UPLOADED(this.props.property);

        else
             return(<p>Error</p>)

    }

    approveOrRejectPropertyExceptions = (property, actionType) => {
        console.log("Action "+actionType, property);

        let propertyWithException = {
            uploaded_property_id: property.id,
            exceptions: property.exceptions
        }

        if(actionType === "APPROVE") {
            this.state.api.notify.property.approve(this.state.api.getCookie("jwt"),propertyWithException).then((response) => {
                console.log(response)
                this.props.refreshProperty();
                toast.success("Approved Property "+property.property.property_id_receiver)
            }).catch((error) => {
                console.error(error)
            })
        }else if(actionType === "REJECT"){
            this.state.api.notify.property.reject(this.state.api.getCookie("jwt"),propertyWithException).then((response) => {
                console.log(response)
                this.props.refreshProperty();
                toast.error("Rejected Property "+property.property.property_id_receiver)
            }).catch((error) => {
                console.error(error)
            })
        }


    }

    // renderCategoryTypeIcon = (type, size) => {
    //
    //     switch(type){
    //         case "RENTROLL": return (<div title={type}><i className="fas fa-2x fa-home"></i></div>)
    //         case "FINANCIAL": return (<div title={type}><i className="fas fa-2x fa-coins"></i></div>)
    //         case "ESG": return (<div title={type}><i className="fas fa-2x fa-leaf"></i></div>)
    //     }
    // }

    updateError = (error) => {
        console.log("Update Error:", error)

        let state = this.state;
        //TODO: Using state.property lead to informationloss
        for(let errorToUpdate of this.props.property.exceptions){
            // console.log(errorToUpdate, error)
            if(errorToUpdate.id === error.id){
                errorToUpdate.exception = error.exception
                if(errorToUpdate.exception.exception_status === "REJECTED"){
                    state.onlyRejectable = true;
                }
                this.setState(state)
                console.log("Update!")
                break;
            }
        }
    }

    getDataChangeEntriesOfProperty = (property) => {
        let object = {
            "uploadedPropertyId": property.id,
            "reportUploadCommId": property.history_of_property[property.history_of_property.length-1].reportUploadCommunication.id,
            "propertyId": property.property.id
        }

        this.state.api.inspect.property.getDataChangeEntriesOfProperty(this.state.api.getCookie("jwt"), object).then((response) => {
            this.state.events.publish(this.state.events.Topics.changeReport.CHANGE_REPORT_DATA, response.data)
        }).catch((error) => {
            console.log(error)
            toast.error(error.response.data.message)
        })

    }

    render_UploadedBy(last_history_entry){

        if(last_history_entry === null)
            return ""
        else{
            let uploadedBy = (last_history_entry.reportUploadCommunication !== null) ? last_history_entry.reportUploadCommunication.user : null;
            if(uploadedBy !== null){

                // if(last_history_entry.status.includes("USER")){
                    return(
                        <div style={{display: 'flex', flexDirection:'column'}}>
                            {/*<span>{new Date(last_history_entry.reportUploadCommunication.timestamp).toLocaleString()}</span>*/}
                            <span>{moment(new Date(last_history_entry.reportUploadCommunication.timestamp)).format(this.state.dateFormat+' HH:mm:ss')}</span>
                            <span>by {uploadedBy.email}</span>
                        </div>
                    )
                // }else{
                //     return(
                //         <div style={{display: 'flex', flexDirection:'column'}}>
                //             {/*<span>{new Date(last_history_entry.reportUploadCommunication.timestamp).toLocaleString()}</span>*/}
                //             <span>{moment(new Date(last_history_entry.reportUploadCommunication.timestamp)).format(this.state.dateFormat+' HH:mm:ss')}</span>
                //             <span>by SYSTEM</span>
                //         </div>
                //     )
                // }


            }else{
                return ""
            }
        }
    }
    render_ChangedBy(last_history_entry) {
        if(last_history_entry === null) return "";
        else{
            let changedByUser = (last_history_entry.changedByUser !== null) ? last_history_entry.changedByUser : null;
            if(changedByUser !== null){
                return(
                    <div style={{display: 'flex', flexDirection:'column'}}>
                        {/*<span>{new Date(last_history_entry.timestamp).toLocaleString()}</span>*/}
                        <span>{moment(new Date(last_history_entry.timestamp)).format(this.state.dateFormat+' HH:mm:ss')}</span>
                        <span>by {changedByUser.email}</span>
                    </div>
                )
            }else{
                return(
                    <div style={{display: 'flex', flexDirection:'column'}}>
                        {/*<span>{new Date(last_history_entry.timestamp).toLocaleString()}</span>*/}
                        <span>{moment(new Date(last_history_entry.timestamp)).format(this.state.dateFormat+' HH:mm:ss')}</span>
                        <span>by System</span>
                    </div>
                )
            }
        }
    }
    render_Status(status, last_history_entry, overdue){

        const renderApproved = (status) => {


            if(last_history_entry){
                return(
                    <StatusDiv backgroundColor={"#4caf50cf"}>
                        {
                            this.render_ChangedBy(last_history_entry)
                        }
                    </StatusDiv>
                )

                    // return this.render_ChangedBy(last_history_entry)
                // }else{
                //     return(
                //         <>
                //             {/*<StatusDiv backgroundColor={"#4caf50cf"}>*/}
                //             {/*    {*/}
                //             {/*        <span>{"Approved by "  + (status.includes("SYSTEM") ? "system" : "user")}</span>*/}
                //             {/*    }*/}
                //             {/*</StatusDiv>*/}
                //             {
                //                 this.render_ChangedBy(last_history_entry)
                //             }
                //         </>
                //     )
                // }
            }else{
                return(
                    <>Missing history Entry</>
                    // <StatusDiv backgroundColor={"#4caf50cf"}>
                    // {
                    //     <span>{"Approved by "  + (status.includes("SYSTEM") ? "system" : "user")}</span>
                    // }
                    // </StatusDiv>
                )
            }


        }
        const renderRejected = (status) => {
            return(
                <StatusDiv backgroundColor={"#ff000082"}>
                    {
                        // <span>{"Rejected by "  + (status.includes("SYSTEM") ? "system" : "user")}</span>
                        this.render_ChangedBy(last_history_entry)
                    }
                </StatusDiv>
            )
        }
        const renderAwait = (status) => {
            return(
                <StatusDiv backgroundColor={"#1f77b4b3"}>
                    <span>In review</span>
                </StatusDiv>
            )
        }

        switch(true){
            case status.includes("APPROVED"):   return renderApproved(status);
            case status.includes("AWAIT"):      return renderAwait(status);
            case status.includes("REJECTED"):   return renderRejected(status);
        }
    }

    render_ActionButtons = (property) => {
        const showReport = (property) => {
            console.log(this.props)
            this.props.history.push({
                pathname: "/inspect",
                state: {}
            })
        }

        return(
            <button onClick={ ()=>{showReport(property)} }>ACTION</button>
        )
    }


    /** Render Tabs */
    render_APPROVED = (property) => {

        // console.log(this.props.role)

        let history = property.history_of_property;
        let last_history_entry = history[history.length - 1];

        let daysLeft = this.calculateDaysOverdue(property.delivery_deadline, property.history_of_property[property.history_of_property.length - 1].timestamp);

        return(
            <TableRow key={property.id} className="tableRow">
                <TableCell type={"string"} value = {property.type} style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                <TableCell type={"string"} value = {property.property.property_id_receiver}> {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                <TableCell type={"string"} value = {property.period}> {convertDate(property.period)}</TableCell>
                <TableCell type={"date_by"}> {this.render_UploadedBy(property.history_of_property[property.history_of_property.length - 1])} </TableCell>
                <TableCell type={"date_by"}> {this.render_ChangedBy(last_history_entry)}</TableCell>
                <TableCell> {moment(property.delivery_deadline).format(this.state.dateFormat)}</TableCell>
                {/*<TableCell>*/}
                {/*    { (property.statusOverdue=="NOT_OVERDUE") ? "On Time" : this.calculateDaysOverdue(property.delivery_deadline, property.history_of_property[property.history_of_property.length - 1].timestamp)+" Days late"}*/}
                {/*</TableCell>*/}
                <TableCell value={property.statusOverdue=="NOT_OVERDUE"?-1:daysLeft}>
                    { (property.statusOverdue=="NOT_OVERDUE") ?
                        <OverdueStatusSpan status={property.statusOverdue}>On Time</OverdueStatusSpan>
                        :
                        <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>

                    }
                </TableCell>

                {
                    (this.props.role === "AM")
                        ? <TableCell><button style={{display: "none"}} disabled={true}>Undo</button></TableCell>
                        : <TableCell/>
                }
                <TableCell>{this.render_ActionButtons(property)}</TableCell>
            </TableRow>
        );
    }
    render_AWAITING = (property) => {

        let {open} = this.state;
        let {role} = this.props;
        let daysLeft = this.calculateDaysOverdue(property.delivery_deadline);

        let history = property.history_of_property;
        let last_history_entry = history[history.length - 1];

        return(
            <React.Fragment>
                <TableRow key={property.id} className="tableRow" onClick={() => this.openCollapse(open)}>
                    {/*<TableRow key={property.id} className="tableRow">*/}
                    <TableCell style={{width: 20}}>
                        <IconButton aria-label="expand row" size="small">
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell type={"string"} value = {property.type} style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                    <TableCell type={"string"} value = {property.property.property_id_receiver} > {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                    <TableCell type={"string"} value = {property.period}> {convertDate(property.period)}</TableCell>
                    <TableCell type={"date_by"}> {this.render_UploadedBy(last_history_entry)} </TableCell>
                    <TableCell type={"date_by"}> {this.render_ChangedBy(last_history_entry)}</TableCell>
                    <TableCell value = {(property.exceptions) ? property.exceptions.length : 0}> {(property.exceptions) ? property.exceptions.length : 0}</TableCell>
                    {/*<TableCell> {property.status}</TableCell>*/}
                    <TableCell value = {property.delivery_deadline}> {moment(property.delivery_deadline).format('YYYY/MM/DD')}</TableCell>
                    <TableCell value={property.statusOverdue=="NOT_OVERDUE"?-1:daysLeft}>
                        { (property.statusOverdue=="NOT_OVERDUE") ?
                            <OverdueStatusSpan status={property.statusOverdue}>On Time</OverdueStatusSpan>
                            :
                            <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>
                        }
                    </TableCell>
                    {/*<TableCell> {this.render_ActionButtons(property)} </TableCell>*/}
                </TableRow>
                <TableRow className="">
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                        {/*<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}  className={classes.fileInfoTable}>*/}
                        {/*    Collapsible Content*/}
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={0} >
                                <ErrorsWithPagination
                                    property={property}
                                    updateError={this.updateError}
                                    role={role}
                                />
                            </Box>

                            {
                                (role === "AM")
                                    ?
                                    <ExceptionActionButtons>
                                        <button className={(this.state.onlyRejectable) ? "" : "approve"} onClick={() => this.approveOrRejectPropertyExceptions(this.props.property, "APPROVE")} disabled={this.state.onlyRejectable}>
                                            <i className="fas fa-check-square fa-lg" aria-hidden="true"></i>
                                            <span>Approve</span>
                                        </button>
                                        <button className="reject" onClick={() => this.approveOrRejectPropertyExceptions(this.props.property, "REJECT")}>
                                            <i className="fas fa-window-close fa-lg" aria-hidden="true"></i>
                                            <span>Reject</span>
                                        </button>
                                    </ExceptionActionButtons>
                                    :
                                    <></>

                            }
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }
    render_REJECTED = (property) => {
        // console.log("REJECTED", property)
        let assigned_users = property.property.assigned_users.filter((user, key) => user.role === "PM");
        // let assigned_users = property.property.assigned_users;
        let daysLeft = this.calculateDaysOverdue(property.delivery_deadline);

        let history = property.history_of_property;
        let last_history_entry = history[history.length - 1];

        return(
            <TableRow key={property.id} className="tableRow">
                <TableCell type={"string"} value = {property.type}  style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                <TableCell type={"string"} value = {property.property.property_id_receiver} > {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                <TableCell type={"string"} value = {property.period}> {convertDate(property.period)}</TableCell>
                <TableCell type={"date_by"}> {this.render_UploadedBy(property.history_of_property[property.history_of_property.length - 1])} </TableCell>
                <TableCell type={assigned_users.length}>
                    <ManagerButton assigned_users = {assigned_users}></ManagerButton>
                </TableCell>
                {/*<TableCell> {property.status}</TableCell>*/}
                <TableCell type={"date_by"}>{this.render_ChangedBy(last_history_entry)}</TableCell>
                <TableCell value = {property.delivery_deadline}> {moment(property.delivery_deadline).format('YYYY/MM/DD')}</TableCell>
                <TableCell value={property.statusOverdue=="NOT_OVERDUE"?-1:daysLeft}>
                    { (property.statusOverdue=="NOT_OVERDUE") ?
                        <OverdueStatusSpan status={property.statusOverdue}>On Time</OverdueStatusSpan>
                        :
                        <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>
                    }
                </TableCell>

                {
                    (property.enabled_features.includes("CHANGE_REPORTING"))
                        ?
                        <TableCell>
                            <div className={"w3-right"}>
                                    <button style={{width:"initial", backgroundColor: this.props.roleColor, color: "white", borderRadius:0, border: "none", display: "inline-block", padding: "8px 16px", verticalAlign: "middle", overflow: "hidden", textDecoration: "none", textAlign: "center", cursor: "pointer", whiteSpace: "nowrap"}} onClick={() => this.getDataChangeEntriesOfProperty(property)} title="Change Report">
                                        <span>Download Change Report </span>
                                        <i
                                            className="far fa-file-excel w3-xlarge"></i><span className="w3-small" style={{marginLeft:"-2px"}}><i
                                        className="fa fa-arrow-down w3-small"></i></span>
                                    </button>
                            </div>
                        </TableCell>
                        :
                        <></>
                }

                {
                    (this.props.role === "AM") ? <TableCell><button style={{display: "none"}} disabled={true}>Undo</button></TableCell> : <></>
                }

            </TableRow>
        );
    }
    render_OVERDUE = (property) => {
        console.log("RENDER OVERDUE", property.status, property.statusOverdue)
        let assigned_users = property.property.assigned_users

        let last_update = new Date(property.last_update);

        let history = property.history_of_property;
        let last_history_entry = history[history.length - 1];

        let daysLeft;
        if(property.status.includes("APPROVED"))
            daysLeft = this.calculateDaysOverdue(property.delivery_deadline, property.history_of_property[property.history_of_property.length - 1].timestamp);
        else
            daysLeft = this.calculateDaysOverdue(property.delivery_deadline);

        return(
            <TableRow key={property.id} className="tableRow" style={{zIndex: "unset"}}>
                <TableCell type={"string"} value = {property.type}  style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                <TableCell type={"string"} value = {property.property.property_id_receiver}> {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                <TableCell value = {property.period}> {convertDate(property.period)}</TableCell>
                <TableCell type={"date_by"}> {this.render_UploadedBy(property.history_of_property[property.history_of_property.length - 1])} </TableCell>
                <TableCell value={assigned_users.length}>
                    <ManagerButton assigned_users = {assigned_users}></ManagerButton>
                </TableCell>
                <TableCell value = {property.last_update} > {last_update.toLocaleDateString("de-de")}</TableCell>
                <TableCell type={"date_by"}> {this.render_Status(property.status, last_history_entry, true)}</TableCell>
                <TableCell value={property.delivery_deadline}> {moment(property.delivery_deadline).format('YYYY/MM/DD')}</TableCell>
                <TableCell value={property.statusOverdue=="NOT_OVERDUE"?-1:daysLeft}>
                    <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>
                </TableCell>
                {/*<TableCell> {property.statusOverdue}</TableCell>*/}

            </TableRow>
        );
    }
    render_NOT_UPLOADED = (property) => {
        // console.log("RENDER OVERDUE", property.status, property.status)
        let assigned_users = property.property.assigned_users

        let last_update = new Date(property.last_update);

        let daysLeft = this.calculateDaysOverdue(property.delivery_deadline);
        console.log("daysLeft", daysLeft)
        return(
            <TableRow key={property.id} className="" style={{zIndex: "unset", position:"relative"}}>
                <TableCell type={"string"} value = {property.type} style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                <TableCell type={"string"} value = {property.property.property_id_receiver}> {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                <TableCell value = {property.period}> {convertDate(property.period)}</TableCell>
                <TableCell value={property.delivery_deadline}> {(property.delivery_deadline !== null) ? moment(property.delivery_deadline).format('YYYY/MM/DD') : ""}</TableCell>
                <TableCell value={assigned_users.length}>
                    <ManagerButton assigned_users = {assigned_users}></ManagerButton>
                </TableCell>
                <TableCell value = {property.last_update}> {moment(last_update).format(this.state.dateFormat)}</TableCell>
                {/*<TableCell> {property.status}</TableCell>*/}
                <TableCell value={property.statusOverdue=="NOT_OVERDUE"?-1:daysLeft}>
                    { (property.statusOverdue=="NOT_OVERDUE") ?
                        <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>On Time</OverdueStatusSpan>
                        :
                        <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>
                    }
                </TableCell>
            </TableRow>
        );
    }

    calculateDaysOverdue = (delivery_deadline, dateOfApproval) => {

        if(dateOfApproval){
            let deadline = new Date(delivery_deadline);
            let daysOverdue = moment(dateOfApproval).diff(deadline, 'days')
            return daysOverdue;
        }else{
            let deadline = new Date(delivery_deadline);
            let now = new Date();
            let daysOverdue = moment(now).diff(deadline, 'days')
            return daysOverdue;
        }

    }

}

class ManagerButton extends Component{
    constructor(props) {
        super(props);
        this.state = {
            clicked: false
        }
    }


    render(){
        let length = this.props.assigned_users.length
        let label = length + " " + (length==1 ? "Manager" : "Managers")

        let pm_length = this.props.assigned_users.filter((user, key) => user.role === "PM").length;
        let am_length = this.props.assigned_users.filter((user, key) => user.role === "AM").length;
        let max_table_length = pm_length > am_length ? pm_length : am_length;

        let pm_users = this.props.assigned_users.filter((user, key) => user.role === "PM")
        let am_users = this.props.assigned_users.filter((user, key) => user.role === "AM")

        // console.log([...Array(max_table_length).keys()], max_table_length);

        return(
            <ManagerContainer onClick={()=>{this.setState({clicked:!this.state.clicked})}}>
                <div className = {("w3-center transition label "+(this.state.clicked?"clicked":""))}>
                    <span>{label}</span>
                        <table className={"w3-left-align"}>
                            <thead>
                                <tr className={""}>
                                    <th style={{paddingLeft:"16px"}}>PM</th>
                                    {
                                        (am_length === 0)
                                            ?
                                            <></>
                                            :
                                            <th style={{paddingLeft:"16px"}}>AM</th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                            {
                                [...Array(max_table_length).keys()].map(i=>{
                                    return <tr key={i}>
                                        <td style={{paddingRight:"8px"}}>
                                            {
                                                (pm_users[i]!== undefined)?
                                                    <div>
                                                        <div style={{margin:"4px 0 -4px 0"}}>{pm_users[i].user.first_name} {pm_users[i].user.last_name}</div>
                                                        <div className={"w3-tiny"}>{pm_users[i].user.email}</div>
                                                    </div>:
                                                    <div></div>
                                            }
                                        </td>
                                        <td style={{paddingLeft:"8px"}}>
                                            {
                                                (am_users[i]!== undefined)?
                                                    <div>
                                                        <div style={{margin:"4px 0 -4px 0"}}>{am_users[i].user.first_name} {am_users[i].user.last_name}</div>
                                                        <div className={"w3-tiny"}>{am_users[i].user.email}</div>
                                                    </div>:
                                                    <div></div>
                                            }
                                        </td>
                                    </tr>
                                })
                            }
                            </tbody>
                        </table>
                </div>

            </ManagerContainer>
        )
    }
}

function ErrorsWithPagination(props) {

    let {property, updateError, role} = props;
    // console.log(property)

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getTableRows = (property, errors) => {
        // console.log(errors)

        let rows = [];
        errors.map((error) => {
            // console.log(error)
            rows.push({
                property: {
                    id: property.property.id,
                    period: property.period,
                    type: property.type,
                    status: property.status,
                    uploaded_property_id: property.id
                },
                error_id: error.id,
                error_type: error.error_type,
                table_name: error.table_name,
                field_name: error.field_name,
                line_number: error.line_number,
                error_description: error.error_description,
                exception: error.exception,
            });
        })
        rows.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));

        return rows;
    }

    const render = () => {
        let rows = getTableRows(property, property.exceptions);
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

        return(
            <Table size="small" aria-label="filenames">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {/*<TableCell>Error ID</TableCell>*/}
                        <TableCell>Table</TableCell>
                        <TableCell>Field</TableCell>
                        <TableCell>Line</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Managers</TableCell>
                        <TableCell>Note</TableCell>
                        {
                            (role === "AM")
                                ?
                                <TableCell align="center">Approve/Reject</TableCell>
                                :
                                <TableCell/>
                        }

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (rowsPerPage > 0
                                ?
                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                :
                                rows
                        ).map((error, key) => (
                            <ExceptionContent error={error} updateError={updateError} role={role}></ExceptionContent>
                        ))
                    }

                    {
                        emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={9} />
                            </TableRow>
                        )
                    }

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: rows.length }]}
                            colSpan={9}
                            count={rows.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }

    return render();
}
class ExceptionContent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: props.error,
            events: EventComponent.getInstance(),
            showExceptionModal: false,
            exceptionMessage: "",
            updateError: props.updateError,
        }
    }

    errorActionCallback = (error) => {
        // console.log(error)
        if(error.exception.exception_status === "REJECTED")
            this.setState({showExceptionModal: !this.state.showExceptionModal});
        console.log("ErrorActionCallback: updateError")
        this.state.updateError(error);
    }

    handleErrorAction = (type) => {
        if(type === "APPROVE") {
            this.state.events.publish(this.state.events.Topics.exception.APPROVE_EXCEPTION, this.props.error, this.errorActionCallback);
        } else if(type === "REJECT_WITH_EXCEPTION") {
            this.state.events.publish(this.state.events.Topics.exception.REJECT_EXCEPTION, this.props.error, this.errorActionCallback)
        }else if(type === "REJECT"){
            this.setState({showExceptionModal: !this.state.showExceptionModal});
        }else
            console.error("Error on Error-Action!")

    }

    setExceptionMessage = (textarea) => {
        // this.setState({exceptionMessage: textarea.target.value})
        let state = this.props;
        state.error.exception.exception_note_am = textarea.target.value;
        this.setState(state)
    }

    renderExceptionActionButtons = (exception_status) => {
        if(exception_status === "PENDING"){
            return(
                <ExceptionActionButtons>
                    <button className="approve exception" onClick={() => this.handleErrorAction("APPROVE")}>
                        <i className="fas fa-check-square fa-2x" aria-hidden="true"></i>

                    </button>
                    <button className="reject exception" onClick={() => this.handleErrorAction("REJECT")}>
                        <i className="fas fa-window-close fa-2x" aria-hidden="true"></i>
                    </button>
                </ExceptionActionButtons>
            )
        }else if(exception_status === "ACCEPTED"){
            return(
                <ExceptionActionButtons>
                    <button className="approve exception">
                        <i className="fas fa-check-square fa-2x" aria-hidden="true"></i>
                    </button>
                </ExceptionActionButtons>
            )
        }else if(exception_status === "REJECTED"){
            return(
                <ExceptionActionButtons>
                    <button className="reject exception" onClick={() => this.handleErrorAction("REJECT")}>
                        <i className="fas fa-window-close fa-2x" aria-hidden="true"></i>
                    </button>
                </ExceptionActionButtons>
            )
        }
    }

    render() {

        let {error, role} = this.props;
        // console.log(error)
        return (
            <TableRow key={error.id}>
                <TableCell component="th" scope="row" align="left" style={{width: 50}}>
                    {renderErrorTypeIcon(error.error_type)}
                </TableCell>
                {/*<TableCell>{error.id}</TableCell>*/}
                <TableCell>{error.table_name}</TableCell>
                <TableCell>{error.field_name}</TableCell>
                <TableCell>{error.line_number}</TableCell>
                <TableCell>{error.error_description}</TableCell>
                <TableCell>{error.exception.exception_writer_pm.email}</TableCell>
                <TableCell>{error.exception.exception_note_pm}</TableCell>
                {
                    (role === "AM")
                        ?
                        <TableCell>
                            {
                                this.renderExceptionActionButtons(error.exception.exception_status)
                            }
                        </TableCell>
                        :
                        <TableCell/>
                }

                <TableCell style={{padding: 0}}>
                    <Modal
                        show={this.state.showExceptionModal}
                        handleClose={() => this.setState({showExceptionModal: !this.state.showExceptionModal})}
                        showCloseButton={true}
                        headerText="Exception-Note for Property Manager"
                        customStyle={"customModal"}
                    >
                        <div className="exceptionModal">
                            <label>Exception ID</label> <span>{error.error_id}</span>
                            <label>Error type</label> {renderErrorTypeIcon(error.error_type)}
                            <labal>Table</labal><span>{error.table_name}</span>
                            <labal>Field</labal><span>{error.field_name}</span>
                            <labal>Line</labal><span>{error.line_number}</span>
                            <labal>Description</labal><span style={{color: "red"}}>{error.error_description}</span>
                            <labal>Table</labal><span>{error.table_name}</span>
                            <labal>Exception Note</labal><span>{error.exception.exception_note_pm}</span>
                            <textarea placeholder="Note for the PM why the error is being rejected" name="exceptionMessage" onChange={this.setExceptionMessage} value={this.props.error.exception.exception_note_am}></textarea>

                            {
                                (error.exception.exception_status === "PENDING")
                                    ?
                                    <button className="reject" onClick={() => this.handleErrorAction("REJECT_WITH_EXCEPTION") }>
                                        Reject
                                    </button>
                                    :
                                    <></>
                            }

                        </div>

                    </Modal>
                </TableCell>
            </TableRow>
        )
    }
}

export default PropertiesOverviewOld;