
const ThemeGlobal = {

    palette: {
        primary: "#000000"
    },
    status: {
        AWAIT_EXCEPTION_APPROVAL: "#1F77B4",
        REJECTED_BY_USER: "#ff0000",
        REJECTED_BY_SYSTEM: "#ff0000",
        APPROVED_BY_USER: "#4caf50",
        APPROVED_BY_SYSTEM: "#4caf50",
        OVERDUE: "#f1bf5ae6"
    }

}
export default ThemeGlobal;