import React, {useState} from "react";
import {Alert} from "react-bootstrap";

export function AlertWarningWithDissmiss(message) {
    const [show, setShow] = useState(true);
    console.log(message)
    if (show) {
        return (
            <Alert variant="warning" onClose={() => setShow(false)} dismissible>
                {message.children}
            </Alert>
        );
    }
    return <></>;
    // return <Button onClick={() => setShow(true)}>Show Alert</Button>;
}