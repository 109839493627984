import React from 'react'
import {Navigate, Outlet, useLocation, useNavigate} from 'react-router-dom'
import {useUserService} from "./services/userService/UserServiceProvider";

function PrivateRoute({ component, ...rest }){

    // const location = useLocation();
    // const navigate = useNavigate();
    const userService = useUserService();

    // console.log(navigate)
    // console.log("PrivateRoute - Location", location)
    // const forwardLocation = {...location};

    return userService.isLoggedIn()
        ?
        <Outlet/>
        // <></>
        :
        <Navigate to={{ pathname: '/' }} />
}

export default PrivateRoute