import styled from "styled-components";
import ChooseModal from "./Modal";
import React, {useState} from "react";
import {useUserService} from "../../services/userService/UserServiceProvider";
import {useAPI} from "../../api/v2/apiV2";
import {useLiveQuery} from "dexie-react-hooks";
import {browserDB} from "../../services/storageService/IndexedDB";
import {Button, Checkbox, FormControlLabel} from "@mui/material";
import {toast} from "react-toastify";

const RoleSelectionDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px 20px;
  gap: 20px;
`;

const RoleDiv = styled.div`
  

  width: 100%;
  height: 220px;
  text-align: center;
  //margin: 20px;
  
  cursor: pointer;
  background-color: transparent;
  
  perspective: 1000px; /* Remove this if you don't want the 3D effect */

  
  
  .cardInner{

    position: relative;
    width: 100%;
    height: 100%;
    
    border: 2px solid black;
    border-radius: 5px;
    
    //margin: 0px 10px;
    
    text-align: center;
    transition: transform 0.3s;
    transform-style: preserve-3d;
  }
  
  .coverFront{
    
  }
  
  .coverBack{
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    //background-color: white;
  }
  .coverFront, .coverBack{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;

    >p{
      margin: 20px;
      font-size: 0.8em;
    }
  }
`;

const FooterDiv = styled.div`
    padding: 10px 20px;
`;

function RoleModalV2(props){

    const userService = useUserService();
    const api = useAPI();

    // const allRoles = useLiveQuery(() => browserDB.roles.toArray(), []);

    const [show, setShow] = useState(props.show);
    const [roles, setRoles] = useState(userService.getUser().roles);
    const [selectedRole, setSelectedRole] = useState({});

    const [saveDefaultRole, setSaveDefaultRole] = useState(false);

    // if(allRoles){
	// // console.log(allRoles)
	// if(allRoles.length === 0){		//Init storing all roles
	//     roles.map(role=>{
	// 	browserDB.roles.add({
	// 	    id: role.role,
	// 	    role: role.role,
	// 	    roleName: role.roleName
	// 	})
	//     })
	// }else{
	//     //Still need to load or is empty
	// }
    // }

    const hideModal = () => { setShow(false) }

    const roleText = (role) => {
	if(role === "PM")
	    return `
                As a property manager you will be responsible to upload several 
                properties of your company managed by your asset manager
            `;
	else if(role === "AM")
	    return `
                As a asset manager you will manage several property managers which 
                are uploading properties of your company
            `;
    }

    const handleRoleSelection = (role) => {
	if(saveDefaultRole){
	    userService.setDefaultRole(role);
	    toast.success(role.roleName+"-Role saved as default for the next login")
	}else{
	    userService.setRole(role);
	}
    }

    return(
	<ChooseModal
	    show={show}
	    handleClose={hideModal}
	    headerText="Choose your Dashboard"
	    logout={api.logout}
	    showLogout={true}
	>
	    <RoleSelectionDiv>
		{
		    roles.map((roleObject, key) => {
			return (
			    <RoleDiv
				key={key}
				onClick={() => { handleRoleSelection(roleObject) } }
			    >
				<div className={"cardInner"}>
				    <div className={"coverFront"}>
					<h5>{roleObject.roleName}</h5>
					<p>{roleText(roleObject.role)}</p>
				    </div>
				</div>
			    </RoleDiv>
			)
		    })
		}
	    </RoleSelectionDiv>

	    <FooterDiv>
		<FormControlLabel
		    label="Save selected Role as default"
		    title={"Checking the box will save the selected Role as default for next login"}
		    control={<Checkbox checked={saveDefaultRole} onChange={()=>{setSaveDefaultRole(!saveDefaultRole)}} />}
		/>
	    </FooterDiv>

	</ChooseModal>
    )
}

export default RoleModalV2;