import React, {createContext, useContext, useMemo, useState} from "react";
import LoadingSpinner from "../uiElements/LoadingSpinner";

const LanguageContext = createContext(undefined);

export const LANG = {
    en_US: "en_US",
    de_DE: "de_DE"
}

export function LanguageProvider({children}) {

    const [language, setLanguage] = useState(LANG.en_US);
    const [languageModel, setLanguageModel] = useState(null);

    // const lang = import('./'+language+'/string.js');

    // const [languageModel, setLanguageModel] = useState(import {lang} from ('./'+language+'/strings.js'));

    // console.log("Lang-Model", languageModel, lang)

    const changeLanguage = (lang) => {

        console.log("Change Language", lang)
        if(LANG.hasOwnProperty(lang)){
            setLanguage(lang);
            import("./"+lang+"/string").then((content) => {
                setLanguageModel(content.lang)
            }).catch((error) => {
                console.warn(error)
            })
            return;
        }else{
            console.warn("Does not have", lang)
        }
        // (langType) => {(LANG.hasOwnProperty(langType)) ? setLanguage(langType) : Error(langType+" not supported. Supported languages "+LANG)},
    }

    useMemo(()=> {
        if(languageModel === null){
            import("./"+language+"/string").then((content) => {
                // console.log(content.lang)
                setLanguageModel(content.lang)
            }).catch((error) => {
                console.warn(error)
            })
        }
    }, [])

    if(languageModel !== null){
        // console.log("Finished Loading")
        return(
            <LanguageContext.Provider
                value={{
                    changeLanguage,
                    strings: languageModel
                }}
            >
                {children}
            </LanguageContext.Provider>

        )
    }else{
        // console.log("Loading LanguageProvider")
        return(
            <LoadingSpinner title={"Loading"}/>
        )
    }
}


export function useLanguageService() {
    const context = useContext(LanguageContext);
    if (!context) throw Error('useLanguageService can only be used within the LanguageProvider component')
    return context;
}