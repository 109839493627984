import React, {useEffect, useMemo, useState} from 'react';
import {ContentCard, ContentDiv, DataExportForm, PeriodFilterStyle, StyledExportButton} from "./DataExport.styles";
import {useAPI} from "../../../api/v2/apiV2";
import {useUserService} from "../../../services/userService/UserServiceProvider";
import {FilterPeriod} from "../../../uiElements/filter/PeriodFilter";
import {useLanguageService} from "../../../language/LanguageProvider";
import {toast} from "react-toastify";
import {
    Button,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    CircularProgress
} from "@mui/material";

import PropTypes from "prop-types";
import Select from "react-select";
import {getIconForOption} from "../../../uiElements/Utils";
import {FileWriter} from "./FileWriter";
import {useSSE} from "react-hooks-sse";
import {StreamType} from "../../utils/Constants";

const PropertyTypes = {
    ESG: "ESG",
    FINANCIAL: "FINANCIAL",
    RENTROLL: "RENTROLL",
    VALUATION: "VALUATION"
}

/**
 * DataExport-Page includes following components:
 *
 * Period-Filter
 * Properties - MultiSelect Searchable Dropdown
 * DataType
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function DataExport(props){

    const api = useAPI();
    const {activeRole, activeClient} = useUserService();
    const {strings} = useLanguageService();

    const [availablePeriods, setAvailablePeriods] = useState([])
    const [selectedPeriod, setSelectedPeriod] = useState("");

    const [selectedDataType, setSelectedDataType] = useState();
    const [selectedFileType, setSelectedFileType] = useState();
    const [selectedPlatform, setSelectedPlatform] = useState();
    const [fileExportErrorMessage, setFileExportErrorMessage] = useState();
    const fileExportNotificationRealtime = useSSE("FILE_EXPORT", {});

    const [fileGenerationStatus, setFileGenerationStatus] = useState("idle");
    const [selectedExportFormat, setSelectedExportFormat] = useState();
    const [selectedProperties, setSelectedProperties] = useState([]);

    const [availableProperties, setAvailableProperties] = useState([]);


    const submitDataExport = (event) => {
        event.preventDefault();

        //Old Data-Export
        let obj = {
            period: selectedPeriod,
            fileType: selectedFileType,
            dataType: selectedDataType,
            platform: selectedPlatform,
            export_format: selectedExportFormat,
            dataToExport: selectedProperties.map((propertiesToExport) => propertiesToExport.obj),
            // properties_to_export: selectedProperties.map((propertiesToExport) => propertiesToExport.obj.propertyId),
        }

        //New File-Export
        let export_obj = {

            clientId: activeClient.id,

            fileType: selectedFileType,
            platform: selectedPlatform,
            exportFormat: selectedExportFormat,

            dataType: selectedDataType,
            period: selectedPeriod,
            propertiesToExport: selectedProperties.map((propertiesToExport) => propertiesToExport.obj.propertyId),
        }

        //

        api.propertyExport.exportFile(export_obj).then((result) => {
            if(result.status === 200){
                // change "Generate Files" button to "loading" while showing carousel
                setFileGenerationStatus("processing");
            }
            if(result.status === 204){
                // no object sent, payload empty
                toast.error("Please selcect property to export", {position: toast.POSITION.TOP_RIGHT});

            }
            if(result.status === 422){
                // unprocessable entity
                toast.error("Unprocessable File: "+ result.message, {position: toast.POSITION.TOP_RIGHT});
            }
        })

        // api.propertyExport.exportData(obj).then((result) => {
        //     if(result.status === 200){
        //         let data = result.message;
        //         let filetype = data["_exported_data"].fileType;
        //         let period = data["_exported_data"].period;
        //         delete data["_exported_data"];
        //         FileWriter(filetype, period).createFile(data);
        //     }
        //
        // }).catch((error) => {
        //     console.error(error)
        // })
    }

    /**
     * Initial Requests to get available (all)Periods & (all)Properties
     */
    useMemo(()=> {
        console.log("Init", activeClient)

        api.inspect.availablePeriods({role: activeRole.role, status: "APPROVED"}).then((periods) => {
            setAvailablePeriods(periods)
        }).catch((err) => {
            console.error(err)
        })

        api.propertyExport.getProperties({role: activeRole.role}).then((result) => {
            setAvailableProperties(result.message)
        }).catch((error) => {
            console.error(error)
        })
    }, [])

    /**
     * Triggers when Period has changed and requesting Properties regarding selected Period
     */
    useEffect(() => {
        console.log("FILE_EXPORT, fileExportNotificationRealtime", fileExportNotificationRealtime)
        let exportStatuses = fileExportNotificationRealtime.data?.status_history?.map(x=>x.status)
        let showExportButton = exportStatuses?.reduce((y,x)=>{if( x == "DONE"|| y == 1)return 1;return 0},0)
        let showLoadingButton = exportStatuses?.reduce((y,x)=>{if( x == "PROCESSED"|| x == "PROCESSING"|| x == "READY_FOR_PROCESS" || y == 1)return 1;return 0},0)

        let hasError = exportStatuses?.includes("ERROR");
        if(hasError) showLoadingButton = 0;
        if(showLoadingButton)
            setFileGenerationStatus("processing")
        if(showExportButton)
            setFileGenerationStatus("done")
        if(hasError){
            setFileExportErrorMessage(fileExportNotificationRealtime.message)
            //toast.error("Error while generating files", {position: toast.POSITION.TOP_RIGHT});
        }
    }, [fileExportNotificationRealtime])
    useEffect(() => {
        if(selectedPeriod != ""){

            let dataExport = {
                role: activeRole.role,
                // period: selectedPeriod,
                propertyType: selectedDataType,
                // fileType: selectedFileType,
                // format: selectedPlatform
            }
            if(selectedPeriod !== "none")
                dataExport["period"] = selectedPeriod

            api.propertyExport.getProperties(dataExport).then((result) => {
                setAvailableProperties(result.message)
            }).catch((error) => {
                console.error(error)
            })
        }

    }, [selectedPeriod])

    const renderFormButton = (fileGenerationStatus) => {
        if (fileGenerationStatus == "done") {
            return <StyledExportButton onClick={() => {
                let exportId = fileExportNotificationRealtime.data.export_id;
                api.download(exportId, StreamType.FILE_EXPORT)
            }} status={fileGenerationStatus} type="button" style={{width: "100%"}}>
                Download Files
            </StyledExportButton>
        }
        if (fileGenerationStatus == "processing") {
            return <StyledExportButton status={fileGenerationStatus} style={{width: "100%"}} disabled={true} type="button">
                <CircularProgress size={"0.7em"}/> <span style={{marginLeft: "8px"}}>Loading</span>
                </StyledExportButton>
        }
        return <StyledExportButton status={fileGenerationStatus} type={"submit"} style={{width: "100%"}}>
            Generate Files
        </StyledExportButton>

    }
    return(
        <ContentCard>
            <h1>Data Export</h1>
            <hr/>
            <p>{strings.DATA_EXPORT.DESCRIPTION}</p>
            <ContentDiv>
                <DataExportForm onSubmit={submitDataExport}>
                    <label>Period</label>
                    <FilterPeriod
                        availablePeriods={availablePeriods}
                        setSelectedPeriod={setSelectedPeriod}
                        style={PeriodFilterStyle(availablePeriods)}
                    />
                    <label>Data Type</label>
                    <DataTypeSelection
                        dataTypes={Object.keys(PropertyTypes)}
                        disabledDataTypes={["VALUATION"]}
                        setSelectedDataType={setSelectedDataType}
                        properties={availableProperties}

                    />

                    <label>Properties</label>
                    <MultiSelectPropertiesFilter
                        selectableProperties={availableProperties}

                        selectedDataType={selectedDataType}
                        selectedPeriod={selectedPeriod}

                        setSelectedProperties={setSelectedProperties}
                        disabled={(selectedPeriod === "" || selectedPeriod === "none") ? true : false}
                    />

                    <label>Platform</label>
                    <PlatformSelection
                        platforms={activeClient.instance_settings?.allowed_platforms}
                        setSelectedPlatform={setSelectedPlatform}
                        isSearchable={false}
                    />

                    <label>Export-Format</label>
                    <PlatformSelection
                        platforms={activeClient.instance_settings?.allowed_exports}
                        setSelectedPlatform={setSelectedExportFormat}
                        isSearchable={false}
                    />

                    <label>File Type</label>
                    <DataTypeSelection
                        dataTypes={["XLSX", "CSV", "JSON"]}
                        disabledDataTypes={["JSON"]}
                        setSelectedDataType={setSelectedFileType}
                        // isSearchable={false}
                    />


                    <div style={{gridColumn: "1 /-1", textAlign: "center", display:fileExportErrorMessage?"block":"none"}}>
                        <p style={{color: "red"}}>{fileExportErrorMessage}</p>
                    </div>

                    <div style={{gridColumn: "1 /-1", textAlign: "center"}}>
                        {renderFormButton(fileGenerationStatus)}

                    </div>
                </DataExportForm>
            </ContentDiv>
        </ContentCard>
    )
}

DataExport.propTypes = {}

function DataTypeSelection(props) {

    const [selectedValue, setSelectedValue] = useState(null);

    const handleChange = (elem) => {
        console.log("Set DataType", elem.target.value, elem)
        setSelectedValue(elem.target.value);
        props.setSelectedDataType(elem.target.value);
    }

    const groupStyling = {
        '.MuiFormControlLabel-label':{
            fontSize: "1em",
            textTransform: "capitalize"
        }
    }

    const buttonStyling = {
        '& .MuiSvgIcon-root': {
            fontSize: 18
        }
    }

    const countProperties = (properties, dataType) => {
        return properties?.filter((property) => property.datatype === dataType).length;
    }

    const getLabel = (dataType) => {
        if(props.properties){
            return <>{getIconForOption(dataType)} <span>{dataType.toLowerCase()}({countProperties(props.properties, dataType)})</span> </>
        }else{
            return <>{getIconForOption(dataType)} <span>{dataType.toLowerCase()}</span> </>
        }
    }

    return(
        <FormControl>
            {/*<FormLabel id="demo-radio-buttons-group-label">Data Type</FormLabel>*/}
            <RadioGroup
                // aria-labelledby="demo-radio-buttons-group-label"
                // name="radio-buttons-group"
                value={selectedValue}
                onChange={handleChange}
                row
                sx={groupStyling}
            >
                {
                    props.dataTypes?.map((dataType, key) => {
                        return (
                            <FormControlLabel
                                control={<Radio sx={buttonStyling} required={true}></Radio>}
                                label={ getLabel(dataType) }
                                labelPlacement={"top"}
                                value={dataType}
                                key={key}
                                disabled={(props.disabledDataTypes.some( elem => dataType === elem) || countProperties(props.properties, dataType) === 0)}
                            />
                        )
                    })
                }
            </RadioGroup>
        </FormControl>
    )

}
DataTypeSelection.propTypes = {
    dataTypes: PropTypes.array,
    setSelectedDataType: PropTypes.func,
    disabledDataTypes: PropTypes.array
}

/**
 * UI-Component for MultiSelect & Searchable Properties
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function MultiSelectPropertiesFilter(props){

    console.log(props)

    const [properties, setProperties] = React.useState([]);

    const handleChange = (selectedOption) => {
        console.log(selectedOption)
        setProperties(selectedOption)
        props.setSelectedProperties(selectedOption)
    }

    /**
     * Option-Structure for <Select>
     * @param availableProperties
     * @returns {*[]}
     */
    const getOptions = (availableProperties) => {
        let options = [];

        let filteredProperties = [];
        if(props.selectedDataType){
            filteredProperties = availableProperties.filter((property) => property.datatype === props.selectedDataType)
        }else{
            filteredProperties = availableProperties;
        }

        for(let property of filteredProperties){
            options.push(
                {
                    // value: {
                    //     propertyId: property.property_id,
                    //     period: property.period,
                    //     dataType: property.datatype,
                    //     communication_upload_id: property.comm_upload_id
                    // },
                    value: property.id,
                    label: property.name+" | "+property.city+" "+property.zip+", "+property.street+" "+property.house_number,
                    obj: {
                        propertyId: property.property_id,
                        period: property.period,
                        dataType: property.datatype,
                        communication_upload_id: property.comm_upload_id
                    }
                }
                // { value: property.id, label: property.name }
            )
        }
        return options;
    }

    useEffect(() => {
        console.log("DataType changed", props.selectedDataType)
        setProperties([])
    }, [props.selectedDataType])
    useEffect(() => {
        console.log("Period changed", props.selectedPeriod)
    }, [props.selectedPeriod])

    let options = getOptions(props.selectableProperties);

    return(
        <Select
            isMulti
            value={properties}
            options={options}
            placeholder={options.length+" available properties"}
            onChange={handleChange}
            isDisabled={props.disabled}
        />
    )
}
MultiSelectPropertiesFilter.propTypes = {
    selectableProperties: PropTypes.array,
    selectedDataType: PropTypes.string,
    selectedPeriod: PropTypes.string,
    setSelectedProperties: PropTypes.func,
    disabled: PropTypes.bool
}

function PlatformSelection(props){

    const {activeClient} = useUserService();
    console.log(activeClient);

    const [selectedPlatform, setSelectedPlatform] = useState();

    const platformOptions = (data) => {
        let options = [];
        if(!data)
            return options;
        for(let platform of data){
            options.push({value: platform, label: platform})
        }
        return options;
    }

    const handleChange = (elem) => {
        console.log(elem.value)
        setSelectedPlatform(elem)
        props.setSelectedPlatform(elem.value)
    }

    useEffect(() => {
        if(!props.platforms)
            return;
        if(props.platforms.length === 1)
            props.setSelectedPlatform(props.platforms[0])
    }, [])

    let platform_options = platformOptions(props.platforms);
    return(
        <Select
            defaultValue={platform_options.length === 1 ? platform_options[0] : null}
            value={selectedPlatform}
            options={platform_options}
            onChange={handleChange}
            isSearchable={props.isSearchable}
            required
        />
    )
}
PlatformSelection.propTypes = {
    platforms: PropTypes.array,
    setSelectedPlatform: PropTypes.func,
    isSearchable: PropTypes.bool
}
export default DataExport;