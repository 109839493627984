export function sameObjectArrayById(arr1, arr2){
    if(arr1 == undefined || arr2 == undefined)
        return false
    if(arr1.length != arr2.length)
        return false
    arr1.map(a=>{
        if(arr2.find(b=>b.id==a.id)==undefined)
            return false
    })
    return true
}
export function sameObjectArrayByPropertyId(arr1, arr2){
    let returnvalue = true
    if(arr1 == undefined || arr2 == undefined)
        returnvalue = false
    if(!returnvalue)
        return returnvalue
    if(arr1.length != arr2.length)
        returnvalue = false
    if(!returnvalue)
        return returnvalue
    arr1.map(a=>{
        if(arr2.find(b=>b.propertyId==a.propertyId)===undefined){
            returnvalue = false
        }
    })
    return returnvalue
}
export function getColorBySeqNr(i){
    let colors = [
        '#6929c4',
        '#1192e8',
        '#005d5d',
        '#9f1853',
        '#fa4d56',
        '#570408',
        '#198038',
        '#002d9c',
        '#ee538b',
        '#b28600',
        '#009d9a',
        '#012749',
        '#8a3800',
        '#a56eff',
    ]
    return colors[i%colors.length]
}
export function getIconByType(type){
    let icons = {
        'electricity': 'fa fa-bolt',
        'water': 'fa fa-tint',
        'waste': 'fa fa-trash',
        'heating': 'fa fa-fire',
        'gas': 'fa fa-burn',
        'cooling': 'fa fa-snowflake'
    }
    let value = ""
    Object.keys(icons).every(key=>{
        if(type.toLowerCase().indexOf(key)>-1){
            value = icons[key]
            return false
        }
        return true;
    })
    if(value=="")
        return 'fa fa-question'
    return value
}
export function getColorByType(type) {
    // console.log(type)
    let colors = {
        'electricity_production': '#ccab38',
        'electricity production': '#ccab38',
        'electricity': '#ffd747',
        'warmwater': '#ff9a6b',
        'water': '#6b7fff',
        'waste': '#1f1b00',
        'heating': '#ff4f23',
        'gas': '#23d3ff',
        'cooling': '#00b3ff'
    }
    let value = ""
    Object.keys(colors).every(key=> {
        if (type.toLowerCase().indexOf(key) > -1) {
            value = colors[key]
            return false
        }
        return true;
    })
    if(value=="")
        return '#FF0000'
    return value
}
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}