import API from "../../../../api/api";
import React from "react";
import ESGTileMap from "./ESGTileMap";
import {sameObjectArrayById} from "../functions";
import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/source/Vector';
import View from 'ol/View';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Feature, Overlay} from "ol";
import {Circle, Fill, Icon, Style} from 'ol/style';
import {Point} from "ol/geom";
import {useGeographic} from 'ol/proj';
import {fromLonLat} from 'ol/proj';
import {easeIn, easeOut} from 'ol/easing';
import './mapStyle.css';




class ESGMap extends ESGTileMap{
    constructor(props) {
        super(props);

            let api=new API()

    }
    content(){
        return <div id="ESGMap" style={{"height":"550px", borderRadius:"16px"}} className={""}>
            <div id="popup"></div>
        </div>

    }
    updateLayers = (response)=>{
        let mapData = this.state.mapData
        if(response != undefined)
            mapData = response.data

        let l = []
        let new_layers = []

        let layers_to_remove = this.state.ESGMap_Object.getLayers().array_.slice(1)
        if(this.props.settings.buildings!==undefined)
        this.props.settings.buildings.map(b=> {
            if(mapData === undefined)
                return false;
            let x  = mapData.find(y=> {return y.propertyId == b.propertyId})
            if(x===undefined)
                return false;
            if(x.address_longitude === null || x.address_latitude === null)
                return false;
            let place = [x.address_longitude, x.address_latitude];

             place = [8.624420, 50.139439];
            let point = new Point(place);
            let feature = new Feature(point)
            feature.building = x;
            feature.building_simple = b
            feature.building.selected = false
            if (this.props.settings.selectedBuildings.findIndex(y => y.propertyId === x.propertyId) !== -1) {
                feature.building.selected = true
                feature.building.iconType = "Building_selected"
            }
            else{
                feature.building.iconType = "Building"
            }
            if(this.props.role == "PM")
                feature.building.iconType = feature.building.iconType + "_PM"

            if(this.props.role == "AM")
                feature.building.iconType = feature.building.iconType + "_AM"
            l.push(feature)
            })

            l.forEach(x=>{
                new_layers.push(new VectorLayer({
                    source: new VectorSource({
                        features: [x],
                    }),
                    style: new Style({
                        image: new Icon({
                            scale: [1,1],
                            anchor: [0.50, 1],
                            src: '/images/mapIcons/'+x.building.iconType+'.png',
                        }),
                    }),
                    building:x
                }))
            })
        layers_to_remove.forEach(x=>this.state.ESGMap_Object.removeLayer(x))
        console.log(layers_to_remove, new_layers)
        let min_lat = 180
        let max_lat = -180
        let min_lon = 180
        let max_lon = -180
        new_layers.forEach(x=>{
            this.state.ESGMap_Object.addLayer(x)
            let b = x.values_.building.building
            if(b.selected){
                if(b.address_latitude < min_lat) min_lat = b.address_latitude
                if(b.address_latitude > max_lat) max_lat = b.address_latitude
                if(b.address_longitude < min_lon) min_lon = b.address_longitude
                if(b.address_longitude > max_lon) max_lon = b.address_longitude
            }

        })
        let lat = (min_lat + max_lat)/2
        let lon = (min_lon + max_lon)/2
        let lat_diff = max_lat - min_lat
        let lon_diff = max_lon - min_lon
        console.log(lon_diff, lat_diff)
        lat_diff = lat_diff*1.5
        let diff = lat_diff
        if(lon_diff > lat_diff) diff = lon_diff

        let zoom = 0
        for(let i = 1; i < 80; i++){
            if(diff < (360/(2**(i/10))))
                zoom = (i/10)
        }


        // console.log({new_layers})

        let position = [lon, lat+(diff*0.1)]

        window.mapView.animate({
                center: position,
            duration: 800,
            zoom: zoom,
            }
        );
        }




    drawMap=(response)=>{
        if(this.state.ESGMap_Object !== undefined){
            this.updateLayers(response)
            return "";
        }


        new useGeographic();


        window.layers = [
            new TileLayer({
                source: new OSM(),
            })
        ]
        window.mapView = new View({
            center: [10, 51],
            zoom: 5,
        })

        this.state.ESGMap_Object = new Map({
            layers: window.layers,
            target: 'ESGMap',
            view: window.mapView,
        });

        const element = document.getElementById('popup');

        const popup = new Overlay({
            element: element,
            positioning: 'bottom-center',
            stopEvent: false,
            offset: [0, -10],
        });
        this.state.ESGMap_Object.addOverlay(popup);






        this.state.ESGMap_Object.on('click',  (event)=> {
            const feature = this.state.ESGMap_Object.getFeaturesAtPixel(event.pixel)[0];
            if (feature) {
                // console.log({feature})
                // console.log({event})
                // console.log(this.state.ESGMap_Object )
                if (event.originalEvent.ctrlKey || event.originalEvent.shiftKey){
                 if(this.props.settings.selectedBuildings.findIndex(x=>x.id == feature.building_simple.id) == -1) {
                     // console.log(this.props.settings.selectedBuildings.concat([feature.building_simple]))
                     this.props.buildingSelector(this.props.settings.selectedBuildings.concat([feature.building_simple]))
                 }
                 else{
                     // console.log(this.props.settings.selectedBuildings.filter(x=>x.id != feature.building_simple.id))
                     this.props.buildingSelector(this.props.settings.selectedBuildings.filter(x=>x.id != feature.building_simple.id))


                 }
                }
                else{
                    this.props.buildingSelector([feature.building_simple]);
                    //https://openlayers.org/en/latest/examples/animation.html
                    let center = window.mapView.getCenter();
                    let position = [feature.building.address_longitude, feature.building.address_latitude]

                    window.mapView.animate({
                            center: position,
                            duration: 1000,
                        }
                    );
                }
                this.drawMap();
            } else {
                //document.getElementById("customTtip").style.visibility = 'hidden';
            }
        });
        /*
        this.state.ESGMap_Object.on('pointermove', function (event) {
            if (this.state.ESGMap_Object.hasFeatureAtPixel(event.pixel)) {
                this.state.ESGMap_Object.getViewport().style.cursor = 'pointer';
            } else {
                this.state.ESGMap_Object.getViewport().style.cursor = 'inherit';
            }
            const feature = this.state.ESGMap_Object.getFeaturesAtPixel(event.pixel)[0];
            if (feature) {
                const coordinate = feature.getGeometry().getCoordinates();
                popup.setPosition(coordinate);
                //document.getElementById("customTtip").style.visibility = 'visible';
                //document.getElementById("customTtip").innerText = ""+feature.building.objectidsender+"\n"+feature.building.address_city
            } else {
                //document.getElementById("customTtip").style.visibility = 'hidden';
            }
        });

         */
        this.updateLayers()

    }

    componentDidMount(){
        let api=new API()
        api.esg.getMapData.get(api.getCookie("jwt"), {role: this.props.role, timestamp:this.props.settings.selectedTimestamp, selectedBuildings:[]}).then((response)=>{
            this.setState({mapData:response.data})
            this.drawMap(response)
        })
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        console.log("aaaaaa", nextProps)
        if ( nextProps.settings.selectedBuildings === undefined && this.state.settings.selectedBuildings === undefined)
            return 0
        // console.log(sameObjectArrayById(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings))
        if (!sameObjectArrayById(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings)){
            this.setState({settings:  JSON.parse(JSON.stringify(nextProps.settings))})
            let api=new API()
            api.esg.getMapData.get(api.getCookie("jwt"), {role: nextProps.role, timestamp:nextProps.settings.selectedTimestamp, selectedBuildings:[]}).then((response)=>{
                this.setState({mapData:response.data})
                this.drawMap(response)
            })


        }
    }
}

export default ESGMap;