/* eslint-disable */
import React, {Component} from "react";
import Chart from "chart.js/auto";
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);

class VerbrauchsDashboardAlt extends Component {

    constructor(props) {
        super(props);
        this.state = {
            zeitCheck : {}
        }
        let annotation1 = false;
        let annotation2 = false;
        let annotation3 = false;
        this.loadProperties = this.loadProperties.bind(this);
        this.loadPropertiesTopDrop = this.loadPropertiesTopDrop.bind(this);


        this.loadDates = this.loadDates.bind(this);
        this.loadDatesTopDrop = this.loadDatesTopDrop.bind(this);

        this.loadSelectedType = this.loadSelectedType.bind(this);
        this.loadTypeTopDrop = this.loadTypeTopDrop.bind(this);

        this.loadProperties(msdata.properties)
        this.loadDates(msdata.date)
        this.loadSelectedType(msdata.Consumption)

        this.getBarData = this.getBarData.bind(this)
        this.drawBar = this.drawBar.bind(this);
        this.drawCharts = this.drawCharts.bind(this);
        this.get_month_external_drop_settings = this.get_month_external_drop_settings.bind(this)

        this.selectType = this.selectType.bind(this);
    }
    selectType=(t)=>{
        // console.log(t)
        // console.log(window.topDropTypes.getElementsByTagName("input").filter(x=>x.value==t)[0])
        window.topDropTypes.getElementsByTagName("input").filter(x=>x.value==t)[0].checked = true
        this.drawCharts()
    }
    drawCharts(){
        // console.log("called drawCharts")
        // console.log(this.get_top_drop_settings())

        // console.log(this.get_filtered_msdata())
        if(window.topDropDates)
            this.drawBar()
        this.drawLine()
    }
    loadSelectedType = (typeList)=>{
        let tSelect = null
        let tempstate = this.state
        tempstate.selectedType = typeList.map(x=>{if(x.consumptiontype == "OTHER")return x.consumptiontype_other; return x.consumptiontype})[0]
        this.state = tempstate
    }
    loadTypeTopDrop = (typeList) =>{
        // console.log(this.state)
        return Array.from(new Set(typeList.map(x=>{if(x.consumptiontype == "OTHER")return x.consumptiontype_other; return x.consumptiontype}))).map((x,i)=>{
            return <a className="w3-bar-item w3-button"><input name="topRadioType" defaultChecked = {x===this.state.selectedType} type="radio" value={x}/> {x}</a>
        })
    }
    loadDates = (dateList) =>{
        let zCheck = {}
        let tempstate = this.state
        Array.from(this.getYearFromYMList(dateList)).sort((a,b)=> {return parseInt(a)-parseInt(b)}).reverse().map(
            (x)=>{zCheck[x]=true})
        tempstate.zeitCheck = zCheck

        this.state = tempstate
    }

    loadProperties = (proplist) =>{
        let pCheck = {}
        // console.log(proplist)
        let tempstate = this.state
        Object.keys(proplist).map(
            (x)=>{pCheck[x] = {
                state: true,
                number: proplist[x]
            }})
        tempstate.propCheck = pCheck
        this.state = tempstate
    }
    getYearFromYMList = (yml)=>{
        let y = new Set();
        yml.forEach(x=>{y.add(x.split("-")[0])})
        return y
    }
    loadDatesTopDrop = ()=>{

        if(Object.keys(this.state.zeitCheck).length>=0)
            return Object.keys(this.state.zeitCheck).map((x)=>{return(<a className="w3-bar-item w3-button"><input type="checkbox" defaultChecked={this.state.zeitCheck[x]} value={x}/> {x}</a>)})
    }
    loadPropertiesTopDrop = ()=>{
        // console.log(this.state)
        if(Object.keys(this.state.propCheck).length>=0)
            return Object.keys(this.state.propCheck).map((x)=>{return(<a className="w3-bar-item w3-button"><input type="checkbox" defaultChecked={this.state.propCheck[x].state} value={this.state.propCheck[x].number}/> {x}</a>)})
    }

    get_top_drop_settings = ()=>{
        return {
            "dates": window.topDropDates.children.filter(x=>x.children[0].checked).map((x)=>x.children[0].value),
            "types": window.topDropTypes.children.filter(x=>x.children[0].checked).map((x)=>x.children[0].value),
            "props": window.topDropProps.children.filter(x=>x.children[0].checked).map((x)=>x.children[0].value),

        }
    }
    get_data_by_date_pn = (data, date, pn)=>{
        return Number(data.filter(x=>x.Property_ID == pn && x.Date.split("-")[0]==date).map(x=>x.CONSUMPTION).reduce((a, b) => parseFloat(a) + parseFloat(b), 0).toFixed(0))
    }
    get_filtered_msdata = ()=>{
        return msdata.BI
            .filter(x=>this.get_top_drop_settings().dates.includes(x['Date'].split("-")[0]))
            .filter(x=>{return this.get_top_drop_settings().types.includes(x['Type']) })
            .filter(x=>this.get_top_drop_settings().props.includes(x['Property_ID']))
    }

    getBarData = ()=>{
        return {
            labels: Array.from(new Set( this.get_filtered_msdata().map(x=>x['Date'].split('-')[0]))),
            unit: this.get_filtered_msdata()[0].UNIT,
            datasets: this.get_top_drop_settings().props.map(x=>{
                    return{
                        label: Object.fromEntries(Object.entries(msdata.properties).map(([k, v]) => [v, k]))[x],
                        backgroundColor: this.getColorByProperty(x),
                        data: Array.from(new Set( this.get_filtered_msdata().map(x=>x['Date'].split('-')[0]))).map(d=>this.get_data_by_date_pn(this.get_filtered_msdata(),d,x)),
                    }
                }
            )
        }
    }

    getColorByProperty(p) {
        if(p == 1 || p == "VIE01") return "#FDD2BF";
        if(p == 2 || p == "VIE02") return "#FF6B6B";
        if(p == 3 || p == "FRA01") return "#3D550C";
        if(p == 4 || p == "MUN01") return "#ECF87F";

        return this.getRandomColor();
    }
    getRandomColor=()=> {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    switchAnnotation1=()=>{
        if (this.annotation1) this.annotation1 = false;
        else this.annotation1 = true;
        this.drawLine()
    }

    switchAnnotation2=()=>{
        if (this.annotation2) this.annotation2 = false;
        else this.annotation2 = true;
        this.drawLine()
    }

    switchAnnotation3=()=>{
        if (this.annotation3) this.annotation3 = false;
        else this.annotation3 = true;
        this.drawLine()
    }
    getLineDataByYear = (year) =>{
        let out = []
        let msd = this.get_filtered_msdata()
        let months = [...Array(12).keys()]
        months.map(x=>('0'+(x+1)).slice(-2)).forEach(d=>{
            if(msd.filter(x=>x.Date == year + "-" + d).map((a)=>a.CONSUMPTION).length != 0)
                out.push(msd.filter(x=>x.Date == year + "-" + d).map((a)=>a.CONSUMPTION).reduce((a,b)=>{return parseFloat(a)+parseFloat(b)}))
        })
        return out
    }

    getLineData = ()=>{

        let data = {
            labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
            datasets:
                this.get_top_drop_settings().dates.reverse().map((x,i)=>{return{
                    label: x,
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: "hsl(0,100%,"+(80-20*i)+"%)",
                    borderColor: "hsl(0,100%,"+(80-20*i)+"%)", // The main line color
                    borderCapStyle: 'square',
                    borderDash: [], // try [5, 15] for instance
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: "black",
                    pointBackgroundColor: "white",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "white",
                    pointHoverBorderColor: "brown",
                    pointHoverBorderWidth: 2,
                    pointRadius: 4,
                    pointHitRadius: 10,
                    // notice the gap in the data and the spanGaps: true
                    data: this.getLineDataByYear(x),
                    spanGaps: true,
                }})


        };
        data.unit = this.get_filtered_msdata()[0].UNIT
        data.datasets = data.datasets.concat(
            this.get_month_external_drop_data()
        )
        // console.log(data)
        return data;
    }
    get_month_external_drop_settings=()=>{
        if(document.getElementsByName('externalType').filter(x=>x.checked).length==0)
            return {value:[], type: "None"}
        let cat = document.getElementsByName('externalType').filter(x=>x.checked)[0].value;
        if(document.getElementById(cat+"Values"))
            return {
                value:document.getElementById(cat+"Values").querySelectorAll("input").filter(x=>x.checked).map(x=>x.value),
                type: cat
            }
        return{value:[]}
    }
    get_month_external_drop_data=()=>{
        let out = []
        let settings = this.get_month_external_drop_settings()
        if(settings.value.length == 0) return []
        settings.value.forEach(x=>{
            out.push(
                {
                    label: settings.type + " " + x,
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: externalData[settings.type].data[x].color,
                    borderColor: externalData[settings.type].data[x].color, // The main line color
                    borderCapStyle: 'square',
                    borderDash: [], // try [5, 15] for instance
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: "black",
                    pointBackgroundColor: "white",
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: "white",
                    pointHoverBorderColor: "brown",
                    pointHoverBorderWidth: 2,
                    pointRadius: 4,
                    pointHitRadius: 10,
                    // notice the gap in the data and the spanGaps: true
                    data: externalData[settings.type].data[x].data,
                    yAxisID: settings.type,

                    spanGaps: true,
                }
            )
        })
        return out;
    }

    drawLine=()=>{
        var canvas = document.getElementById("canvas2");
        var ctx = canvas.getContext('2d');
        // console.log("annotations", this.annotation1)
// Global Options:
//Chart.defaults.global.defaultFontColor = 'black';
//Chart.defaults.global.defaultFontSize = 16;

        var data = this.getLineData()
// Notice the scaleLabel at the same level as Ticks
        var options = {
            plugins: {
            },
            maintainAspectRatio: false,
            scales: {
                yAxes: {
                    ticks: {
                        beginAtZero:true,
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                            return '' + value + ' ' + data.unit;
                        }

                    },
                    scaleLabel: {
                        display: true,
                        labelString: 'CO2 in t',
                        fontSize: 20
                    }
                },
            }
        };
        if(this.get_month_external_drop_settings().type!="None" && this.get_month_external_drop_settings().value.length!=0)
            options.scales[this.get_month_external_drop_settings().type] ={
                type: 'linear',
                display: true,
                position: 'right',
                ticks:{
                    callback: (value, index, values) =>{
                        return '' + value + ' ' + externalData[this.get_month_external_drop_settings().type].unit;
                    }
                }
            }


        if(this.annotation1 || this.annotation2 || this.annotation3)
            options.plugins = {    autocolors: false,
                annotation: {
                    annotations: {
                    }
                }}
        if(this.annotation1)
            options.plugins.annotation.annotations.box =
                {
                    type: 'box',
                    xMin: 1.5,
                    xMax: 2.5,
                    yMin: 50,
                    yMax: 70,
                    backgroundColor: 'rgba(255, 99, 132, 0.25)',
                    enter: (context, event)=>{
                        document.getElementById("customTtip").style.visibility = 'visible';
                        document.getElementById("customTtip").innerText = "This is a Box"
                    },
                    leave: (context, event)=>{
                        document.getElementById("customTtip").style.visibility = 'hidden';
                    },
                    label:{
                        content:"asdf",
                        backgroundColor: "black",
                        color:"white",
                        enabled: true,
                    }
                }
        if(this.annotation2)
            options.plugins.annotation.annotations.line =
                {
                    type: 'line',
                    yScaleID: 'yAxes',
                    yMin: 5000,
                    yMax: 5000,
                    borderColor: 'green',
                    borderWidth: 2,
                    label:{
                        content:"5000",
                        backgroundColor: "black",
                        color:"white",
                        enabled: true,
                    }
                }
        if(this.annotation3)
            options.plugins.annotation.annotations.point =
                {
                    type: 'point',
                    yScaleID: 'yAxes',
                    xValue: "Oct",
                    yValue: 4000,
                    borderColor: 'pink',
                    borderWidth: 2,
                    enter: (context, event)=>{
                        document.getElementById("customTtip").style.visibility = 'visible';
                        document.getElementById("customTtip").innerText = "This is a Point"
                    },
                    leave: (context, event)=>{
                        document.getElementById("customTtip").style.visibility = 'hidden';
                    }

                }

// Chart declaration:
        if(window.myLine)window.myLine.destroy()

        window.myLine = new Chart(ctx, {
            type: 'line',
            data: data,
            options: options
        });
        console.log(options)
    }
    drawBar = ()=>{
        let bardata = this.getBarData()
        var ctx = document.getElementById('canvas').getContext('2d');

        if(window.myBar)window.myBar.destroy()
        window.myBar = new Chart(ctx, {
            type: 'bar',

            data: bardata,
            options: {
                onClick: function(e) {
                    var activePoints =window.myBar.getDatasetMeta(e);
                },
                legend:{
                    onClick : function(a,b){
                        console.log("Click on Legend: " + b.text)
                    }
                },
                title: {
                    display: true,
                    text: this.get_top_drop_settings().types
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    callbacks:{
                        label: (item) => {return`${item.yLabel} ${this.get_filtered_msdata()[0].UNIT}`},

                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return '' + value + ' ' + bardata.unit;
                            }
                        },
                        stacked: true
                    }
                }
            }
        });
    }




    componentDidMount() {
        this.drawCharts()
    }

    render() {
        return(
            <div style={{"display":"grid"}}>
                <div className="w3-padding" >
                    <div className="verbrauchTopCards">
                        <div onClick={()=>{this.selectType("Warmwasser")}} className="w3-card w3-leftbar w3-border-green w3-container w3-white verbrauchTopCardsInner">
                            <h3>Warmwasser</h3>
                            <p><i className="fa fa-caret-down" style={{"color":"green"}}></i> -9.4% <span
                                className="w3-hide-small w3-hide-medium">im Vergleich</span> zum Vorjahr </p>
                        </div>
                    </div>

                    <div onClick={()=>{this.selectType("Fernwärme")}} className="verbrauchTopCards">
                        <div className="w3-card w3-leftbar w3-border-red w3-container w3-white verbrauchTopCardsInner">
                            <h3>Fernwärme</h3>
                            <p><i className="fa fa-caret-up" style={{"color":"red"}}></i> +11.7% <span
                                className="w3-hide-small w3-hide-medium">im Vergleich</span> zum Vorjahr </p>
                        </div>
                    </div>
                    <div className="verbrauchTopCards">
                        <div onClick={()=>{this.selectType("Strom Tag")}} className="w3-card w3-leftbar w3-border-green w3-container w3-white verbrauchTopCardsInner">
                            <h3>Strom Tag</h3>
                            <p><i className="fa fa-caret-down" style={{"color":"green"}}></i> -7.2% <span
                                className="w3-hide-small w3-hide-medium">im Vergleich</span> zum Vorjahr </p>
                        </div>
                    </div>


                    <div className="verbrauchTopCards">
                        <div onClick={()=>{this.selectType("Strom Nacht")}} className="w3-card w3-leftbar w3-border-green w3-container w3-white verbrauchTopCardsInner">
                            <h3>Strom Nacht</h3>
                            <p><i className="fa fa-caret-down" style={{"color":"green"}}></i> -3.9% <span
                                className="w3-hide-small w3-hide-medium">im Vergleich</span> zum Vorjahr </p>
                        </div>
                    </div>

                    <div className="verbrauchTopCards">
                        <div onClick={()=>{this.selectType("Fernkälte")}} className="w3-card w3-leftbar w3-border-red w3-container w3-white verbrauchTopCardsInner">
                            <h3>Fernkälte</h3>
                            <p><i className="fa fa-caret-up" style={{"color":"red"}}></i> +27.5% <span
                                className="w3-hide-small w3-hide-medium">im Vergleich</span> zum Vorjahr </p>
                        </div>
                    </div>
                </div>
                <div>

                    <div className="w3-row-padding w3-padding">
                        <div className="w3-white w3-card w3-panel" style={{"marginTop":"0px"}}>
                            <div className="w3-dropdown-hover w3-white ">
                                <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Zeit
                                </button>
                                <div className="w3-dropdown-content w3-bar-block w3-border">
                                    <form id="topDropDates" onChange={this.drawCharts}>
                                        {this.loadDatesTopDrop()}

                                    </form>
                                </div>
                            </div>

                            <div className="w3-dropdown-hover w3-white w3-padding">
                                <button className="w3-button w3-border"><i
                                    className="fa fa-caret-down"></i> Verbrauchsart
                                </button>
                                <div className="w3-dropdown-content w3-bar-block w3-border" onChange={this.drawCharts}
                                     id="topDropTypes">
                                    {this.loadTypeTopDrop(msdata.Consumption)}
                                </div>
                            </div>

                            <div className="w3-dropdown-hover w3-white w3-padding">
                                <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Objekte
                                </button>
                                <div className="w3-dropdown-content w3-bar-block w3-border">
                                    <form id="topDropProps" onChange={this.drawCharts}>

                                        {this.loadPropertiesTopDrop()}

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w3-row-padding">
                    <div className="w3-twothird">
                        <div className="w3-white w3-card w3-center w3-margin-bottom " style={{"height":"520px"}}>
                            <div className="w3-panel w3-border-bottom" style={{"width":"100%", "marginTop":"0px"}}>
                                <div className="w3-left"><h4>Jahresüberblick</h4></div>
                                <div className="w3-right"><h4><i className="dl-icon"></i></h4></div>
                                <br/>

                            </div>
                            <div style={{"height":"400px"}} className="w3-padding">
                                <div className="w3-center"><h4 id="bartitle"></h4></div>

                                <canvas id="canvas"></canvas>
                            </div>
                        </div>


                        <div className="w3-white w3-card w3-center w3-margin-bottom " style={{"height":"580px"}}>
                            <div className="w3-right w3-panel w3-border-bottom" style={{"width":"100%"}}>
                                <div className="w3-left"><h4>Monatsüberblick</h4></div>
                                <div className="w3-right"><h4><i className="dl-icon"></i></h4></div>
                            </div>
                            <div className="w3-container w3-row">
                                <div className="w3-third">&#8203;</div>
                                <div className="w3-third"><h4 className=" w3-center" id="linetitle"></h4></div>
                                <div className="w3-third">
                                    <ExternalDrop drawLine={this.drawLine}></ExternalDrop>
                                </div>


                            </div>
                            <div className="w3-padding" style={{"height": "400px"}}>

                                <canvas id="canvas2"></canvas>
                            </div>
                        </div>

                    </div>
                    <div className="w3-third l4 m6 s12 tile-container" style={{"overflowY":"scroll"}} id="tileContainer">

                        <div className="w3-card sideChangeBar w3-container w3-white"
                             style={{"margin":"0px 0px 30px 16px", "borderColor":"#FDD2BF"}}>
                            <h3>VIE01</h3>
                            <span className="w3-tiny">August 2019 - August 2020</span>
                            <h5><i className="fa fa-caret-down" style={{"color":"green"}}></i> -5.2% Strom </h5>
                            <h5><i className="fa fa-caret-down" style={{"color":"green"}}></i> -3.4% kaltes Wasser </h5>
                            <h5><i className="fa fa-caret-up" style={{"color":"red"}}></i> +2.1% Fernwärme </h5>
                        </div>


                        <div className="w3-card sideChangeBar w3-container w3-white"
                             style={{"margin":"0px 0px 30px 16px", "borderColor":"#FF6B6B"}}>
                            <h3>VIE02</h3>
                            <span className="w3-tiny">August 2019 - August 2020</span>
                            <h5><i className="fa fa-caret-down" style={{"color":"green"}}></i> -5.2% Strom </h5>
                            <h5><i className="fa fa-caret-down" style={{"color":"green"}}></i> -3.4% kaltes Wasser </h5>
                            <h5><i className="fa fa-caret-up" style={{"color":"red"}}></i> +2.1% Fernwärme </h5>
                        </div>

                        <div className="w3-card sideChangeBar w3-container w3-white"
                             style={{"margin":"0px 0px 30px 16px", "borderColor":"#3D550C"}}>
                            <h3>FRA01</h3>
                            <span className="w3-tiny">August 2019 - August 2020</span>
                            <h5><i className="fa fa-caret-down" style={{"color":"green"}}></i> -5.2% Strom </h5>
                            <h5><i className="fa fa-caret-down" style={{"color":"green"}}></i> -3.4% kaltes Wasser </h5>
                            <h5><i className="fa fa-caret-up" style={{"color":"red"}}></i> +2.1% Fernwärme </h5>
                        </div>

                        <div className="w3-card sideChangeBar w3-container w3-white"
                             style={{"margin":"0px 0px 30px 16px", "borderColor":"#ECF87F"}}>
                            <h3>MUN01</h3>
                            <span className="w3-tiny">August 2019 - August 2020</span>
                            <h5><i className="fa fa-caret-down" style={{"color":"green"}}></i> -5.2% Strom </h5>
                            <h5><i className="fa fa-caret-down" style={{"color":"green"}}></i> -3.4% kaltes Wasser </h5>
                            <h5><i className="fa fa-caret-up" style={{"color":"red"}}></i> +2.1% Fernwärme </h5>
                        </div>


                    </div>

                </div>
                <div className="w3-panel w3-card w3-white">
                    <p>Click Button below to test annotations.</p>
                    <button className="w3-button" onClick={this.switchAnnotation1}>Switch Bar Annotation</button>
                    <button className="w3-button" onClick={this.switchAnnotation2}>Switch Line Annotation</button>
                    <button className="w3-button" onClick={this.switchAnnotation3}>Switch Point Annotation</button>
                </div>
            </div>
        )
    }

}
class ExternalDrop extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <div className="w3-dropdown-click w3-right">
                <div style={{"paddingRight":"12px"}}>

                    <button  id="monthExternalDropButton"
                             className="w3-button w3-border" onClick = {this.openMonthExternalDrop}>Wetter Daten
                    </button>
                </div>
                <div id="monthExternalDrop"
                     className="w3-dropdown-content w3-bar-block w3-border">
                    {this.fillMonthExternalDrop()}
                </div>
            </div>
        )
    }
    generateCityCheckboxes(x){
        return(Object.keys(externalData[x].data).map(y=>{

            return(
                <span className="w3-bar-item" style={{"padding":"2px 8px"}}>
          <input onChange={()=>this.props.drawLine()} className="" id={("externalCheck"+x+y)} type="checkbox" name={(x+y)} value={y}/> <label  onClick={("document.getElementById('externalCheck"+x+y+"').checked = !document.getElementById('externalCheck"+x+y+"').checked")} >{y}</label>
        </span>
            )
        }))
    }
    fillMonthExternalDrop(){
        return(Object.keys(externalData).map((x,i)=>{
            return(<a onClick={()=>{this.props.drawLine();document.getElementById('externalRadio'+x).checked = true}} className="w3-bar-item" style={{"borderLeft": ("4px solid "+externalData[x].color)}}>
                <input className="" onInput={()=>this.props.drawLine()} id={("externalRadio"+x)} type="radio" name="externalType" value={x} defaultChecked={(!i)}/> <label>{x}</label>
                <div id={x+"Values"}>
                    {(this.generateCityCheckboxes(x))}
                </div>
            </a>)
        }))

    }
    openMonthExternalDrop=()=>{
        var x = document.getElementById("monthExternalDrop");
        if (x.className.indexOf("w3-show") == -1) {
            x.className += " w3-show";
        } else {
            x.className = x.className.replace(" w3-show", "");
        }
    }

}

var externalData = {
    None: {
        color: "lightgrey",
        data:{}
    },
    Temperatur : {
        color:"yellow",
        unit:"°C",
        data:{
            "Wien":{data: [0,2,6,11,15,19,21,21,16,11,5,1], color:"hsl(40,100%,"+(80-20*0)+"%)"},
            "München":{data: [-3,-1,4,8,13,16,18,18,14,9,3,-2], color:"hsl(40,100%,"+(80-20*1)+"%)"},
            "Frankfurt":{data: [-1,1,6,11,16,19,21,20,16,11,5,0], color:"hsl(40,100%,"+(80-20*2)+"%)"},
        }
    },
    Niederschlag : {
        color:"blue",
        unit:"mm",
        data:{
            "Wien":{data: [6.5,6.9,8.5,7.2,16,16.1,18.4,15.8,12.8,7.6,5.8,6.7], color:"hsl(240,100%,"+(80-20*0)+"%)"},
            "München":{data: [7,7.8,14.2,25.6,42.7,68.1,69.8,66.5,40.6,26.8,18.8,11], color:"hsl(240,100%,"+(80-20*1)+"%)"},
            "Frankfurt":{data: [3,3.2,5.2,12.3,24.4,27.4,31.7,28.8,24.1,12.5,7.3,4.8], color:"hsl(240,100%,"+(80-20*2)+"%)"},
        }
    }
}

var msdata ={
    date : ["2019-01","2019-02","2019-03","2019-04","2019-05","2019-06","2019-07","2019-08","2019-09","2019-10","2019-11","2019-12","2020-01","2020-02","2020-03","2020-04","2020-05","2020-06","2020-07","2020-08","2020-09","2020-10","2020-11","2020-12","2021-01","2021-02","2021-03","2021-04","2021-05"],
    properties: {
        "VIE01": 1,
        "VIE02": 2,
        "FRA01": 3,
        "MUN01": 4,
    },
    "Consumption": [
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "271761866655058978642608780758645041497",
            "objectidsender": "1",
            "objectidreceiver": "1",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "286512319471792157247717246214518682647",
            "objectidsender": "2",
            "objectidreceiver": "2",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "306690343237747294654660520918739372385",
            "objectidsender": "3",
            "objectidreceiver": "3",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "215494535920458940428620749733285771882",
            "objectidsender": "4",
            "objectidreceiver": "4",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "108201835412527634344135216070420776971",
            "objectidsender": "5",
            "objectidreceiver": "5",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "66563697165624605662089692843501350020",
            "objectidsender": "6",
            "objectidreceiver": "6",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "310341588070908885426642772852576799782",
            "objectidsender": "7",
            "objectidreceiver": "7",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "145221044359630365279023889099095152615",
            "objectidsender": "8",
            "objectidreceiver": "8",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "339077960865193302191866267334186567421",
            "objectidsender": "9",
            "objectidreceiver": "9",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "66771427247818147182344585238350435740",
            "objectidsender": "10",
            "objectidreceiver": "10",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "179596316536340633596511823717301147437",
            "objectidsender": "11",
            "objectidreceiver": "11",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "334832459727990236568872705276191506177",
            "objectidsender": "12",
            "objectidreceiver": "12",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "223485505432219500538284573120797326571",
            "objectidsender": "13",
            "objectidreceiver": "13",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "20478213095917181338106699434216506128",
            "objectidsender": "14",
            "objectidreceiver": "14",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "127306867473567312141876852887149788854",
            "objectidsender": "15",
            "objectidreceiver": "15",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "29989479860632188801074496241894290125",
            "objectidsender": "16",
            "objectidreceiver": "16",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "144172401663047907853021632456307317915",
            "objectidsender": "17",
            "objectidreceiver": "17",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "50407696034511109691885078368178120251",
            "objectidsender": "18",
            "objectidreceiver": "18",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "234463987429963069123534136818353649923",
            "objectidsender": "19",
            "objectidreceiver": "19",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "275022047099490145180166657922219161305",
            "objectidsender": "20",
            "objectidreceiver": "20",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "108481293066134371394096801180019192882",
            "objectidsender": "21",
            "objectidreceiver": "21",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "234230823426039758481470008240408959454",
            "objectidsender": "22",
            "objectidreceiver": "22",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "181596257563409059079477705877189070713",
            "objectidsender": "23",
            "objectidreceiver": "23",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "255770557240821895127203329301309545564",
            "objectidsender": "24",
            "objectidreceiver": "24",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "235555086700280707594161437951679581085",
            "objectidsender": "25",
            "objectidreceiver": "25",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "236248506870035539896672172529788589710",
            "objectidsender": "26",
            "objectidreceiver": "26",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "335905146324783151718587668368552673763",
            "objectidsender": "27",
            "objectidreceiver": "27",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "48208857395777090047830644974889157664",
            "objectidsender": "28",
            "objectidreceiver": "28",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "198004665453425000256582437705294770589",
            "objectidsender": "29",
            "objectidreceiver": "29",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "65229410212984540490444418957206239741",
            "objectidsender": "30",
            "objectidreceiver": "30",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "125202342033226653034506807750554371930",
            "objectidsender": "31",
            "objectidreceiver": "31",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "153372146077633401477415901934863007572",
            "objectidsender": "32",
            "objectidreceiver": "32",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "249010881266309987142548064145968261423",
            "objectidsender": "33",
            "objectidreceiver": "33",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "321988997716385525465594992831185837731",
            "objectidsender": "34",
            "objectidreceiver": "34",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "169164696780604274869651932199814420134",
            "objectidsender": "35",
            "objectidreceiver": "35",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "234211578831574539627604107931186389896",
            "objectidsender": "36",
            "objectidreceiver": "36",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "284592506096328282743216269234926706199",
            "objectidsender": "37",
            "objectidreceiver": "37",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "72624244237812793915366975447672696887",
            "objectidsender": "38",
            "objectidreceiver": "38",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "296562313324977395685629874766522226593",
            "objectidsender": "39",
            "objectidreceiver": "39",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "221471398753541505669467565087165157057",
            "objectidsender": "40",
            "objectidreceiver": "40",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "307181227440888170179300282270469336900",
            "objectidsender": "41",
            "objectidreceiver": "41",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "179810961044058132075719872928431331515",
            "objectidsender": "42",
            "objectidreceiver": "42",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "17301687865121175877861547944821477659",
            "objectidsender": "43",
            "objectidreceiver": "43",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "73815748348397037428398867723647536068",
            "objectidsender": "44",
            "objectidreceiver": "44",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "336529034608462083535437160320070212953",
            "objectidsender": "45",
            "objectidreceiver": "45",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "284198878547219899352072585488712438761",
            "objectidsender": "46",
            "objectidreceiver": "46",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "256781347306221088977758141399360160416",
            "objectidsender": "47",
            "objectidreceiver": "47",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "329027062232728042734778087796365136500",
            "objectidsender": "48",
            "objectidreceiver": "48",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "53716213361953787908317606292745448789",
            "objectidsender": "49",
            "objectidreceiver": "49",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "242254162348672552645213713592308605687",
            "objectidsender": "50",
            "objectidreceiver": "50",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "155839252716741310200841781549494619990",
            "objectidsender": "51",
            "objectidreceiver": "51",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "272635886286202789282979089171549871623",
            "objectidsender": "52",
            "objectidreceiver": "52",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "151808354057615050489857413128386806286",
            "objectidsender": "53",
            "objectidreceiver": "53",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "146602869370016706136864997687000857534",
            "objectidsender": "54",
            "objectidreceiver": "54",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "153761701039351150827527940637774815966",
            "objectidsender": "55",
            "objectidreceiver": "55",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "130980258828700777225656494370744497694",
            "objectidsender": "56",
            "objectidreceiver": "56",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "238490990090864997301665935510821741207",
            "objectidsender": "57",
            "objectidreceiver": "57",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "268037590180670936239014905586909906769",
            "objectidsender": "58",
            "objectidreceiver": "58",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "299700846662526024226060616022963072915",
            "objectidsender": "59",
            "objectidreceiver": "59",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "168069992073656524525419096120387966624",
            "objectidsender": "60",
            "objectidreceiver": "60",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "204833849379139974261119367735155567023",
            "objectidsender": "61",
            "objectidreceiver": "61",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "175840174855299267270984648733537188104",
            "objectidsender": "62",
            "objectidreceiver": "62",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "136509213879896687422269847383881183604",
            "objectidsender": "63",
            "objectidreceiver": "63",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "186537149168653121304846717455519038907",
            "objectidsender": "64",
            "objectidreceiver": "64",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "255517735293602930064958062696545177702",
            "objectidsender": "65",
            "objectidreceiver": "65",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "90124690240591118330900265091807740462",
            "objectidsender": "66",
            "objectidreceiver": "66",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "257441928171323803424650644608582464695",
            "objectidsender": "67",
            "objectidreceiver": "67",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "160639298306740832788104271820677246521",
            "objectidsender": "68",
            "objectidreceiver": "68",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "328317371642826266396705150840121457369",
            "objectidsender": "69",
            "objectidreceiver": "69",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "11876996308082596122216434298640977397",
            "objectidsender": "70",
            "objectidreceiver": "70",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "241543901656242170998796230246972440906",
            "objectidsender": "71",
            "objectidreceiver": "71",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "222602052268109864356145500947855138038",
            "objectidsender": "72",
            "objectidreceiver": "72",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "307039172258753615477973046707141138239",
            "objectidsender": "73",
            "objectidreceiver": "73",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "186443375055476630424201277458587998018",
            "objectidsender": "74",
            "objectidreceiver": "74",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "7519540418432355435493672063728007607",
            "objectidsender": "75",
            "objectidreceiver": "75",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "165469108020227934856408857946041311659",
            "objectidsender": "76",
            "objectidreceiver": "76",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "111301914358217656490953629818861601321",
            "objectidsender": "77",
            "objectidreceiver": "77",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "73469141758847118377476678482539293677",
            "objectidsender": "78",
            "objectidreceiver": "78",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "238948969489273218859444317478959376258",
            "objectidsender": "79",
            "objectidreceiver": "79",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "171665223751752353057615723223462481051",
            "objectidsender": "80",
            "objectidreceiver": "80",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "239451606577204752944997057262542129612",
            "objectidsender": "81",
            "objectidreceiver": "81",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "306905403248285578425535295663385988326",
            "objectidsender": "82",
            "objectidreceiver": "82",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "309922254372153850982778639857672880227",
            "objectidsender": "83",
            "objectidreceiver": "83",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "160134342983704916770929234432000580281",
            "objectidsender": "84",
            "objectidreceiver": "84",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "71657691950434793541356010144004279447",
            "objectidsender": "85",
            "objectidreceiver": "85",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "205870548213760808042338294532151361922",
            "objectidsender": "86",
            "objectidreceiver": "86",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "20197238045357612137363231475638759761",
            "objectidsender": "87",
            "objectidreceiver": "87",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "161438576606984420562340195220700182870",
            "objectidsender": "88",
            "objectidreceiver": "88",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "93692659529048426294966786994832551718",
            "objectidsender": "89",
            "objectidreceiver": "89",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "272806708483795513998618712380925237567",
            "objectidsender": "90",
            "objectidreceiver": "90",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "184899085191457318894393970990456260222",
            "objectidsender": "91",
            "objectidreceiver": "91",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "253367978596899878641873577427755135683",
            "objectidsender": "92",
            "objectidreceiver": "92",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "141120402793502466975624530784794054487",
            "objectidsender": "93",
            "objectidreceiver": "93",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "166185905672075707387415455630008769683",
            "objectidsender": "94",
            "objectidreceiver": "94",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "294403339551837268418267945558641153269",
            "objectidsender": "95",
            "objectidreceiver": "95",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "139563333313988228043402566790459297135",
            "objectidsender": "96",
            "objectidreceiver": "96",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "223818738989361788758853834418645287981",
            "objectidsender": "97",
            "objectidreceiver": "97",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "246442441504560652756968034655896473274",
            "objectidsender": "98",
            "objectidreceiver": "98",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "180520807437676969142575480333031793308",
            "objectidsender": "99",
            "objectidreceiver": "99",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "56699768533161458535696100174862870135",
            "objectidsender": "100",
            "objectidreceiver": "100",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "242056104099150583627949080410596541385",
            "objectidsender": "101",
            "objectidreceiver": "101",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "215817550855944297574061192668046210930",
            "objectidsender": "102",
            "objectidreceiver": "102",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "231541315430319330653618401986005101648",
            "objectidsender": "103",
            "objectidreceiver": "103",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "337131975552364745861110238595210938244",
            "objectidsender": "104",
            "objectidreceiver": "104",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "160820837526804083554588750426766689736",
            "objectidsender": "105",
            "objectidreceiver": "105",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "49040184544572724068940586720212620519",
            "objectidsender": "106",
            "objectidreceiver": "106",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "122023954674070965708570559782389512577",
            "objectidsender": "107",
            "objectidreceiver": "107",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "95395596872479933328613532496085008845",
            "objectidsender": "108",
            "objectidreceiver": "108",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "89556415826181719128312351600965908617",
            "objectidsender": "109",
            "objectidreceiver": "109",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "127969677436690902263048899093939677010",
            "objectidsender": "110",
            "objectidreceiver": "110",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "216160247053631964362141114407528708425",
            "objectidsender": "111",
            "objectidreceiver": "111",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "75926033263967283430561370223668704333",
            "objectidsender": "112",
            "objectidreceiver": "112",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "231695017154569891619168927509024460144",
            "objectidsender": "113",
            "objectidreceiver": "113",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "202808956996423598322872970483132856844",
            "objectidsender": "114",
            "objectidreceiver": "114",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "20386128939749920259979548453342041325",
            "objectidsender": "115",
            "objectidreceiver": "115",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "56901956320543145027932229348890883167",
            "objectidsender": "116",
            "objectidreceiver": "116",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "2529222552539174830295086159268770476",
            "objectidsender": "117",
            "objectidreceiver": "117",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "122186533024353939540502198107599561220",
            "objectidsender": "118",
            "objectidreceiver": "118",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "211943374680073850346283179602099829910",
            "objectidsender": "119",
            "objectidreceiver": "119",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "26612306703630625113885284640755559540",
            "objectidsender": "120",
            "objectidreceiver": "120",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "176834991499962657937880845981101098215",
            "objectidsender": "121",
            "objectidreceiver": "121",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "296936468894765290794550434530574058310",
            "objectidsender": "122",
            "objectidreceiver": "122",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "43515050383433490870011318218246703597",
            "objectidsender": "123",
            "objectidreceiver": "123",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "90506201257394398134265405319302409405",
            "objectidsender": "124",
            "objectidreceiver": "124",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "133098184073459901461675136301557911284",
            "objectidsender": "125",
            "objectidreceiver": "125",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "16773751215806209653677764529233215072",
            "objectidsender": "126",
            "objectidreceiver": "126",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "38064349202640962423605643772147759685",
            "objectidsender": "127",
            "objectidreceiver": "127",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "274290409994034120767201742024819419359",
            "objectidsender": "128",
            "objectidreceiver": "128",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "138454483478335871597023137627156852143",
            "objectidsender": "129",
            "objectidreceiver": "129",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "173754589136953668576680604739883290717",
            "objectidsender": "130",
            "objectidreceiver": "130",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "26770185893456796311433270737562317848",
            "objectidsender": "131",
            "objectidreceiver": "131",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "31818041910769938754693705780651712419",
            "objectidsender": "132",
            "objectidreceiver": "132",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "39415953123038179560651886179202770690",
            "objectidsender": "133",
            "objectidreceiver": "133",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "34260968021748739335055920045200781136",
            "objectidsender": "134",
            "objectidreceiver": "134",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "170423456360809894304670084393939610628",
            "objectidsender": "135",
            "objectidreceiver": "135",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "236336043581551395983421113474248877026",
            "objectidsender": "136",
            "objectidreceiver": "136",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "121147927261313336719007529805812689486",
            "objectidsender": "137",
            "objectidreceiver": "137",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "30078375684206045319619206641019017592",
            "objectidsender": "138",
            "objectidreceiver": "138",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "61686536833429690387524110148436983265",
            "objectidsender": "139",
            "objectidreceiver": "139",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "276987080184255274605516367622862263439",
            "objectidsender": "140",
            "objectidreceiver": "140",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "44559627741365455021273725741600795017",
            "objectidsender": "141",
            "objectidreceiver": "141",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "41375756461822384915293566985365564406",
            "objectidsender": "142",
            "objectidreceiver": "142",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "131284824538293047488806700368726872949",
            "objectidsender": "143",
            "objectidreceiver": "143",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "325412095333048976951332921563834233121",
            "objectidsender": "144",
            "objectidreceiver": "144",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "226164672081923868617219348013738369068",
            "objectidsender": "145",
            "objectidreceiver": "145",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "65307436969408534114406267375263398227",
            "objectidsender": "146",
            "objectidreceiver": "146",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "38571931540758987325085786215220048696",
            "objectidsender": "147",
            "objectidreceiver": "147",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "73472476461025905136008253193557282304",
            "objectidsender": "148",
            "objectidreceiver": "148",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "155572494272139622955723486068121135999",
            "objectidsender": "149",
            "objectidreceiver": "149",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "253849203124913913573251455599286109166",
            "objectidsender": "150",
            "objectidreceiver": "150",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "129357544138490277444569847730903344271",
            "objectidsender": "151",
            "objectidreceiver": "151",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "153700594637336162459944268916083439632",
            "objectidsender": "152",
            "objectidreceiver": "152",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "21739127787328175423062243072061935627",
            "objectidsender": "153",
            "objectidreceiver": "153",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "224648876983836391904506621494071587443",
            "objectidsender": "154",
            "objectidreceiver": "154",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "291145720481767267527319190676701100522",
            "objectidsender": "155",
            "objectidreceiver": "155",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "19172446891681676546890740925344691436",
            "objectidsender": "156",
            "objectidreceiver": "156",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "92424365183343367079121675084348944255",
            "objectidsender": "157",
            "objectidreceiver": "157",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "296507173573635070493834821043707926338",
            "objectidsender": "158",
            "objectidreceiver": "158",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "229839450560419434925131055746124559510",
            "objectidsender": "159",
            "objectidreceiver": "159",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "321425673212418946772736929995638664797",
            "objectidsender": "160",
            "objectidreceiver": "160",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "141977652678551680852762279010082080531",
            "objectidsender": "161",
            "objectidreceiver": "161",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "134074984280355675318826432720908710623",
            "objectidsender": "162",
            "objectidreceiver": "162",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "148426969058156318775694948882705124022",
            "objectidsender": "163",
            "objectidreceiver": "163",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "89558350557684178425668308637445062430",
            "objectidsender": "164",
            "objectidreceiver": "164",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "1",
            "property.objectidreceiver": "1",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "63382470347848443733415795556198794578",
            "objectidsender": "165",
            "objectidreceiver": "165",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "315627160927786429128325238928234488830",
            "objectidsender": "166",
            "objectidreceiver": "166",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "313221399925115097750988421787108279167",
            "objectidsender": "167",
            "objectidreceiver": "167",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "42670238330198999733463548439352576493",
            "objectidsender": "168",
            "objectidreceiver": "168",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "15158460860913319968924299633648192478",
            "objectidsender": "169",
            "objectidreceiver": "169",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "163815304860162247805996138473935814637",
            "objectidsender": "170",
            "objectidreceiver": "170",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "64034850979992665016817221767676116868",
            "objectidsender": "171",
            "objectidreceiver": "171",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "264516551990066028292694096133550820957",
            "objectidsender": "172",
            "objectidreceiver": "172",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "66950015109689072987915372912090510266",
            "objectidsender": "173",
            "objectidreceiver": "173",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "277655460978384073494476542106307715148",
            "objectidsender": "174",
            "objectidreceiver": "174",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "198062020438145880660336719636228068454",
            "objectidsender": "175",
            "objectidreceiver": "175",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "241759934645914933090234653988926230665",
            "objectidsender": "176",
            "objectidreceiver": "176",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "90846677922846598003047140214549237856",
            "objectidsender": "177",
            "objectidreceiver": "177",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "105732903501035778700723602987323650621",
            "objectidsender": "178",
            "objectidreceiver": "178",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "34455884633361011803396219068728667390",
            "objectidsender": "179",
            "objectidreceiver": "179",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "8378712063551015095095719803241280303",
            "objectidsender": "180",
            "objectidreceiver": "180",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "279462587897262370666587124358862738044",
            "objectidsender": "181",
            "objectidreceiver": "181",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "131810754990419199169238698002195257055",
            "objectidsender": "182",
            "objectidreceiver": "182",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "173165079525401386299814249877309747524",
            "objectidsender": "183",
            "objectidreceiver": "183",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "17791163325516998779595048650038833099",
            "objectidsender": "184",
            "objectidreceiver": "184",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "10609423503595179267224159118916701098",
            "objectidsender": "185",
            "objectidreceiver": "185",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "93009977524838155307065899483078629483",
            "objectidsender": "186",
            "objectidreceiver": "186",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "156749180472160438147825173134830588923",
            "objectidsender": "187",
            "objectidreceiver": "187",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "94872403135003646739774767596659794754",
            "objectidsender": "188",
            "objectidreceiver": "188",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "74985486739049483882505283880061047030",
            "objectidsender": "189",
            "objectidreceiver": "189",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "265495868679252225289175031675311069769",
            "objectidsender": "190",
            "objectidreceiver": "190",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "333119298677499852634758988869709308768",
            "objectidsender": "191",
            "objectidreceiver": "191",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "298087834719842006960172337378939709886",
            "objectidsender": "192",
            "objectidreceiver": "192",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "337386428743484762765273143277634520989",
            "objectidsender": "193",
            "objectidreceiver": "193",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "97790004347965671209466035134517999300",
            "objectidsender": "194",
            "objectidreceiver": "194",
            "consumptiontype": "Fernkälte",
            "purpose": "COOLING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "245389053992914081771092462999258845161",
            "objectidsender": "195",
            "objectidreceiver": "195",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "222626721671835774870705748862152926565",
            "objectidsender": "196",
            "objectidreceiver": "196",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "239538529723061135271666110516733658252",
            "objectidsender": "197",
            "objectidreceiver": "197",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "6698200722625227608849080043979785555",
            "objectidsender": "198",
            "objectidreceiver": "198",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "8325507161960058961590276480318390967",
            "objectidsender": "199",
            "objectidreceiver": "199",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "25169598071279719014461143841820608725",
            "objectidsender": "200",
            "objectidreceiver": "200",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "17970382324937909211026037854877200610",
            "objectidsender": "201",
            "objectidreceiver": "201",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "315231446289104923750602246331359438926",
            "objectidsender": "202",
            "objectidreceiver": "202",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "80473759558808947406584836870360344146",
            "objectidsender": "203",
            "objectidreceiver": "203",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "21018175369837202168264141344548523204",
            "objectidsender": "204",
            "objectidreceiver": "204",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "123182867447330672783178861939758433872",
            "objectidsender": "205",
            "objectidreceiver": "205",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "199373587225348794725315650406191781281",
            "objectidsender": "206",
            "objectidreceiver": "206",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "24013446447899850148899607484468924685",
            "objectidsender": "207",
            "objectidreceiver": "207",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "185024393687363251531494582852794694122",
            "objectidsender": "208",
            "objectidreceiver": "208",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "227710269236595994307178630895318352642",
            "objectidsender": "209",
            "objectidreceiver": "209",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "255560480099946519502265273846839204750",
            "objectidsender": "210",
            "objectidreceiver": "210",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "329627381208361722278163255849985300461",
            "objectidsender": "211",
            "objectidreceiver": "211",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "297148685012310920762021325827070201771",
            "objectidsender": "212",
            "objectidreceiver": "212",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "133055323541052671202835485289191793721",
            "objectidsender": "213",
            "objectidreceiver": "213",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "260574755846736048197294542597038609793",
            "objectidsender": "214",
            "objectidreceiver": "214",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "12374837068575037032009222206216019325",
            "objectidsender": "215",
            "objectidreceiver": "215",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "138489231904878209487896378186099726689",
            "objectidsender": "216",
            "objectidreceiver": "216",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "329752683515863355359052603876471148073",
            "objectidsender": "217",
            "objectidreceiver": "217",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "256767766303240506930512866910442398804",
            "objectidsender": "218",
            "objectidreceiver": "218",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "77799928366598080374531905641694104151",
            "objectidsender": "219",
            "objectidreceiver": "219",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "427393180485293063062674319020435246",
            "objectidsender": "220",
            "objectidreceiver": "220",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "97669663102634268690650350761454816198",
            "objectidsender": "221",
            "objectidreceiver": "221",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "179903336851229562558643105275855153562",
            "objectidsender": "222",
            "objectidreceiver": "222",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "216717128225797406484930094260484144291",
            "objectidsender": "223",
            "objectidreceiver": "223",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "162350375858307347662701788246863241337",
            "objectidsender": "224",
            "objectidreceiver": "224",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "100376321068483478112122369768102083097",
            "objectidsender": "225",
            "objectidreceiver": "225",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "249097773352825304286823996949074179186",
            "objectidsender": "226",
            "objectidreceiver": "226",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "226109446113828427538358858679537588905",
            "objectidsender": "227",
            "objectidreceiver": "227",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "36461395208707675894855186003667112925",
            "objectidsender": "228",
            "objectidreceiver": "228",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "13934874302506509116856875111904080383",
            "objectidsender": "229",
            "objectidreceiver": "229",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "326627220693733647542880181605874661488",
            "objectidsender": "230",
            "objectidreceiver": "230",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "198116060528004495662946888012076698897",
            "objectidsender": "231",
            "objectidreceiver": "231",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "15280529607795458409451979685555720588",
            "objectidsender": "232",
            "objectidreceiver": "232",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "188189208139038022805803503763635853938",
            "objectidsender": "233",
            "objectidreceiver": "233",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "186086559681234118077349078106323190597",
            "objectidsender": "234",
            "objectidreceiver": "234",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "221347904680965492236305336161535502289",
            "objectidsender": "235",
            "objectidreceiver": "235",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "49761417864553142878249777846194278046",
            "objectidsender": "236",
            "objectidreceiver": "236",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "49394476238093314504129283994215791522",
            "objectidsender": "237",
            "objectidreceiver": "237",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "78066852089074003479806128222797381263",
            "objectidsender": "238",
            "objectidreceiver": "238",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "58824462329732262375485432352296649263",
            "objectidsender": "239",
            "objectidreceiver": "239",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "236225766390758398052232380833396707745",
            "objectidsender": "240",
            "objectidreceiver": "240",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "244723245305659595990529755454243448094",
            "objectidsender": "241",
            "objectidreceiver": "241",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "276291096372356617713593428174468025300",
            "objectidsender": "242",
            "objectidreceiver": "242",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "150869705085317644249408245502091703383",
            "objectidsender": "243",
            "objectidreceiver": "243",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "17593820745436179308662990546675671008",
            "objectidsender": "244",
            "objectidreceiver": "244",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "4311384974248741013029395345018295871",
            "objectidsender": "245",
            "objectidreceiver": "245",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "280784655712611427470700072541576637057",
            "objectidsender": "246",
            "objectidreceiver": "246",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "255514812645827062633288030152780570167",
            "objectidsender": "247",
            "objectidreceiver": "247",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "29060465724388374552304287993524707281",
            "objectidsender": "248",
            "objectidreceiver": "248",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "326080271742183030609022085440241675134",
            "objectidsender": "249",
            "objectidreceiver": "249",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "193756151055981358362092215916659251282",
            "objectidsender": "250",
            "objectidreceiver": "250",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "334519031319092347975947736356888083399",
            "objectidsender": "251",
            "objectidreceiver": "251",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "60758114733610440434709446137310702627",
            "objectidsender": "252",
            "objectidreceiver": "252",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "31253764838715251158763050548595231264",
            "objectidsender": "253",
            "objectidreceiver": "253",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "238168839146142769744591664897898496195",
            "objectidsender": "254",
            "objectidreceiver": "254",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "326550100451378274226890611739248996370",
            "objectidsender": "255",
            "objectidreceiver": "255",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "247286391129248479283939097520948522792",
            "objectidsender": "256",
            "objectidreceiver": "256",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "220548693398795462405491924900780888860",
            "objectidsender": "257",
            "objectidreceiver": "257",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "225788343737156111929675115100646338584",
            "objectidsender": "258",
            "objectidreceiver": "258",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "3433652436963281147353484194283797348",
            "objectidsender": "259",
            "objectidreceiver": "259",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "58742361858528448894730386396695809641",
            "objectidsender": "260",
            "objectidreceiver": "260",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "284008000539542978458320485885130454897",
            "objectidsender": "261",
            "objectidreceiver": "261",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "338354967691727783003284070601574137843",
            "objectidsender": "262",
            "objectidreceiver": "262",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "87748617534536131264618816344872965371",
            "objectidsender": "263",
            "objectidreceiver": "263",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "273897309260182859284764335162697991184",
            "objectidsender": "264",
            "objectidreceiver": "264",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "326890795081070827160136043267474499079",
            "objectidsender": "265",
            "objectidreceiver": "265",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "198531737221525593909498965762102346903",
            "objectidsender": "266",
            "objectidreceiver": "266",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "13271159773872379651597761044922755722",
            "objectidsender": "267",
            "objectidreceiver": "267",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "158342812880780516074718835558871592200",
            "objectidsender": "268",
            "objectidreceiver": "268",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "156187069700714147920020881090376763127",
            "objectidsender": "269",
            "objectidreceiver": "269",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "279223291226107333876171185285598204958",
            "objectidsender": "270",
            "objectidreceiver": "270",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "291513072482937301125844392339912025977",
            "objectidsender": "271",
            "objectidreceiver": "271",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "198058960834404262326437338905621594965",
            "objectidsender": "272",
            "objectidreceiver": "272",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "317112636933409390095604990771010983508",
            "objectidsender": "273",
            "objectidreceiver": "273",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "304886594500610631303035276069947963321",
            "objectidsender": "274",
            "objectidreceiver": "274",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "188284974290094018387990688305987002098",
            "objectidsender": "275",
            "objectidreceiver": "275",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "66630020432656604832701683592896054292",
            "objectidsender": "276",
            "objectidreceiver": "276",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "202358036226231130079069863641010491213",
            "objectidsender": "277",
            "objectidreceiver": "277",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "96167872627619434758641697530627825680",
            "objectidsender": "278",
            "objectidreceiver": "278",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "263371162977276457581339389378829128460",
            "objectidsender": "279",
            "objectidreceiver": "279",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "48876712291465949903466614207194974435",
            "objectidsender": "280",
            "objectidreceiver": "280",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "250288844348740234219861460172680371923",
            "objectidsender": "281",
            "objectidreceiver": "281",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "25387463415201864056381676755710017375",
            "objectidsender": "282",
            "objectidreceiver": "282",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "138781529029142822585448003037471576808",
            "objectidsender": "283",
            "objectidreceiver": "283",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "270364379807835871990684560930971176640",
            "objectidsender": "284",
            "objectidreceiver": "284",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "169775166352265255021711341161794025997",
            "objectidsender": "285",
            "objectidreceiver": "285",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "166620047552338364765095022993764606110",
            "objectidsender": "286",
            "objectidreceiver": "286",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "201227600109684391188327035842030671184",
            "objectidsender": "287",
            "objectidreceiver": "287",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "113116938200164766027253524089252757150",
            "objectidsender": "288",
            "objectidreceiver": "288",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "63192597461569580437426932420731773958",
            "objectidsender": "289",
            "objectidreceiver": "289",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "281217819165942633755021679648343499243",
            "objectidsender": "290",
            "objectidreceiver": "290",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "201807970307139116457851973661462879019",
            "objectidsender": "291",
            "objectidreceiver": "291",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "307987367236572909700096477708752893170",
            "objectidsender": "292",
            "objectidreceiver": "292",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "158069630995330088738064504734857418981",
            "objectidsender": "293",
            "objectidreceiver": "293",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "279474790671570604041706381361954145451",
            "objectidsender": "294",
            "objectidreceiver": "294",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "39247891168796915825223077809456755037",
            "objectidsender": "295",
            "objectidreceiver": "295",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "324402678913165404576944444277842812261",
            "objectidsender": "296",
            "objectidreceiver": "296",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "66904551041420010863352570294560010416",
            "objectidsender": "297",
            "objectidreceiver": "297",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "227415701944183124207329036334685807997",
            "objectidsender": "298",
            "objectidreceiver": "298",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "90336993704328756112799760814381224253",
            "objectidsender": "299",
            "objectidreceiver": "299",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "67480764730721069938953708902354154212",
            "objectidsender": "300",
            "objectidreceiver": "300",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "119991878621784451533788245180944351781",
            "objectidsender": "301",
            "objectidreceiver": "301",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "68997017457469818647210170589202901914",
            "objectidsender": "302",
            "objectidreceiver": "302",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "110545896890558046788599595973080165176",
            "objectidsender": "303",
            "objectidreceiver": "303",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "230514676979836034766509425067269026543",
            "objectidsender": "304",
            "objectidreceiver": "304",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "285198933337773620391468656167060117543",
            "objectidsender": "305",
            "objectidreceiver": "305",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "238961115584121617997971425717100389744",
            "objectidsender": "306",
            "objectidreceiver": "306",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "40382962250706065539192358391767813722",
            "objectidsender": "307",
            "objectidreceiver": "307",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "335254693892982243843706053485231112231",
            "objectidsender": "308",
            "objectidreceiver": "308",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "58703959086922442911524170883859559138",
            "objectidsender": "309",
            "objectidreceiver": "309",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "117942315162272371293074303009357698644",
            "objectidsender": "310",
            "objectidreceiver": "310",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "31878898967119379984230516921143601965",
            "objectidsender": "311",
            "objectidreceiver": "311",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "282920712280518265584867712417519917833",
            "objectidsender": "312",
            "objectidreceiver": "312",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "16393380820515730067451399940752675739",
            "objectidsender": "313",
            "objectidreceiver": "313",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "311627079327558463966915087786981330702",
            "objectidsender": "314",
            "objectidreceiver": "314",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "159875449545914296314583549562475262650",
            "objectidsender": "315",
            "objectidreceiver": "315",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "156897392314864270346738581540641794130",
            "objectidsender": "316",
            "objectidreceiver": "316",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "229577962242540637668136302738095506898",
            "objectidsender": "317",
            "objectidreceiver": "317",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "321770609887468099664803406922464580310",
            "objectidsender": "318",
            "objectidreceiver": "318",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "252329399475329237427579347850990139579",
            "objectidsender": "319",
            "objectidreceiver": "319",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "240913235807910321011597397769789262564",
            "objectidsender": "320",
            "objectidreceiver": "320",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "312595265320744726419441190627799614911",
            "objectidsender": "321",
            "objectidreceiver": "321",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "45388168158828306696366805419159000240",
            "objectidsender": "322",
            "objectidreceiver": "322",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "133891496665906592325223849200075575472",
            "objectidsender": "323",
            "objectidreceiver": "323",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "28558460189537247354104478637738010437",
            "objectidsender": "324",
            "objectidreceiver": "324",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "28857624526038229473414880717777752279",
            "objectidsender": "325",
            "objectidreceiver": "325",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "115949815397568643921375493363771746870",
            "objectidsender": "326",
            "objectidreceiver": "326",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "68622229018694977519653075682917648977",
            "objectidsender": "327",
            "objectidreceiver": "327",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "43471886491084528214717580991531582103",
            "objectidsender": "328",
            "objectidreceiver": "328",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "261503601169777172413982769842195157192",
            "objectidsender": "329",
            "objectidreceiver": "329",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "280256801231954049421126630740712535448",
            "objectidsender": "330",
            "objectidreceiver": "330",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "85993462567922983923688055673461638999",
            "objectidsender": "331",
            "objectidreceiver": "331",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "116308938414392818326509323202378380820",
            "objectidsender": "332",
            "objectidreceiver": "332",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "289268657857168198959172824126273246724",
            "objectidsender": "333",
            "objectidreceiver": "333",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "316449615564791494211107960653475935662",
            "objectidsender": "334",
            "objectidreceiver": "334",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "296017015529430695525397087288422481016",
            "objectidsender": "335",
            "objectidreceiver": "335",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "142848665653487889855269752198776694293",
            "objectidsender": "336",
            "objectidreceiver": "336",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "126350353712688631924529170455020238597",
            "objectidsender": "337",
            "objectidreceiver": "337",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "190907902362923197523128584186829961839",
            "objectidsender": "338",
            "objectidreceiver": "338",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "272377374370214178968601085232326790761",
            "objectidsender": "339",
            "objectidreceiver": "339",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "253542398373526120208085362145547472218",
            "objectidsender": "340",
            "objectidreceiver": "340",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "91424586430767628516642936064882465674",
            "objectidsender": "341",
            "objectidreceiver": "341",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "127799992087627446917726703369807531307",
            "objectidsender": "342",
            "objectidreceiver": "342",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "177031281847765691206211489645010099047",
            "objectidsender": "343",
            "objectidreceiver": "343",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "101716226832888402138030973084963142428",
            "objectidsender": "344",
            "objectidreceiver": "344",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "315089542482519684671414774120086984378",
            "objectidsender": "345",
            "objectidreceiver": "345",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "212735906118707248164465001796929777487",
            "objectidsender": "346",
            "objectidreceiver": "346",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "330622098198823580384893263615407803397",
            "objectidsender": "347",
            "objectidreceiver": "347",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "282648875478400616035141797362634868491",
            "objectidsender": "348",
            "objectidreceiver": "348",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "59073267221863727806090662915005619898",
            "objectidsender": "349",
            "objectidreceiver": "349",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "301686031801457367246270819702213981211",
            "objectidsender": "350",
            "objectidreceiver": "350",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "283273312117483347800719174356337233347",
            "objectidsender": "351",
            "objectidreceiver": "351",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "18736389438914690665368589516859075286",
            "objectidsender": "352",
            "objectidreceiver": "352",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "269897747155887274732389669839400968983",
            "objectidsender": "353",
            "objectidreceiver": "353",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "242112713606803888071417429808850530257",
            "objectidsender": "354",
            "objectidreceiver": "354",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "141878090244192875907185042542597776642",
            "objectidsender": "355",
            "objectidreceiver": "355",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "79198413404048877007632825653932629164",
            "objectidsender": "356",
            "objectidreceiver": "356",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "221658621266993817087521675279895717316",
            "objectidsender": "357",
            "objectidreceiver": "357",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "242530741086093294243242268702289707597",
            "objectidsender": "358",
            "objectidreceiver": "358",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "238106281695621047847477527033852154321",
            "objectidsender": "359",
            "objectidreceiver": "359",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "89175636802014623563642714332352891394",
            "objectidsender": "360",
            "objectidreceiver": "360",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "186982152662720862912847809578101523297",
            "objectidsender": "361",
            "objectidreceiver": "361",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "500030872543374196712546977160866414",
            "objectidsender": "362",
            "objectidreceiver": "362",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "118604777414724587665788251505814216374",
            "objectidsender": "363",
            "objectidreceiver": "363",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "162494588490125959282286686995476025908",
            "objectidsender": "364",
            "objectidreceiver": "364",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "308658218621397240037133237686333228925",
            "objectidsender": "365",
            "objectidreceiver": "365",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "71582129439058252936285296907929414899",
            "objectidsender": "366",
            "objectidreceiver": "366",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "235948019209509087118885971861698621045",
            "objectidsender": "367",
            "objectidreceiver": "367",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "2",
            "property.objectidreceiver": "2",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "65261643165623789834516845715951298719",
            "objectidsender": "368",
            "objectidreceiver": "368",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "199418516675108235452988302739800730590",
            "objectidsender": "369",
            "objectidreceiver": "369",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "259389443195749131828824328482280269308",
            "objectidsender": "370",
            "objectidreceiver": "370",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "264028837729844383130355484527334384786",
            "objectidsender": "371",
            "objectidreceiver": "371",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "279001945499274594694244632544432634732",
            "objectidsender": "372",
            "objectidreceiver": "372",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "27881883021405762352416547594925416686",
            "objectidsender": "373",
            "objectidreceiver": "373",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "63484554156624369234196935037287835564",
            "objectidsender": "374",
            "objectidreceiver": "374",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "138926736043630278109779171873269997217",
            "objectidsender": "375",
            "objectidreceiver": "375",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "21964556744798396199837754375026946228",
            "objectidsender": "376",
            "objectidreceiver": "376",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "226737059795013023248209938557495977896",
            "objectidsender": "377",
            "objectidreceiver": "377",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "259348951330123351945968560246811684128",
            "objectidsender": "378",
            "objectidreceiver": "378",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "322168983979388311408692643303909416906",
            "objectidsender": "379",
            "objectidreceiver": "379",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "268530360832027649907705843913787380477",
            "objectidsender": "380",
            "objectidreceiver": "380",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "97703546564109662019775424203848605827",
            "objectidsender": "381",
            "objectidreceiver": "381",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "112209258746689760825584518794608112400",
            "objectidsender": "382",
            "objectidreceiver": "382",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "76621094489626595682316036939579061527",
            "objectidsender": "383",
            "objectidreceiver": "383",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "325588936625506892610628231452161387478",
            "objectidsender": "384",
            "objectidreceiver": "384",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "220116169199402559527024942627308421347",
            "objectidsender": "385",
            "objectidreceiver": "385",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "78356426593903966053462972740990857409",
            "objectidsender": "386",
            "objectidreceiver": "386",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "50858379157546299269459698005236774422",
            "objectidsender": "387",
            "objectidreceiver": "387",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "291082779398030432647097388417315652845",
            "objectidsender": "388",
            "objectidreceiver": "388",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "277128581263547920840895927930067405912",
            "objectidsender": "389",
            "objectidreceiver": "389",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "4210589156384738436607112159947490560",
            "objectidsender": "390",
            "objectidreceiver": "390",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "324232779791210072259680379122604133907",
            "objectidsender": "391",
            "objectidreceiver": "391",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "293894258652749548418676003294846103898",
            "objectidsender": "392",
            "objectidreceiver": "392",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "235913650290892143054413545829814378043",
            "objectidsender": "393",
            "objectidreceiver": "393",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "28870344765380932595774152453214924801",
            "objectidsender": "394",
            "objectidreceiver": "394",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "130430713148892607040437331862218243389",
            "objectidsender": "395",
            "objectidreceiver": "395",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "322459815916200620707356495593204589568",
            "objectidsender": "396",
            "objectidreceiver": "396",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "254403219429588620126030281348173745154",
            "objectidsender": "397",
            "objectidreceiver": "397",
            "consumptiontype": "Fernwärme",
            "purpose": "HEATING",
            "measure_unit": "MWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "14620858226430967108752484780118803865",
            "objectidsender": "398",
            "objectidreceiver": "398",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "336133735918814520272549299699182000810",
            "objectidsender": "399",
            "objectidreceiver": "399",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "69513534185212728302463082862857009491",
            "objectidsender": "400",
            "objectidreceiver": "400",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "335687862364500028027563835334896967328",
            "objectidsender": "401",
            "objectidreceiver": "401",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "73587385501400019441833111780933707088",
            "objectidsender": "402",
            "objectidreceiver": "402",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "61183640240827097822475329245602163352",
            "objectidsender": "403",
            "objectidreceiver": "403",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "152526604576997453499530603786500131649",
            "objectidsender": "404",
            "objectidreceiver": "404",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "174053441876496813793226354317021058281",
            "objectidsender": "405",
            "objectidreceiver": "405",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "12540097278951376565658392222241501943",
            "objectidsender": "406",
            "objectidreceiver": "406",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "33945860831761650727701002526268317439",
            "objectidsender": "407",
            "objectidreceiver": "407",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "299305695082245368100550638525430629223",
            "objectidsender": "408",
            "objectidreceiver": "408",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "242953816767386800590532659229996090389",
            "objectidsender": "409",
            "objectidreceiver": "409",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "70531523103904192766594334698862501804",
            "objectidsender": "410",
            "objectidreceiver": "410",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "97018252630171937443973054345625963545",
            "objectidsender": "411",
            "objectidreceiver": "411",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "305140125788471919444126232131253491304",
            "objectidsender": "412",
            "objectidreceiver": "412",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "254524205581515404337394745138802707449",
            "objectidsender": "413",
            "objectidreceiver": "413",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "224961671221905706132376456090893702295",
            "objectidsender": "414",
            "objectidreceiver": "414",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "253687169149940616514806140171776193322",
            "objectidsender": "415",
            "objectidreceiver": "415",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "2918129847190420166847145075017603259",
            "objectidsender": "416",
            "objectidreceiver": "416",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "190402128602213772095818079595810966595",
            "objectidsender": "417",
            "objectidreceiver": "417",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "220649188658536084332894445265733354230",
            "objectidsender": "418",
            "objectidreceiver": "418",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "309200355241491359009379120837707884604",
            "objectidsender": "419",
            "objectidreceiver": "419",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "8606732563828919989329601994382832704",
            "objectidsender": "420",
            "objectidreceiver": "420",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-07",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "36469514410924362081750500470830721358",
            "objectidsender": "421",
            "objectidreceiver": "421",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "158858955628471522836414752714114086090",
            "objectidsender": "422",
            "objectidreceiver": "422",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "122838874337834718026604519837106986730",
            "objectidsender": "423",
            "objectidreceiver": "423",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "318247809683882335135221884397075190227",
            "objectidsender": "424",
            "objectidreceiver": "424",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "144602681486281601105359933226253888825",
            "objectidsender": "425",
            "objectidreceiver": "425",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "319115581231686714822181264116688810617",
            "objectidsender": "426",
            "objectidreceiver": "426",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "182920639605351614865392966569744509482",
            "objectidsender": "427",
            "objectidreceiver": "427",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "224292972360488943067622765362689920923",
            "objectidsender": "428",
            "objectidreceiver": "428",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "97197442081994859083709407420483716754",
            "objectidsender": "429",
            "objectidreceiver": "429",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "265235776172728802412698666782329610293",
            "objectidsender": "430",
            "objectidreceiver": "430",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "249485283711407949698638247851153973559",
            "objectidsender": "431",
            "objectidreceiver": "431",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "226031686016273399560427870467452931629",
            "objectidsender": "432",
            "objectidreceiver": "432",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "27572409382466970553305059971589406267",
            "objectidsender": "433",
            "objectidreceiver": "433",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "170990422575230253394255631712911895833",
            "objectidsender": "434",
            "objectidreceiver": "434",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "308180903593636645655090295850067580369",
            "objectidsender": "435",
            "objectidreceiver": "435",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "75033148435453456044832286763787926618",
            "objectidsender": "436",
            "objectidreceiver": "436",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "37278511175821127906083685940871972369",
            "objectidsender": "437",
            "objectidreceiver": "437",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "22188239481849881963308084115176174711",
            "objectidsender": "438",
            "objectidreceiver": "438",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "235462598858312938954839774825366730447",
            "objectidsender": "439",
            "objectidreceiver": "439",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "3767099213975668973311505221205832709",
            "objectidsender": "440",
            "objectidreceiver": "440",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "49990435091167168355275286141892916199",
            "objectidsender": "441",
            "objectidreceiver": "441",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "169039581184721943032774112229697460386",
            "objectidsender": "442",
            "objectidreceiver": "442",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "145726809757186874856463373479213924975",
            "objectidsender": "443",
            "objectidreceiver": "443",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "267335948107528422865905882919093606983",
            "objectidsender": "444",
            "objectidreceiver": "444",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "15653477032999189850577600628982199310",
            "objectidsender": "445",
            "objectidreceiver": "445",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "163259213052405461383834730029775404324",
            "objectidsender": "446",
            "objectidreceiver": "446",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "164165002141534216885641833071398253885",
            "objectidsender": "447",
            "objectidreceiver": "447",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "7460373997136104345866991776321741054",
            "objectidsender": "448",
            "objectidreceiver": "448",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "179180174464185127324335624151077188083",
            "objectidsender": "449",
            "objectidreceiver": "449",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "66623175140352357672465801425594334657",
            "objectidsender": "450",
            "objectidreceiver": "450",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "126505098950391649550494819855558786930",
            "objectidsender": "451",
            "objectidreceiver": "451",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "340110936485895108572355649801528666130",
            "objectidsender": "452",
            "objectidreceiver": "452",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "182887135674558835561180028161137331928",
            "objectidsender": "453",
            "objectidreceiver": "453",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "112534580016927624225137938607868223108",
            "objectidsender": "454",
            "objectidreceiver": "454",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "67423326628503495061297486156329245427",
            "objectidsender": "455",
            "objectidreceiver": "455",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "132411269098747887863965692282389502115",
            "objectidsender": "456",
            "objectidreceiver": "456",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "54225886133202700157829968202333634719",
            "objectidsender": "457",
            "objectidreceiver": "457",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "80207531220163403366682881982350527050",
            "objectidsender": "458",
            "objectidreceiver": "458",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "252326117078386693958787209777105951400",
            "objectidsender": "459",
            "objectidreceiver": "459",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "233100414213696485066807963304398902084",
            "objectidsender": "460",
            "objectidreceiver": "460",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "182275234906691999303575924584331784417",
            "objectidsender": "461",
            "objectidreceiver": "461",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "44272091689874350303073647061186755530",
            "objectidsender": "462",
            "objectidreceiver": "462",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "276856780057303868316745351932502534373",
            "objectidsender": "463",
            "objectidreceiver": "463",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "64786535039613126405292098233249741808",
            "objectidsender": "464",
            "objectidreceiver": "464",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "1243516749447879924243899451525924641",
            "objectidsender": "465",
            "objectidreceiver": "465",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "26470247092721208756628093854760982741",
            "objectidsender": "466",
            "objectidreceiver": "466",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "17601427492938156654827386969267507044",
            "objectidsender": "467",
            "objectidreceiver": "467",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "41677611979524146486332204095700879",
            "objectidsender": "468",
            "objectidreceiver": "468",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "233292560514021150271864605938375050125",
            "objectidsender": "469",
            "objectidreceiver": "469",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "293683486557229711685393175576296824255",
            "objectidsender": "470",
            "objectidreceiver": "470",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "321468297462028179674628298542526739389",
            "objectidsender": "471",
            "objectidreceiver": "471",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "285709990725247442705091634909563839204",
            "objectidsender": "472",
            "objectidreceiver": "472",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "99241235866079694026657023969606696346",
            "objectidsender": "473",
            "objectidreceiver": "473",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "18519999420597441820252113783425535998",
            "objectidsender": "474",
            "objectidreceiver": "474",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "241945234128217192324538983781351745887",
            "objectidsender": "475",
            "objectidreceiver": "475",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "22767806020422399964299137735518708092",
            "objectidsender": "476",
            "objectidreceiver": "476",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "18016560160876452508669703100710063971",
            "objectidsender": "477",
            "objectidreceiver": "477",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "230709769069635472556516553346667304044",
            "objectidsender": "478",
            "objectidreceiver": "478",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "338296802602060097598594902509233194039",
            "objectidsender": "479",
            "objectidreceiver": "479",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "302750936759444888680795655152838613448",
            "objectidsender": "480",
            "objectidreceiver": "480",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "217167825677066635003622073576367318642",
            "objectidsender": "481",
            "objectidreceiver": "481",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "300820454268123247847808206624255727570",
            "objectidsender": "482",
            "objectidreceiver": "482",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "277104807662798000455963120801438811105",
            "objectidsender": "483",
            "objectidreceiver": "483",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "271854019005588387657772097306240600848",
            "objectidsender": "484",
            "objectidreceiver": "484",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "81647804687288916663575476066757560490",
            "objectidsender": "485",
            "objectidreceiver": "485",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "152933589504173878992892900206552351627",
            "objectidsender": "486",
            "objectidreceiver": "486",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "22613300200335543742598931802202963637",
            "objectidsender": "487",
            "objectidreceiver": "487",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "110257680912317674813886016501944468858",
            "objectidsender": "488",
            "objectidreceiver": "488",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "128923759507319672448812195049086806801",
            "objectidsender": "489",
            "objectidreceiver": "489",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "80359952671588655501889888279090418915",
            "objectidsender": "490",
            "objectidreceiver": "490",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "87450653693360220569216300392897339270",
            "objectidsender": "491",
            "objectidreceiver": "491",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "293877768514491241937971238044595723102",
            "objectidsender": "492",
            "objectidreceiver": "492",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "31299123661130299296112278655075985525",
            "objectidsender": "493",
            "objectidreceiver": "493",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "322710575042761054270253141155247492852",
            "objectidsender": "494",
            "objectidreceiver": "494",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "273842186835465764583524206998993555014",
            "objectidsender": "495",
            "objectidreceiver": "495",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "95965855344489724887921550589504240455",
            "objectidsender": "496",
            "objectidreceiver": "496",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2019-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "189493312305120254483552389631110209855",
            "objectidsender": "497",
            "objectidreceiver": "497",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "278749212977844315847174381308497732480",
            "objectidsender": "498",
            "objectidreceiver": "498",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "304980961746833519203685030971572125397",
            "objectidsender": "499",
            "objectidreceiver": "499",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "156948941148874858181791533933042348828",
            "objectidsender": "500",
            "objectidreceiver": "500",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "284320162141847412277360788333193992740",
            "objectidsender": "501",
            "objectidreceiver": "501",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "64569942622185320762866812475511013283",
            "objectidsender": "502",
            "objectidreceiver": "502",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-06",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "268123668820961612062864298135606591574",
            "objectidsender": "503",
            "objectidreceiver": "503",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-07",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "96714685896900345339065574145700876593",
            "objectidsender": "504",
            "objectidreceiver": "504",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-08",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "96932810377885204622859780049810641436",
            "objectidsender": "505",
            "objectidreceiver": "505",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-09",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "26164288826608268365355752749691015689",
            "objectidsender": "506",
            "objectidreceiver": "506",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-10",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "190447409162435224952412822778151818703",
            "objectidsender": "507",
            "objectidreceiver": "507",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-11",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "140491046997284614905116483960188641174",
            "objectidsender": "508",
            "objectidreceiver": "508",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2020-12",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "199753807061094381708260748536231626874",
            "objectidsender": "509",
            "objectidreceiver": "509",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "56934830656794184334546368126398990416",
            "objectidsender": "510",
            "objectidreceiver": "510",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "118720100454724595997872700831920949354",
            "objectidsender": "511",
            "objectidreceiver": "511",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "303624138224992590739189592391093668961",
            "objectidsender": "512",
            "objectidreceiver": "512",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "78619541995725685106594519286338513705",
            "objectidsender": "513",
            "objectidreceiver": "513",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "3",
            "property.objectidreceiver": "3",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "40359866089094229658193872532939445815",
            "objectidsender": "514",
            "objectidreceiver": "514",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "104860284860690059780017510409776063891",
            "objectidsender": "515",
            "objectidreceiver": "515",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "271669573725271267054455761050590734518",
            "objectidsender": "516",
            "objectidreceiver": "516",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Warmwasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "263965444579013085099921560179127822249",
            "objectidsender": "517",
            "objectidreceiver": "517",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "314467772393879864583458335079044985083",
            "objectidsender": "518",
            "objectidreceiver": "518",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "237689045981579700926320237440944297990",
            "objectidsender": "519",
            "objectidreceiver": "519",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "19151628786996592995164316620451083047",
            "objectidsender": "520",
            "objectidreceiver": "520",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-05",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "106830730033201312605140241236354049388",
            "objectidsender": "521",
            "objectidreceiver": "521",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Wasser",
            "purpose": "OTHER",
            "measure_unit": "m³",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "137373295220945955965592925398304604510",
            "objectidsender": "522",
            "objectidreceiver": "522",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "303699679727561315953116447885889251116",
            "objectidsender": "523",
            "objectidreceiver": "523",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "243825266419402820809408897075077240956",
            "objectidsender": "524",
            "objectidreceiver": "524",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "2919559246313151222695555636445236505",
            "objectidsender": "525",
            "objectidreceiver": "525",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Tag",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "322545779508104826983929178207448610250",
            "objectidsender": "526",
            "objectidreceiver": "526",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-02",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "25693003233273852938470304777824604706",
            "objectidsender": "527",
            "objectidreceiver": "527",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-03",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "136903051256590862089144469386387631582",
            "objectidsender": "528",
            "objectidreceiver": "528",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-04",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "305264869888432994758056307771305237375",
            "objectidsender": "529",
            "objectidreceiver": "529",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "Strom Nacht",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        },
        {
            "period.identifier": "2021-01",
            "property.objectidsender": "4",
            "property.objectidreceiver": "4",
            "company.objectidsender": "1",
            "company.objectidreceiver": "1",
            "hash": "5767801842723906199847421899806541967",
            "objectidsender": "530",
            "objectidreceiver": "530",
            "consumptiontype": "OTHER",
            "consumptiontype_other": "ELECTRICITY_REACTIVE",
            "purpose": "ELECTRIC_OPERATION",
            "measure_unit": "KWH",
            "valid_from": "2019-01-01",
            "consumptioninitiator": "LANDLORD_CONTROLLED"
        }
    ],
    "BI": [
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-01",
            "CONSUMPTION": "244.24",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-02",
            "CONSUMPTION": "321.5",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-03",
            "CONSUMPTION": "401.99",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-04",
            "CONSUMPTION": "459.44",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-05",
            "CONSUMPTION": "526.35",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-06",
            "CONSUMPTION": "572.48",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-07",
            "CONSUMPTION": "601.95",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-08",
            "CONSUMPTION": "629.26",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-09",
            "CONSUMPTION": "674.69",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-10",
            "CONSUMPTION": "732.76",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-11",
            "CONSUMPTION": "795.56",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2019-12",
            "CONSUMPTION": "854.79",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2020-01",
            "CONSUMPTION": "926.13",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2020-02",
            "CONSUMPTION": "978.21",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2020-03",
            "CONSUMPTION": "1035.75",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2020-04",
            "CONSUMPTION": "1081.75",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2020-05",
            "CONSUMPTION": "1124.87",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2020-06",
            "CONSUMPTION": "1168.19",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2020-07",
            "CONSUMPTION": "1204.76",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Fernwärme",
            "Date": "2020-08",
            "CONSUMPTION": "1238.68",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-01",
            "CONSUMPTION": "129.46",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-02",
            "CONSUMPTION": "253.74",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-03",
            "CONSUMPTION": "322.17",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-04",
            "CONSUMPTION": "402.48",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-05",
            "CONSUMPTION": "471.04",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-06",
            "CONSUMPTION": "530.35",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-07",
            "CONSUMPTION": "572.66",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-08",
            "CONSUMPTION": "605.41",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-09",
            "CONSUMPTION": "631.96",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-10",
            "CONSUMPTION": "739.26",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-11",
            "CONSUMPTION": "800.42",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2019-12",
            "CONSUMPTION": "856.07",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-01",
            "CONSUMPTION": "932.24",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-02",
            "CONSUMPTION": "981.43",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-03",
            "CONSUMPTION": "1036.15",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-04",
            "CONSUMPTION": "1066.03",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-05",
            "CONSUMPTION": "1128.38",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-06",
            "CONSUMPTION": "1170.96",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-07",
            "CONSUMPTION": "1203.81",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-08",
            "CONSUMPTION": "1241.41",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-09",
            "CONSUMPTION": "1282.36",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-10",
            "CONSUMPTION": "1336.44",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-11",
            "CONSUMPTION": "1398.84",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2020-12",
            "CONSUMPTION": "1503.17",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2021-01",
            "CONSUMPTION": "1599.02",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2021-02",
            "CONSUMPTION": "1672.09",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2021-03",
            "CONSUMPTION": "1766.15",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2021-04",
            "CONSUMPTION": "1809.24",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Warmwasser",
            "Date": "2021-05",
            "CONSUMPTION": "1850.05",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-01",
            "CONSUMPTION": "2044.2",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-02",
            "CONSUMPTION": "2241.4",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-03",
            "CONSUMPTION": "2375.4",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-04",
            "CONSUMPTION": "2589.3",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-05",
            "CONSUMPTION": "2789.6",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-06",
            "CONSUMPTION": "2977.1",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-07",
            "CONSUMPTION": "3292.2",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-08",
            "CONSUMPTION": "3377.4",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-09",
            "CONSUMPTION": "3484.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-10",
            "CONSUMPTION": "3874",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-11",
            "CONSUMPTION": "4081.2",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2019-12",
            "CONSUMPTION": "4244.7",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-01",
            "CONSUMPTION": "4455.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-02",
            "CONSUMPTION": "4603.1",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-03",
            "CONSUMPTION": "4777.4",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-04",
            "CONSUMPTION": "4943",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-05",
            "CONSUMPTION": "5099",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-06",
            "CONSUMPTION": "5253.4",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-07",
            "CONSUMPTION": "5379.1",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-08",
            "CONSUMPTION": "5530.1",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-09",
            "CONSUMPTION": "5678.6",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-10",
            "CONSUMPTION": "5850",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-11",
            "CONSUMPTION": "5999.2",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2020-12",
            "CONSUMPTION": "6117",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2021-01",
            "CONSUMPTION": "6220.9",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2021-02",
            "CONSUMPTION": "6333.3",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2021-03",
            "CONSUMPTION": "6488.6",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2021-04",
            "CONSUMPTION": "6599",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Wasser",
            "Date": "2021-05",
            "CONSUMPTION": "6728",
            "UNIT": "m³"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-01",
            "CONSUMPTION": "2273.57",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-02",
            "CONSUMPTION": "2311.5",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-03",
            "CONSUMPTION": "2362.82",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-04",
            "CONSUMPTION": "2394.37",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-05",
            "CONSUMPTION": "2434",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-06",
            "CONSUMPTION": "2470.22",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-07",
            "CONSUMPTION": "2500.99",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-08",
            "CONSUMPTION": "2531.41",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-09",
            "CONSUMPTION": "2560.57",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-10",
            "CONSUMPTION": "2587.77",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-11",
            "CONSUMPTION": "2625.03",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2019-12",
            "CONSUMPTION": "2712.25",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-01",
            "CONSUMPTION": "2755.84",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-02",
            "CONSUMPTION": "2854.99",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-03",
            "CONSUMPTION": "2895.44",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-04",
            "CONSUMPTION": "2927.25",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-05",
            "CONSUMPTION": "2960.24",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-06",
            "CONSUMPTION": "2995.8",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-07",
            "CONSUMPTION": "3028.73",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-08",
            "CONSUMPTION": "3070.3",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-09",
            "CONSUMPTION": "3104.54",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-10",
            "CONSUMPTION": "3139.1",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-11",
            "CONSUMPTION": "3175.79",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2020-12",
            "CONSUMPTION": "3208.8",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2021-01",
            "CONSUMPTION": "3237.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2021-02",
            "CONSUMPTION": "3269.26",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2021-03",
            "CONSUMPTION": "3306.15",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2021-04",
            "CONSUMPTION": "3331.21",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Tag",
            "Date": "2021-05",
            "CONSUMPTION": "3360.57",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-01",
            "CONSUMPTION": "971.64",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-02",
            "CONSUMPTION": "992.35",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-03",
            "CONSUMPTION": "1005.54",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-04",
            "CONSUMPTION": "1022.04",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-05",
            "CONSUMPTION": "1037.58",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-06",
            "CONSUMPTION": "1050.57",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-07",
            "CONSUMPTION": "1063.99",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-08",
            "CONSUMPTION": "1076.58",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-09",
            "CONSUMPTION": "1088.47",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-10",
            "CONSUMPTION": "1104.13",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-11",
            "CONSUMPTION": "1141.02",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2019-12",
            "CONSUMPTION": "1161.04",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-01",
            "CONSUMPTION": "1188.64",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-02",
            "CONSUMPTION": "1206.1",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-03",
            "CONSUMPTION": "1224.39",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-04",
            "CONSUMPTION": "1238.51",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-05",
            "CONSUMPTION": "1252.73",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-06",
            "CONSUMPTION": "1268.38",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-07",
            "CONSUMPTION": "1283.17",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-08",
            "CONSUMPTION": "1301.82",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-09",
            "CONSUMPTION": "1316.48",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-10",
            "CONSUMPTION": "1330.95",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-11",
            "CONSUMPTION": "1345.24",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2020-12",
            "CONSUMPTION": "1358.87",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2021-01",
            "CONSUMPTION": "1370.48",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2021-02",
            "CONSUMPTION": "1383.91",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2021-03",
            "CONSUMPTION": "1399.1",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2021-04",
            "CONSUMPTION": "1409.96",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "Strom Nacht",
            "Date": "2021-05",
            "CONSUMPTION": "1423.08",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-01",
            "CONSUMPTION": "29.86",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-02",
            "CONSUMPTION": "29.87",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-03",
            "CONSUMPTION": "29.88",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-04",
            "CONSUMPTION": "29.91",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-05",
            "CONSUMPTION": "30.21",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-06",
            "CONSUMPTION": "29.47",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-07",
            "CONSUMPTION": "31.45",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-08",
            "CONSUMPTION": "35.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-09",
            "CONSUMPTION": "41.02",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-10",
            "CONSUMPTION": "46.86",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-11",
            "CONSUMPTION": "56.55",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-12",
            "CONSUMPTION": "65.68",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-01",
            "CONSUMPTION": "79.5",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-02",
            "CONSUMPTION": "85.57",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-03",
            "CONSUMPTION": "91.36",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-04",
            "CONSUMPTION": "93.07",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-05",
            "CONSUMPTION": "98.03",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-06",
            "CONSUMPTION": "105.88",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-07",
            "CONSUMPTION": "117.53",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-08",
            "CONSUMPTION": "133.38",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-09",
            "CONSUMPTION": "140.05",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-10",
            "CONSUMPTION": "141.68",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-11",
            "CONSUMPTION": "144.01",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-12",
            "CONSUMPTION": "144.71",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-01",
            "CONSUMPTION": "144.92",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-02",
            "CONSUMPTION": "148.74",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-03",
            "CONSUMPTION": "149.66",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-04",
            "CONSUMPTION": "149.95",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "1",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-05",
            "CONSUMPTION": "152.73",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-01",
            "CONSUMPTION": "137.5",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-02",
            "CONSUMPTION": "140",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-03",
            "CONSUMPTION": "142.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-04",
            "CONSUMPTION": "149.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-05",
            "CONSUMPTION": "155.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-06",
            "CONSUMPTION": "164.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-07",
            "CONSUMPTION": "185.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-08",
            "CONSUMPTION": "210.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-09",
            "CONSUMPTION": "235.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-10",
            "CONSUMPTION": "248.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-11",
            "CONSUMPTION": "258.3",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2019-12",
            "CONSUMPTION": "265.7",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-01",
            "CONSUMPTION": "271.5",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-02",
            "CONSUMPTION": "277.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-03",
            "CONSUMPTION": "283.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-04",
            "CONSUMPTION": "289.5",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-05",
            "CONSUMPTION": "295.3",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-06",
            "CONSUMPTION": "302.3",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-07",
            "CONSUMPTION": "314.8",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-08",
            "CONSUMPTION": "333.3",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-09",
            "CONSUMPTION": "354.5",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-10",
            "CONSUMPTION": "366.7",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-11",
            "CONSUMPTION": "373.7",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2020-12",
            "CONSUMPTION": "377.3",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2021-01",
            "CONSUMPTION": "382.5",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2021-02",
            "CONSUMPTION": "385.2",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2021-03",
            "CONSUMPTION": "389",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2021-04",
            "CONSUMPTION": "392.8",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernkälte",
            "Date": "2021-05",
            "CONSUMPTION": "398.7",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-01",
            "CONSUMPTION": "605.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-02",
            "CONSUMPTION": "694.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-03",
            "CONSUMPTION": "756.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-04",
            "CONSUMPTION": "804.3",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-05",
            "CONSUMPTION": "835.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-06",
            "CONSUMPTION": "866.4",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-07",
            "CONSUMPTION": "866.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-08",
            "CONSUMPTION": "867.7",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-09",
            "CONSUMPTION": "868.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-10",
            "CONSUMPTION": "880.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-11",
            "CONSUMPTION": "915.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2019-12",
            "CONSUMPTION": "975.4",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-01",
            "CONSUMPTION": "1056.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-02",
            "CONSUMPTION": "1149.2",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-03",
            "CONSUMPTION": "1205.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-04",
            "CONSUMPTION": "1255.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-05",
            "CONSUMPTION": "1282.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-06",
            "CONSUMPTION": "1304.7",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-07",
            "CONSUMPTION": "1315.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-08",
            "CONSUMPTION": "1319.8",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-09",
            "CONSUMPTION": "1322.4",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-10",
            "CONSUMPTION": "1334",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-11",
            "CONSUMPTION": "1368.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2020-12",
            "CONSUMPTION": "1421.4",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2021-01",
            "CONSUMPTION": "1547.1",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2021-02",
            "CONSUMPTION": "1601.7",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2021-03",
            "CONSUMPTION": "1652",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2021-04",
            "CONSUMPTION": "1687.3",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Fernwärme",
            "Date": "2021-05",
            "CONSUMPTION": "1708.9",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-01",
            "CONSUMPTION": "88368.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-02",
            "CONSUMPTION": "90665.80469",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-03",
            "CONSUMPTION": "92933.10156",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-04",
            "CONSUMPTION": "95642.30469",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-05",
            "CONSUMPTION": "98264.60156",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-06",
            "CONSUMPTION": "100972.6016",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-07",
            "CONSUMPTION": "103620.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-08",
            "CONSUMPTION": "106306.6016",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-09",
            "CONSUMPTION": "109029.3047",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-10",
            "CONSUMPTION": "111640.7031",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-11",
            "CONSUMPTION": "114324",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2019-12",
            "CONSUMPTION": "116920.6016",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-01",
            "CONSUMPTION": "122284",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-02",
            "CONSUMPTION": "124768.8984",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-03",
            "CONSUMPTION": "127424.1016",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-04",
            "CONSUMPTION": "130007.8984",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-05",
            "CONSUMPTION": "132695.2969",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-06",
            "CONSUMPTION": "135300.9063",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-07",
            "CONSUMPTION": "137982.6094",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-08",
            "CONSUMPTION": "140644.7031",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-09",
            "CONSUMPTION": "143104",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-10",
            "CONSUMPTION": "145719.7969",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-11",
            "CONSUMPTION": "148308.7031",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2020-12",
            "CONSUMPTION": "150889.7031",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2021-01",
            "CONSUMPTION": "153412.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2021-02",
            "CONSUMPTION": "155641.2031",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2021-03",
            "CONSUMPTION": "157996.61",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2021-04",
            "CONSUMPTION": "160328.61",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Warmwasser",
            "Date": "2021-05",
            "CONSUMPTION": "163001.61",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-01",
            "CONSUMPTION": "17839.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-02",
            "CONSUMPTION": "19009.20117",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-03",
            "CONSUMPTION": "19897.70117",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-04",
            "CONSUMPTION": "21180.20117",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-05",
            "CONSUMPTION": "22306.30078",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-06",
            "CONSUMPTION": "23643.40039",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-07",
            "CONSUMPTION": "24902.90039",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-08",
            "CONSUMPTION": "25499",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-09",
            "CONSUMPTION": "26101.20117",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-10",
            "CONSUMPTION": "27156",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-11",
            "CONSUMPTION": "28639",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2019-12",
            "CONSUMPTION": "31220.80078",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-01",
            "CONSUMPTION": "32486",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-02",
            "CONSUMPTION": "33528.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-03",
            "CONSUMPTION": "34619.10156",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-04",
            "CONSUMPTION": "35363.40234",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-05",
            "CONSUMPTION": "36256.10156",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-06",
            "CONSUMPTION": "37175.10156",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-07",
            "CONSUMPTION": "37912.40234",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-08",
            "CONSUMPTION": "38643.60156",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-09",
            "CONSUMPTION": "39639",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-10",
            "CONSUMPTION": "40714.19922",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-11",
            "CONSUMPTION": "41498.30078",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2020-12",
            "CONSUMPTION": "42027.80078",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2021-01",
            "CONSUMPTION": "42614.30078",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2021-02",
            "CONSUMPTION": "43254",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2021-03",
            "CONSUMPTION": "44062.699",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2021-04",
            "CONSUMPTION": "44816.2",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Wasser",
            "Date": "2021-05",
            "CONSUMPTION": "45659.4",
            "UNIT": "m³"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-01",
            "CONSUMPTION": "4031.42",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-02",
            "CONSUMPTION": "4281.35",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-03",
            "CONSUMPTION": "4489.61",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-04",
            "CONSUMPTION": "4732.02",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-05",
            "CONSUMPTION": "4958.19",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-06",
            "CONSUMPTION": "5182.75",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-07",
            "CONSUMPTION": "5417.72",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-08",
            "CONSUMPTION": "5601.31",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-09",
            "CONSUMPTION": "5786.03",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-10",
            "CONSUMPTION": "5989.94",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-11",
            "CONSUMPTION": "6230.87",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2019-12",
            "CONSUMPTION": "6503.5",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-01",
            "CONSUMPTION": "7009.45",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-02",
            "CONSUMPTION": "7216.61",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-03",
            "CONSUMPTION": "7426.62",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-04",
            "CONSUMPTION": "7607.55",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-05",
            "CONSUMPTION": "7822.54",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-06",
            "CONSUMPTION": "8018.82",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-07",
            "CONSUMPTION": "8213.16",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-08",
            "CONSUMPTION": "104.79",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-09",
            "CONSUMPTION": "308.1",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-10",
            "CONSUMPTION": "529.24",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-11",
            "CONSUMPTION": "735.43",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2020-12",
            "CONSUMPTION": "939.35",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2021-01",
            "CONSUMPTION": "1096.61",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2021-02",
            "CONSUMPTION": "1124.49",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2021-03",
            "CONSUMPTION": "1303.46",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2021-04",
            "CONSUMPTION": "1688.09",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Tag",
            "Date": "2021-05",
            "CONSUMPTION": "1891.07",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-01",
            "CONSUMPTION": "1757.82",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-02",
            "CONSUMPTION": "1858.59",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-03",
            "CONSUMPTION": "1946.69",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-04",
            "CONSUMPTION": "2045.75",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-05",
            "CONSUMPTION": "2139.82",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-06",
            "CONSUMPTION": "2232.12",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-07",
            "CONSUMPTION": "2333.93",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-08",
            "CONSUMPTION": "2418.4",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-09",
            "CONSUMPTION": "2503.26",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-10",
            "CONSUMPTION": "2589.43",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-11",
            "CONSUMPTION": "2685.74",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2019-12",
            "CONSUMPTION": "2798.85",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-01",
            "CONSUMPTION": "3012.48",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-02",
            "CONSUMPTION": "3098.48",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-03",
            "CONSUMPTION": "3187.2",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-04",
            "CONSUMPTION": "3264.98",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-05",
            "CONSUMPTION": "3358.41",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-06",
            "CONSUMPTION": "3446.22",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-07",
            "CONSUMPTION": "3536.46",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-08",
            "CONSUMPTION": "49.01",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-09",
            "CONSUMPTION": "139.61",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-10",
            "CONSUMPTION": "232.15",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-11",
            "CONSUMPTION": "318.37",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2020-12",
            "CONSUMPTION": "402.85",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2021-01",
            "CONSUMPTION": "467.812",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2021-02",
            "CONSUMPTION": "548.55",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2021-03",
            "CONSUMPTION": "630.86",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2021-04",
            "CONSUMPTION": "706.4",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "Strom Nacht",
            "Date": "2021-05",
            "CONSUMPTION": "790.5",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-01",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-02",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-03",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-04",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-05",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-06",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-07",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-08",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-09",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-10",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-11",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-12",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-01",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-02",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-03",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-04",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-05",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-06",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-07",
            "CONSUMPTION": "1.34",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-08",
            "CONSUMPTION": "1.33",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-09",
            "CONSUMPTION": "1.33",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-10",
            "CONSUMPTION": "1.33",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-11",
            "CONSUMPTION": "1.33",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-12",
            "CONSUMPTION": "1.33",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-01",
            "CONSUMPTION": "1.338",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-02",
            "CONSUMPTION": "1.338",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-03",
            "CONSUMPTION": "1.338",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-04",
            "CONSUMPTION": "1.338",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "2",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-05",
            "CONSUMPTION": "1.338",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-01",
            "CONSUMPTION": "1951.61",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-02",
            "CONSUMPTION": "2051.97",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-03",
            "CONSUMPTION": "2101.6",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-04",
            "CONSUMPTION": "2156.44",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-05",
            "CONSUMPTION": "2204.56",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-06",
            "CONSUMPTION": "2250.69",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-07",
            "CONSUMPTION": "2284.825",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-08",
            "CONSUMPTION": "2318.96",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-09",
            "CONSUMPTION": "2349.57",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-10",
            "CONSUMPTION": "2411.57",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-11",
            "CONSUMPTION": "2491.2",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2019-12",
            "CONSUMPTION": "2583.76",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-01",
            "CONSUMPTION": "2676.32",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-02",
            "CONSUMPTION": "2725.2",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-03",
            "CONSUMPTION": "2841.02",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-04",
            "CONSUMPTION": "3788.45",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-05",
            "CONSUMPTION": "9.68",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-06",
            "CONSUMPTION": "39.28",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-07",
            "CONSUMPTION": "67.77",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-08",
            "CONSUMPTION": "86.68",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-09",
            "CONSUMPTION": "114.83",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-10",
            "CONSUMPTION": "168.49",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-11",
            "CONSUMPTION": "239.91",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2020-12",
            "CONSUMPTION": "312.42",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2021-01",
            "CONSUMPTION": "398.06",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2021-02",
            "CONSUMPTION": "459.24",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2021-03",
            "CONSUMPTION": "517.81",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2021-04",
            "CONSUMPTION": "565.89",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Fernwärme",
            "Date": "2021-05",
            "CONSUMPTION": "600.98",
            "UNIT": "MWH"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2019-05",
            "CONSUMPTION": "1189.286",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-02",
            "CONSUMPTION": "1480.668",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-03",
            "CONSUMPTION": "1514.248",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-04",
            "CONSUMPTION": "1547.11",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-05",
            "CONSUMPTION": "1574.485",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-06",
            "CONSUMPTION": "1601.701",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-07",
            "CONSUMPTION": "1628.179",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-08",
            "CONSUMPTION": "1646.348",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-09",
            "CONSUMPTION": "1669.066",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-10",
            "CONSUMPTION": "1701.588",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-11",
            "CONSUMPTION": "1730.833",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2020-12",
            "CONSUMPTION": "1757.606",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2021-01",
            "CONSUMPTION": "1788.515",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2021-02",
            "CONSUMPTION": "1815.319",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2021-03",
            "CONSUMPTION": "1846.914",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2021-04",
            "CONSUMPTION": "1877.221",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Warmwasser",
            "Date": "2021-05",
            "CONSUMPTION": "1904.74",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-01",
            "CONSUMPTION": "3275.3",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-02",
            "CONSUMPTION": "3408.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-03",
            "CONSUMPTION": "3473.1",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-04",
            "CONSUMPTION": "3580.3",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-05",
            "CONSUMPTION": "3685.6",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-06",
            "CONSUMPTION": "3801",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-07",
            "CONSUMPTION": "3909.9",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-08",
            "CONSUMPTION": "4018.8",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-09",
            "CONSUMPTION": "4094.3",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-10",
            "CONSUMPTION": "4226.8",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-11",
            "CONSUMPTION": "4349.4",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2019-12",
            "CONSUMPTION": "4447.3",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2020-01",
            "CONSUMPTION": "4545.2",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2020-02",
            "CONSUMPTION": "4666.5",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2020-11",
            "CONSUMPTION": "5645.72",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2020-12",
            "CONSUMPTION": "6097.31",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2021-01",
            "CONSUMPTION": "6945.01",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2021-02",
            "CONSUMPTION": "7675.96",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2021-03",
            "CONSUMPTION": "8575.19",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2021-04",
            "CONSUMPTION": "9692.39",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Wasser",
            "Date": "2021-05",
            "CONSUMPTION": "10508.49",
            "UNIT": "m³"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-01",
            "CONSUMPTION": "8078.59",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-02",
            "CONSUMPTION": "8678.01",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-03",
            "CONSUMPTION": "8869.42",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-04",
            "CONSUMPTION": "9130.87",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-05",
            "CONSUMPTION": "9389.62",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-06",
            "CONSUMPTION": "9667.14",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-08",
            "CONSUMPTION": "10313.82",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-09",
            "CONSUMPTION": "10543.82",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-10",
            "CONSUMPTION": "10875.04",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-11",
            "CONSUMPTION": "11185.63",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2019-12",
            "CONSUMPTION": "11489.82",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-01",
            "CONSUMPTION": "11794.01",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-02",
            "CONSUMPTION": "12034.52",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-03",
            "CONSUMPTION": "12323.74",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-04",
            "CONSUMPTION": "12609.51",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-05",
            "CONSUMPTION": "12843.88",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-06",
            "CONSUMPTION": "13081.99",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-07",
            "CONSUMPTION": "13341.98",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-08",
            "CONSUMPTION": "13546.57",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-09",
            "CONSUMPTION": "13775.31",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-10",
            "CONSUMPTION": "14052.18",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-11",
            "CONSUMPTION": "14292.24",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2020-12",
            "CONSUMPTION": "14505.7",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2021-01",
            "CONSUMPTION": "14753.47",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2021-02",
            "CONSUMPTION": "14959.83",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2021-03",
            "CONSUMPTION": "15192.83",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2021-04",
            "CONSUMPTION": "15426.41",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Tag",
            "Date": "2021-05",
            "CONSUMPTION": "15641.56",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-01",
            "CONSUMPTION": "2750.97",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-02",
            "CONSUMPTION": "2855.17",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-03",
            "CONSUMPTION": "2915.12",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-04",
            "CONSUMPTION": "2993.55",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-05",
            "CONSUMPTION": "3078.71",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-06",
            "CONSUMPTION": "3164.69",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-08",
            "CONSUMPTION": "3375.11",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-09",
            "CONSUMPTION": "3442.39",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-10",
            "CONSUMPTION": "3534.99",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-11",
            "CONSUMPTION": "3619.47",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2019-12",
            "CONSUMPTION": "3704.68",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-01",
            "CONSUMPTION": "3789.89",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-02",
            "CONSUMPTION": "3852.29",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-03",
            "CONSUMPTION": "3930.58",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-04",
            "CONSUMPTION": "4010.84",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-05",
            "CONSUMPTION": "4076.8",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-06",
            "CONSUMPTION": "4145.86",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-07",
            "CONSUMPTION": "4223.45",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-08",
            "CONSUMPTION": "4284.62",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-09",
            "CONSUMPTION": "4350.04",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-10",
            "CONSUMPTION": "4424.04",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-11",
            "CONSUMPTION": "4484.94",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2020-12",
            "CONSUMPTION": "4540.75",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2021-01",
            "CONSUMPTION": "4606.71",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2021-02",
            "CONSUMPTION": "4662.19",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2021-03",
            "CONSUMPTION": "4722.78",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2021-04",
            "CONSUMPTION": "4786.09",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "Strom Nacht",
            "Date": "2021-05",
            "CONSUMPTION": "4786.02",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-04",
            "CONSUMPTION": "3078.71",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-08",
            "CONSUMPTION": "6938.7",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-09",
            "CONSUMPTION": "7101.43",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-10",
            "CONSUMPTION": "7340.05",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-11",
            "CONSUMPTION": "7566.16",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2019-12",
            "CONSUMPTION": "7785.13",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-01",
            "CONSUMPTION": "8004.1",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-02",
            "CONSUMPTION": "8182.19",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-03",
            "CONSUMPTION": "8393.15",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-04",
            "CONSUMPTION": "8598.67",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-05",
            "CONSUMPTION": "8767.08",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-06",
            "CONSUMPTION": "8936.11",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-07",
            "CONSUMPTION": "9118.52",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-08",
            "CONSUMPTION": "9261.94",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-09",
            "CONSUMPTION": "9425.26",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-10",
            "CONSUMPTION": "9628.13",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-11",
            "CONSUMPTION": "9807.29",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2020-12",
            "CONSUMPTION": "9964.95",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-01",
            "CONSUMPTION": "10146.76",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-02",
            "CONSUMPTION": "10297.63",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-03",
            "CONSUMPTION": "10470.05",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-04",
            "CONSUMPTION": "10640.32",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "3",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-05",
            "CONSUMPTION": "10795.55",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "Warmwasser",
            "Date": "2021-04",
            "CONSUMPTION": "373766",
            "UNIT": "m³"
        },
        {
            "Property_ID": "4",
            "Type": "Warmwasser",
            "Date": "2021-05",
            "CONSUMPTION": "377901",
            "UNIT": "m³"
        },
        {
            "Property_ID": "4",
            "Type": "Wasser",
            "Date": "2021-01",
            "CONSUMPTION": "9072",
            "UNIT": "m³"
        },
        {
            "Property_ID": "4",
            "Type": "Wasser",
            "Date": "2021-02",
            "CONSUMPTION": "9446",
            "UNIT": "m³"
        },
        {
            "Property_ID": "4",
            "Type": "Wasser",
            "Date": "2021-03",
            "CONSUMPTION": "9680",
            "UNIT": "m³"
        },
        {
            "Property_ID": "4",
            "Type": "Wasser",
            "Date": "2021-04",
            "CONSUMPTION": "9945",
            "UNIT": "m³"
        },
        {
            "Property_ID": "4",
            "Type": "Wasser",
            "Date": "2021-05",
            "CONSUMPTION": "10291",
            "UNIT": "m³"
        },
        {
            "Property_ID": "4",
            "Type": "Strom Tag",
            "Date": "2021-01",
            "CONSUMPTION": "7575.61",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "Strom Tag",
            "Date": "2021-02",
            "CONSUMPTION": "6040.74",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "Strom Tag",
            "Date": "2021-03",
            "CONSUMPTION": "7093.77",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "Strom Tag",
            "Date": "2021-04",
            "CONSUMPTION": "6040.74",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "Strom Nacht",
            "Date": "2021-01",
            "CONSUMPTION": "2707.08",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "Strom Nacht",
            "Date": "2021-02",
            "CONSUMPTION": "2347.01",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "Strom Nacht",
            "Date": "2021-03",
            "CONSUMPTION": "2920.72",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "Strom Nacht",
            "Date": "2021-04",
            "CONSUMPTION": "2347.01",
            "UNIT": "KWH"
        },
        {
            "Property_ID": "4",
            "Type": "ELECTRICITY_REACTIVE",
            "Date": "2021-01",
            "CONSUMPTION": "28.56",
            "UNIT": "KWH"
        }
    ]
}

export default VerbrauchsDashboardAlt;