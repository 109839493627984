import EnumPage from "./EnumPage";
import {useAPI} from "../../../../api/v2/apiV2";
import React, {useEffect, useMemo, useState} from "react";
import styled from "styled-components";


const EnumSidebarCard = styled.div`
  width: 100%;

  border-radius: 8px;
  border: 1px solid #ccc;
  margin: 16px 8px 0 8px;
`
const EnumSidebarElementContainer = styled.div`

  height: calc(100vh - 195px);
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  `
const EnumSortContainer = styled.div`
  border-bottom: 1px solid #ccc;
  border-radius: 8px;
  
`
const SortInput = styled.input`

  border: none;
  border-radius: 8px;
  width: 100%;
  margin-top: 2px;
  height: 36px;
  background-color: transparent;
`
const EnumSidebarElement = styled.div`
    border-bottom: 1px solid #ccc;
    width: 100%;
    text-align: left;
    border-radius: 0;
    line-height:1;
    padding: 0px 16px;
  
    `

function EnumSidebar(props){

    console.log(props)

    const api = useAPI();
    let enum_keys = Object.keys(props.data)
    let enumeration_data = props.data
    console.log(props,"proops enum sidebar")

    function clickSidebarElement(elem){
        props.setSelectedEnum(elem)
    }
    function sortInput(elem){
        let val = elem.target.value.toLowerCase()
        document.querySelectorAll(".enumSidebarElement").forEach((x)=>{
            if(val == ""){
                x.style.display = "block"
            }else{
                if(x.getAttribute("data-title-lowercase").includes(val.toLowerCase()) || x.getAttribute("data-content-lowercase").includes(val.toLowerCase())){
                    x.style.display = "block"
                }else{
                    x.style.display = "none"
                }
            }
        })


        console.log(val)

    }
    const handleClick = (e) => {
        // Change the background color to a random color
        if(e.key === "Enter")
            document.querySelector('.enumSidebarElement:not([style*="display:none"]):not([style*="display: none"])').click()
    }

    return(
        <div className={props.className}>
            <EnumSidebarCard className={""}>
                <EnumSortContainer className={"w3-display-container"}>
                    <div className="w3-display-container">
                        <SortInput onInput={sortInput} onKeyPress={handleClick}></SortInput>
                            <div className={"w3-display-right w3-text-grey"} style={{padding:"24px"}}><i
                                className={"fa fa-search"}></i></div>
                    </div>
                </EnumSortContainer>
                <EnumSidebarElementContainer>
                    {
                        enum_keys.map((x,i)=>{


                            return <EnumSidebarElement data-title-lowercase={x.toLowerCase()} data-content-lowercase={x.toLowerCase()} onClick={()=>{clickSidebarElement(x)}}  key={x} className={"w3-button w3-display-container enumSidebarElement"} >
                                <div className={"w3-left"} style={{width:"100%"}}>
                                    <h5><b>{x}</b></h5>
                                    <p >{enumeration_data[x].values.length} Entries</p>
                                </div>
                            </EnumSidebarElement>


                        })
                    }
                </EnumSidebarElementContainer>
            </EnumSidebarCard>

        </div>
    )
}

export default EnumSidebar;
