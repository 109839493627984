import React, {Component} from "react";
import Chart from "chart.js/auto";
class ErzeugerDashboard extends Component {
    constructor(props) {
        super(props);



    }

    onlyUnique=(value, index, self) =>{
        return self.indexOf(value) === index;
    }

    getRandomColor=() =>{
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    inputConvertAbsolute=(input)=>{
        let datasets = [];
        let labels = [];
        input.data.values.forEach(x=>{labels.push(x.timestamp)})
        labels = labels.filter(this.onlyUnique);
        labels = labels.sort();
        input.data.topics.forEach(x=>{
            let ds = {};
            ds.label = x;
            ds.backgroundColor = this.getRandomColor();
            ds.data = []
            labels.forEach(y=>{
                input.data.values.forEach(z=>{
                    if(z.timestamp == y && z.topic == x)
                        ds.data.push(z.value);
                })
            })
            datasets.push(ds)

        })
        return {"labels":labels, "datasets":datasets};
    }
    drawBar=()=>{
        let erzeugerTypes = ["Solar", "Geothermie"]
        let labels = this.props.data.buildings.filter(b=>Object.keys(b.resources).filter(x=>erzeugerTypes.includes(x)).length).map(x=>x.id)
        let datasets = erzeugerTypes.map(t=>{
            return {
                label: t,
                backgroundColor: this.props.resourceSettings[t].color,
                data: this.props.data.buildings.filter(b=>Object.keys(b.resources).filter(x=>erzeugerTypes.includes(x)).length).map(x=>{
                    return x.resources[t]?x.resources[t].values.map(y=>y?y.value:0).reduce((a,b)=>{return a+b},0):0
                })
            }
        })
        // console.log(labels)
        // console.log(datasets)
        let barChartData = {labels:labels, datasets: datasets}
        // console.log(this.resourceSettings)
        // console.log(this.props)
        // console.log("bcd", barChartData)
        let ctx = document.getElementById('canvas').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'bar',

            data: barChartData,
            options: {
                plugins:{
                    legend:{
                        display: true,
                        onClick : function(a,b){
                            // console.log("Click on Legend: " + b.text)
                        }
                    }
                },
                layout:{padding:8},
                title: {
                    display: false,
                    text: ''
                },
                tooltips: {
                    mode: 'index',
                    intersect: false
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,

                    },
                    y: {
                        stacked: true,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value+ " kwh";
                            }
                        }
                    }
                }
            }
        });
    }
    componentDidMount(){
        this.drawBar()
    }
    render() {
        return(<div>

            <div className="w3-row-padding">
                <div className="w3-white w3-card w3-panel" style={{"margin-top":"0px"}}>
                    <div className="w3-dropdown-hover w3-white ">
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Zeit</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input name={"zeitRadio"} defaultChecked={true} type="radio"/> 2017</a>
                            <a className="w3-bar-item w3-button"><input name={"zeitRadio"} type="radio"/> 2018</a>
                            <a className="w3-bar-item w3-button"><input name={"zeitRadio"} type="radio"/> 2019</a>
                            <a className="w3-bar-item w3-button"><input name={"zeitRadio"} type="radio"/> 2020</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white w3-padding">
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Region</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> Nord</a>
                            <a className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> Mitte</a>
                            <a className="w3-bar-item w3-button"><input type="checkbox"/> Süd</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white w3-padding">
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Nutzungsart
                        </button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> Office</a>
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}
                                                                                 type="checkbox"/> Residential</a>
                            <a href="#" className="w3-bar-item w3-button"><input type="checkbox"/> Retail</a>
                            <a href="#" className="w3-bar-item w3-button"><input type="checkbox"/> Parking</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white w3-padding">
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Objekte</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> p1874</a>
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> p1877</a>
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> p1922</a>
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> p1953</a>
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> p1982</a>
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> p1987</a>
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> p1988</a>
                            <a href="#" className="w3-bar-item w3-button"><input defaultChecked={true}  type="checkbox"/> p1995</a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w3-row-padding ">
                <div className="w3-twothird w3-white w3-card w3-center w3-margin-bottom" style={{"height":"400px"}}>
                    <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                        <div className="w3-left"><h4>Property Vergleich</h4></div>
                        <div className="w3-right">

                        </div>
                    </div>
                    <div style={{"maxHeight":"300px"}} className="w3-padding">
                        <canvas id="canvas" style={{"height":"210px", "width":"700px"}}></canvas>
                    </div>

                </div>
                <div className="w3-third" style={{"paddingRight":"0px"}}>
                    <ul className="w3-ul w3-card w3-white w3-margin-bottom" style={{"height":"400px","overflowY":"scroll"}}>
                        <li className="w3-bar">
                            <div className="w3-bar-item w3-circle w3-hide-small" style={{"width":"185px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 20% <br/>zu letztem Monat</div>
                            <div className="w3-bar-item">
                                <span className="w3-large" style={{"fontWeight":"bold"}}>p1874</span><br/>
                                <span className="addr">Arabella Bogen</span>
                            </div>
                        </li>
                        <li className="w3-bar">
                            <div className="w3-bar-item w3-circle w3-hide-small" style={{"width":"185px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 7% <br/>zu letztem Monat</div>
                            <div className="w3-bar-item">
                                <span className="w3-large" style={{"fontWeight":"bold"}}>p1877</span><br/>
                                <span className="addr">Fabrik MSG</span>
                            </div>
                        </li>
                        <li className="w3-bar">
                            <div className="w3-bar-item w3-circle w3-hide-small" style={{"width":"185px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 5% <br/>zu letztem Monat</div>
                            <div className="w3-bar-item">
                                <span className="w3-large" style={{"fontWeight":"bold"}}>p1922</span><br/>
                                <span className="addr">Hinter der Haide 2</span>
                            </div>
                        </li>
                        <li className="w3-bar">
                            <div className="w3-bar-item w3-circle w3-hide-small" style={{"width":"185px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 11% <br/>zu letztem Monat</div>
                            <div className="w3-bar-item">
                                <span className="w3-large" style={{"fontWeight":"bold"}}>p1953</span><br/>
                                <span className="addr">Handelskai 234</span>
                            </div>
                        </li>
                        <li className="w3-bar">
                            <div className="w3-bar-item w3-circle w3-hide-small" style={{"width":"185px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 8% <br/>zu letztem Monat</div>
                            <div className="w3-bar-item">
                                <span className="w3-large" style={{"fontWeight":"bold"}}>p1982</span><br/>
                                <span className="addr">Lange Gasse 17</span>
                            </div>
                        </li>

                        <li className="w3-bar">
                            <div className="w3-bar-item w3-circle w3-hide-small" style={{"width":"185px"}}><i className="fa fa-caret-up" style={{"font-size":"28px","color":"red"}}></i> 18% <br/>zu letztem Monat</div>
                            <div className="w3-bar-item">
                                <span className="w3-large" style={{"fontWeight":"bold"}}>p1987</span><br/>
                                <span className="addr">Hinterholz 8</span>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>


        </div>)
    }
}

export default ErzeugerDashboard;