import React, {Component} from "react";
import 'ol/ol.css';
import GeoJSON from 'ol/format/GeoJSON';
import Map from 'ol/Map';
import OSM from 'ol/source/OSM';
import TileLayer from 'ol/layer/Tile';
import Vector from 'ol/source/Vector';
import View from 'ol/View';
import WebGLPointsLayer from 'ol/layer/WebGLPoints';
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import {Feature, Overlay} from "ol";
import {Circle, Fill, Icon, Style} from 'ol/style';
import {Point} from "ol/geom";
import {useGeographic} from 'ol/proj';
import Chart from 'chart.js/auto';
import * as Gauge from 'chartjs-gauge';


class ESGHome extends Component {

    constructor(props) {
        super(props);
        this.state={
            selectedBuilding : {},
            imageCarousel: 0
        }

        this.drawMap = this.drawMap.bind(this);


    }

    drawScatter=()=>{
        const data = {
            datasets: [{
                label: 'Office',
                data: [
                    {
                        x: 200,
                        y: 119,
                        r:12
                    }, {
                        x: 170,
                        y: 80,
                        r:25
                    }, {
                        x: 190,
                        y: 115,
                        r:13
                    }, {
                        x: 235.5,
                        y: 68.5,
                        r:5
                    }],
                backgroundColor: 'rgba(25,0,255,0.8)'
            },{
                label: 'Retail',
                data: [{
                    x: 212,
                    y: 77,
                    r:15
                }, {
                    x: 190,
                    y: 80,
                    r:25
                }, {
                    x: 165,
                    y: 111,
                    r:8
                }, {
                    x: 198.5,
                    y: 95
                }],
                backgroundColor: 'rgba(255,200,0,0.8)'
            },{
                label: 'Residential',
                data: [{
                    x: 162,
                    y: 72,
                    r:15
                }, {
                    x: 150,
                    y: 88,
                    r:25
                }, {
                    x: 135,
                    y: 115,
                    r:8
                }, {
                    x: 198.5,
                    y: 95.5
                }],
                backgroundColor: 'rgba(89,255,0,0.8)'
            }

            ],
        };
        let ctx = document.getElementById('canvas').getContext('2d');
        window.myScatter = new Chart(ctx, {
            type: 'bubble',

            data: data,
            options: {
                onClick: function(e) {
                    var activePoints =window.myBar.getDatasetMeta(e);
                },
                legend:{
                    onClick : function(a,b){
                        // console.log("Click on Legend: " + b.text)
                    }
                },
                title: {
                    display: true,
                    text: ''
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label = data.labels[tooltipItem.index];
                            return "Objekt "+ Math.floor(Math.random() * 10+1) + ': ' + tooltipItem.xLabel + ' m², ' + tooltipItem.yLabel + 't CO2';
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        suggestedMin: 100,
                        title: {
                            display: true,
                            text: "Energy intensity (kWh/m²)"
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value+ "";
                            }
                        }
                    },
                    y: {

                        beginAtZero: true,
                        title: {
                          display: true,
                          text: "Carbon intensity (kgCO2e/m²)"
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value;
                            }
                        }
                    }
                }
            }
        });
    };
    drawZertifizierungen=()=>{

        const data = {
            labels: ['A','B','C','D','E'],
            datasets: [{
                label: 'Residential',
                data: [0,2,3,1,0],
                borderColor: "rgba(89,255,0,0.8)",
                backgroundColor: "rgba(89,255,0,0.8)",
            },{
                label: 'Office',
                data: [1,1,1,0,1],
                borderColor: "rgba(25,0,255,0.8)",
                backgroundColor: "rgba(25,0,255,0.8)",
            },{
                label: 'Retail',
                data: [0,0,1,1,1],
                borderColor: "rgba(255,200,0,0.8)",
                backgroundColor: "rgba(255,200,0,0.8)",
            }]


        };
        let ctx = document.getElementById('zertifizierungen').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'bar',

            data: data,
            options: {
                title: {
                    display: false,
                    text: ''
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            callback: function(value) {if (value % 1 === 0) {return value;}}
                        }
                    }
                }

            }
        });
    };

    drawGauge=()=>{

        const data = {
            labels: ['AAA','AA','A','B'],
            datasets: [{
                label: 'Housing',
                data: [3,2,3,1],
                borderColor: "rgba(89,255,0,0.8)",
                backgroundColor: "rgba(89,255,0,0.8)",
            },{
                label: 'Office',
                data: [1,1,1,0],
                borderColor: "rgba(25,0,255,0.8)",
                backgroundColor: "rgba(25,0,255,0.8)",
            },{
                label: 'Retail',
                data: [1,0,1,0],
                borderColor: "rgba(255,200,0,0.8)",
                backgroundColor: "rgba(255,200,0,0.8)",
            }]


        };
        let ctx = document.getElementById('gauge').getContext('2d');
        window.myGauge = new Gauge(ctx,{
            type: 'gauge',
            data: {
                datasets: [{
                    data: [20,50,80,100],
                    value: 75,
                    backgroundColor: ['red', 'orange', 'yellow', 'green'],
                    borderWidth: 2
                }]
            },
            options: {
                responsive: false,
                maintainAspectRatio: true,

                title: {
                    display: false,
                    text: 'Gauge chart'
                },
                layout: {
                    padding: {
                        bottom: 30
                    }
                },
                needle: {
                    // Needle circle radius as the percentage of the chart area width
                    radiusPercentage: 2,
                    // Needle width as the percentage of the chart area width
                    widthPercentage: 3.2,
                    // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
                    lengthPercentage: 80,
                    // The color of the needle
                    color: 'rgba(0, 0, 0, 1)'
                },
                valueLabel: {
                    formatter: Math.round
                }
            }
        }
        );
        window.myGauge.canvas.style.display ="inline-block"
    };
    drawMap=()=>{
        new useGeographic();

        let buildings = this.props.data.buildings;
        let feature_list = buildings.map((x)=>{
            let place = [x.lon, x.lat];
            let point = new Point(place);
            let f1 = new Feature(point)
            f1.building = x;
            return f1
        })


        let layers = [
            new TileLayer({
                source: new OSM(),
            })
        ]
            new Set(feature_list.map(x=>x.building.iconType)).forEach(x=>{
                // console.log("oldfeature",feature_list.filter(y=>y.building.iconType==x))
                layers.push(new VectorLayer({
                    source: new VectorSource({
                        features: feature_list.filter(y=>y.building.iconType==x),
                    }),
                    style: new Style({
                        image: new Icon({
                            scale: [0.08, 0.08],

                            src: '../images/mapIcons/'+x+'.png',
                        }),
                    }),
                }))
        })
        window.old_map = new Map({
            layers: layers,
            target: 'mapdiv',
            view: new View({
                center: [12, 48],
                zoom: 6,
            }),
        });

        const element = document.getElementById('popup');

        const popup = new Overlay({
            element: element,
            positioning: 'bottom-center',
            stopEvent: false,
            offset: [0, -10],
        });
        window.old_map.addOverlay(popup);






        window.old_map.on('click',  (event)=> {
            const feature = window.old_map.getFeaturesAtPixel(event.pixel)[0];
            if (feature) {
                this.props.buildingSelector(feature.building);
            } else {
                document.getElementById("customTtip").style.visibility = 'hidden';
            }
        });
        window.old_map.on('pointermove', function (event) {
            if (window.old_map.hasFeatureAtPixel(event.pixel)) {
                window.old_map.getViewport().style.cursor = 'pointer';
            } else {
                window.old_map.getViewport().style.cursor = 'inherit';
            }
            const feature = window.old_map.getFeaturesAtPixel(event.pixel)[0];
            if (feature) {
                const coordinate = feature.getGeometry().getCoordinates();
                popup.setPosition(coordinate);
                document.getElementById("customTtip").style.visibility = 'visible';
                document.getElementById("customTtip").innerText = ""+feature.building.name+"\n"+feature.building.city
            } else {
                document.getElementById("customTtip").style.visibility = 'hidden';
            }
        });
    }
    componentDidMount(){
        this.drawMap()
        this.drawScatter()
        this.drawZertifizierungen()
        this.drawGauge()
    }

    generateImageTiles() {
        let i = this.state.imageCarousel;
        let max = this.props.data.buildings.length;
        return(
            <div className={"w3-row-padding"} style={{paddingTop:"8px"}}>
                <div className={"w3-third w3-display-container"} onClick={()=>this.props.buildingSelector(this.props.data.buildings[(((i+0)%max)+max)%max])}>
                    <img className={"w3-image"} style={{objectFit: "cover", width: "100%", height: "180px"}} src={"images/buildings/"+this.props.data.buildings[(((i+0)%max)+max)%max].image}></img>
                      <div className="w3-display-bottommiddle w3-container w3-white w3-left-align" style={{opacity:"0.8",width:"100%", marginBottom:"16px"}}>
                          <p>{this.props.data.buildings[(((i+0)%max)+max)%max].name}<br/>{this.props.data.buildings[(((i+0)%max)+max)%max].city}</p>
                      </div>
                </div>
                <div className={"w3-third w3-display-container"} onClick={()=>this.props.buildingSelector(this.props.data.buildings[(((i+1)%max)+max)%max])}>
                    <img className={"w3-image"} style={{objectFit: "cover", width: "100%", height: "180px"}} src={"images/buildings/"+this.props.data.buildings[(((i+1)%max)+max)%max].image}></img>
                      <div className="w3-display-bottommiddle w3-container w3-white w3-left-align" style={{opacity:"0.8",width:"100%", marginBottom:"16px"}}>
                          <p>{this.props.data.buildings[(((i+1)%max)+max)%max].name}<br/>{this.props.data.buildings[(((i+1)%max)+max)%max].city}</p>
                      </div>
                </div>
                <div className={"w3-third w3-display-container"} onClick={()=>this.props.buildingSelector(this.props.data.buildings[(((i+2)%max)+max)%max])}>
                    <img className={"w3-image"} style={{objectFit: "cover", width: "100%", height: "180px"}} src={"images/buildings/"+this.props.data.buildings[(((i+2)%max)+max)%max].image}></img>
                      <div className="w3-display-bottommiddle w3-container w3-white w3-left-align" style={{opacity:"0.8",width:"100%", marginBottom:"16px"}}>
                          <p>{this.props.data.buildings[(((i+2)%max)+max)%max].name}<br/>{this.props.data.buildings[(((i+2)%max)+max)%max].city}</p>
                      </div>
                </div>
            </div>
        )

    }

    render() {
        return(<div>
            <div className="w3-row-padding w3-padding">
                <div className="w3-white w3-card w3-panel" style={{"marginTop":"0px"}}>
                    <div className="w3-dropdown-hover w3-white "  style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Jahr</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} defaultChecked={true} type="radio"/> 2020</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2019</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2018</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2017</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white"  style={{"padding":"8px 8px"}} >
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Land</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> Nord</a>
                            <a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> Mitte</a>
                            <a className="w3-bar-item w3-button"><input type="checkbox"/> Süd</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white"  style={{"padding":"8px 8px"}} >
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Nutzungsart
                        </button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            {Array.from(new Set(this.props.data.buildings.map(x=>{return x.type}))).map(x=>{return(<a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> {x}</a>)})}

                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white"  style={{"padding":"8px 8px"}} >
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Objekte</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            {this.props.data.buildings.map(x=>{return(<a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> {x.id}</a>)})}
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white "  style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Portfolio</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-1</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-2</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-3</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-4</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="w3-row-padding">
                <div className={"w3-twothird"}>
                <div className="w3-white w3-card w3-center w3-margin-bottom " style={{"height":"500px"}}>
                    <div className="w3-right w3-panel w3-border-bottom"  style={{"width":"100%"}}>
                        <div className="w3-left"><h4><i className="fas fa-map"></i> Interaktive Karte</h4></div>
                    </div>
                    <div></div>
                    <div id="mapdiv" style={{"height":"400px"}} className={"w3-container"}>
                        <div id="popup"></div>
                    </div>

                </div>
                    <div className="w3-white w3-card w3-center w3-margin-bottom w3-row" style={{"height":"200px"}}>
                        <div className={"w3-button w3-col"} onClick={()=>{let t = this.state; t.imageCarousel-=1; this.setState(t)}} style={{width:"5%", borderRadius:"15px 0 0 15px", fontSize: "24px", paddingTop:"78px", height:"200px"}}><i className={"fa fa-angle-left"}></i></div>
                        <div className={"w3-col"} style={{width:"90%"}}>

                                {this.generateImageTiles()}


                        </div>
                        <div className={"w3-button w3-col"} onClick={()=>{let t = this.state; t.imageCarousel+=1; this.setState(t)}} style={{width:"5%", borderRadius:"0 15px 15px 0", fontSize: "24px", paddingTop:"78px", height:"200px"}}><i className={"fa fa-angle-right"}></i></div>
                    </div>

                <div className=" w3-white w3-card w3-center w3-margin-bottom " style={{"height":"500px"}}>
                    <div className="w3-right w3-panel w3-border-bottom" style={{"width":"100%"}}>
                        <div className="w3-left"><h4><i className="fas fa-smog"></i> Carbon Efficiency</h4></div>
                    </div>

                    <div style={{"height":"400px"}} className="w3-container">
                        <canvas id="canvas"></canvas>
                    </div>
                </div>

            </div>
                <div className={"w3-third"}>

                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"paddingBottom":"8px"}}>
                        <div className="w3-right w3-panel w3-border-bottom"  style={{"width":"100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-bullseye"></i> Ziele 2021</h4></div>
                        </div>
                        <div className={"w3-container w3-center"}>
                            <div className={"w3-center"}>
                                <canvas id="gauge"></canvas>
                            </div>
                        </div>

                        <div className={"w3-row-padding w3-left-align"}>
                            <div className={"w3-half"}>
                                <div className={"w3-container w3-large w3-padding-large"}>
                                    <p><span className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-red"}>&#11044;</span> Wasser</p>
                                    <p><span className={"w3-text-green"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> CO2</p>
                                    <p><span className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-yellow"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> Strom</p>
                                </div>
                            </div>
                            <div className={"w3-half"}>
                                <div className={"w3-container w3-large w3-padding-large"}>
                                    <p><span className={"w3-text-green"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> Abfall</p>
                                    <p><span className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-yellow"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> Zertifizierung</p>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="w3-white w3-card w3-center w3-margin-bottom " style={{"height":"280px"}}>
                        <div className="w3-right w3-panel w3-border-bottom"  style={{"width":"100%"}}>
                            <div className="w3-row">
                                <div className={"w3-half"}><h4 className={" w3-left"}><i className="fas fa-certificate"></i> Zertifizierungen</h4></div>
                                <div className={"w3-half"}>
                                    <select className="w3-select" name="option">
                                        <option value="1">Energieausweis</option>
                                        <option value="2">GRESB</option>
                                        <option value="3">DGNB</option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div style={{"height":"200px"}} className={"w3-container"}>
                            <canvas id="zertifizierungen"></canvas>
                        </div>


                    </div>

                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"paddingBottom":"8px"}}>
                        <div className="w3-right w3-panel w3-border-bottom"  style={{"width":"100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-temperature-high"></i> CRREM aktueller Status</h4></div>
                        </div>
                        <div className={"w3-container"}>
                            <div className={"w3-left-align"}>
                                <h5>aktueller Status: 1,5° Ziel</h5>
                                <h5>60% im Pfad</h5>
                                <h5>40% gestrandet</h5>
                            </div>
                        </div>




                    </div>
                    <div className="w3-white w3-card w3-center w3-margin-bottom " >
                        <div className="w3-right w3-panel w3-border-bottom"  style={{"width":"100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-thumbs-up"></i> Upgrades</h4></div>
                        </div>
                        <div className={"w3-container"}>
                            <div className={"w3-card w3-margin-bottom w3-row"}>
                                <div className={"w3-col"} style={{"width":"20%","height":"60px", backgroundColor:"green"}}>
                                    <div className={"w3-center w3-xlarge"} style={{color:"white",paddingTop:"8px"}}><i className="fas fa-leaf"></i></div>
                                </div>
                                <div className={"w3-col w3-left-align w3-container"} style={{"width":"80%","height":"60px", paddingTop: "8px"}}>
                                    <p>
                                        <span className={"w3-large"}>Fassadenbegrünung Office Park Klagenfurt</span><br/>
                                        <span className={"w3-text-grey w3-small"}>August 2021</span>
                                    </p>
                                </div>
                            </div>
                            <div className={"w3-card-4 w3-margin-bottom w3-row"}>
                                <div className={"w3-col"} style={{"width":"20%","height":"60px", backgroundColor:"yellow"}}>
                                    <div className={"w3-center w3-xlarge"} style={{color:"white",paddingTop:"8px"}}><i className="fas fa-solar-panel"></i></div>
                                </div>
                                <div className={"w3-col w3-left-align w3-container"} style={{"width":"80%","height":"60px", paddingTop: "8px"}}>
                                    <p>
                                        <span className={"w3-large"}>Solar Panel Gallus Tower</span><br/>
                                        <span className={"w3-text-grey w3-small"}>Februar 2020</span>
                                    </p>
                                </div>
                            </div>
                            <div className={"w3-card-4 w3-margin-bottom w3-row"}>
                                <div className={"w3-col"} style={{"width":"20%","height":"60px", backgroundColor:"lightblue"}}>
                                    <div className={"w3-center w3-xlarge"} style={{color:"white",paddingTop:"8px"}}><i className="far fa-window-maximize"></i></div>
                                </div>
                                <div className={"w3-col w3-left-align w3-container"} style={{"width":"80%","height":"60px", paddingTop: "8px"}}>
                                    <p>
                                        <span className={"w3-large"}>Thermoverglasung eingebaut Center Graz</span><br/>
                                        <span className={"w3-text-grey w3-small"}>Jänner 2020</span>
                                    </p>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>)
    }

}

export default ESGHome;