import styled from "styled-components";

const TemplateDiv = styled.div`
  
  //border: 1px solid black;
  width: 100%;
  height: calc(100vh - 50px);
  text-align: center;
  //padding: 8px; 
  overflow-y: scroll;

  ::-webkit-scrollbar{
    //display: none;
    display: block;

    width: 10px;
    //height: 20px;
    //background-color: #aaa; /* or add it to the track */
  }
  ::-webkit-scrollbar-thumb {
    background: #000;
  }
  
`;

export default TemplateDiv;