import styled from "styled-components";

export const NotificationBellDiv = styled.div`

  float: right;
  margin: 0px 8px;
  backgroundColor: unset;
  height: 100%;
  z-index: 1001;  //Because Overdue Entries starts with 1000
`;

export const NotificationEntries = styled.div`
  
  display: grid;
  //flex-direction: column;
  
  //max-height: 550px;

  width: 460px;
  max-height: 600px;
  overflow-y: auto;
  right: 0;
  border: 2px solid ${props => props.theme.palette.primary};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  
  font-size: 1em;

  z-index: 10;
  
  > header {
    padding: 10px;
    text-align: center;
  }
  
  ::-webkit-scrollbar{
    //display: none;
    display: block;

    width: 6px;
    //height: 20px;
    //background-color: #aaa; /* or add it to the track */
  }
  ::-webkit-scrollbar-thumb {
    background: #000;
  }
  
`;

export const NotificationDiv = styled.div`
  position: relative;
  height: max-content;
  margin: 20px;
  //padding: 6px;
  //border: 2px solid grey;
  //border-radius: 10px;
  background-color: white;
  //overflow: hidden;
  box-shadow: 2px 2px 6px 2px;
  
  > header{
    padding: 6px;
    background-color: ${props => (props.status) ? props.theme.global.status[props.status]+"a4" : "lightgrey"};
  }
  
`;

export const NotificationContentDiv = styled.div`
  position: relative;
  padding: 10px; 
  overflow: auto;
  //padding-bottom: 10px;
`;


export const NotificationDismissButton = styled.div`
  position: absolute;
  top: -16px;
  right: -16px;
  cursor: pointer;
  padding: 2px;
  //background-color: transparent;
  background-color: white;
  border-radius: 50%;
  border: 0px solid white;
  
  &:hover{
    background-color: lightgrey;
    > svg{
      color: red !important;
    }
  }
  
`;
