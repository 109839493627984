import React from "react";
import moment from "moment/moment";

export const getCurrentDate = () => {
    let today = new Date();
    let mm = String(today.getMonth()+1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let dateString = yyyy+mm;
    return dateString;
}

export const convertDate = (date) => {
    let year = date.substring(0,4);
    let month = date.substring(4, 6);
    let d = new Date(year, month-1);
    let options = {
        month: "long", //to display the full name of the month
        year: "numeric"
    }
    return d.toLocaleDateString("en-US", options)
}

export const renderCategoryTypeIcon = (type, size) => {

    switch(type){
        case "RENTROLL": return (<div title={type}><i className="fas fa-2x fa-home"></i></div>)
        case "FINANCIAL": return (<div title={type}><i className="fas fa-2x fa-coins"></i></div>)
        case "ESG": return (<div title={type}><i className="fas fa-2x fa-leaf"></i></div>)
        default: return (<div title={type}><i className="fas fa-2x fa-file-image"></i></div>)

    }
}

/**
 * Compares two JSON-Objects if they are Equal
 * @param arg1
 * @param arg2
 * @returns {this is string[]|boolean}
 */
export const deepCompare = (arg1, arg2) => {
    if (Object.prototype.toString.call(arg1) === Object.prototype.toString.call(arg2)){
        if (Object.prototype.toString.call(arg1) === '[object Object]' || Object.prototype.toString.call(arg1) === '[object Array]' ){
            if (Object.keys(arg1).length !== Object.keys(arg2).length ){
                return false;
            }
            return (Object.keys(arg1).every(function(key){
                return deepCompare(arg1[key],arg2[key]);
            }));
        }
        return (arg1===arg2);
    }
    return false;
}

export const calculateDaysOverdue = (delivery_deadline, dateOfApproval) => {

    if(dateOfApproval){
        let deadline = new Date(delivery_deadline);
        let daysOverdue = moment(dateOfApproval).diff(deadline, 'days')
        return daysOverdue;
    }else{
        let deadline = new Date(delivery_deadline);
        let now = new Date();
        let daysOverdue = moment(now).diff(deadline, 'days')
        return daysOverdue;
    }

}