// @ts-ignore
import React, {createContext, useContext, useEffect, useMemo} from "react";
import http from "../http-common";
import {MethodType, ServerUrl} from "./Endpoints";
import {HTTP_STATUS_CODE} from "./HTTP_STATUSCODE";
import {toast} from "react-toastify";
import {useCookie} from "../../services/storageService/Storage";
import {useNavigate} from "react-router-dom";
import {useUserService} from "../../services/userService/UserServiceProvider";
import {ServerEventComponent} from "../../dashboard/utils/ServerEventComponent";
import PropTypes from "prop-types";
import API from "../api";


const ApiContext = createContext(undefined);

// interface ApiProviderProps{
//     children: ReactNode
// }

export function ApiProvider({ children }){
// export function ApiProvider({ children }: ApiProviderProps){

    let navigate = useNavigate();
    let userService = useUserService();

    const [jwt, setJWT] = useCookie("jwt", "");

    const HTTP_CODE = HTTP_STATUS_CODE;

    const events = ServerEventComponent.getInstance();

    /**
     * Functions for Error-Responses
     * @param res
     */
    const checkErrorResponse = (res) => {
        console.log("ErrorResponse", res)
        if(res.response && res.response.data){

            let response = res.response.data;

            switch (response.status) {

                case HTTP_CODE.UNAUTHORIZED:            toast.warn(response.message);   break;
                case HTTP_CODE.FORBIDDEN:               toast.warn(response.message);   break;
                case HTTP_CODE.UNPROCESSABLE_ENTITY:    toast.warn(response.message);   break;
                case HTTP_CODE.SERVICE_UNAVAILABLE:     toast.error(response.message);  break;
                case HTTP_CODE.INTERNAL_SERVER_ERROR:   toast.error(response.message);  break;

                case HTTP_CODE.OK:
                    toast.error("FATAL: 200 should not be in Error");
                    throw Error("FATAL, Status 200 should not be in ErrorResponse");

                default: toast.warn(response.status + ": "+response.message);
            }

            // console.log(response)
            throw Error(JSON.stringify(response));
        }else{

            if(res.response){
                console.table(res)
                let response = res.response;
                switch (response.status){
                    case HTTP_CODE.FORBIDDEN:                toast.warn(res.message); break;
                    case HTTP_CODE.UNAUTHORIZED:             toast.warn(res.message); break;
                    default: 
                }

                // if(res.response.data)

                throw Error(JSON.stringify(res))
            }else{
                //No connection to Server -> net::ERR_COONNECTION_REFUSED
                if(res.code === "ERR_NETWORK"){
                    console.log("Error: ", res.message)
                    // toast.warn("Could not connect to Server")
                    let connectionErrorObj = {
                        code: res.code,
                        message: res.message
                    }
                    throw Error(JSON.stringify(connectionErrorObj));
                }
            }
        }
    }

    /**
     * Function for Success-Responses
     * @param res
     * @returns {*}
     */
    const checkSuccessResponse = (res) => {
        let response = res;
        switch (response.status) {
            case HTTP_CODE.OK: return response.data;
            default:
                console.table(response);
                toast.warn(response.status + ": "+response.message);
        }
    }

    const config = {
        defaultConfig:  {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ,
        authConfig: (jwt) => {
            return {
                headers: {
                    'Content-Type': config.defaultConfig.headers["Content-Type"],
                    'Authorization': 'Bearer ' + jwt
                }
            }
        }
    }

    /**
     * Authentication before login
     */
    const authenticate = async(username, password, form) => {
        console.log("Authenticate")
        let authUsername = username ? username : form.target.elements.email.value;
        let authPassword = password ? password : form.target.elements.password.value;

        http.defaults.withCredentials = true;
        let result = http.post(ServerUrl.AUTHENTICATION, {username: authUsername, password: authPassword}, config.defaultConfig).then(checkSuccessResponse).catch(checkErrorResponse)
        return result.then((result) => {
            if(result.message.apikey && result.message.apikey !== "") {
                setJWT(result.message.apikey);
                login(result.message.apikey).then((result) => {
                    userService.setUser(result)
                });
                return "SUCCESS";
            }
            else if(result.message.totpRequired){
                toast.info("2FA-Code was send. Please check you Mailbox.")
                return result;
            }
            else {
                toast.warn("No ApiKey received from Server")
                throw Error("ApiKey not received from Server");
            }
        }).catch((error) => {
            throw JSON.parse(error.message);
        })

        // return result;
    }
    const login = async(token) => {

        console.log("Login...")

        if(!token){
            if(jwt) token = jwt;
            else {
                console.warn("No Token or Cookie")
                navigate("/")
                // navigate("/login")
            }
        }

        http.defaults.withCredentials = true;
        let result = http.post(ServerUrl.LOGIN, {}, config.authConfig(token) ).then(checkSuccessResponse).catch(checkErrorResponse);

        return result.then((user) => {
            console.log("Success", user)
            toast.success("Login successful", {autoClose: 1000, position: "bottom-right"})
            setJWT(token);
            // userService.setUser(user);

            // navigate("/dashboard"); //Navigate to Dashboard and should have User-Object
            return user;
        })
        .catch((error) => {
            setJWT("", 0);  //Removing JWT by Setting ValidDate to 0
            throw JSON.parse(error.message);
            // try{
            //     let err = JSON.parse(error.message)
            //     console.log(err)
            //     toast.warn(err.message)
            //     // setJWT("", 0);
            //
            //     // return error;
            //     throw Error(error);
            // }catch(e){
            //     // console.log(e)
            //     throw Error(e);
            // }
        })
    }
    const verifyTotp = async(form) => {
        let result = http.post(ServerUrl.VERIFY_TOTP, {twoAuthCode: form.target.elements.twoAuthCode.value}, config.defaultConfig).then(checkSuccessResponse).catch(checkErrorResponse)
        result.then((result) => {
            // console.table(result)
            if(result.status === 200)
                login(result.message.apikey)

        }).catch((error) => {
            throw JSON.parse(error.message);
        })
    }
    const forgotPassword = async(username, form)=>{
        if(!username) username = form.target.elements.email.value;
        return http.post(ServerUrl.RESET, {email: username}, config.defaultConfig).then(checkSuccessResponse).catch(checkErrorResponse)
        // result.then((result)=>{
        //     console.table(result)
        // }).catch(error => {
        //     throw JSON.parse(error.message)
        // })
    }
    const logout = async() => {
        let result = http.post(ServerUrl.LOGOUT, {}, config.defaultConfig).then(checkSuccessResponse).catch(checkErrorResponse)
        return result.then(result => {
            userService.setUser(null)
            setJWT("", 0);
            // navigate("/login")
            navigate("/")
            return result
        }).catch((error) => {
            console.table(error)
            userService.setUser(null)
            setJWT("", 0);
            // navigate("/login")
            navigate("/")
            throw JSON.parse(error.message);
        })
    }

    /**
     * Main Function which every API-Endpoint will call where Success-Check & Error-Check are included
     * @param url
     * @param data
     * @param methodType
     * @returns {Promise<AxiosResponse<any> | void>|Promise<AxiosResponse<any>>}
     */
    const apiCall = (url, data = {}, methodType) => {

        switch (methodType) {
            case MethodType.GET: return http.get(url, config.authConfig(jwt)).then(checkSuccessResponse).catch(checkErrorResponse);
            case MethodType.POST: return http.post(url, data, config.authConfig(jwt)).then(checkSuccessResponse).catch(checkErrorResponse);
            case MethodType.PUT: return http.put(url, data, config.authConfig(jwt)).then(checkSuccessResponse).catch(checkErrorResponse);
            case MethodType.DELETE: {
                let conf = config.authConfig(jwt);
                conf.data = data;
                return http.delete(url, conf)
            }
        }
    }

    const apiCallDownload = (url, data, methodType) => {

        let downloadConfig = {
            responseType: 'blob',
            headers: config.authConfig(jwt).headers
        }

        switch(methodType) {
            case MethodType.GET:     return http.get(url, downloadConfig).then(checkSuccessResponse).catch(checkErrorResponse);
        }
    }

    /**
     * Administration Endpoints
     * @type {{updateService: (function(*): Promise<AxiosResponse<any> | void>|Promise<AxiosResponse<any>>), getSettings: (function(*): Promise<AxiosResponse<any> | void>|Promise<AxiosResponse<any>>), getServices: (function(*): Promise<AxiosResponse<any> | void>|Promise<AxiosResponse<any>>), getSettingsUploadTypes: (function(*): Promise<AxiosResponse<any> | void>|Promise<AxiosResponse<any>>), updateSettings: (function(*): Promise<AxiosResponse<any> | void>|Promise<AxiosResponse<any>>), deleteUploads: (function(*): Promise<AxiosResponse<any> | void>|Promise<AxiosResponse<any>>)}}
     */
    const admin = {
        getServices:    (data) => apiCall(ServerUrl.ADMIN.SERVICE.GET, data, MethodType.GET),
        updateService:  (data) => apiCall(ServerUrl.ADMIN.SERVICE.UPDATE, data, MethodType.PUT),
        getSettings:    (data) => apiCall(ServerUrl.ADMIN.INSTANCE_SETTINGS.GET, data, MethodType.GET),
        updateSettings: (data) => apiCall(ServerUrl.ADMIN.INSTANCE_SETTINGS.UPDATE, data, MethodType.PUT),
        getSettingsUploadTypes: (data) => apiCall(ServerUrl.ADMIN.INSTANCE_SETTINGS.UPLOADS.GET, data, MethodType.GET),
        deleteUploads:  (data) => apiCall(ServerUrl.ADMIN.UPLOADS.DELETE, data, MethodType.DELETE)
    }

    /**
     * Profile Endpoints
     * @type {{update2FA: (function(*): Promise<AxiosResponse<*>|void>|Promise<AxiosResponse<*>>), updateDetails: (function(*): Promise<AxiosResponse<*>|void>|Promise<AxiosResponse<*>>), changePassword: (function(*): Promise<AxiosResponse<*>|void>|Promise<AxiosResponse<*>>)}}
     */
    const profile = {
        updateDetails:  (data) => apiCall(ServerUrl.PROFILE.UPDATE, data, MethodType.POST),
        changePassword: (data) => apiCall(ServerUrl.PROFILE.CHANGE_PASSWORD, data, MethodType.POST),
        update2FA: (data) => apiCall(ServerUrl.PROFILE.ENABLE_2FA, data, MethodType.POST),
        updateSettings: (data) => apiCall(ServerUrl.PROFILE.UPDATE_SETTINGS, data, MethodType.POST)
    }

    /**
     * Report & Properties Inspection-Endpoints
     * @type {{uploadedProperties: (function(*): Promise<AxiosResponse<*>|void>|Promise<AxiosResponse<*>>)}}
     */
    const inspect = {
        uploadedProperties: (data) => apiCall(ServerUrl.INSPECT.PROPERTIES, data, MethodType.POST),
        dataChangeEntriesOfProperty: (data) => apiCall(ServerUrl.PROPERTIES.DATACHANGE, data, MethodType.POST),

        //Dont know if it fits here
        /**
         *
         * @param {role: String}
         * @returns {Promise<AxiosResponse<*>|void>|Promise<AxiosResponse<*>>}
         */
        availablePeriods: (data) => apiCall(ServerUrl.PROPERTIES.PERIODS, data, MethodType.POST),

        // property: {
        //     getUploadedProperties: (data) => apiCall(ServerUrl.INSPECT.PROPERTIES, data, MethodType.POST),
        //
        // }
    }

    const propertyExport = {
        getProperties:      (data) => apiCall(ServerUrl.PROPERTIES.DATAEXPORT, data, MethodType.POST),
        exportData:     (data) => apiCall(ServerUrl.EXPORT.DATA_OF_PROPERTIES, data, MethodType.POST),
        exportFile:     (data) => apiCall(ServerUrl.EXPORT.FILE, data, MethodType.POST),
    }

    const exceptions = {
        newException:       (data) => apiCall(ServerUrl.EXCEPTIONS.NEW,     data, MethodType.POST),
        updateException:    (data) => apiCall(ServerUrl.EXCEPTIONS.UPDATE,  data, MethodType.PUT),
        approveException:   (data) => apiCall(ServerUrl.EXCEPTIONS.APPROVE, data, MethodType.POST),
        rejectException:    (data) => apiCall(ServerUrl.EXCEPTIONS.REJECT,  data, MethodType.POST)
    }

    const notify = {
        assetmanager:(data) => apiCall(ServerUrl.NOTIFICATION.ASSETMANAGER, data, MethodType.POST),
        property: {
            approve: (data) => apiCall(ServerUrl.PROPERTIES.APPROVE,        data, MethodType.POST),
            reject:  (data) => apiCall(ServerUrl.PROPERTIES.REJECT,         data, MethodType.POST)
        }
    }

    const notification = {
        dismiss: (data) => apiCall(ServerUrl.EVENTS.DISMISS_NOTIFICATION, data, MethodType.POST)
    }

    const downloads = {
        get: (fileId, streamType) => apiCallDownload(
            ServerUrl.DOWNLOAD.FILE+"/"+fileId+"?streamType="+streamType,
            {},
            MethodType.GET
        )
    }

    useMemo(() => {
        if(userService.isUserAuthenticated()){
            login().then((result) => {
                console.log("AutoLogin", result !== null, result)
                userService.setUser(result);
            }).catch((error) => {
                console.log(error)
                // let err = JSON.parse(error);
                // console.error(err)
                if(error.code && error.code === "ERR_NETWORK")
                    toast.warn("Could not connect to Server")
            })
        }
    },[])
    useEffect(()=>{
        if(jwt === undefined){
            // navigate("/login");
            navigate("/");
        }
    }, [jwt])

    const handleDownload = (fileId, streamType) => {
        const api = new API();

        api.downloads.get(api.getCookie("jwt"), fileId, streamType).then(response => {
            console.log("Response", response)
            if (!(response.data instanceof Blob)) return;
            else{
                const blob = new Blob([response.data], { type: 'application/excel' });
                let disposition = response.headers["content-disposition"]
                let filename;
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        }).catch((error) => {
            console.table(error)
            console.error(error)
        })

        // downloads.get(fileId, streamType).then(response => {
        //
        //     console.log("HandleDownload Exportfile", response)
        //
        //     if (!(response.data instanceof Blob)) return;
        //     else{
        //         const blob = new Blob([response.data], { type: 'application/excel' });
        //         let disposition = response.headers["content-disposition"]
        //         let filename;
        //         if (disposition && disposition.indexOf('attachment') !== -1) {
        //             let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        //             let matches = filenameRegex.exec(disposition);
        //             if (matches != null && matches[1]) {
        //                 filename = matches[1].replace(/['"]/g, '');
        //             }
        //         }
        //         const link = document.createElement('a');
        //         link.href = window.URL.createObjectURL(blob);
        //         link.download = filename;
        //         link.click();
        //     }
        // }).catch((error) => {
        //     console.error(error)
        // })

    }

    return(
        <ApiContext.Provider
            value={{
                totp: {
                    verifyTotp
                },
                forgotPassword,
                authenticate,
                login,
                logout,

                inspect,
                exceptions,
                notify,
                admin,
                profile,

                propertyExport,

                events,     //ServerEventsComponent
                notification,
                download: handleDownload,
                token: jwt
            }}
        >
            {children}
        </ApiContext.Provider>
    )
}

// ApiProvider.Provider.propTypes = {
//     value: PropTypes.object
// }

export function useAPI(){
    const context = useContext(ApiContext);
    if(!context) throw Error('useAPI can only be used within the ApiProvider component')
    return context;
}

