import styled from "styled-components";

export const QualityPage = styled.div`
  width: 100%;
  text-align: center;
  padding: 8px;
  min-height: calc(100vh - 50px);
  p{
    color: grey;
  }
`
export const DataTable = styled.div`
  margin-top:39px;
`

export const SelectContainer = styled.div`
  background-color:white;
  width: 153px;
  height:28.5px;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  align-items: center;
  display: inline-flex;
  cursor: default;
  overflow: hidden;
  padding: 0;
  span{
    overflow: hidden;
    text-overflow:ellipsis;
    margin-left:2px;
    width: calc(100% - 22px);
  }
  i{
    margin-left:4px;
  }
  `

