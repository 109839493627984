import React, {Component} from "react";
import API from "../../../api/api";

import styled from "styled-components";

const UploadTypesDiv = styled.div`
  
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

/**
 * @deprecated
 */
class InstanceSettings extends Component{

    constructor(props) {
        super(props);
        this.state = {
            uploadTypes: [],
            instanceSettings: {}
        }
        this.api = new API();
    }

    componentDidMount() {
        this.api.admin.getInstanceSettingsUploadTypes({}).then((uploadTypes) => {
            // console.log(uploadTypes.data)
            this.setState({uploadTypes: uploadTypes.data})
        })
    }

    setInstanceSettings = (obj) => {
        this.setState({instanceSettings: obj})
    }

    render() {

        return(
            <UploadTypesDiv>
                {
                    Object.entries(this.state.uploadTypes).map((entry, index, array) => {
                        let uploadType = entry[0];
                        let uploadTypeEntries = entry[1]

                        return(
                            <UploadTypes uploadType={uploadType} uploadTypeEntries={uploadTypeEntries} onInput={this.props.onInput} instanceSettings={this.state.instanceSettings}></UploadTypes>
                        )

                    })
                }
            </UploadTypesDiv>
        );

    }

}

const StyledMultiSelectionDiv = styled.div`
  
    input[type='checkbox']{
      width: 20px;
      height: 20px;
    }
    
    div[class='uploadType']{
      display: flex;
      gap: 10px;
      
      label{
        margin-bottom: unset;
      }
      
    }
    
    div[class*='checked']{
      display: block;
      //padding-left: 20px;
      margin-left: 20px;
      label{
        padding-left: 10px;
      }
    }
    div[class*='unchecked']{

      margin-left: 20px;
      pointer-events: none;
      opacity: 0.4;
    }
      
`;

Array.prototype.remove = function(from, to) {
    var rest = this.slice((to || from) + 1 || this.length);
    this.length = from < 0 ? this.length + from : from;
    return this.push.apply(this, rest);
};

function UploadTypes(props){

    const [uploadType, setUploadType] = React.useState(props.uploadType);
    const [uploadTypeEntries, setUploadTypeEntries] = React.useState(props.uploadTypeEntries);

    const [checkedUploadType, setCheckedUploadType] = React.useState(false);

    const [instanceSettings, setInstanceSettings] = React.useState(props.instanceSettings);

    const setUploadTypeChecked = (uploadType) => {
        // console.log(uploadType.target.checked)
        setCheckedUploadType(uploadType.target.checked)

        instanceSettings[uploadType.target.name] = []

        setInstanceSettings(
            instanceSettings
        )
        // console.log(instanceSettings)
        props.onInput(uploadType, instanceSettings)
    }

    const setUploadTypeEntryChecked = (uploadTypeEntry) => {
        // props.onInput(uploadTypeEntry)

        let [uploadType, entry] = uploadTypeEntry.target.name.split("_");
        // console.log(uploadType, entry)
        if(instanceSettings[uploadType].includes(entry)){
            instanceSettings[uploadType].remove(instanceSettings[uploadType].indexOf(entry));
        }else{
            instanceSettings[uploadType].push(entry)
        }
        // instanceSettings[uploadType][entry] = uploadTypeEntry.target.checked;

        setInstanceSettings(instanceSettings)
        props.onInput(uploadTypeEntry, instanceSettings)
    }

    const render = () => {
        return(
            <StyledMultiSelectionDiv>
                <div className={"uploadType"}>
                    <input className={"uploadTypes"} type="checkbox" name={uploadType} onChange={setUploadTypeChecked}></input>
                    <label htmlFor={uploadType} style={{fontWeight: 'bold', textTransform: 'capitalize'}}>
                        {uploadType}
                    </label>
                </div>

                {
                    uploadTypeEntries.map( (entry) => {
                        return(
                            <div className={"form-check "+ (checkedUploadType ? "checked" : "unchecked") }>
                                <input type="checkbox"
                                       className={"uploadTypes"}
                                       name={uploadType+"_"+entry}
                                       onChange={setUploadTypeEntryChecked}
                                    // checked={this.state.Avatar}
                                    // onChange={this.onChange2}
                                       />
                                <label className="form-check-label">
                                    {entry}
                                </label>
                            </div>
                        )
                    })
                }



            </StyledMultiSelectionDiv>

        )
    }

    return(
        render()
    )

}

export default InstanceSettings;