
const TopLevelUrl = {

    LOGIN:              "/login",
    LOGOUT:             "/logout",
    AUTHENTICATION:     "/authenticate",
    VERIFY_TOTP: 	    "/verifyTotp",
    VERIFICATION:       "/verification",
    RESET:              "/reset",

    MASTER_DATA:        "/masterdata",
    PROPERTIES:         "/properties",
    REPORT:             "/report",
    EXPORT:             "/export",
    NOTIFICATION:       "/notification", //TODO: Currently not supported by BE
    EVENTS:             "/events",

    DATA_CHANGE:        "/datachange",
    PROFILE:            "/profile",
    ADMIN:              "/admin",
    UTILS:              "/utils"
}
export const ServerUrl = {

    LOGIN: 		        TopLevelUrl.LOGIN,
    LOGOUT: 		    TopLevelUrl.LOGOUT,
    AUTHENTICATION:     TopLevelUrl.AUTHENTICATION,

    VERIFICATION:	    TopLevelUrl.VERIFICATION,
    VERIFY_TOTP: 	    TopLevelUrl.VERIFY_TOTP,
    RESET: 		        TopLevelUrl.RESET,


    ADMIN: {
        SERVICE: {
            GET:        TopLevelUrl.ADMIN + "/getServices",
            UPDATE:     TopLevelUrl.ADMIN + "/setService"
        },
        INSTANCE_SETTINGS: {
            GET:        TopLevelUrl.ADMIN + "/getInstanceSettings",
            UPDATE:     TopLevelUrl.ADMIN + "/updateInstanceSettings",
            UPLOAD_TYPES:    {
                GET:    TopLevelUrl.ADMIN + "/getInstanceSettingsUploadTypes",
            },

        },
        UPLOADS: {
            DELETE:     TopLevelUrl.ADMIN + "/deleteAllUploads"
        }
    },
    PROFILE: {
            UPDATE:             TopLevelUrl.PROFILE     + "/updateProfile",
            UPDATE_SETTINGS:    TopLevelUrl.PROFILE     + "/updateProfileSettings",
            CHANGE_PASSWORD:    TopLevelUrl.PROFILE     + "/changePassword",
            ENABLE_2FA:         TopLevelUrl.PROFILE     + "/enableTwoFactor"
    },
    INSPECT: {
            PROPERTIES:         TopLevelUrl.PROPERTIES  + "/getUploadedProperties",
    },
    PROPERTIES: {
            PERIODS:            TopLevelUrl.PROPERTIES  + "/getPeriods",
            DATACHANGE:         TopLevelUrl.DATA_CHANGE + "/getDataChangeEntriesOfProperty",
            DATAEXPORT:         TopLevelUrl.PROPERTIES  + "/getPropertiesForDataExport",
            APPROVE:            TopLevelUrl.PROPERTIES  + "/approveErrors",
            REJECT:             TopLevelUrl.PROPERTIES  + "/rejectErrors",

    },
    EXPORT: {
            DATA_OF_PROPERTIES: TopLevelUrl.EXPORT      + "/data",
            FILE:               TopLevelUrl.EXPORT      + "/file"
    },
    DOWNLOAD: {
            FILE:               TopLevelUrl.REPORT      + "/download" //TODO: Maybe use other Endpoint
    },
    NOTIFICATION: {
            ASSETMANAGER:       TopLevelUrl.PROPERTIES  + "/notifyAssetManager"  //TODO: Need to be moved to other TopLevelUrl.NOTIFICATION
    },
    EXCEPTIONS: {
            NEW:                TopLevelUrl.PROPERTIES  + "/newPropertyException",
            UPDATE:             TopLevelUrl.PROPERTIES  + "/updatePropertyException",
            APPROVE:            TopLevelUrl.PROPERTIES  + "/approveError",
            REJECT:             TopLevelUrl.PROPERTIES  + "/rejectError",
    },
    EVENTS: {
            DISMISS_NOTIFICATION:   TopLevelUrl.EVENTS + "/dismissNotification",
            NOTIFICATION:           TopLevelUrl.EVENTS + "/notification"
    }
}

export const MethodType = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    OPTIONS: "OPTIONS",
}

