import React, {useState} from "react";
import {
    BrowserRouter,
    Route,
    Routes,
    useLocation,
    useNavigate,
    Navigate
} from "react-router-dom";

import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

// import Dashboard from "./components/dashboard/Dashboard";
import Package from "../package.json";
import PrivateRoute from "./components/PrivateRoute";
import {ApiProvider, useAPI} from "./components/api/v2/apiV2";
import LoginPage from "./components/Login";
import {UserServiceProvider, useUserService} from "./components/services/userService/UserServiceProvider";

import 'react-toastify/dist/ReactToastify.css';
import DashboardV2 from "./components/dashboard/DashboardV2";
import {LanguageProvider} from "./components/language/LanguageProvider";

// const WrappedDashboard = () => {
//     // let context = useOutletContext();
//     let location = useLocation();
//     let navigate = useNavigate();
//
//     const api = useAPI();
//     const userService = useUserService();
//
//     return (
//         <Dashboard user={userService.getUser()} api={api} location={location} navigate={navigate}/>
//     )
// }

function App(){


    return (
            <BrowserRouter basename={Package.homepage} >
                <UserServiceProvider>
                    <ApiProvider>
                        <LanguageProvider>
                            <Routes>
                                <Route path={"/"} element={ <LoginPage />} />
                                <Route path={"/login"} element={ <LoginPage />} />
                                <Route path={"/dashboard/*"} element={<PrivateRoute/>}>
                                    <Route path={"*"} element={<DashboardV2 />} index/>
                                </Route>
                                <Route path={"*"} element={<Navigate to={"/"} />} />
                            </Routes>
                        </LanguageProvider>
                    </ApiProvider>
                </UserServiceProvider>
            </BrowserRouter>
    );
}

export default App;
