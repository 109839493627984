import React from 'react';
import ExcelJS from "exceljs";
import * as PropType from "prop-types";

/**
 * @param {'XLSX' | 'CSV'} fileType - FileType,
 * @param {eg. '202012'} period - period
 */
export function FileWriter(fileType, period){

    const createFile = (data) => {
        console.log(data)
        if(fileType === "XLSX"){
            _createFileExcel(data)
        }
        else if(fileType === "CSV"){
            _createFileCSV(data)
        }else{
            throw Error("."+fileType+" filetype is not supported")
        }
    }

    const _createFileExcel = (dataContent) => {
        // console.log("Create Excel File", dataContent);
        let workbook = new ExcelJS.Workbook();
        let sheetNames = Object.keys(dataContent).map(x => x);
        dataContent = cleanContent(dataContent);

        //Fill Workbook with content
        sheetNames.forEach((sheet) => {
            let worksheet = workbook.addWorksheet(sheet);
            let header = Object.keys(dataContent[sheet][0]);
            // console.log("Header", header)
            worksheet.columns = header.map((key) => {
                return {header: key, key: key}
            })
            dataContent[sheet].forEach((row) => {
                worksheet.addRow(row)
            })

        })
        //Create File
        workbook.xlsx.writeBuffer().then((buffer) => {
            let blob = new Blob([buffer], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = "export_"+period+".xlsx";
            link.click();
            link.remove();
        })
    }

    const _createFileCSV = (dataContent) => {
        let sheets = Object.keys(dataContent).map(x => x);
        dataContent = cleanContent(dataContent);

        sheets.forEach((sheet) => {
            let header = Object.keys(dataContent[sheet][0]);
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += header.join(";") + "\r"
            dataContent[sheet].forEach((row) => {
                csvContent += Object.values(row).join(";") + "\r"
            })
            let encodedUri = encodeURI(csvContent);
            let link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", "export_"+sheet+"_"+period+".csv");
            document.body.appendChild(link); // Required for FF
            link.click();
            link.remove();
        })
    }

    const cleanContent = (dataContent) => {
        let content = {}
        Object.entries(dataContent).map((doc) => {
            let sheetName = doc[0];
            let sheetContent = doc[1];
            let contentArray = Object.values(sheetContent).map( (docContent) => { return removeEmpty(docContent) } )
            content[sheetName] = contentArray;
        });
        return content;
    }

    /**
     * Removes entries with value === null or undefined
     * @param obj
     * @returns {*}
     */
    const removeEmpty = (obj) => {
        Object.keys(obj).forEach((k) => (!obj[k] && obj[k] !== undefined) && delete obj[k]);
        return obj;
    };

    return {createFile}
}
FileWriter.propTypes = {
    fileType: PropType.string.isRequired
}