import API from "../../../../api/api";
import React from "react";
import ESGTile from "./ESGTile";
import moment from "moment";
import Chart from "chart.js/auto";
import {getColorBySeqNr, numberWithCommas, sameObjectArrayById, sameObjectArrayByPropertyId} from "../functions";
import styled from "styled-components";

const Switch = styled.div`
  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
`;

class PieContainer extends React.Component {
    constructor() {
        super();
        this.state = {
            switch_state: false,
            noData: false
        }
    }
    drawPieChart(b_data, noData){

        console.log(b_data)
        let datasets = [{
            data: Object.keys(b_data.building).map(x=>b_data.building[x].co2),
            backgroundColor: Object.keys(b_data.building).map((x,i)=>getColorBySeqNr(i)),
            hoverOffset: 4
        }]
        let data = {
            labels: Object.keys(b_data.building).map(x=>b_data.building[x].building.label),
            datasets: datasets
        };
        let config = {
            type: 'doughnut',
            data: data,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title:{
                        display: true,
                        text: 'CO2/Building'
                    }
                }
            }
        };

        let datasets_type = [{
            data: Object.keys(b_data.type).map(x=>b_data.type[x]),
            backgroundColor: Object.keys(b_data.type).map((x,i)=>getColorBySeqNr(i)),
            hoverOffset: 4
        }]

        let data_type = {
            labels: Object.keys(b_data.type),
            datasets: datasets_type
        };
        let config_type = {
            type: 'doughnut',
            data: data_type,
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title:{
                        display: true,
                        text: 'CO2/Type'
                    }
                }
            }
        };
        if(this.props.isMultipleBuildings()){

            if(this.portfolioChart != undefined)
                this.portfolioChart.destroy()

            this.portfolioChart = new Chart(
                document.getElementById('portfolioPie'),
                config
            );

            if(this.portfolioChartType!= undefined)
                this.portfolioChartType.destroy()

            this.portfolioChartType = new Chart(
                document.getElementById('portfolioPieType'),
                config_type
            );
            console.log(config)
            console.log(config_type)

        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.b_data !== this.props.b_data){
            if(Object.keys(this.props.b_data).map(x=>this.props.b_data[x].co2).reduce((p,c)=>p+c,0)== 0){
                this.setState({noData:true})
            }
            else{
                this.setState({noData:false})
                this.drawPieChart(this.props.b_data);
            }
        }
    }

    render() {



        //else if(document.getElementById("donutSlider")!= null)
         //   switch_state = document.getElementById("donutSlider").checked

        let switch_state = this.state.switch_state;

        let buildingdisplay = ((switch_state) ? 'block' : 'none')
        let typedisplay = ((!switch_state) ? 'block' : 'none')

        return <div className={"w3-display-container"} style={{maxHeight:"calc(400px - 64px)"}}>
            <div className={"w3-display-topright"}>
                <Switch>
                    <label className="switch">
                        <input checked={switch_state} id={"donutSlider"} onChange={()=>{this.setState({switch_state:!switch_state})}} type="checkbox"/>
                            <span className="slider"></span>
                    </label>
                </Switch>
            </div>
            <div style={{display: buildingdisplay, height:"calc(400px - 64px)"}}><canvas id={"portfolioPie"}></canvas></div>
            <div style={{display: typedisplay, height:"calc(400px - 64px)"}}><canvas id={"portfolioPieType"}></canvas></div>
        </div>
    }
}

class ESGDetail extends ESGTile{
    constructor(props) {
        super(props);
        this.api = new API();
        this.state = {}
        //window.setTimeout(this.getCarbonData,500)
    }
    getCarbonData(){
        let d = {
            selectedBuildings: (this.props.settings.buildings!==undefined)?this.props.settings.selectedBuildings.map(x=>x.propertyId):[],
            timestamp:this.props.settings.selectedTimestamp,
            role:this.props.settings.role
        }

        this.api.esg.getCarbonByType.get(this.api.getCookie("jwt"),d).then(response=>{
            if(this.portfolioChart !== undefined)
                this.portfolioChart.destroy()
            this.setState({data:response.data})
        })

    }
    /*insertCarbonData(data,request_settings){

        let b_data = {}
        if(Object.keys(this.props.settings).length === 0)
            return 0
        if(this.props.settings.selectedBuildings.length === 0)
            return 0
        this.props.settings.selectedBuildings.map(b=>{
            b_data[b.propertyId] = {
                building:b,
                data:data.filter(x=>x.propertyId==b.propertyId && x.co2_conversion_factor !== null && x.meter_type === "CONSUMPTION"),
                sqm: 0
            }
        })
        Object.keys(b_data).map(z=>{b_data[z].co2 = b_data[z].data.reduce((y,x)=>y+x.co2_conversion_factor*x.consumption_since_last_measure,0)})
        Object.keys(b_data).map(z=>{if(b_data[z].building !== undefined && b_data[z].building.rentable_area !== null  )b_data[z].sqm = parseFloat(b_data[z].building.rentable_area.split(" ")[0])})
        Object.keys(b_data).map(z=>{b_data[z].co2int = b_data[z].co2 / b_data[z].sqm})
        Object.keys(b_data).map(z=>{b_data[z].energy = b_data[z].data.reduce((y,x)=>y+x.consumption_since_last_measure,0)})
        Object.keys(b_data).map(z=>{b_data[z].energyint = b_data[z].energy / b_data[z].sqm})
        //b_data.sort()
        if(this.state.data !==b_data){
            this.setState({data:b_data})
        }
    }

     */
    componentWillUnmount() {
        this.setState({data:undefined})
    }

    placeholderImage(type){
        let icon = "fas fa-building"

        if(type == "Building")
            icon = "fas fa-building"
        return <div className={"w3-display-container w3-right"} style={{backgroundColor:"#ccc", minHeight:"200px", minWidth:"300px"}}>
            <div className={"w3-display-middle"}>
                <i className = {icon} style ={{color:"#969696", fontSize:"86px"}}></i>
            </div>
        </div>

    }
    content(){
        if(this.state == undefined || this.state.data == undefined){
            return <div></div>
        }
        let b_data = this.state.data;
        if(!this.hasBuildingData())
            return <div></div>
        if(this.isMultipleBuildings()){
            return <div>

                <div id="ESGPortfolioTopinfo" className="tile showPortfolio w3-row-padding" style={{height:"calc(400px - 16px)"}}>

                        <div className="w3-col l6 m12 s12">
                            <h3>Portfolio 1</h3>
                            <hr style={{borderRadius:"4px", borderWidth:"8px", maxWidth:"150px", borderColor: this.props.roleColor}}className="w3-round" />
                            <div style={{marginTop:"auto"}}>
                                <div className = {"w3-row-padding"}>
                                    <div className = {"w3-col"} style = {{width:"150px", marginTop:"2px"}}>Selected Buildings:</div>
                                    <div className = {"w3-rest w3-large"} >{this.props.settings.selectedBuildings.length}</div>
                                </div>

                                <div className = {"w3-row-padding"}>
                                    <div className = {"w3-col"} style = {{width:"150px", marginTop:"2px"}}>Total Area:</div>
                                    <div className = {"w3-rest w3-large"} >{numberWithCommas(this.props.settings.selectedBuildings.reduce((p,c)=>p+parseInt(c.rentable_area!=null?c.rentable_area.split(" ")[0]:0),0))
                                    } m²</div>
                                </div>

                                <div className = {"w3-row-padding"}>
                                    <div className = {"w3-col"} style = {{width:"150px", marginTop:"2px"}}>Rentable Area:</div>
                                    <div className = {"w3-rest w3-large"} >{numberWithCommas(this.props.settings.selectedBuildings.reduce((p,c)=>p+parseInt(c.rentable_area!=null?c.rentable_area.split(" ")[0]:0),0))
                                    } m²</div>
                                </div>

                                <div className = {"w3-row-padding"}>
                                    <div className = {"w3-col"} style = {{width:"150px", marginTop:"2px"}}>kg CO&#x2082; / year:</div>
                                    <div className = {"w3-rest w3-large"} >{numberWithCommas(Object.keys(this.state.data).map(d=>this.state.data[d].co2).reduce((p,c)=>p+c,0).toFixed(2))}</div>
                                </div>
                            </div>

                            <div style={{marginTop:	"32px"}}>
                                <h3>Data Quality</h3>
                                <hr style={{borderRadius:"4px", borderWidth:"8px", maxWidth:"150px", borderColor: this.props.roleColor}}className="w3-round" />
                                    <p><span className="w3-text-grey">⬤</span> <span className="w3-text-yellow">⬤</span> <span className="w3-text-grey">⬤</span> Missing Data</p>
                            </div>

                        </div>
                        <div className="w3-col l6 m12 s12 w3-center" style={{height:"100%", maxHeight:"calc(400px - 16px)", paddingBottom:"16px"}}>
                            <PieContainer b_data = {b_data} isMultipleBuildings = {this.isMultipleBuildings}></PieContainer>
                        </div>
                </div>

            </div>
        }
        else{
            if(this.props.settings.selectedBuildings[0] === undefined)
                return <div></div>

            return <div id="ESGBuildingTopinfo" className={"w3-margin tile showBuilding w3-container"} style={{height:"350px", position:"relative"}}>
                <div className="w3-container">
                    {this.placeholderImage("Building")}
                        <div className="">
                            <h3 id="esgTopName">{this.props.settings.selectedBuildings[0].label}</h3>
                            <hr className="w3-round" style={{borderRadius:"4px", borderWidth:"8px", maxWidth:"150px", borderColor: this.props.roleColor}} />
                                <p><span id="esgTopAddress" className="w3-large">{this.props.settings.selectedBuildings[0].address_street} {this.props.settings.selectedBuildings[0].address_housenumber}<br/>{this.props.settings.selectedBuildings[0].address_zip} {this.props.settings.selectedBuildings[0].address_city} <br/>{this.props.settings.selectedBuildings[0].address_country}</span>
                                </p>
                        </div>
                </div>
                <div style={{marginTop:"auto", bottom:16, position:"absolute"}}>
                    <div className = {"w3-row-padding"}>
                        <div className = {"w3-col"} style = {{width:"120px", marginTop:"5px"}}>Total Area:</div>
                        <div className = {"w3-rest w3-large"} ><span><span id="esgTopArea">{numberWithCommas(this.props.settings.selectedBuildings[0].rentable_area.split(" ")[0])}</span> m²</span></div>
                    </div>

                    <div className = {"w3-row-padding"}>
                        <div className = {"w3-col"} style = {{width:"120px", marginTop:"5px"}}>Rentable Area:</div>
                        <div className = {"w3-rest w3-large"} ><span><span id="esgTopLetArea">{numberWithCommas(this.props.settings.selectedBuildings[0].rentable_area.split(" ")[0])}</span> m²</span></div>
                    </div>

                    <div className = {"w3-row-padding"}>
                        <div className = {"w3-col"} style = {{width:"120px", marginTop:"5px"}}>Type:</div>
                        <div className = {"w3-rest w3-large"} ><span id="esgTopType">{this.props.settings.selectedBuildings[0].use_type_primary}</span></div>
                    </div>

                    <div className = {"w3-row-padding"}>
                        <div className = {"w3-col"} style = {{width:"120px", marginTop:"5px"}}>Total Area:</div>
                        <div className = {"w3-rest w3-large"} ><span id="esgConstructionYear">{this.props.settings.selectedBuildings[0].constructiondate === null? "n/a": moment(this.props.settings.selectedBuildings[0].constructiondate).format("YYYY")}</span></div>
                    </div>
                </div>


            </div>
        }
        // return <div id="ESGDetailContainer" style={{"height":"400px"}} className={"w3-container"}>
        // </div>
    }
    isMultipleBuildings=()=>{
        if(!this.hasBuildingData())
            return false
        return this.props.settings.selectedBuildings.length > 1
    }
    hasBuildingData(){
        return this.props.settings.selectedBuildings !== undefined
    }

    componentDidMount() {
        this.getCarbonData()
    }
    componentWillUpdate(nextProps, nextState, nextContext) {
        if(nextProps.settings.selectedBuildings == undefined)
            return 0
        if (this.state.settings == undefined || !sameObjectArrayByPropertyId(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings) || this.props.settings.selectedTimestamp !== this.state.settings.selectedTimestamp){
            this.setState({settings:  JSON.parse(JSON.stringify(nextProps.settings))})
            this.getCarbonData()


        }

    }
}

export default ESGDetail;