import React, {Component} from "react";
import Chart from "chart.js/auto";
import * as Gauge from 'chartjs-gauge';
class CO2Dashboard extends Component {

    constructor(props) {
        super(props);



    }
    getFilteredBuildings=()=>{
        return this.props.data.buildings.filter(b=>Object.keys(b.resources).includes("Electricity"))
    }
    getEnergyByYearMonth=(b,year,month)=>{
        return b.map(x=>{return {
            [x.id]: x.resources.Electricity.values.filter(y=>{return y.date.getUTCFullYear()==year && y.date.getUTCMonth()==month}).map(z=>z.value).reduce((a,b)=>{return a+b})
        }})
    }


    onlyUnique=(value, index, self) =>{
        return self.indexOf(value) === index;
    }

    getRandomColor=() =>{
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    inputConvertAbsolute=(input)=>{
        let datasets = [];
        let labels = [];
        input.data.values.forEach(x=>{labels.push(x.timestamp)})
        labels = labels.filter(this.onlyUnique);
        labels = labels.sort();
        input.data.topics.forEach(x=>{
            let ds = {};
            ds.label = x;
            ds.backgroundColor = this.getRandomColor();
            ds.data = []
            labels.forEach(y=>{
                input.data.values.forEach(z=>{
                    if(z.timestamp == y && z.topic == x)
                        ds.data.push(z.value);
                })
            })
            datasets.push(ds)

        })
        return {"labels":labels, "datasets":datasets};
    }
    drawGauge=()=>{

        const data = {
            labels: ['AAA','AA','A','B'],
            datasets: [{
                label: 'Housing',
                data: [3,2,3,1],
                borderColor: "rgba(89,255,0,0.8)",
                backgroundColor: "rgba(89,255,0,0.8)",
            },{
                label: 'Office',
                data: [1,1,1,0],
                borderColor: "rgba(25,0,255,0.8)",
                backgroundColor: "rgba(25,0,255,0.8)",
            },{
                label: 'Retail',
                data: [1,0,1,0],
                borderColor: "rgba(255,200,0,0.8)",
                backgroundColor: "rgba(255,200,0,0.8)",
            }]


        };
        let ctx = document.getElementById('gauge').getContext('2d');
        window.myGauge = new Gauge(ctx,{
                type: 'gauge',
                data: {
                    datasets: [{
                        data: [20,50,80,100],
                        value: 75,
                        backgroundColor: ['red', 'orange', 'yellow', 'green'],
                        borderWidth: 2
                    }]
                },
                options: {
                    responsive: false,
                    maintainAspectRatio: true,

                    title: {
                        display: false,
                        text: 'Gauge chart'
                    },
                    layout: {
                        padding: {
                            bottom: 30
                        }
                    },
                    needle: {
                        // Needle circle radius as the percentage of the chart area width
                        radiusPercentage: 2,
                        // Needle width as the percentage of the chart area width
                        widthPercentage: 3.2,
                        // Needle length as the percentage of the interval between inner radius (0%) and outer radius (100%) of the arc
                        lengthPercentage: 80,
                        // The color of the needle
                        color: 'rgba(0, 0, 0, 1)'
                    },
                    valueLabel: {
                        formatter: Math.round
                    }
                }
            }
        );
        window.myGauge.canvas.style.display ="inline-block"
    };
    drawBar=()=>{
        let barChartData ={
            labels: ["Portfolio-1","Portfolio-2", "Portfolio-3"],
            datasets: [
                {
                    label:'residential',
                    data : [1.7,2.3,2.2],
                    backgroundColor: 'rgba(25,0,255,0.8)'
                },
                {
                    label:'office',
                    data : [1.2,2.8,1.5],
                    backgroundColor: 'rgba(89,255,0,0.8)'
                },
                {
                    label:'shopping',
                    data : [0,1, 2.2],
                    backgroundColor: 'rgba(255,200,0,0.8)'
                },
            ]
        }
        let ctx = document.getElementById('canvas').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                        title: {
                            display: true,
                            text: "Carbon emissions ktCO2e/year"
                        },
                    }
                }
            }
        });
    }

    drawBarVacancy=()=>{
        let barChartData ={
            labels: ["Portfolio-1","Portfolio-2", "Portfolio-3"],
            datasets: [

                {
                    label:'Korrelation',
                    data : [0.5, 0.8, 0.9],
                    backgroundColor: 'darkSlateGrey'
                },
            ]
        }
        let ctx = document.getElementById('vacancyBar').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                }
            }
        });
    }
    drawBarTemperature=()=>{
        let barChartData ={
            labels: ["Portfolio-1","Portfolio-2", "Portfolio-3"],
            datasets: [

                {
                    label:'Korrelation',
                    data : [0.7, 0.7, 0.9],
                    backgroundColor: 'darkSlateGrey'
                },
            ]
        }
        let ctx = document.getElementById('temperatureBar').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'bar',
            data: barChartData,
            options: {
                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true
                    }
                }
            }
        });
    }

    drawLineVacancy=()=>{
        let chartData ={
            labels: ["2019-01","2019-02","2019-03","2019-04","2019-05","2019-06","2019-07","2019-08","2019-09","2019-10","2019-11","2019-12","2020-01","2020-02","2020-03","2020-04","2020-05","2020-06","2020-07","2020-08","2020-09","2020-10","2020-11","2020-12","2021-01","2021-02","2021-03","2021-04","2021-05","2021-06","2021-07","2021-08","2021-09"],
            datasets: [

                {
                    label:'CO2',
                    data : [17.2,17.8,17.5,17.2,17.8,17.5,16.2,16.8,16.5,16.2,16.8,15.5,15.2,15.8,15.5,15.2,15.8,15.5,14.2,14.8,14.5,14.2,14.8,14.5,14.2,13.8,13.5,13.2,13.8,13.5,13.2,13.8,12.5,],
                    backgroundColor: 'darkSlateGrey',
                    yAxisID: 'y',

                },
                {
                    label:'Vacancy',
                    data : [9,9,9.5,8,7.4,8,7.4,7,7.5,7.4,7.8,8.0,8.2,8.4,7.4,7.7,7,7,7,7.6,7,6.5,7,7.2,7.5,7.5,7.2,7,6.9,6.8,6.6,7.4,7.2],
                    backgroundColor: 'rgba(255,200,0,0.8)',
                    yAxisID: 'y1'
                },
            ]
        }
        let ctx = document.getElementById('vacancyLine').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'line',
            data: chartData,
            options: {
                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Carbon emissions kgCO2e/year"
                        },


                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',

                        // grid line settings
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        ticks:{
                            callback: function(value) {if (value % 1 === 0) {return value + "%";}}
                        },
                        beginAtZero: true
                    },

                }
            }
        });
    }

    drawLineTemp=()=>{
        let chartData ={
            labels: ["2019-01","2019-02","2019-03","2019-04","2019-05","2019-06","2019-07","2019-08","2019-09","2019-10","2019-11","2019-12","2020-01","2020-02","2020-03","2020-04","2020-05","2020-06","2020-07","2020-08","2020-09","2020-10","2020-11","2020-12","2021-01","2021-02","2021-03","2021-04","2021-05","2021-06","2021-07","2021-08","2021-09"],
            datasets: [

                {
                    label:'CO2',
                    data : [17.2,17.8,17.5,17.2,17.8,17.5,16.2,16.8,16.5,16.2,16.8,15.5,15.2,15.8,15.5,15.2,15.8,15.5,14.2,14.8,14.5,14.2,14.8,14.5,14.2,13.8,13.5,13.2,13.8,13.5,13.2,13.8,12.5,],
                    backgroundColor: 'darkSlateGrey',
                    yAxisID: 'y',

                },
                {
                    label:'Temperatur',
                    data : [0,2,6,11,15,19,21,21,16,11,5,1,0,2,6,11,15,19,21,21,16,11,5,1,0,2,6,11,15,19,21,21,16],
                    backgroundColor: 'rgba(255,200,0,0.8)',
                    yAxisID: 'y1'
                },
            ]
        }
        let ctx = document.getElementById('temperatureLine').getContext('2d');
        window.myBar = new Chart(ctx, {
            type: 'line',
            data: chartData,
            options: {
                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Carbon emissions kgCO2e/year"
                        },


                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',

                        // grid line settings
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        ticks:{
                            callback: function(value) {if (value % 1 === 0) {return value + "°C";}}
                        }
                    },

                }
            }
        });
    }

    componentDidMount(){
        this.drawBar()
        this.drawGauge()
        this.drawScatter()
        this.drawLineVacancy()
        this.drawLineTemp()
        this.drawBarVacancy()
        this.drawBarTemperature()
    }
    drawScatter=()=>{
        const data = {
            datasets: [{
                label: 'Office',
                data: [
                    {
                        x: 200,
                        y: 119,
                        r:12
                    }, {
                        x: 170,
                        y: 80,
                        r:25
                    }, {
                        x: 190,
                        y: 115,
                        r:13
                    }, {
                        x: 235.5,
                        y: 68.5,
                        r:5
                    }],
                backgroundColor: 'rgba(25,0,255,0.8)'
            },{
                label: 'Retail',
                data: [{
                    x: 212,
                    y: 77,
                    r:15
                }, {
                    x: 190,
                    y: 80,
                    r:25
                }, {
                    x: 165,
                    y: 111,
                    r:8
                }, {
                    x: 198.5,
                    y: 95
                }],
                backgroundColor: 'rgba(255,200,0,0.8)'
            },{
                label: 'Residential',
                data: [{
                    x: 162,
                    y: 72,
                    r:15
                }, {
                    x: 150,
                    y: 88,
                    r:25
                }, {
                    x: 135,
                    y: 115,
                    r:8
                }, {
                    x: 198.5,
                    y: 95.5
                }],
                backgroundColor: 'rgba(89,255,0,0.8)'
            }

            ],
        };
        let ctx = document.getElementById('intensity').getContext('2d');
        window.myScatter = new Chart(ctx, {
            type: 'bubble',

            data: data,
            options: {
                onClick: function(e) {
                    var activePoints =window.myBar.getDatasetMeta(e);
                },
                legend:{
                    onClick : function(a,b){
                        console.log("Click on Legend: " + b.text)
                    }
                },
                title: {
                    display: true,
                    text: ''
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label = data.labels[tooltipItem.index];
                            return "Objekt "+ Math.floor(Math.random() * 10+1) + ': ' + tooltipItem.xLabel + ' m², ' + tooltipItem.yLabel + 't CO2';
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        suggestedMin: 100,
                        title: {
                            display: true,
                            text: "Energy intensity (kWh/m² )"
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value+ "";
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Carbon intensity (kgCO2e/m²)"
                        },
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function(value, index, values) {
                                return value;
                            }
                        }
                    }
                }
            }
        });
    };
    render() {


        return(<div>


            <div className="w3-row-padding w3-padding">
                <div className="w3-white w3-card w3-panel" style={{"marginTop":"0px"}}>
                    <div className="w3-dropdown-hover w3-white" style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Jahr</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} defaultChecked={true} type="radio"/> 2020</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2019</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2018</a>
                            <a className="w3-bar-item w3-button"><input name={"filterYear"} type="radio"/> 2017</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white" style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Land</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> Nord</a>
                            <a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> Mitte</a>
                            <a className="w3-bar-item w3-button"><input type="checkbox"/> Süd</a>
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white" style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Nutzungsart
                        </button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            {Array.from(new Set(this.props.data.buildings.map(x=>{return x.type}))).map(x=>{return(<a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> {x}</a>)})}

                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white" style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Objekte</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            {this.props.data.buildings.map(x=>{return(<a className="w3-bar-item w3-button"><input defaultChecked={true} type="checkbox"/> {x.id}</a>)})}
                        </div>
                    </div>

                    <div className="w3-dropdown-hover w3-white" style={{"padding":"8px 8px"}}>
                        <button className="w3-button w3-border"><i className="fa fa-caret-down"></i> Portfolio</button>
                        <div className="w3-dropdown-content w3-bar-block w3-border">
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-1</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-2</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-3</a>
                            <a className="w3-bar-item w3-button"><input name={"filterPortfolio"} defaultChecked={true} type="checkbox"/> Portfolio-4</a>
                        </div>
                    </div>
                </div>

            </div>

            <div className="w3-row-padding ">
                <div className="w3-twothird">

                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"height":"480px"}}>
                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-chart-bar"></i> Portfoliovergleich CO2 Emissionen</h4></div>
                        </div>
                        <div style={{"height":"380px"}} className="w3-padding">
                            <canvas id="canvas" style={{"height":"210px", "width":"700px"}}></canvas>
                        </div>

                    </div>

                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"height":"486px"}}>
                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-smog"></i> CO2 Intensität</h4></div>
                        </div>
                        <div style={{"maxHeight":"386px"}} className="w3-padding">
                            <canvas id="intensity" style={{"height":"210px", "width":"700px"}}></canvas>
                        </div>

                    </div>

                </div>
                <div className="w3-third" style={{"paddingRight":"0px"}}>
                        <div className={"w3-card w3-white w3-margin-bottom"} style={{"paddingBottom":"8px", "paddingRight":"8px"}}>

                            <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                                <div className="w3-left"><h4><i className="fas fa-binoculars"></i> Portfolioüberblick CO2</h4></div>
                            </div>
                            <ul className={"w3-ul"} style={{"height":"212px","overflowY":"scroll","width":"100%"}}>
                                <li className="w3-bar">
                                    <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px"}}>
                                        <span style={{"verticalAlign":"middle"}}>P1</span>
                                    </div>
                                    <div className="w3-center w3-bar-item" style={{"width":"100px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 7%</div>

                                    <div className="w3-bar-item">
                                        <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-1</span><br/>

                                    </div>
                                </li>

                                <li className="w3-bar">
                                    <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px"}}>
                                        <span style={{"verticalAlign":"middle"}}>P2</span>
                                    </div>
                                    <div className="w3-center w3-bar-item" style={{"width":"100px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 8%</div>

                                    <div className="w3-bar-item">
                                        <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-2</span><br/>

                                    </div>
                                </li>

                                <li className="w3-bar">
                                    <div className="w3-bar-item w3-circle w3-border w3-grey w3-text-white w3-text-center" style={{"width":"50px","height":"50px","fontSize":"18px"}}>
                                        <span style={{"verticalAlign":"middle"}}>P3</span>
                                    </div>
                                    <div className="w3-center w3-bar-item" style={{"width":"100px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 4%</div>

                                    <div className="w3-bar-item">
                                        <span className="w3-large" style={{"fontWeight":"normal"}}>Portfolio-3</span><br/>

                                    </div>
                                </li>


                        </ul>
                        </div>


                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"paddingBottom":"8px"}}>
                        <div className="w3-right w3-panel w3-border-bottom"  style={{"width":"100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-bullseye"></i> Ziele - CO2 2021</h4></div>
                        </div>
                        <div className={"w3-container w3-center"}>
                            <div className={"w3-center"}>
                                <canvas id="gauge"></canvas>
                            </div>
                        </div>

                        <div className={"w3-row-padding w3-left-align"}>
                            <div className={"w3-half"}>
                                <div className={"w3-container w3-large w3-padding-large"}>
                                    <p><span className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-red"}>&#11044;</span> Portfolio-1</p>
                                    <p><span className={"w3-text-green"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> Portfolio-2</p>
                                </div>
                            </div>
                            <div className={"w3-half"}>
                                <div className={"w3-container w3-large w3-padding-large"}>
                                    <p><span className={"w3-text-grey"}>&#11044;</span> <span
                                        className={"w3-text-yellow"}>&#11044;</span> <span
                                        className={"w3-text-grey"}>&#11044;</span> Portfolio-3</p>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className={"w3-card w3-white w3-margin-bottom"} style={{"paddingBottom":"8px", "paddingRight":"8px"}}>

                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-exclamation-circle"></i> Anomalien</h4></div>
                        </div>
                        <ul className={"w3-ul "} style={{"height":"212px","overflowY":"scroll","width":"100%"}}>
                            <li className="w3-bar">
                                <img className={"w3-image w3-bar-item w3-circle"} style={{objectFit: "cover", width: "50px", height: "50px", "padding":"0"}} src={"images/buildings/vie01.jpg"}></img>

                                <div className="w3-center w3-bar-item" style={{"width":"100px"}}><i className="fa fa-caret-up" style={{"fontSize":"28px", "color":"red"}}></i> 30%</div>

                                <div className="w3-bar-item">
                                    <span className="w3-large" style={{"fontWeight":"bold"}}>VIE01</span><br/>
                                    <span className="w3-large" style={{"fontWeight":"normal"}}>Wienerberg</span>
                                </div>
                            </li>
                            <li className="w3-bar">
                                <img className={"w3-image w3-bar-item w3-circle"} style={{objectFit: "cover", width: "50px", height: "50px", "padding":"0"}} src={"images/buildings/gra01.jpg"}></img>

                                <div className="w3-center w3-bar-item" style={{"width":"100px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 20%</div>

                                <div className="w3-bar-item">
                                    <span className="w3-large" style={{"fontWeight":"bold"}}>GRA01</span><br/>
                                    <span className="w3-large" style={{"fontWeight":"normal"}}>Center Graz</span>
                                </div>
                            </li>
                            <li className="w3-bar">
                                <img className={"w3-image w3-bar-item w3-circle"} style={{objectFit: "cover", width: "50px", height: "50px", "padding":"0"}} src={"images/buildings/fra01.jpg"}></img>

                                <div className="w3-center w3-bar-item" style={{"width":"100px"}}><i className="fa fa-caret-down" style={{"fontSize":"28px", "color":"green"}}></i> 14%</div>

                                <div className="w3-bar-item">
                                    <span className="w3-large" style={{"fontWeight":"bold"}}>FRA01</span><br/>
                                    <span className="w3-large" style={{"fontWeight":"normal"}}>Gallus Tower</span>
                                </div>
                            </li>


                        </ul>
                    </div>



                </div>
            </div>

            <div className={"w3-row-padding"}>
                <div className={"w3-half"}>
                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"height":"400px"}}>
                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-chart-line"></i> Verlauf Temperatur & CO2 Emissionen</h4></div>
                        </div>
                        <div style={{"maxHeight":"300px"}} className="w3-padding">
                            <canvas id="temperatureLine" style={{"height":"210px", "width":"700px"}}></canvas>
                        </div>

                    </div>

                </div>
                <div className={"w3-half"}>
                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"height":"400px"}}>
                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-chart-bar"></i> Korrelation Temperatur & CO2 Emissionen</h4></div>
                        </div>
                        <div style={{"maxHeight":"300px"}} className="w3-padding">
                            <canvas id="temperatureBar" style={{"height":"210px", "width":"700px"}}></canvas>
                        </div>

                    </div>

                </div>
                <div className={"w3-half"}>
                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"height":"400px"}}>
                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-chart-line"></i> Verlauf Leerstand & CO2 Emissionen</h4></div>
                        </div>
                        <div style={{"maxHeight":"300px"}} className="w3-padding">
                            <canvas id="vacancyLine" style={{"height":"210px", "width":"700px"}}></canvas>
                        </div>

                    </div>

                </div>

                <div className={"w3-half"}>
                    <div className="w3-white w3-card w3-center w3-margin-bottom" style={{"height":"400px"}}>
                        <div className="w3-right w3-panel w3-border-bottom" style={{"width":" 100%"}}>
                            <div className="w3-left"><h4><i className="fas fa-chart-bar"> </i> Korrelation Leerstand & CO2 Emissionen</h4></div>
                        </div>
                        <div style={{"maxHeight":"300px"}} className="w3-padding">
                            <canvas id="vacancyBar" style={{"height":"210px", "width":"700px"}}></canvas>
                        </div>

                    </div>

                </div>
            </div>

        </div>)
    }
}

export default CO2Dashboard;