import * as PropTypes from "prop-types";
import React, {useState} from "react";
import {calculateDaysOverdue, convertDate, renderCategoryTypeIcon} from "../../../utils/GlobalUtils";
import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow
} from "@mui/material";
import moment from "moment/moment";
import {TABS} from "../PropertiesOverviewV2";
import {
    ExceptionActionButtons,
    ManagerContainer,
    OverdueStatusSpan,
    StatusDiv
} from "../styles/PropertiesOverview.styles";
import {useUserService} from "../../../../services/userService/UserServiceProvider";
import {useNavigate} from "react-router-dom";
import {useAPI} from "../../../../api/v2/apiV2";
import {useTheme} from "styled-components";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ExceptionContent from "./ExceptionContent";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import {toast} from "react-toastify";
import {ServerEventComponent} from "../../../utils/ServerEventComponent";

const ACTIONTYPE = {
    APPROVE: "APPROVE",
    REJECT: "REJECT"
}

function PropertyContent(props){

    const api = useAPI();
    const {activeRole} = useUserService();
    const navigate = useNavigate();
    const theme = useTheme();

    const dateFormat = "YYYY/MM/DD";

    // const [property, setProperty] = useState(props.property);
    const property = props.property;
    // console.log(property);
    const [open, setOpen] = useState(false);

    const isOnlyRejectable = (propertyExceptions) => {
        if(propertyExceptions && propertyExceptions !== []){
            return (property.exceptions.filter((exception) => exception.exception !== null && exception.exception.exception_status.includes("REJECTED")).length > 0)
        }
        return false;
    }
    //Checks if at least one Exception is REJECTED -> true : false
    const [onlyRejectable, setOnlyRejectable] = useState(
        (property.exceptions) ? isOnlyRejectable(property.exceptions) : false
        // (
        //     property.exceptions !== [] &&
        //     property.exceptions.filter((exception) => exception.exception !== null && exception.exception.exception_status.includes("REJECTED")).length > 0
        // ) ? true : false
    );

    const [update, setUpdate] = useState(false);



    const getDataChangeEntriesOfProperty = (property) => {
        let object = {
            "uploadedPropertyId": property.id,
            "reportUploadCommId": property.history_of_property[property.history_of_property.length-1].reportUploadCommunication.id,
            "propertyId": property.property.id
        }
        api.inspect.dataChangeEntriesOfProperty(object).then((response) => {
            // console.log("Data-Change Response", response)
        }).catch((error) => {
            console.error(error)
            console.table(error)
        })

    }

    const approveOrRejectPropertyExceptions = (property, actionType) => {
        // console.log("Action "+actionType, property);

        let propertyWithException = {
            uploaded_property_id: property.id,
            exceptions: property.exceptions
        }

        // console.log(propertyWithException)

        if(actionType === ACTIONTYPE.APPROVE) {
            api.notify.property.approve(propertyWithException).then((response) => {
                api.events.publish(ServerEventComponent.Topics.updates.PROPERTY, response.message);
                toast.success("Approved Property "+property.property.property_id_receiver)
            })
        }else if(actionType === ACTIONTYPE.REJECT){
            api.notify.property.reject(propertyWithException).then((response) => {
                api.events.publish(ServerEventComponent.Topics.updates.PROPERTY, response.message);
                toast.info("Rejected Property "+property.property.property_id_receiver)
            })
        }
    }

    /**
     * Updates the Error after Approve or Reject from ExceptionContent.js
     * @param error
     */
    const updateError = (error) => {
        for(let errorToUpdate of property.exceptions){
            if(errorToUpdate.id === error.id){
                errorToUpdate.exception = error.exception;
                break;
            }
        }
        let isOneExceptionREJECTED = property.exceptions.filter((error) => error.exception.exception_status === "REJECTED").length > 0;
        if(isOneExceptionREJECTED) setOnlyRejectable(true); else setOnlyRejectable(false);
        setUpdate(!update);
    }

    const render_UploadedBy = (history_entry) => {
        if(history_entry === null) return "";
        else{
            let uploadedBy = (history_entry.reportUploadCommunication !== null) ? history_entry.reportUploadCommunication.user : null;
            if(uploadedBy !== null){
                return(
                    <div style={{display: 'flex', flexDirection:'column'}}>
                        {/*<span>{new Date(last_history_entry.reportUploadCommunication.timestamp).toLocaleString()}</span>*/}
                        <span>{moment(new Date(history_entry.reportUploadCommunication.timestamp)).format(dateFormat+' HH:mm:ss')}</span>
                        <span>by {uploadedBy.email}</span>
                    </div>
                )
            }else{
                return "";
            }
        }
    }
    const render_ChangedBy = (history_entry) => {
        if(history_entry === null) return "";
        else{
            let changedByUser = (history_entry.changedByUser !== null) ? history_entry.changedByUser : null;
            if(changedByUser !== null){
                return(
                    <div style={{display: 'flex', flexDirection:'column'}}>
                        {/*<span>{new Date(history_entry.timestamp).toLocaleString()}</span>*/}
                        <span>{moment(new Date(history_entry.timestamp)).format(dateFormat+' HH:mm:ss')}</span>
                        <span>by {changedByUser.email}</span>
                    </div>
                )
            }else{
                return(
                    <div style={{display: 'flex', flexDirection:'column'}}>
                        {/*<span>{new Date(history_entry.timestamp).toLocaleString()}</span>*/}
                        <span>{moment(new Date(history_entry.timestamp)).format(dateFormat+' HH:mm:ss')}</span>
                        <span>by System</span>
                    </div>
                )
            }
        }
    }

    const render_Status = (status, last_history_entry, overdue) => {

        const renderApproved = (status) => {
            if(last_history_entry) {
                return (
                    <StatusDiv backgroundColor={"#4caf50cf"}>
                        {
                            render_ChangedBy(last_history_entry)
                        }
                    </StatusDiv>
                )
            }else{
                return <>Missing history Entry</>
            }
        }
        const renderRejected = (status) => {
            return(
                <StatusDiv backgroundColor={"#ff000082"}>
                    {
                        // <span>{"Rejected by "  + (status.includes("SYSTEM") ? "system" : "user")}</span>
                        render_ChangedBy(last_history_entry)
                    }
                </StatusDiv>
            )
        }
        const renderAwait = (status) => {
            return(
                <StatusDiv backgroundColor={"#1f77b4b3"}>
                    <span>In Review</span>
                </StatusDiv>
            )
        }

        const renderNotUploaded = (status) => {
            return(
                <StatusDiv backgroundColor={"rgba( 93, 92, 86, 0.6)"}>
                    <span>Not Uploaded</span>
                </StatusDiv>
            )
        }

        switch(true){
            case status.includes("APPROVED"):   return renderApproved(status);
            case status.includes("AWAIT"):      return renderAwait(status);
            case status.includes("REJECTED"):   return renderRejected(status);
            case status.includes("NOT_UPLOADED"): return renderNotUploaded(status);
        }



    }

    const render_ActionButton = (property) => {
        const showReport = (property) => {
            navigate("../inspect", {state: {property: property}})
        }
        return(
            <button onClick={ ()=>{showReport(property)} }>ACTION</button>
        )
    }

    const render_APPROVED = () => {
        let history = property.history_of_property;
        let last_history_entry = history[history.length - 1];
        let daysLeft = calculateDaysOverdue(property.delivery_deadline, last_history_entry.timestamp);

        return(
            <TableRow key={property.id} className={"tableRow"}>
                <TableCell type={"string"} value = {property.type} style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                <TableCell type={"string"} value = {property.property.property_id_receiver}> {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                <TableCell type={"string"} value = {property.period}> {convertDate(property.period)}</TableCell>
                <TableCell type={"date_by"}> {render_UploadedBy(last_history_entry)} </TableCell>
                <TableCell type={"date_by"}> {render_ChangedBy(last_history_entry)}</TableCell>
                <TableCell> {moment(property.delivery_deadline).format(dateFormat)}</TableCell>
                <TableCell value={property.statusOverdue === "NOT_OVERDUE" ? -1 : daysLeft}>
                    { (property.statusOverdue === "NOT_OVERDUE")
                        ?
                        <OverdueStatusSpan status={property.statusOverdue}>On Time</OverdueStatusSpan>
                        :
                        <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>

                    }
                </TableCell>

                {
                    (activeRole.role === "AM")
                    ?
                        <TableCell><button style={{display: "none"}} disabled={true}>Undo</button></TableCell>
                    :
                        <TableCell/>
                }
                {/*{*/}
                {/*    (activeRole.role === "PM")*/}
                {/*        ?*/}
                {/*        <TableCell>{render_ActionButton(property)}</TableCell>*/}
                {/*        :*/}
                {/*        <TableCell/>*/}
                {/*}*/}

            </TableRow>
        )

    }
    const render_REJECTED = () => {

        // console.log("Property", property, props)
        // console.log("Render_REJECTED", props.number, props.property.status, props.property.statusOverdue)

        let assigned_users = property.property.assigned_users.filter((user) => user.role === "PM");
        let daysLeft = calculateDaysOverdue(property.delivery_deadline);

        let history = property.history_of_property;
        let last_history_entry = history[history.length -1];

        return(
            <TableRow key={property.id} name={property.id} className="tableRow" sx={{zIndex: 1000-props.number}}>
                <TableCell type={"string"} value = {property.type}  style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                <TableCell type={"string"} value = {property.property.property_id_receiver} > {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                <TableCell type={"string"} value = {property.period}> {convertDate(property.period)}</TableCell>
                <TableCell type={"date_by"}> {render_UploadedBy(last_history_entry)} </TableCell>
                <TableCell type={assigned_users.length}>
                    <ManagerButton assigned_users = {assigned_users}></ManagerButton>
                </TableCell>
                <TableCell type={"date_by"}>{render_ChangedBy(last_history_entry)}</TableCell>
                <TableCell value = {property.delivery_deadline}> {moment(property.delivery_deadline).format(dateFormat)}</TableCell>
                <TableCell value = {property.statusOverdue === "NOT_OVERDUE" ? -1 : daysLeft}>
                    { (property.statusOverdue === "NOT_OVERDUE") ?
                        <OverdueStatusSpan status={property.statusOverdue}>On Time</OverdueStatusSpan>
                        :
                        <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>
                    }
                </TableCell>
                {
                    (property.enabled_features.includes("CHANGE_REPORTING"))
                    ?
                        <TableCell>
                            <div className={"w3-right"}>
                                <button style={{
                                    width:"initial", backgroundColor: theme.palette.primary, color: "white",
                                    borderRadius:0, border: "none", display: "inline-block",
                                    padding: "8px 16px", verticalAlign: "middle", overflow: "hidden",
                                    textDecoration: "none", textAlign: "center", cursor: "pointer",
                                    whiteSpace: "nowrap"
                                }} onClick={() => getDataChangeEntriesOfProperty(property)} title="Change Report">
                                    <span>Download Change Report </span>
                                    <i className="far fa-file-excel w3-xlarge" />
                                    <span className="w3-small" style={{marginLeft:"-2px"}}>
                                        <i className="fa fa-arrow-down w3-small" />
                                    </span>
                                </button>
                            </div>
                        </TableCell>
                        : <></>
                }
                {
                    (activeRole.role === "AM") ? <TableCell><button style={{display: "none"}} disabled={true}>Undo</button></TableCell> : <></>
                }
            </TableRow>
        )


        // console.log(assigned_users)

    }
    const render_AWAITING = () => {

        let daysLeft = calculateDaysOverdue(property.delivery_deadline);
        let history = property.history_of_property;
        let last_history_entry = history[history.length - 1];

        return(
            <>
                <TableRow key={property.id} className="tableRow" onClick={() => setOpen(!open)}>
                    <TableCell style={{width: 20}}>
                        <IconButton aria-label="expand row" size="small">
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell type={"string"} value = {property.type} style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                    <TableCell type={"string"} value = {property.property.property_id_receiver} > {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                    <TableCell type={"string"} value = {property.period}> {convertDate(property.period)}</TableCell>
                    <TableCell type={"date_by"}> {render_UploadedBy(last_history_entry)} </TableCell>
                    <TableCell type={"date_by"}> {render_ChangedBy(last_history_entry)}</TableCell>
                    <TableCell value = {(property.exceptions) ? property.exceptions.length : 0}> {(property.exceptions) ? property.exceptions.length : 0}</TableCell>
                    <TableCell value = {property.delivery_deadline}> {moment(property.delivery_deadline).format(dateFormat)}</TableCell>
                    <TableCell value={property.statusOverdue === "NOT_OVERDUE" ? -1 : daysLeft}>
                        { (property.statusOverdue === "NOT_OVERDUE")
                            ?
                            <OverdueStatusSpan status={property.statusOverdue}>On Time</OverdueStatusSpan>
                            :
                            <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>
                        }
                    </TableCell>
                </TableRow>
                <TableRow className={""}>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={0} >
                                <ErrorsWithPagination
                                    property={property}
                                    updateError={updateError}
                                    // role={role}
                                />
                            </Box>

                            {
                                (activeRole.role === "AM")
                                ?
                                    <ExceptionActionButtons>
                                        <button className={(onlyRejectable) ? "" : "approve"}
                                                onClick={() => approveOrRejectPropertyExceptions(property, "APPROVE")}
                                                disabled={onlyRejectable}
                                        >
                                            <i className="fas fa-check-square fa-lg" aria-hidden="true"></i>
                                            <span>Approve</span>
                                        </button>
                                        <button className="reject" onClick={() => approveOrRejectPropertyExceptions(property, "REJECT")}>
                                            <i className="fas fa-window-close fa-lg" aria-hidden="true"></i>
                                            <span>Reject</span>
                                        </button>
                                    </ExceptionActionButtons>
                                :
                                    <></>
                            }

                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        );

    }
    const render_OVERDUE = () => {

        let assigned_users = property.property.assigned_users;
        let last_update = new Date(property.last_update);
        let history = property.history_of_property;
        let last_history_entry = history[history.length - 1];

        let daysLeft;
        if(property.status.includes("APPROVED"))
            daysLeft = calculateDaysOverdue(property.delivery_deadline, last_history_entry.timestamp);
        else
            daysLeft = calculateDaysOverdue(property.delivery_deadline);

        return(
            <TableRow key={property.id} className="tableRow" style={{zIndex: "unset"}}>
                <TableCell type={"string"} value = {property.type}  style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                <TableCell type={"string"} value = {property.property.property_id_receiver}> {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                <TableCell value = {property.period}> {convertDate(property.period)} </TableCell>
                <TableCell type={"date_by"}> {render_UploadedBy(last_history_entry)} </TableCell>
                <TableCell value={assigned_users.length}>
                    <ManagerButton assigned_users = {assigned_users}></ManagerButton>
                </TableCell>
                <TableCell value={property.last_update}> {last_update.toLocaleDateString("de-de")}</TableCell>
                <TableCell type={"date_by"}> {render_Status(property.status, last_history_entry, true)}</TableCell>
                <TableCell value={property.delivery_deadline}> {moment(property.delivery_deadline).format('YYYY/MM/DD')}</TableCell>
                <TableCell value={property.statusOverdue === "NOT_OVERDUE" ? -1 : daysLeft}>
                    <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>
                        { (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}
                    </OverdueStatusSpan>
                </TableCell>
                {/*<TableCell> {property.statusOverdue}</TableCell>*/}

            </TableRow>
        )

    }
    const render_NOT_UPLOADED = () => {
        let assigned_users = property.property.assigned_users;
        let last_update = new Date(property.last_update);
        let daysLeft = calculateDaysOverdue(property.delivery_deadline);

        return(
            <TableRow key={property.id} className="" style={{position:"relative"}} sx={{zIndex: 1000-props.number}}>
                <TableCell type={"string"} value={property.type} style={{width: 60}}> {renderCategoryTypeIcon(property.type)} </TableCell>
                <TableCell type={"string"} value={property.property.property_id_receiver}> {property.property.property_name+" ("+property.property.property_id_receiver+")"}</TableCell>
                <TableCell value={property.period}> {convertDate(property.period)}</TableCell>
                <TableCell value={property.delivery_deadline}> {(property.delivery_deadline !== null) ? moment(property.delivery_deadline).format('YYYY/MM/DD') : ""}</TableCell>
                <TableCell value={assigned_users.length}>
                    <ManagerButton assigned_users = {assigned_users}></ManagerButton>
                </TableCell>
                <TableCell value={property.last_update}> {moment(last_update).format(dateFormat)}</TableCell>
                {/*<TableCell> {property.status}</TableCell>*/}
                <TableCell value={property.statusOverdue === "NOT_OVERDUE" ? -1 : daysLeft}>
                    { (property.statusOverdue === "NOT_OVERDUE") ?
                        <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>On Time</OverdueStatusSpan>
                        :
                        <OverdueStatusSpan status={property.statusOverdue} daysLeft={daysLeft}>{ (daysLeft !== 0 ? daysLeft+" Days late" : "Due today")}</OverdueStatusSpan>
                    }
                </TableCell>
            </TableRow>
        );
    }

    const render = () => {
        switch(props.type){
            case TABS.APPROVED: return render_APPROVED();
            case TABS.REJECTED: return render_REJECTED();
            case TABS.AWAITING: return render_AWAITING();
            case TABS.OVERDUE:  return render_OVERDUE();
            case TABS.NOT_UPLOADED: return render_NOT_UPLOADED();
        }
    }

    return render();
}

PropertyContent.propTypes = {
    number: PropTypes.number,
    property: PropTypes.object,
};

/**
 * Styled-Component of a Button
 * @param props
 * @constructor
 */
function ManagerButton(props){

    const [clicked, setClicked] = useState(false);

    let label = props.assigned_users.length + " " + (props.assigned_users.length === 1 ? "Manager" : "Managers")

    let pm_users = props.assigned_users.filter((user, key) => user.role === "PM")
    let am_users = props.assigned_users.filter((user, key) => user.role === "AM")
    let max_table_length = pm_users.length > am_users.length ? pm_users.length : am_users.length;

    return(
        <ManagerContainer onClick={()=>{ setClicked(!clicked) }}>
            <div className = {("w3-center transition label "+(clicked ? "clicked" : ""))}>
                <span>{label}</span>
                <table className={"w3-left-align"}>
                    <thead>
                    <tr className={""}>
                        <th style={{paddingLeft:"16px"}}>PM</th>
                        {
                            (am_users.length === 0)
                                ?
                                <></>
                                :
                                <th style={{paddingLeft:"16px"}}>AM</th>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {
                        [...Array(max_table_length).keys()].map(i=>{
                            return <tr key={i}>
                                <td style={{paddingRight:"8px"}}>
                                    {
                                        (pm_users[i] !== undefined)
                                            ?
                                            <div>
                                                <div style={{margin:"4px 0 -4px 0"}}>{pm_users[i].user.first_name} {pm_users[i].user.last_name}</div>
                                                <div className={"w3-tiny"}>{pm_users[i].user.email}</div>
                                            </div>
                                            :
                                            <div></div>
                                    }
                                </td>
                                <td style={{paddingLeft:"8px"}}>
                                    {
                                        (am_users[i] !== undefined)
                                            ?
                                            <div>
                                                <div style={{margin:"4px 0 -4px 0"}}>{am_users[i].user.first_name} {am_users[i].user.last_name}</div>
                                                <div className={"w3-tiny"}>{am_users[i].user.email}</div>
                                            </div>
                                            :
                                            <div></div>
                                    }
                                </td>
                            </tr>
                        })
                    }
                    </tbody>
                </table>
            </div>
        </ManagerContainer>
    )

}

function ErrorsWithPagination(props) {

    let {property, updateError, role} = props;
    // console.log(property)

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    // const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getTableRows = (property, errors) => {
        // console.log(errors)

        let rows = [];
        errors.map((error) => {
            // console.log(error)
            rows.push({
                property: {
                    id: property.property.id,
                    period: property.period,
                    type: property.type,
                    status: property.status,
                    uploaded_property_id: property.id
                },
                error_id: error.id,
                error_type: error.error_type,
                table_name: error.table_name,
                field_name: error.field_name,
                line_number: error.line_number,
                error_description: error.error_description,
                exception: error.exception,
            });
        })
        rows.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));

        return rows;
    }

    const render = () => {
        let rows = getTableRows(property, property.exceptions);
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

        return(
            <Table size="small" aria-label="filenames">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        {/*<TableCell>Error ID</TableCell>*/}
                        <TableCell>Table</TableCell>
                        <TableCell>Field</TableCell>
                        <TableCell>Line</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Managers</TableCell>
                        <TableCell>Note</TableCell>
                        {
                            (role === "AM")
                                ?
                                <TableCell align="center">Approve/Reject</TableCell>
                                :
                                <TableCell/>
                        }

                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        (rowsPerPage > 0
                                ?
                                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                :
                                rows
                        ).map((error, key) => (
                            <ExceptionContent
                                key={key}
                                error={error}
                                updateError={updateError}
                                // role={role}
                            />
                            // <h1>EXCEPTION CONTENT</h1>
                        ))
                    }

                    {
                        emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={9} />
                            </TableRow>
                        )
                    }

                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPage={rowsPerPage}
                            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: rows.length }]}
                            count={rows.length}
                            page={page}
                            SelectProps={{
                                inputProps: { 'aria-label': 'rows per page' },
                                // native: true,
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={PaginationActions}
                            sx={{
                                '.MuiTablePagination-selectLabel': {
                                    margin: 0
                                },
                                '.MuiTablePagination-displayedRows':{
                                    margin: 0
                                }
                            }}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        )
    }

    return render();
}

function PaginationActions(props){
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
    // console.log(props)
    const handleFirstPageButtonClick = (event) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div style={{display: "flex"}}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

export default PropertyContent;