/* eslint-disable */
import React,{Component} from "react";
import API from "../../../api/api";

import Checkbox from 'react-three-state-checkbox'


import moment from 'moment';
import styled, {useTheme} from "styled-components";
import TemplateDiv from "../StyledPages";
import "../../global/css/ESG.css";
import VerbrauchsDashboard from "./ESGVerbrauch";
import CO2Dashboard from "./ESGCO2";
import HomeDashboard from "./ESGHome";
import ErzeugerDashboard from "./ESGErzeuger";
import VerbrauchsDashboardAlt from "./ESGVerbrauch_bak";

import ESGTile from "./components/ESGTile";
import ESGTrends from "./ESGTrends";
import ESGDetail from "./components/ESGDetail";
import ESGZertifizierungen from "./components/ESGZertifizierungen";
import ESGMap from "./components/ESGMap"
import ESGCarbonBubble from "./components/ESGCarbonBubble";
import ESGConsumption from "./ESGConsumption";
import ESGIntensities from "./components/ESGIntensities";
import {Route, Routes} from "react-router-dom";
import ESGIntensityComponsite from "./components/ESGIntensityComposite";
import {useUserService} from "../../../services/userService/UserServiceProvider";


const SelectContainer = styled.div`
  background-color:white;
  width: 200px;
  height:28.5px;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  text-align: start;
  appearance: auto;
  -webkit-rtl-ordering: logical;
  cursor: text;
  border-width: 1px;
  border-style: solid;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
  border-image: initial;
  align-items: center;
  display: block;
  cursor: default;
  overflow: hidden;
  padding: 0px 4px;
  div{
    overflow: hidden;
    text-overflow:ellipsis;
    margin-left:2px;
    width: calc(100% - 22px);
  }
  i{
    margin:4px 0 0 4px;
    float: right;
    
  }
  `
const SelectAllLink = styled.div`
  border: 1px solid #dddddd;
  border-radius: 32px;
  text-align: center;
  max-width: 120px;
  margin: 4px 8px 4px 19px;

  &:hover {
    border: 1px solid #aaaaaa;
    cursor: pointer;
  }
`

const resourceSettings = {
    "Electricity":{
        "color":"#536878",
        "icon": "fa fa-bolt",
        "unit": "kwh",
    },
    "Water":{
        "color":"#2a9de2",
        "icon": "fas fa-tint",
        "unit": "m³",
    },
    "Waste":{
        "color":"#414400",
        "icon": "fa fa-trash",
        "unit": "kg",
    },
    "Solar":{
        "color":"#f0d117",
        "icon": "fas fa-solar-panel",
        "unit": "kwh",
    },
    "Geothermie":{
        "color":"#bf6b59",
        "icon": "fas fa-water",
        "unit": "kwh",
    },
}

function ESG(props){

    const {activeRole} = useUserService();
    const theme = useTheme();

    return(
        <ESG_Old role={activeRole} roleColor={theme.palette.primary} />
    )

}

class ESG_Old extends Component {

    constructor(props) {
        super(props);
        this.state = {
            settings: {
                role:this.props.role.role
            },
            noData:false
        }
        this.settingsSetter = this.settingsSetter.bind(this);
        this.stateSetter = this.stateSetter.bind(this);
        this.buildingSelector = this.buildingSelector.bind(this);
        this.timeSelector = this.timeSelector.bind(this);

    }
    stateSetter = (key, value)=>{

        let ts = this.state
        ts[key]=value
        this.setState(ts)
    }

    settingsSetter(settings){
        let ts = this.state
        if(ts==undefined)
            ts = {
                settings: {role:this.props.role}
            }
        ts.settings = settings
        this.setState(ts)
    }
    buildingSelector(buildings){
        if(buildings.length==0)
            this.setState({noData:true})
        else{
            this.setState({noData:false})
        }
        buildings = buildings.filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
        let s = this.state.settings
        s.selectedBuildings = buildings
        this.setState({settings:s})

    }
    timeSelector(timestamp){
        let s = this.state.settings
        s.selectedTimestamp = timestamp
        this.setState({settings:s})

    }
    renderNoData(){
        return <div className={"w3-col s12 m12 l12"}>
            <div className={"w3-white w3-card w3-margin"}>
                <div className = "w3-container w3-center">
                    <h4>No Data</h4>
                    <p>No ESG Data was uploaded yet.</p>
                </div>
            </div>
        </div>
    }

    renderDashboards(){

        return(
            <Routes>
                <Route path={"overview"} element={ <ESGOverview role = {this.props.role.role} roleColor = {this.props.roleColor} buildingSelector = {this.buildingSelector} settings = {this.state.settings} settingsSetter = {this.settingsSetter}></ESGOverview> } />
                <Route path={"trends"} element={ <ESGTrends buildingSelector = {this.buildingSelector} settings = {this.state.settings} settingsSetter = {this.settingsSetter}></ESGTrends> } />
                <Route path={"consumption"} element={ <ESGConsumption buildingSelector = {this.buildingSelector} settings = {this.state.settings} settingsSetter = {this.settingsSetter}></ESGConsumption> } />
            </Routes>

            // <Routes>
            //     <Route path={"overview"} element={ <ESGOverview role = {this.props.role.role} roleColor = {this.props.roleColor} buildingSelector = {this.buildingSelector} settings = {this.state.settings} settingsSetter = {this.settingsSetter}></ESGOverview> } />
            // </Routes>
        )



        // let location = this.props.location.pathname;
        // if(location.includes("trends"))
        //     return <ESGTrends buildingSelector = {this.buildingSelector} settings = {this.state.settings} settingsSetter = {this.settingsSetter}></ESGTrends>
        // if(location.includes("consumption"))
        //     return <ESGConsumption buildingSelector = {this.buildingSelector} settings = {this.state.settings} settingsSetter = {this.settingsSetter}></ESGConsumption>
        //
        // return <ESGOverview role = {this.props.role.role} roleColor = {this.props.roleColor} buildingSelector = {this.buildingSelector} settings = {this.state.settings} settingsSetter = {this.settingsSetter}></ESGOverview>

    }


    render() {


        return(
            <React.Fragment >
                <div style={{backgroundColor:"#4d6986"}}>
                    <div style={{borderRadius:"16px 0 0 0", backgroundColor:"white", marginBottom:"32px"}}>
                <SettingsBar stateSetter = {this.stateSetter} buildingSelector = {this.buildingSelector} timeSelector = {this.timeSelector} globalSettings = {this.state.settings} settingsSetter = {this.settingsSetter} role={this.props.role} roleColor = {this.props.roleColor}></SettingsBar>
                {
                    !this.state.noData?
                        this.renderDashboards():
                        this.renderNoData()
                }
                    </div>
            </div>

            </React.Fragment>
        )
    }

}
class SettingsBar extends Component{
    constructor(props) {
        super(props);
        this.state = {
            api: new API(),
            settings:{
                selectedBuildings:[],
                buildings:[],
                selectedTimestamp:"",
                role:this.props.role.role,
                timestamps:[],
                from: moment().subtract(3, "years"),
                until: moment()
            }
        }
        this.state.api.esg.getPeriods(this.state.api.getCookie("jwt"),{role:"PM"}).then(response=>{
            let timestamps =  response.data.sort((a,b)=>b-a)
            let timestamp = timestamps[0]
            let ts = this.state.settings

            Object.assign(ts.timestamps, timestamps)
            ts.selectedTimestamp = timestamp
            this.state.api.esg.getMapData.get(this.state.api.getCookie("jwt"), {role:this.props.role.role, timestamp:ts.selectedTimestamp,selectedBuildings:[]}).then((response)=>{
                // console.log(response)
                console.log("data", response.data)
                if(response.data.length === 0){
                    this.props.stateSetter("noData", true)
                }
                response.data = response.data.sort((a,b)=>{
                    return moment(b.period_identifier)-moment(a.period_identifier)
                })
                // console.log(response.data)
                response.data = response.data.filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.propertyId === value.propertyId
                    ))
                )
                console.log(response.data)
                Object.assign(ts.selectedBuildings, response.data)
                Object.assign(ts.buildings, response.data)
                this.setState({settings:ts})
                this.saveSettings()



            })
        })
    }

    getHue(H) {
        // Convert hex to RGB first
        let r = 0, g = 0, b = 0;
        if (H.length == 4) {
            r = "0x" + H[1] + H[1];
            g = "0x" + H[2] + H[2];
            b = "0x" + H[3] + H[3];
        } else if (H.length == 7) {
            r = "0x" + H[1] + H[2];
            g = "0x" + H[3] + H[4];
            b = "0x" + H[5] + H[6];
        }
        // Then to HSL
        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r,g,b),
            cmax = Math.max(r,g,b),
            delta = cmax - cmin,
            h = 0,
            s = 0,
            l = 0;

        if (delta == 0)
            h = 0;
        else if (cmax == r)
            h = ((g - b) / delta) % 6;
        else if (cmax == g)
            h = (b - r) / delta + 2;
        else
            h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0)
            h += 360;

        l = (cmax + cmin) / 2;
        s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return h
    }
    setSettings=(name, setting)=>{
        let ts = this.state
        ts.settings[name] = setting
        this.setState(ts)
        this.saveSettings()
    }
    saveSettings= ()=>{
        this.props.settingsSetter(this.state.settings)
        console.log("settings setter called")
        return this.state.settings
    }
    test=()=>{
        this.state.api.esg.getProperties.get(this.state.api.getCookie("jwt"), {selectedBuildings:["62b1c95aa62a8046c50f318a"]}).then((response)=>{console.log("test", response)})
    }

    test2=()=>{
        let d = {

            consumptionTypes:["WASTE", "DISTRICT_COOLING"],
            role:"PM",
            selectedBuildings: ["62b1c95aa62a8046c50f318a"],
            from: moment().utc().startOf("year").subtract(2,"year"),
            //selectedBuildings: document.getElementsByClassName("buildingSelectCheck").filter(x=>x.checked).map(x=>x.id),
            until: moment().utc().endOf("month").format(),
            timestamp:this.state.settings.selectedTimestamp
        }
        let api = new API()
        api.esg.getTrendData.get(api.getCookie("jwt"),d).then(response=>{
            console.log(response)
        })

    }

    test____2=()=>{
        let d = {
            consumptionTypes: [
                "DISTRICT_COOLING",
                "DISTRICT_HEATING",
                "ELECTRICITY",
                "GAS",
                "WASTE",
                "WATER"
            ],
            from: moment().utc().startOf("year").subtract(2,"year"),
            //selectedBuildings: document.getElementsByClassName("buildingSelectCheck").filter(x=>x.checked).map(x=>x.id),
            selectedBuildings: document.getElementsByClassName("buildingSelectCheck").filter(x=>x.checked).map(x=>x.id),
            until: moment().utc().endOf("month").format(),
            timestamp:this.state.settings.selectedTimestamp
        }
        //console.log(api.esg.getConsumption.get(api.getCookie("jwt"),{from:this.state.settings.from,until:this.state.settings.from,selectedBuildings: (this.state.settings.selectedBuildings == undefined)?[]:this.state.settings.selectedBuildings.map(b=>b.id)}))
        this.state.api.esg.getMeasurements.get(this.api.getCookie("jwt"),d).then((response)=>{console.log("test2", response)}) }

    render(){
        return(
            <div style={{padding:"8px 0 8px 16px"}}>

                <div  className={"w3-bar"} style={{"backgroundColor": "hsl(" + this.getHue(this.props.roleColor)+ ",20%,80%)", borderRadius:"8px 0 0 8px", marginLeft:"4px"}}>
                    <div className={"w3-bar-item w3-text-white"}>
                        <i className="fas fa-filter"></i>
                    </div>
                    <div className={"w3-bar-item w3-text-white"}>
                        <TimeSelect timeSelector = {this.props.timeSelector} selectedTimestamp = {this.state.settings.selectedTimestamp} timestamps = {this.state.settings.timestamps} setSettings ={this.setSettings}></TimeSelect>
                    </div>
                    <div className={"w3-bar-item w3-text-white"}>
                        <BuildingSelect buildingSelector = {this.props.buildingSelector} selectedBuildings = {this.state.settings.selectedBuildings} buildings = {this.state.settings.buildings} setSettings ={this.setSettings}></BuildingSelect>
                    </div>
                    <div className={"w3-bar-item w3-text-white"}>
                        <RegionSelect buildingSelector = {this.props.buildingSelector} selectedBuildings = {this.state.settings.selectedBuildings} buildings = {this.state.settings.buildings} setSettings ={this.setSettings}></RegionSelect>
                    </div>
                    <div className={"w3-bar-item w3-text-white"}>
                        <UseTypeSelect buildingSelector = {this.props.buildingSelector} selectedBuildings = {this.state.settings.selectedBuildings} buildings = {this.state.settings.buildings} setSettings ={this.setSettings}></UseTypeSelect>
                    </div>
                    <div className={"w3-bar-item w3-text-white"}>
                        <DeliverySelect buildingSelector = {this.props.buildingSelector} selectedBuildings = {this.state.settings.selectedBuildings} buildings = {this.state.settings.buildings} setSettings ={this.setSettings}></DeliverySelect>
                    </div>
                    {/*<button onClick={this.test}>Testbutton1</button>*/}
                    {/*<button onClick={this.test2}>Testbutton2</button>*/}
                </div>
            </div>
        )
    }
}


class ESGOverview extends Component{
    constructor(props) {
        super(props);
        }


    render(){
        console.log(this.props.settings, "settings")
        return(
            <div>
                <div className={"w3-row-padding"}>
                    <ESGMap role = {this.props.role} colSetup = {"s12 m12 l6"} settings = {this.props.settings} buildingSelector = {this.props.buildingSelector} title ={<span><i className='fas fa-map'></i> Interactive Map</span>}></ESGMap>
                    {/*<ESGTile colSetup = {"s12 m6 l6"} settings = {this.props.settings} title = "testtitle"></ESGTile>*/}
                    <ESGDetail roleColor = {this.props.roleColor} colSetup = {"s12 m12 l6"} title ={<span><i className='fas fa-building'></i> Details</span>} settings = {this.props.settings}> </ESGDetail>

                </div>
                <div className={"w3-row-padding"} style={{marginTop:"32px"}}>
                    <ESGIntensityComponsite colSetup = {"s12 m12 l12"} settings = {this.props.settings}></ESGIntensityComponsite>
                </div>
                <div className={"w3-row-padding"} style={{marginTop:"32px"}}>
                    <ESGCarbonBubble colSetup = {"s12 m12 l6"} settings = {this.props.settings} title = {<span><i className='fas fa-chart-line'></i> CO2 Efficiency</span>}></ESGCarbonBubble>
                    <ESGZertifizierungen colSetup = {"s12 m12 l6"} title ={<span><i className='fas fa-certificate'></i> Certificates</span>} settings = {this.props.settings}></ESGZertifizierungen>
                </div>
            </div>
        )
    }
}
class TimeSelect extends Component{
    constructor(props) {
        super(props);
        this.state = {
            api: new API(),
            timestamps :this.props.timestamps ,
            selectedTimestamp:this.props.selectedTimestamp,
            title:""
        }
        this.updateTitle()



    }
    updateTitle = function (tp){
        let ts = this.state
        if(tp==undefined)
            return false;
        ts.title = tp.selectedTimestamp;
        this.setState(ts)
    }
    checkboxOnclick = function(e, b){

        this.props.timeSelector(e.target.id)
        this.updateTitle();

    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.props !== nextProps){
            this.updateTitle(nextProps)
        }
    }

    // componentWillUpdate(nextProps, nextState, nextContext) {
    //     if(this.props !== nextProps){
    //         this.updateTitle(nextProps)
    //     }
    // }
    selectAll = function (){
        this.props.buildingSelector(this.props.buildings)
    }


    render(){
        if(this.props.timestamps === undefined)
            return <div></div>

        return(
            <div className="w3-dropdown-hover">

                <SelectContainer style={{width:"120px"}}>
                    <span>{this.state.title}</span>
                    <i className={"fas fa-caret-down"}></i>
                </SelectContainer>
                <div
                     className="w3-dropdown-content w3-left-align w3-bar-block w3-border"
                     style={{"padding": "4px", zIndex:"100"}}>
                    {
                        this.props.timestamps.map(t=>{
                            let label = t
                            // this.props.globalSettings.selectedBuildings.map(b=>{
                            return(  <div><input className="timeRadio" type="radio" id={t} onChange={(element)=>{this.checkboxOnclick(element,t)}} checked={this.props.selectedTimestamp==t}></input> <label htmlFor={t}>{label}</label></div>)
                        })
                    }
                </div>
            </div>

        )
    }
}
class BuildingSelect extends Component{
    constructor(props) {
        super(props);
        this.state = {
            api: new API(),
            buildings:this.props.buildings,
            selectedBuildings:this.props.selectedBuildings,
            title:""
        }
        this.updateTitle()



    }
    updateTitle = function (tp){
        let ts = this.state
        if(tp==undefined)
            return false;
        ts.title = (tp.selectedBuildings.length==1)? "1 Building selected": tp.selectedBuildings.length+ " Buildings selected";
        this.setState(ts)
    }
    checkboxOnclick = function(e, b){
        let selectedBuildings = document.getElementsByClassName("buildingSelectCheck").flatMap(x=>
            x.checked?x.id:[false]
        )
        if(e.target.checked)
            if(selectedBuildings.indexOf(e.target.id) ==-1)selectedBuildings.remove(selectedBuildings.indexOf(e.target.id))
            else
            if(selectedBuildings.indexOf(e.target.id) !=-1)selectedBuildings.push(e.target.id)



        this.props.setSettings("selectedBuildings",selectedBuildings)
        let sb = selectedBuildings.map(x=>this.state.buildings.find(y=>y.propertyId==x)).filter(x=>x!== undefined)
        this.props.buildingSelector(sb)
        this.updateTitle();

    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.props !== nextProps){
            this.updateTitle(nextProps)
        }
    }
    selectAll = function (){
        this.props.buildingSelector(this.props.buildings)
    }


    render(){

        return(
            <div className="w3-dropdown-hover">

                <SelectContainer>
                    <span>{this.state.title}</span>
                    <i className={"fas fa-caret-down"}></i>
                </SelectContainer>
                <div
                    className="w3-dropdown-content w3-left-align w3-bar-block w3-border"
                    style={{"padding": "4px", zIndex:"100"}}>
                    <div><SelectAllLink onClick={()=>this.selectAll()}>Select All</SelectAllLink></div>

                    {
                        this.props.buildings.map(b=>{
                            let label = b.label
                            if(b.label == null)
                                label = b.address_street
                            if(b.address_housenumber !== null)
                                label +=" "+b.address_housenumber
                            // this.props.globalSettings.selectedBuildings.map(b=>{
                            return(  <div><input className=" buildingSelectCheck" type="checkbox" id={b.propertyId} onChange={(element)=>{this.checkboxOnclick(element,b)}} checked={this.props.selectedBuildings.find(x=>x.id==b.id)!=undefined}></input> <label htmlFor={b.propertyId}>{label}</label></div>)
                        })
                    }
                </div>
            </div>

        )
    }
}
class RegionSelect extends Component{
    constructor(props) {
        super(props);
        this.state = {
            api: new API(),
            buildings:this.props.buildings,
            selectedBuildings:this.props.selectedBuildings,
            title:""
        }
        this.updateTitle()



    }
    updateTitle = function (tp){
        let ts = this.state
        if(tp==undefined)
            return false;
        ts.title = (new Array(...new Set(tp.selectedBuildings.map(x=>x.address_country))).length==1)? "1 Country selected": new Array(...new Set(tp.selectedBuildings.map(x=>x.address_country))).length+ " Countries selected";
        this.setState(ts)


    }
    checkboxOnclick = function(e, b){
        let selectedBuildings = this.props.selectedBuildings
        selectedBuildings = selectedBuildings.filter(x=>x.address_country!==b)
        if(e.target.checked)
            selectedBuildings = selectedBuildings.concat(this.props.buildings.filter(x=>x.address_country===b))
        this.props.buildingSelector(selectedBuildings)
        this.updateTitle()
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.props !== nextProps){
            this.updateTitle(nextProps)
        }
    }
    getIndeterminateState(region){
        let total_length = this.props.buildings.filter(x=>x.address_country == region).length
        let selected_length = this.props.selectedBuildings.filter(x=>x.address_country == region).length
        return selected_length !== 0 && selected_length !== total_length ? "indeterminate": false
    }
    getCheckedState(region){
        let total_length = this.props.buildings.filter(x=>x.address_country == region).length
        let selected_length = this.props.selectedBuildings.filter(x=>x.address_country == region).length
        return selected_length == total_length ? "checked": false
    }
    selectAll = function (){
        this.props.buildingSelector(this.props.buildings)
    }


    render(){

        return(

            <div className="w3-dropdown-hover">

                <SelectContainer>
                    <span>{this.state.title}</span>
                    <i className={"fas fa-caret-down"}></i>
                </SelectContainer>
                <div
                    className="w3-dropdown-content w3-left-align w3-bar-block w3-border"
                    style={{"padding": "4px", zIndex:"100"}}>
                    <div><SelectAllLink onClick={()=>this.selectAll()}>Select All</SelectAllLink></div>

                    {
                        new Array(...new Set(this.props.buildings.map(x=>x.address_country))).map(b=>{
                            // this.props.globalSettings.selectedBuildings.map(b=>{
                            return(  <div><Checkbox className=" regionSelectCheck" checked = {this.getCheckedState(b)} indeterminate = {this.getIndeterminateState(b)}  type="checkbox" id={b} onChange={(element)=>{this.checkboxOnclick(element,b)}} ></Checkbox> <label>{b}</label></div>)
                        })
                    }
                </div>
            </div>

        )
    }
}

class DeliverySelect extends Component{
    constructor(props) {
        super(props);
        this.state = {
            api: new API(),
            buildings:this.props.buildings,
            selectedBuildings:this.props.selectedBuildings,
            title:""
        }
        this.updateTitle()



    }
    updateTitle = function (tp){
        let ts = this.state
        if(tp==undefined)
            return false;
        ts.title = (new Array(...new Set(tp.selectedBuildings.map(x=>x.delivery.id))).length==1)? "1 Fund selected": new Array(...new Set(tp.selectedBuildings.map(x=>x.delivery.id))).length+ " Funds selected";
        this.setState(ts)


    }
    checkboxOnclick = function(e, b){
        let selectedBuildings = this.props.selectedBuildings
        selectedBuildings = selectedBuildings.filter(x=>x.delivery.id!==b)
        if(e.target.checked)
            selectedBuildings = selectedBuildings.concat(this.props.buildings.filter(x=>x.delivery.id===b))
        this.props.buildingSelector(selectedBuildings)
        this.updateTitle()
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.props !== nextProps){
            this.updateTitle(nextProps)
        }
    }
    getIndeterminateState(region){
        let total_length = this.props.buildings.filter(x=>x.delivery.id == region).length
        let selected_length = this.props.selectedBuildings.filter(x=>x.delivery.id == region).length
        return selected_length !== 0 && selected_length !== total_length ? "indeterminate": false
    }
    getCheckedState(region){
        let total_length = this.props.buildings.filter(x=>x.delivery.id == region).length
        let selected_length = this.props.selectedBuildings.filter(x=>x.delivery.id == region).length
        return selected_length == total_length ? "checked": false
    }
    selectAll = function (){
        this.props.buildingSelector(this.props.buildings)
    }


    render(){
        return(

            <div className="w3-dropdown-hover">

                <SelectContainer>
                    <span>{this.state.title}</span>
                    <i className={"fas fa-caret-down"}></i>
                </SelectContainer>
                <div
                    className="w3-dropdown-content w3-left-align w3-bar-block w3-border"
                    style={{"padding": "4px", zIndex:"100"}}>
                    <div><SelectAllLink onClick={()=>this.selectAll()}>Select All</SelectAllLink></div>

                    {

                            this.props.buildings.map(x=>x.delivery).filter((v,i,a)=>a.findIndex(v2=>(v2.id===v.id))===i).map(b=>{
                                // this.props.globalSettings.selectedBuildings.map(b=>{
                                 //console.log({b})
                                return(  <div><Checkbox className=" deliverySelectCheck" checked = {this.getCheckedState(b.id)} indeterminate = {this.getIndeterminateState(b.id)}  type="checkbox" id={b.id} onChange={(element)=>{this.checkboxOnclick(element,b.id)}} ></Checkbox> <label>{b.fond_name}</label></div>)
                            })
                        }
                </div>
            </div>

        )
    }
}

class UseTypeSelect extends Component{
    constructor(props) {
        super(props);
        this.state = {
            api: new API(),
            buildings:this.props.buildings,
            selectedBuildings:this.props.selectedBuildings,
            title:""
        }
        this.updateTitle()



    }
    updateTitle = function (tp){
        let ts = this.state
        if(tp==undefined)
            return false;
        ts.title = (new Array(...new Set(tp.selectedBuildings.map(x=>x.use_type_primary))).length==1)? "1 Type selected": new Array(...new Set(tp.selectedBuildings.map(x=>x.use_type_primary))).length+ " Types selected";
        this.setState(ts)


    }
    checkboxOnclick = function(e, b){
        let selectedBuildings = this.props.selectedBuildings
        selectedBuildings = selectedBuildings.filter(x=>x.use_type_primary!==b)
        if(e.target.checked)
            selectedBuildings = selectedBuildings.concat(this.props.buildings.filter(x=>x.use_type_primary===b))
        this.props.buildingSelector(selectedBuildings)
        this.updateTitle();
    }

    selectAll = function (){
        this.props.buildingSelector(this.props.buildings)
    }

    componentWillUpdate(nextProps, nextState, nextContext) {
        if(this.props !== nextProps){
            this.updateTitle(nextProps)
        }
    }
    getIndeterminateState(region){
        let total_length = this.props.buildings.filter(x=>x.use_type_primary == region).length
        let selected_length = this.props.selectedBuildings.filter(x=>x.use_type_primary == region).length
        return selected_length !== 0 && selected_length !== total_length ? "indeterminate": false
    }
    getCheckedState(region){
        let total_length = this.props.buildings.filter(x=>x.use_type_primary == region).length
        let selected_length = this.props.selectedBuildings.filter(x=>x.use_type_primary == region).length
        return selected_length == total_length ? "checked": false
    }


    render(){

        return(
            <div className="w3-dropdown-hover">

                <SelectContainer>
                    <span>{this.state.title}</span>
                    <i className={"fas fa-caret-down"}></i>
                </SelectContainer>
                <div
                    className="w3-dropdown-content w3-left-align w3-bar-block w3-border"
                    style={{"padding": "4px", zIndex:"100"}}>
                    <div><SelectAllLink onClick={()=>this.selectAll()}>Select All</SelectAllLink></div>
                    {
                        new Array(...new Set(this.props.buildings.map(x=>x.use_type_primary))).map(b=>{
                            // this.props.globalSettings.selectedBuildings.map(b=>{
                            return(  <div><Checkbox className=" usetypeSelectCheck" checked = {this.getCheckedState(b)} indeterminate = {this.getIndeterminateState(b)}  type="checkbox" id={b} onChange={(element)=>{this.checkboxOnclick(element,b)}} ></Checkbox> <label>{b}</label></div>)
                        })
                    }
                </div>
            </div>


        )
    }
}
//------------------------------------

export default ESG;