
import {http} from './http-common';

class API{

    getServerURL = () => {return http}

    authenticate = (username, password, form) => {

        // console.log(process.env)

        if(!username || !password) {
            username = form.target.elements.email.value;
            password = form.target.elements.password.value;
        }
        let config = {
            headers: {
                'content-type': 'application/json'
            }
        }
        // http.defaults.withCredentials = true;

        //Overrides the default x-url-encoded bla
        return http.post("/authenticate", {username: username, password: password}, config)
    }

    login = (token) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',

                // 'Access-Control-Allow-Origin' : '*',
                // 'Access-Control-Allow-Methods':'GET,POST,OPTIONS',

                'authorization': 'Bearer '+token,
                // "Cookie": "JSESSIONID="+jSessionId,
                // 'withCredentials': true
            }
        }
        http.defaults.withCredentials = true
        return http.post("/login", {}, config)
    }

    logout = (token) => {
        let config = {
            headers: {
                'Content-Type': 'application/json',
                // 'authorization': 'Bearer '+token,
                // 'withCredentials': true
            }
        }
        http.defaults.withCredentials = true
        return http.post("/logout", {}, config)

    }

    verifyTotp = (form) => {

        let config = {
            headers: {
                'Content-Type': 'application/json',
                // "Accept": "*/*",
                // "Cache-Control": "no-cache"
            },
            // withCredentials: true
            // credentials: "same-origin"
        }
        http.defaults.withCredentials = true;
        return http.post("/verifyTotp", {twoAuthCode: form.target.elements.twoAuthCode.value}, config)
    }

    forgotPassword = (username, form) => {
        // console.log(process.env)

        if(!username){
            username = form.target.elements.email.value;
        }
        let config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }
        return http.post("/reset", {email: username}, config)
    }

    #getDefaultHeaders = (token) => {

        // if(token !== null)
            return {
                'Content-Type': 'application/json',
                'authorization': 'Bearer '+token
            }
        // else{
        //     return {
        //         'Content-Type': 'application/json'
        //     }
        // }
    }

    #callMasterData = (token, url, data, method) => {

        url = "/masterdata"+url;
        let config = {
            headers: this.#getDefaultHeaders(token)
        }

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            case 'PUT':     return http.put(url, data, config)
            case 'DELETE':  {
                config.data = data
                return http.delete(url, config)
            }
        }
    }

    #callUpload = (token, url, reportInfos, files, data, method) => {
        url = "/report"+url;

        let config = {
            headers: {
                // 'Content-Type': 'multipart/mixed',
                // 'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
            }
        };

        let formData;

        if(reportInfos || files){
            config.headers['Content-Type'] = 'multipart/mixed';
            formData = new FormData();
            formData.append("platform", reportInfos.platform);

            if(reportInfos.period)
                formData.append("period", reportInfos.period);

            // formData.append("file", file);
            // console.log(files)
            for(let file of files) {
                formData.append("filesList", file);
            }
            formData.append("clientId", reportInfos.client.id);
        }else
            config.headers['Content-Type'] = 'application/json';

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, formData, config)
            // case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    #callDownload = (token, url, reportId, streamType, method) => {
        url = "/report"+url;

        let config = {
            responseType: 'blob',
            headers: this.#getDefaultHeaders(token)
        };


        switch(method) {
            case 'GET':     return http.get(url+"/"+reportId+"?streamType="+streamType, config)
            // case 'POST':    return http.post(url, formData, config)
            // case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    #callAdmin = (token, url, data, method) => {
        url = "/admin"+url;
        let config = {
            headers: this.#getDefaultHeaders(token)
        }
        // console.log(config)
        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            case 'PUT':     return http.put(url, data, config)
            case 'DELETE':  {
                config.data = data
                return http.delete(url, config)
            }
        }
    }

    #callUtils = (token, url, data, method) => {
        url = "/utils"+url;

        let config = {}
        if(token === null){
            config = {
                headers: this.#getDefaultHeaders(token)
            }
        }

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            case 'PUT':     return http.put(url, data, config)
            case 'DELETE':  {
                config.data = data
                return http.delete(url, config)
            }
        }
    }

    #callInspect = (token, url, reportId, method) => {
        url = "/report"+url;

        let config = {
                headers: this.#getDefaultHeaders(token)
            }

        switch(method) {
            case 'GET':     return http.get(url+"/"+reportId, config)
            // case 'POST':    return http.post(url, data, config)
            // case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    #callInspectProperty = (token, url, data, method) => {
        url = "/report"+url;

        let config = {
            headers: this.#getDefaultHeaders(token)
        }

        switch(method) {
            // case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            // case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    #callDataChangeEntriesOfProperty = (token, url, data, method) => {
        url = "/datachange"+url;
        let config = {
            headers: this.#getDefaultHeaders(token)
        }
        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    #callInspectUploadedProperties = (token, url, data, method) => {
        url = "/properties"+url;

        let config = {
            headers: this.#getDefaultHeaders(token)
        }

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    #callVerification = (token, url, data, method) => {
        url = "/verification"+url;

        let config = {
            headers: this.#getDefaultHeaders(token)
        }

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    #callNotification = (token, url, data, method) => {
        url = "/properties"+url;

        let config = {
            headers: this.#getDefaultHeaders(token)
        }

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            // case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    #callProfile = (token, url, data, method) => {
        url = "/profile"+url;

        let config = {
            headers: this.#getDefaultHeaders(token)
        }

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            // case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    masterData = {
        client: {
            get:    (token, data) => this.#callMasterData(token, "/getClients", data, "GET"),
            save:   (token, data) => this.#callMasterData(token, "/newClient", data, "POST"),
            update: (token, data) => this.#callMasterData(token, "/updateClient", data, "PUT"),
            deactivate: (token, data) => this.#callMasterData(token, "/deactivateClient", data, "POST"),
        },
        user: {
            resendVerification: (token, data) => this.#callVerification(token, "/resendVerification", data, "POST"),
            get:    (token, data) => this.#callMasterData(token, "/getUsers", data, "GET"),
            save:   (token, data) => this.#callMasterData(token, "/newUser", data, "POST"),
            update: (token, data) => this.#callMasterData(token, "/updateUser", data, "PUT"),
            deactivate: (token, data) => this.#callMasterData(token, "/deactivateUser", data, "POST"),
        },
        delivery: {
            get:    (token, data) => this.#callMasterData(token, "/getDeliveries", data, "GET"),
            save:   (token, data) => this.#callMasterData(token, "/newDelivery", data, "POST"),
            update: (token, data) => this.#callMasterData(token, "/updateDelivery", data, "PUT"),
            deactivate: (token, data) => this.#callMasterData(token, "/deactivateDelivery", data, "POST"),
        },
        property: {
            get:    (token, data) => this.#callMasterData(token, "/getProperties", data, "GET"),
            save:   (token, data) => this.#callMasterData(token, "/newProperty", data, "POST"),
            update: (token, data) => this.#callMasterData(token, "/updateProperty", data, "PUT"),
            deactivate: (token, data) => this.#callMasterData(token, "/deactivateProperty", data, "POST"),
        }
    }


    uploads = {
        get: (token, data) => this.#callUpload(token, "/getUploadedReports", null, null, data, "GET"),
        // newUpload: (token, reportInfos, file) => this.#callUpload(token, "/uploadReport", reportInfos, file, null,"POST"),
        newUpload: (token, reportInfos, files) => this.#callUpload(token, "/uploadReport", reportInfos, files, null,"POST"),
        // saveSmallFile: (token, file) => this.#callUpload(token,"/smallreport", file, "POST"),
        // saveLargeFile: (token, file) => this.#callUpload(token,"/largereport", file, "POST"),
    }

    downloads = {
        get: (token, reportId, streamType) => this.#callDownload(token, "/download", reportId, streamType, "GET"),
        // newUpload: (token, reportInfos, file) => this.#callUpload(token, "/uploadReport", reportInfos, file, null,"POST"),
        // saveSmallFile: (token, file) => this.#callUpload(token,"/smallreport", file, "POST"),
        // saveLargeFile: (token, file) => this.#callUpload(token,"/largereport", file, "POST"),
    }

    admin = {
        getServices: (token, data) => this.#callAdmin(token, "/getServices", data, "GET"),
        updateServices: (token, data) => this.#callAdmin(token, "/setService", data, "PUT"),
        getInstanceSettings: (token, data) => this.#callAdmin(token, "/getInstanceSettings", data, "GET"),
        updateInstanceSettings: (token, data) => this.#callAdmin(token, "/updateInstanceSettings", data, "PUT"),

        getInstanceSettingsUploadTypes: (data) => this.#callAdmin(this.getCookie("jwt"), "/getInstanceSettingsUploadTypes", data, "GET"),

        uploads: {
            deleteAllUploads: (token, data) => this.#callAdmin(token, "/deleteAllUploads", data, "DELETE"),
        }
    }

    inspect = {
        report: {
            get: (token, data) => this.#callInspect(token, "/inspect", data, "GET"),
        },
        property: {
            get: (token, data) => this.#callInspectProperty(token, "/inspect/errors", data, "POST"),
            getPeriods: (token, data) => this.#callInspectUploadedProperties(token, "/getPeriods", data,"POST"),
            getUploadedProperties: (token, data) => this.#callInspectUploadedProperties(token, "/getUploadedProperties", data, "POST"),

            getDataChangeEntriesOfProperty: (token, data) => this.#callDataChangeEntriesOfProperty(token, "/getDataChangeEntriesOfProperty", data, "POST"),

            exception: {
                newPropertyException: (token, data) => this.#callInspectUploadedProperties(token, "/newPropertyException", data, "POST"),
                updatePropertyException: (token, data) => this.#callInspectUploadedProperties(token, "/updatePropertyException", data, "PUT"),
                approveException: (token, data) => this.#callInspectUploadedProperties(token, "/approveError", data, "POST"),
                rejectException: (token, data) => this.#callInspectUploadedProperties(token, "/rejectError", data, "POST"),
            }
        }
    }

    notify = {
        am: (token, data) => this.#callNotification(token, "/notifyAssetManager", data, "POST"),
        property: {
            approve: (token, data) => this.#callNotification(token, "/approveErrors", data, "POST"),
            reject: (token, data) => this.#callNotification(token, "/rejectErrors", data, "POST"),
        }
    }

    profile = {
        update: (token, data) => this.#callProfile(token, "/updateProfile", data, "POST"),
        changePassword: (token, data) => this.#callProfile(token, "/changePassword", data, "POST"),

        updateProfileSecurity: (token, data) => this.#callProfile(token, "/enableTwoFactor", data, "POST")
    }

    utils = {
        version: {
            get: (token, data) => this.#callUtils(token, "/getApiVersion", null, "GET")
        }
    }
    esg = {
        getProperties: {
            get: (token, data) => this.#callESG(token, "/getProperties", null, "GET")
        },
        getMapData: {
            get: (token, data) => this.#callESG(token, "/getMapData", data, "POST")
        },
        getConsumption: {
            get: (token, data) => this.#callESG(token, "/getConsumption", data, "POST")
        },
        getMeasurements: {
            get: (token, data) => this.#callESG(token, "/getMeasurements", data, "POST")
        },
        getMeasurements2: {
            get: (token, data) => this.#callESG(token, "/getMeasurements2", data, "POST")
        },
        getConsumptionTypes: {
            get: (token, data) => this.#callESG(token, "/getConsumptionTypes", data, "POST")
        },
        getTrendData: {
            get: (token, data) => this.#callESG(token, "/getTrendData", data, "POST")
        },
        getTrendDataSQL: {
            get: (token, data) => this.#callESG(token, "/getTrendDataSQL", data, "POST")
        },
        getConsumptionData: {
            get: (token, data) => this.#callESG(token, "/getConsumptionData", data, "POST")
        },
        getIntensitiesData: {
            get: (token, data) => this.#callESG(token, "/getIntensitiesData", data, "POST")
        },
        getCarbonBubbleData: {
            get: (token, data) => this.#callESG(token, "/getCarbonBubbleData", data, "POST")
        },
        getCarbonByType: {
            get: (token, data) => this.#callESG(token, "/getCarbonByType", data, "POST")
        },
        getPeriods: (token, data) => this.#callESG(token, "/getPeriods", data, "POST")
        ,

    }
    #callESG = (token, url, data, method) => {
        url = "/esg"+url;

        let config = {
            headers: this.#getDefaultHeaders(token)
        }
        //console.log("calling esg with ", config)

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            case 'PUT':     return http.put(url, data, config)
            case 'DELETE':  {
                config.data = data
                return http.delete(url, config)
            }
        }
    }
    rules = {
        getRules: {
            get: (token, data) => this.#callRules(token, "/getRules", null, "GET")
        }
    }

    #callRules = (token, url, data, method) => {
        url = "/rules"+url;

        let config = {
            headers: this.#getDefaultHeaders(token)
        }

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            case 'PUT':     return http.put(url, data, config)
            case 'DELETE':  {
                config.data = data
                return http.delete(url, config)
            }
        }
    }

    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }



    #callEsgAPI = (token, url, data, method) => {

        // url = window.location.protocol + '//' + window.location.hostname + ":8081" + url;
        //

        url =  "https://904.ementalist.ai:8081"+url;
        // console.log(url)
        let config = {
            headers: this.#getDefaultHeaders(token),
            // authorization: 'Bearer '+token,
        }

        switch(method) {
            case 'GET':     return http.get(url, config)
            case 'POST':    return http.post(url, data, config)
            // case 'PUT':     return http.put(url, data, config)
            // case 'DELETE':  {
            //     config.data = data
            //     return http.delete(url, config)
            // }
        }
    }

    esg_api = {

        GET: (data) => this.#callEsgAPI(this.getCookie("jwt"), "/esg_api.php", data, "GET"),
        POST: (data) => this.#callEsgAPI(this.getCookie("jwt"), "/esg_api.php", data, "POST")

    }
}

export default API;