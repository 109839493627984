import React, {Component, useEffect, useMemo, useState} from 'react';
import { Nav, Navbar } from 'react-bootstrap';

import styles from './navbar.module.css';

import Select from 'react-select';
import styled, {useTheme} from "styled-components";
import {ServerEventComponent} from "../../utils/ServerEventComponent";
import {useUserService} from "../../../services/userService/UserServiceProvider";
import {useSSE} from "react-hooks-sse";
import {useAPI} from "../../../api/v2/apiV2";
import CancelIcon from '@mui/icons-material/Cancel';
import {
    NotificationBellDiv,
    NotificationEntries,
    NotificationDismissButton,
    NotificationDiv, NotificationContentDiv
} from "./NavigationBar.styles";
import moment from "moment";

import {
    AwaitApprovalNotification,
    ExportNotification,
    RedirectToReportOverlay,
    UploadNotification
} from "./notification/UIElements";
import {StyledExportButton} from "../../pages/data_export/DataExport.styles";
/**
 * @deprecated
 */
class NavigationBar_old extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: this.props.clients,
            clientOptions: [],

            selectedClient: null,
            setClient: this.props.setClient,

            roleOptions: [],
            selectedRole: null,

            notifications: [],
            serverEvents: ServerEventComponent.getInstance(),
        }

        // console.log(this.props.clients)
        if(this.props.clients !== null) {
            this.state.clientOptions = this.getClientOptions(this.props.clients)
            this.state.selectedClient = this.state.clientOptions[0]
            this.state.setClient(this.state.clientOptions[0])
        }

        if(this.props.roles !== null) {
            this.state.roleOptions = this.getRolesOptions(this.props.roles)
            this.state.selectedRole = this.props.selectedRole
            // this.state.setClient(this.state.clientOptions[0])
        }

        this.handleClientSelection = this.handleClientSelection.bind(this);
    }

    componentDidMount() {
        this.state.serverEvents.subscribe(ServerEventComponent.Topics.updates.NOTIFICATION, this.showNewNotification)

    }
    componentWillUnmount() {
        this.state.serverEvents.unsubscribe(ServerEventComponent.Topics.updates.NOTIFICATION, this.showNewNotification)
    }

    showNewNotification = (data) =>  {

        let copy = [...this.state.notifications]

        // console.log("ShowNewNotification", data, copy)
        let {notifications} = this.state;
        notifications.push(data.message);
        this.setState({notifications: notifications});
    }

    getClientOptions = (clients) => {
        let options = []

        clients.map((client) => {
            options.push({
                value: client,
                label: client.name
            })
        })
        return options;
    }

    getRolesOptions = (roles) => {
        let options = []

        roles.map((role) => {
            options.push({
                value: role,
                label: role.roleName
            })
        })
        // console.log(options)
        return options;
    }

    handleClientSelection = (selectedClient) => {
        this.setState({selectedClient: selectedClient})
    }

    handleRoleSelection = (selectedRole) => {
        // this.setState({selectedRole: selectedRole})
        this.props.roleSelection(selectedRole.value)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.selectedClient !== this.state.selectedClient) {
            this.state.setClient(this.state.selectedClient)
            // console.log("Setting new Client", this.state.selectedClient)
        }
    }

    render() {

        let {clientOptions} = this.state;
        let {roleOptions} = this.state;

        // console.log("roleOptions", roleOptions)
        let testNotifications  = [
            {
                title:"title",
                description:"text",
                type:"warn"
            },
            {
                title:"title2",
                description:"text2",
                type:"success"
            }
        ]

        return(
            <nav className={styles.navbar} style={{backgroundColor: this.props.roleColor}}>

                <NotificationBell notifications={this.state.notifications}></NotificationBell>

                <div className={styles.item}>
                    {
                        (roleOptions.length > 1)
                            ?
                            <React.Fragment>
                                <span>Current Role: </span>
                                <Select className={styles.roleSelect}
                                        isSearchable={false}
                                        isDisabled={(roleOptions.length > 1) ? false : true}
                                        value={roleOptions.value}
                                        options={roleOptions}
                                        defaultValue={roleOptions.filter((role) => role.value.role == this.state.selectedRole.role)}
                                        name="Role Selection"
                                        placeholder={(roleOptions.length > 0) ? "" : "No Roles available"}
                                        onChange={this.handleRoleSelection}
                                />
                            </React.Fragment>
                            :
                            <></>
                    }
                </div>
                <div className={styles.item} style={{float: 'left', paddingLeft: '20px'}}>
                    {
                        (this.state.clientOptions.length > 0)
                            ?
                            <React.Fragment>
                                <span>Current Client: </span>
                                {
                                    (this.state.clientOptions.length > 1)
                                        ?
                                        <Select className={styles.clientSelect}
                                                isSearchable={false}
                                                isDisabled={(this.state.clientOptions.length > 0) ? false : true}
                                                value={clientOptions.value}
                                                options={clientOptions}
                                                defaultValue={clientOptions[0]}
                                                name="Client Selection"
                                                placeholder={(this.state.clientOptions.length > 0) ? "" : "No Clients available"}
                                                onChange={this.handleClientSelection}
                                        />
                                        :
                                        <span style={{fontWeight: 'bold'}}>{clientOptions[0].value.name}</span>
                                }

                            </React.Fragment>
                            :
                            <></>
                    }
                </div>
            </nav>
        )
    }

}

function NavigationBar(props){

    const userService = useUserService();
    const theme = useTheme();

    const serverEvents = ServerEventComponent.getInstance();

    // const [notifications, setNotifications] = useState([]);

    // const showNewNotification = (data) =>  {
    //     let copyNotification = [...notifications]
    //     copyNotification.push(data.message)
    //     setNotifications(copyNotification)
    // }

    const getRolesOptions = (roles) => {
        let options = []
        roles.map((role) => {
            options.push({
                value: role,
                label: role.roleName
            })
        })
        return options;
    }
    let roleOptions = getRolesOptions(userService.user.roles);

    const getClientOptions = (clients) => {
        let options = []
        clients.map((client) => {
            options.push({
                value: client,
                label: client.name
            })
        })
        return options;
    }
    let clientOptions = getClientOptions((userService.user.clients ? userService.user.clients : []));

    const handleRoleSelection = (selectedRole) => {
        userService.setRole(selectedRole.value)
    }
    const handleClientSelection = (selectedClient) => {
        console.warn("Implement Multiclient Selection")
        // userService.setActiveClient(selectedClient);
    }

    useMemo(()=>{
        console.log("MEMO Navbar")
        // serverEvents.subscribe(ServerEventComponent.Topics.updates.NOTIFICATION, showNewNotification)

    }, [])

    useEffect(() => {
        return() => {
            console.log("Unmount Navbar")
            // serverEvents.unsubscribe(ServerEventComponent.Topics.updates.NOTIFICATION, showNewNotification)
        }
    }, [])

    return(
        <nav className={styles.navbar} style={{backgroundColor: theme.palette.primary}}>
            <NotificationBell/>
            <div className={styles.item}>
                {
                    (roleOptions.length > 1)
                        ?
                        <>
                            <span>Current Role: </span>
                            <Select className={styles.roleSelect}
                                    isSearchable={false}
                                    isDisabled={(roleOptions.length > 1) ? false : true}
                                    value={roleOptions.value}
                                    options={roleOptions}
                                    defaultValue={roleOptions.filter((role) => role.value.role == userService.activeRole.role)}
                                    name="Role Selection"
                                    placeholder={(roleOptions.length > 0) ? "" : "No Roles available"}
                                    onChange={handleRoleSelection}
                            />
                        </>
                        :
                        <></>
                }
            </div>
            <div className={styles.item} style={{float: 'left', paddingLeft: '20px'}}>
                {
                    (clientOptions.length > 0)
                        ?
                        <>
                            <span>Current Client: </span>
                            {
                                (clientOptions.length > 1)
                                    ?
                                    <Select className={styles.clientSelect}
                                            isSearchable={false}
                                            isDisabled={(clientOptions.length > 0) ? false : true}
                                            value={clientOptions.value}
                                            options={clientOptions}
                                            defaultValue={clientOptions[0]}
                                            name="Client Selection"
                                            placeholder={(clientOptions.length > 0) ? "" : "No Clients available"}
                                            onChange={handleClientSelection}
                                    />
                                    :
                                    <span style={{fontWeight: 'bold'}}>{clientOptions[0].value.name}</span>
                            }

                        </>
                        :
                        <></>
                }
            </div>
        </nav>
    )

}

/**
 * Uses ServerSentEvents
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function NotificationBell(props) {

    const api = useAPI();
    const {activeRole} = useUserService();

    //---SSeEmitter-Events---
    const heartbeat = useSSE("HEARTBEAT", {});
    const newNotificationUpdate = useSSE('NOTIFICATION_REQUEST', {});
    const newNotificationRealtime = useSSE('NOTIFICATION_REALTIME', {});
    const fileExportNotificationRealtime = useSSE('FILE_EXPORT', {});
    //---SSeEmitter-Events---
    console.log(newNotificationRealtime, fileExportNotificationRealtime, "###############")

    //Storage of all Notifications
    const [notifications, setNotifications] = useState([]);
    const [ringBell, setRingBell] = useState(false);

    const DEBUG_showNotifications = false;

    //Used for Filtering, where this Role should not be notified by
    const topics = {
        AM: [
            "UPLOAD",
            "PROPERTY_REJECTED_BY_USER",
            "PROPERTY_APPROVED_BY_USER"
        ],
        PM: [
            "PROPERTY_AWAIT_EXCEPTION_APPROVAL"
        ]
    }

    useEffect(() => {

        if(heartbeat)
            console.info(heartbeat)

    }, [heartbeat])

    useEffect(() => {
        if (Object.keys(newNotificationUpdate).length !== 0) {
            console.log("newNotificationUpdate", newNotificationUpdate)
            // copyNotification.push(newNotification)
            setNotifications(newNotificationUpdate)
        }else{
            setNotifications([])
        }
    }, [newNotificationUpdate])     //DB-Stored - Notification
    useEffect(() => {
        if (Object.keys(newNotificationRealtime).length !== 0) {
            console.log("NEW RT-Notification", newNotificationRealtime)

            console.log("Is already in Notification? ["+newNotificationRealtime.id+"]", notifications.some(notification => notification.id === newNotificationRealtime.id))

            let copyNotification;
            if(notifications.some(notification => notification.id === newNotificationRealtime.id) === false) {
                copyNotification = [...notifications];
            }else{
                copyNotification = [...notifications].filter(n => n.id !== newNotificationRealtime.id);
            }
            copyNotification.push(newNotificationRealtime);
            setNotifications(copyNotification)

            setRingBell(true);
        }
    }, [newNotificationRealtime])   //Real Time - Notification

    useEffect(() => {
        if (Object.keys(fileExportNotificationRealtime).length !== 0) {
            console.log("NEW RT-Notification", fileExportNotificationRealtime)

            console.log("Is already in Notification? ["+fileExportNotificationRealtime.id+"]", notifications.some(notification => notification.id === newNotificationRealtime.id))

            let copyNotification;
            if(notifications.some(notification => notification.id === fileExportNotificationRealtime.id) === false) {
                copyNotification = [...notifications];
            }else{
                copyNotification = [...notifications].filter(n => n.id !== fileExportNotificationRealtime.id);
            }
            copyNotification.push(fileExportNotificationRealtime);
            setNotifications(copyNotification)

            setRingBell(true);
        }
    }, [fileExportNotificationRealtime])   //Real Time - Notification

    let current_notifications = notifications;
    if(activeRole.role === "AM") current_notifications = current_notifications.filter((n) => !topics.AM.includes(n.topic));
    if(activeRole.role === "PM") current_notifications = current_notifications.filter((n) => !topics.PM.includes(n.topic));
    current_notifications = current_notifications.sort((n1, n2) => new Date(n2.timestamp).getTime() - new Date(n1.timestamp).getTime() );

    return (
        <NotificationBellDiv className="w3-dropdown-hover w3-hide-small">
            <button className="w3-button w3-padding-large" title="Notifications">
                <Bell ringBell={ringBell} setRingBell={setRingBell}/>
                <span className="w3-badge w3-right w3-small w3-yellow">{current_notifications.length}</span>
            </button>


            <div className="w3-dropdown-content" style={{right: 0, zIndex: 11, display: (DEBUG_showNotifications ? "block" : "") }}>
                <NotificationEntries>

                    <header style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <h3>Notifications</h3>
                        <StyledExportButton onClick={() => {
                            notifications.forEach(notification => {
                                api.notification.dismiss({id: notification.id}).then((result) => {
                                    console.log("DismissNotificationResult", result);
                                }).catch((error) => {
                                    console.error(error);
                                });
                            });
                            setNotifications([]);
                        }}>Clear All</StyledExportButton>
                    </header>
                    {
                        current_notifications.map((notification, key) => (
                            <NotificationTile notification={notification} key={key}/>
                        ))
                    }
                </NotificationEntries>
            </div>

        </NotificationBellDiv>
        //<button className={"w3-button w3-white"} style={{margin:"6px", float:"right"}}><i className="far fa-bell"></i> ({this.getNotificationCount()})</button>

    )

}

function NotificationTile(props){

    const api = useAPI();

    const notification = props.notification;

    const [overlay, setOverlay] = useState(false);

    const dismissNotification = (notification) => {
        api.notification.dismiss({id: notification.id}).then((result) => {
            console.log("DismissNotificationResult", result)
        }).catch((error) => {
            console.error(error)
        })
    }
    const renderNotificationContent = (notification) => {

        // console.log("creating notification content", notification)

        switch(notification.topic){
            case "UPLOAD": return   <UploadNotification notification={notification} />
            case "PROPERTY_AWAIT_EXCEPTION_APPROVAL": return <AwaitApprovalNotification notification={notification} />
            case "PROPERTY_REJECTED_BY_USER": return <AwaitApprovalNotification notification={notification} />
            case "PROPERTY_APPROVED_BY_USER": return <AwaitApprovalNotification notification={notification} />
            case "EXPORT": return <ExportNotification notification={notification} />
            default: return (
                <>
                    <span>Default: </span>
                    <div style={{textAlign: "center"}}>{notification.message}</div>
                </>
            )
        }
    }

    const toggleOverlay = () => {
        setOverlay(!overlay)
    }

    return(
        <NotificationDiv status={(notification.data) ? notification.data.property_status : null}>

            <header style={{minHeight: "36px"}}>
                <label>{notification.id}</label>
                <label style={{float: 'right', paddingRight: "30px"}}>{moment(notification.timestamp).fromNow()}</label>

                <NotificationDismissButton onClick={() => {dismissNotification(notification)}}>
                    <CancelIcon style={{color: 'black', fontSize: "2em"}}/>
                </NotificationDismissButton>
            </header>

            <NotificationContentDiv
                onMouseEnter={() => toggleOverlay()} onMouseLeave={() => toggleOverlay()}
            >
                {/*<div style={{textAlign: "center"}}>{notification.message}</div>*/}
                {/* Specific Notification-UI */}

                {renderNotificationContent(notification)}

                <RedirectToReportOverlay
                    notification={notification}
                    showOverlay={ (notification.topic==="UPLOAD" && notification.data.status_history.length === 3) ? overlay : false }
                />
            </NotificationContentDiv>

        </NotificationDiv>
    )

}


function Bell(props){

    const [bellStyle, setBellStyle] = useState("fa fa-bell w3-text-white");

    useEffect(() => {

        if(props.ringBell){
            let newStyle = bellStyle;
            newStyle += " ring";
            setBellStyle(newStyle);
            setTimeout(() => {
                // setBellStyle(bellStyle.replace(" ring", ""));
                props.setRingBell(false);
            }, 2500)
        }else{
            let newStyle = bellStyle.replace(" ring", "");
            setBellStyle(newStyle);
        }

    }, [props.ringBell])

    return(
        <i className={bellStyle} style={{fontSize: "1.4em"}}></i>
    )
}

export default NavigationBar;