import axios from 'axios';

import project from "../../../package.json";

let baseURL;
if(process.env.NODE_ENV !== 'production') {
    baseURL = "http://localhost:8080"+project.backendURI;
    // baseURL = "https://3.125.144.137/api";
}else{
    baseURL = window.location.origin+project.backendURI;
}
console.log("BaseURL:", baseURL)

export const http = axios.create({
    baseURL: baseURL,
    headers:{
        "Content-type": "application/json",
    }
});

http.defaults.headers = {
    "Content-type": "application/json"
}

export default http;
// export default httpAuth;
