import API from "../../api/api";

import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';

export class ServerEventComponent{

    static events = null;

    api = new API();

    static getInstance() {
        if(!ServerEventComponent.events){
            ServerEventComponent.events = new ServerEventComponent();
            // ServerEventComponent.events.startSubscribeToServerTopics();
        }
        return ServerEventComponent.events;
    }

    static Topics = {
        updates: {
            ERROR_REPORT: 'ERROR_REPORT',
            UPLOAD_ERROR: 'UPLOAD_ERROR',
            NOTIFICATION: 'NOTIFICATION',
            PROPERTY: 'PROPERTY'
            // ROLE: 'ROLE'
        },
        account: {
            ACCOUNT_DISABLED: 'ACCOUNT_DISABLED',
            ROLE: 'ROLE'
        }
    }
    subscribers = {}

    /**
     * Pub-Sub-Pattern
     * @param eventName
     * @param data
     */
    publish = (eventName, data, callbackFnkt) => {
        // console.log(this.subscribers, eventName)
        if (!Array.isArray(this.subscribers[eventName])) {
            return
        }
        this.subscribers[eventName].forEach((callback) => {
            // console.log("Call subscribers callback")
            if(callbackFnkt)
                callback(data, callbackFnkt)
            else
                callback(data)
        })
    }
    /**
     * Subscribe to a specific Topic to receive Updates
     * @param eventName - Topics
     * @param callback
     */
    subscribe = (eventName, callback) => {
        // console.log("Sub has been called", eventName, callback)
        if (!Array.isArray(this.subscribers[eventName])) {
            this.subscribers[eventName] = []
        }

        this.subscribers[eventName].push(callback)
        console.log(this.subscribers)
        // this.startSubscribeToServerTopics(eventName);
    }

    unsubscribe = (eventName, callback) => {
        // console.log("Unsubscribe has been called", eventName, callback, this.subscribers)
        if (this.subscribers[eventName]) {
            for (let i = 0; i < this.subscribers[eventName].length; i++) {
                if (this.subscribers[eventName][i].toString() === callback.toString()) {
                    this.subscribers[eventName].splice(i, 1);
                    // console.log("Unsubscribed from subscription", eventName, callback)
                    break;
                }
            }
        }
    }

    startSubscribeToServerTopics = (Topic) => {
        console.log("Starting to Subscribe to Server Topics")
        let eventSource = undefined;

        const EventSource =  EventSourcePolyfill;
        global.EventSource = EventSourcePolyfill;

        // console.log(this.api.getServerURL());

        let eventSourceInit = {
            // withCredentials: true,
            // headers: {'Authorization': 'test=test'},
            // lastEventIdQueryParameterName: 'updates',
            headers: {
                'Authorization': 'Bearer '+this.api.getCookie("jwt"),
                'Topic': Topic
            },
            heartbeatTimeout: 300000
        }

        let baseURL;
        if(process.env.NODE_ENV !== 'production') {
            baseURL = "http://localhost:8080/api";
            // baseURL = "https://3.125.144.137/api";
        }else{
            baseURL = window.location.origin+"/api"
        }

        eventSource = new EventSource(baseURL+"/events/updates", eventSourceInit);

        console.log(eventSource)

        eventSource.onopen = (event) => {
            console.log("connection opened")
        }

        eventSource.onmessage = (event) => {
            // console.log("result", JSON.parse(event.data));
            let data = JSON.parse(event.data);
            // console.log(data)
            if(data.topic === ServerEventComponent.Topics.updates.ERROR_REPORT){
                // console.log("GOT Update for Event ERROR_REPORT", data)
                this.publish(ServerEventComponent.Topics.updates.ERROR_REPORT, data.data)
            }
            if(data.topic === ServerEventComponent.Topics.updates.UPLOAD_ERROR){
                this.publish(ServerEventComponent.Topics.updates.UPLOAD_ERROR, data.data)
            }
            if(data.topic === ServerEventComponent.Topics.updates.NOTIFICATION){
                this.publish(ServerEventComponent.Topics.updates.NOTIFICATION, data.data)
            }
            if(data.topic === ServerEventComponent.Topics.account.ACCOUNT_DISABLED){
                this.publish(ServerEventComponent.Topics.account.ACCOUNT_DISABLED, data.data)
            }
            if(data.topic === ServerEventComponent.Topics.account.ROLE){
                this.publish(ServerEventComponent.Topics.account.ROLE, data.data)
            }

            // setData(old => [...old, event.data])
        }

        eventSource.onerror = (event) => {
            console.log(event.target.readyState)
            if (event.target.readyState === EventSourcePolyfill.CLOSED) {
                console.log('eventsource closed (' + event.target.readyState + ')')
            }
            eventSource.close();
        }
    }

}