import ThemeGlobal from "./ThemeGlobal";

const ThemeAM = {

    global: ThemeGlobal,

    palette: {
        primary: "#577d58"
    }

    // textColor: "white",
    //
    // footer: "#2F3135",
    //
    //
    // palette:{
    //     // primary: "#3498db",
    //     primary: "#121212",
    //     secondary: "#2F3135",
    //     third: "#4A545C",
    //     fourth: "#657B81",
    //
    //     navPanel: "#2F3135",
    //     content: "#64727c",
    //
    //     service:{
    //         online: "#00c000"
    //     }
    //
    // },
    //
    // icons: {
    //     background: "#f7f5f0",
    // }

}

export default ThemeAM;