import React, {Component} from 'react';
import API from "../../../api/api";
import styled from "styled-components";

import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
// import {makeStyles, withStyles} from "@material-ui/styles";

import CustomPaginationActionsTable from "./UploadHistoryTable";
import ErrorReport from "./ErrorReport";
import {ServerEventComponent} from "../../utils/ServerEventComponent";
import {CardDiv, InspectReportDiv, UploadOverviewDiv} from "./InspectReport.styles";
import {useLocation, useNavigate} from "react-router-dom";

export function WrappedInspectReports(props){

    console.log("Props", props)

    const navigate = useNavigate();
    console.log("Navigation", navigate)

    const location = useLocation();
    console.log("Location", location)

    if(location.search){
        console.log("OPEN REPORT", location.search)
        let uploadId = location.search.replace("?upload=", "");
        return <InspectReports openReport={uploadId}/>
    }else{
        return <InspectReports/>
    }
}

class InspectReports extends Component {

    constructor(props){
        super(props);

        this.state = {
            uploadedReports: [],

            selectedReportToInspect: null,
            selectedReportUploadId: null,

            serverEvents: ServerEventComponent.getInstance(),

        }

        this.api = new API();

        this.handleGetAllUploads = this.handleGetAllUploads.bind(this);
        this.handleGetAllUploads()
    }

    componentDidMount() {
        this.state.serverEvents.subscribe(ServerEventComponent.Topics.updates.ERROR_REPORT, this.handleUpdate)

        if(this.props.openReport){
            this.handleInspection(this.props.openReport)
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        console.log("SHOULD UPDATE", this.props, nextProps)
        if(nextProps.openReport){
            if(this.props.openReport !== nextProps.openReport){
                console.log("SHOW OTHER REPORT")
                this.setState({selectedReportToInspect: null, selectedReportUploadId: null})
                this.handleInspection(nextProps.openReport)
            }
        }
        return true;
    }

    componentWillUnmount() {
        this.state.serverEvents.unsubscribe(ServerEventComponent.Topics.updates.ERROR_REPORT, this.handleUpdate)
    }

    handleUpdate = (data) => {
        // console.log("Inspect Reports, New Updates", data)
        this.handleGetAllUploads();
    }

    handleGetAllUploads = (event) => {
        this.api.uploads.get(this.api.getCookie("jwt"), {}).then((response) => {
            // console.log(response);
            this.setState({uploadedReports: response.data})
        }).catch((error) => {
            console.log(error)
        })
    }

    handleReportToInspect = (reportUploadId, reportObject) => {
        // console.log("Setting Report to Inspect -> View should change!")
        this.setState({
            selectedReportUploadId: reportUploadId,
            selectedReportToInspect: reportObject
        })
    }

    handleInspection = (reportId) => {
        this.api.inspect.report.get(this.api.getCookie("jwt"), reportId).then((response) => {
            console.log("Report Inspection Response ", response)
            this.handleReportToInspect(reportId, response.data);
        }).catch((error) => {

        })
    }

    downloadReport = (reportId, streamType) => {
        console.log("Download report")
        this.api.downloads.get(this.api.getCookie("jwt"), reportId, streamType).then(response => {
            if (!(response.data instanceof Blob)) return;
            else{
                const blob = new Blob([response.data], { type: 'application/excel' });
                let disposition = response.headers["content-disposition"]
                let filename;
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '');
                    }
                }
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = filename;
                link.click();
            }
        }).catch((error) => {
            console.error(error)
        })
    }

    /**
     * Refreshed some pages when Property-Manager is sending Exceptions to Asset-Manager
     */
    refreshReport = () => {
        // alert("refreshReport")
        let selectedReportUploadId = this.state.selectedReportUploadId;
        this.setState({
            uploadedReports: [],
            selectedReportToInspect: null,
        })
        // this.handleGetAllUploads();
        this.handleInspection(selectedReportUploadId)
        // this.render();
    }

    render() {
        return(
            <React.Fragment>
                {
                    (this.state.selectedReportToInspect)
                        ?
                        <ErrorReport
                            report={this.state.selectedReportToInspect}
                            reportUploadId={this.state.selectedReportUploadId}

                            // selectedUploadedReport={this.state.uploadedReports.filter(uploadedReport => uploadedReport.id === this.state.selectedReportUploadId)[0]}

                            downloadReport={this.downloadReport}

                            backToOverview={() => this.setState({selectedReportToInspect: null, selectedReportUploadId: null}) }
                            refreshReport={this.refreshReport}
                        ></ErrorReport>
                        :
                        <InspectReportDiv>
                            <h1>Uploads</h1>
                            <hr/>
                            <UploadOverviewDiv>
                                {/*<input type="button" value="GetUploads" onClick={this.handleGetAllUploads} />*/}
                                {
                                    (this.state.uploadedReports.length === 0)
                                        ?
                                        <p>{this.state.uploadedReports.length} uploaded Reports found</p>
                                        :
                                        // <CardDiv>
                                            <CustomPaginationActionsTable uploads={this.state.uploadedReports} setReportToInspect={this.handleReportToInspect}></CustomPaginationActionsTable>
                                        // </CardDiv>
                                }
                            </UploadOverviewDiv>
                        </InspectReportDiv>
                }
            </React.Fragment>
        );
    }
}

export default InspectReports;