import React, {Component} from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 1em;
  margin-left: 6em;
  margin-right: 6em;
`;
class NoMatch extends Component {

    render() {

        console.log("NO MATCH")

        return(
            <Wrapper>
                <h2>404 - Not found</h2>
            </Wrapper>
        )
    }
}


export default NoMatch;