import {EventSourcePolyfill} from "event-source-polyfill";
import http from "../http-common";
import {ServerUrl} from "./Endpoints";

export const SseSource = (userToken) => {
    const EventSource =  EventSourcePolyfill;

    let heatbeatSeconds = 60;

    let eventSourceInit = {
        // withCredentials: true,
        // headers: {'Authorization': 'test=test'},
        // lastEventIdQueryParameterName: 'updates',
        headers: {
            'Authorization': 'Bearer '+userToken
        },
        heartbeatTimeout: heatbeatSeconds * 1000,
        reconnectInterval: 10000

    }
    let eventSource = new EventSource(http.defaults.baseURL+ServerUrl.EVENTS.NOTIFICATION, eventSourceInit);

    // eventSource.onerror = (event) => {
    //     console.error("ERROR", event)
    // }

    // eventSource.onopen = (event) => {
    //     console.log("Connection open", event)
    // }
    //
    // eventSource.onmessage = (event) => {
    //     console.log("New Message")
    //     console.log(event, event.data)
    //     let data = JSON.parse(event.data);
    //     console.log(data)
    // }
    // eventSource.onerror = (event) => {
    //     console.log(event.target.readyState)
    //     if (event.target.readyState === EventSourcePolyfill.CLOSED) {
    //         console.log('eventsource closed (' + event.target.readyState + ')')
    //     }
    //     eventSource.close();
    // }

    return eventSource;
}