//Styling for React-Select
import styled from "styled-components";

export const getSelectStyling = (options) => {
    return {
        control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: "0px",
            backgroundColor: "unset",
            border: "unset",
            width: "160px",
            minWidth: "max-content",
            opacity: (options.length === 0) ? 0.5 : 1
        }),
        menu: (baseStyles, state) => ({
            ...baseStyles,
            zIndex: 10000,
            width: "160px",
            minWidth: "min-content"
        }),
        placeholder: (baseStyles, state) => ({
            ...baseStyles,
            color: "white"
        }),
        singleValue: (baseStyles, state) => ({
            ...baseStyles,
            color: "white"
        }),
        option: (baseStyles, state) => ({
            ...baseStyles,
            padding: "4px 12px"
        })
    }
}
export const FilterSelectionDiv = styled.div`
  display: flex;
  gap: 2px;
  width: 100%;

  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.palette.primary};

    label {
      margin: 0;
      padding: 10px;
      color: rgba(211, 211, 211, 0.7);
    }
  }
`;
export const FilterDiv = styled.div`

  //background-color: white;
  //padding: 1em;
  padding-bottom: 6px;
`;
export const FilterPeriodDiv = styled.div`

  width: max-content;
  //height: 100%;
  display: flex;

  background-color: ${props => props.theme.palette.primary};

  align-items: center;

  label {
    margin: 0;
    padding: 10px;
    color: rgba(211, 211, 211, 0.5);
  }

  .selectPeriod {
    width: 100%;
    > div {
      z-index: 2;
    }
  }

`;