import RuleDashboard from "../RuleDashboard";
import React, {useEffect, useMemo, useState} from "react";
import {useAPI} from "../../../../api/v2/apiV2";


export default RulesPage;



function RulesPage(){



    const api = useAPI();

    const [rules, setRules] = useState([]);

    useEffect(() => {
        console.log(rules)
    }, [rules])

    useMemo(() => {
        api.rules.getRules().then(response => {
            //console.log(response)
            setRules(response)
        }).catch(err => {

        })
    }, [])

    return(
        <>RulePage</>
    )
}