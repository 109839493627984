import React,{Component} from "react";
import API from "../../../api/api";

import moment from 'moment';
import styled from "styled-components";
import TemplateDiv from "../StyledPages";
import "../../global/css/ESG.css";


import ESGTile from "./components/ESGTile";
import ESGTrendLine from "./components/ESGTrendLine";


const ESGTrendLineAddButton = styled.div`
  height: 480px;
  
  .button{
    height:150px;
    width:160px;
    border-radius:50px;
  }
  
`

const resourceSettings = {
    "Electricity":{
        "color":"#536878",
        "icon": "fa fa-bolt",
        "unit": "kwh",
    },
    "Water":{
        "color":"#2a9de2",
        "icon": "fas fa-tint",
        "unit": "m³",
    },
    "Waste":{
        "color":"#414400",
        "icon": "fa fa-trash",
        "unit": "kg",
    },
    "Solar":{
        "color":"#f0d117",
        "icon": "fas fa-solar-panel",
        "unit": "kwh",
    },
    "Geothermie":{
        "color":"#bf6b59",
        "icon": "fas fa-water",
        "unit": "kwh",
    },
}


class ESGTrends extends Component{
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            idCount:1
        }
    }

    addTrendLine = ()=>{
        this.setState({idCount:this.state.idCount+1})
    }
    drawTrendLine = ()=>{
        if(this.state == undefined || this.state.idCount == undefined)
            return <div></div>
        return [...Array(this.state.idCount).keys()].map(i=><ESGTrendLine id={"trend"+i} colSetup = {"s12 m12 l12"} settings = {this.props.settings} title = {<span><i className='fas fa-chart-line'></i> Trends</span>}></ESGTrendLine>)
    }

    render(){
        return(
            <div className={"w3-row-padding"}>
                <div id={"trendLineContainer"}>
                    {this.drawTrendLine()}
                </div>
                <ESGTrendLineAddButton className={"w3-col s12 m12 l12"}>
                    <div className={"w3-display-container"} style={{height:"100%"}}>
                        <div className={"w3-display-middle w3-btn button w3-card"} onClick={()=>{this.addTrendLine()}}>
                            <div className={"w3-display-container"} style={{height:"100%", width:"100%"}}>
                                <div className={"w3-display-middle"}>
                                    <span style={{fontSize:"96px", color: "#333", fontFamily:"monospace"}}>+</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ESGTrendLineAddButton>
            </div>
        )
    }
}

export default ESGTrends;