import ImportExportIcon from '@mui/icons-material/ImportExport';
const FEATURES = {
    QUALITY : "QUALITY",
    CHANGE_REPORTING : "CHANGE_REPORTING",
    ANALYZE : "ANALYZE",
    ESG_DASHBOARD : "ESG_DASHBOARD",
    DEMO_CLIENT : "DEMO_CLIENT",
    DATA_EXPORT : "DATA_EXPORT"
}

export const ItemTitles = {
    DASHBOARD: "Dashboard",
    UPLOADS: "Uploads",
    MASTERDATA: "Masterdata",
    MANAGEMENT: "Management",
    PROPERTIES: "Properties",
    SUPPORT: "Support",
    SETTINGS: "Settings",
    ADMINISTRATION: "Administration",
    LOGOUT: "Logout"
}

/**
 * Every menuItem has a position(10-100). 10 is first, 100 last
 * @param enabled_features
 * @param role
 * @returns {{}}
 * @constructor
 */
export function SidebarItems(enabled_features, role){

    const menuItems = {};

    /**
     *  DEFAULT("NO_FEATURES"),
     * 	QUALITY("QUALITY"),
     * 	CHANGE_REPORTING("CHANGE_REPORTING"),
     * 	ANALYZE("ANALYZE"),
     * 	ESG_DASHBOARD("ESG_DASHBOARD"),
     * 	DEMO_CLIENT("DEMO_CLIENT");
     */
    this._getDefaultSideItems = function(){

        const getTopItemTemplate = function(heading, position){
            if( position < 10 || position > 100 ) throw Error("Position of TopItemTemplate between 10 and 100")
            else
                return(
                    {
                        heading: heading,
                        items:[],
                        position: position
                    }
                );

        }

        let items = {
            dashboard: getTopItemTemplate(ItemTitles.DASHBOARD, 10),
            uploads: getTopItemTemplate(ItemTitles.UPLOADS, 20),
            management: getTopItemTemplate(ItemTitles.MANAGEMENT, 30),
            properties: getTopItemTemplate(ItemTitles.PROPERTIES, 40),
            support: getTopItemTemplate(ItemTitles.SUPPORT,50),
            settings: getTopItemTemplate(ItemTitles.SETTINGS, 60),
            administration: getTopItemTemplate(ItemTitles.ADMINISTRATION, 70),
            logout: getTopItemTemplate(ItemTitles.LOGOUT, 100)
        }
        return items;
    }

    this._renderDefaultItem = function(title){
        if(title.heading == ItemTitles.SUPPORT){
            title.items.push( { path: "contact",            name: "Contact",            icon: "fas fa-life-ring",   css: "navitem" } )
        }
        if(title.heading == ItemTitles.SETTINGS){
            title.items.push( { path: "myprofile",         name: "My Profile",         icon: "fas fa-user-circle", css: "navitem" } )
        }
        if(title.heading === ItemTitles.PROPERTIES){
            title.items.push( { path: "overview",           name: "Overview",           icon: "far fa-file",        css: "navitem" } )

            //TODO: Remove this line before Push
            // title.items.push( { path: "overviewOld",           name: "Overview Old",           icon: "far fa-file",        css: "navitem" } )
        }
        if(title.heading == ItemTitles.LOGOUT){
            title.items.push( { path: "logout",            name: "Logout",             icon: "fas fa-power-off",css: "navitem logout" } )
        }
        return title;
    }

    this._renderItem_PM = function(title){

        if(enabled_features){
            if(enabled_features.includes(FEATURES.QUALITY) && title.heading == ItemTitles.DASHBOARD){
                title.items.push( { path: "monitor/quality",       name: "Quality Monitor",    icon: "fas fa-certificate",    css: "navitem"} )

            }

            if(enabled_features.includes(FEATURES.ESG_DASHBOARD) && title.heading == ItemTitles.DASHBOARD){
                title.items.push( { path: "esg/overview",      name: "ESG Overview",       icon: "fas fa-magnifying-glass-chart",    css: "navitem" } )
                title.items.push( { path: "esg/trends",        name: "ESG Trends",         icon: "fas fa-chart-line",    css: "navitem" } )
                title.items.push( { path: "esg/consumption",   name: "ESG Consumption",    icon: "fas fa-gauge-high",    css: "navitem" } )
            }
            if(enabled_features.includes(FEATURES.DEMO_CLIENT) && title.heading == ItemTitles.ADMINISTRATION){
                title.items.push( { path: "console",            name: "Console",            icon: "fas fa fa-eye",      css: "navitem" } )
            }
        }

        if(title.heading == ItemTitles.UPLOADS){
            title.items.push( { path: "upload",            name: "Upload Report",      icon: "fas fa-upload",      css: "navitem" } )
            //title.items.push( { path: "uploadManual",            name: "Data Entry",      icon: "fas fa-table",      css: "navitem" } )
            title.items.push( { path: "inspect",           name: "Inspect Report",     icon: "fas fa-ticket-alt",  css: "navitem" } )
        }


        return title;
    }
    this._renderItem_AM = function(title){

        if(enabled_features){
            if(enabled_features.includes(FEATURES.ESG_DASHBOARD) && title.heading == ItemTitles.DASHBOARD){
                title.items.push( { path: "esg/overview",      name: "ESG Overview",       icon: "fas fa-magnifying-glass-chart",    css: "navitem" } )
                title.items.push( { path: "esg/trends",        name: "ESG Trends",         icon: "fas fa-chart-line",    css: "navitem" } )
                title.items.push( { path: "esg/consumption",   name: "ESG Consumption",    icon: "fas fa-gauge-high",    css: "navitem" } )
            }
            if(enabled_features.includes(FEATURES.DEMO_CLIENT) && title.heading == ItemTitles.ADMINISTRATION){
                title.items.push( { path: "console",            name: "Console",            icon: "fas fa fa-eye",      css: "navitem" } )
            }
        }

        if(title.heading == ItemTitles.MANAGEMENT){
            title.items.push( { path: "masterdata",    name: "Master Data",    icon: "fas fa-id-card",     css: "navitem" } )
            // title.items.push( { path: "mapping",      name: "Mapping",       icon: "mapping-icon",        css: "navitem" } )

        }
        if(title.heading === ItemTitles.PROPERTIES){
            title.items.push( { path: "overview",       name: "Overview",       icon: "far fa-file",        css: "navitem" } )

            if(enabled_features.includes(FEATURES.DATA_EXPORT))
                title.items.push( { path: "export",         name: "Data Export",       icon: "fa-solid fa-file-arrow-down",        css: "navitem" } ) //<i class=“fa-solid fa-file-arrow-down”></i>
        }


        return title;
    }
    this._renderItem_Admin = function(title){
        // title.items.push( { path: "rules_old",      name: "Rule Dashboard old",       icon: "fa fa-list",        css: "navitem" } )
        // title.items.push( { path: "rules",      name: "Rule Dashboard",       icon: "fa fa-list",        css: "navitem" } )
        return title
    }


    this._renderItem_ADMIN = function(title){} //Currently empty, no special needs

    this._renderItem_SUPERUSER = function(title){
        if(title.heading == ItemTitles.ADMINISTRATION){
            title.items.push( { path: "console",            name: "Console",            icon: "fas fa fa-eye",      css: "navitem" } )
        }

        return title;
    }

    this.render = function(role){

        let settingsItems = this._getDefaultSideItems().settings

        if(role === "PM"){
            menuItems.dashboard     = this._renderItem_PM(this._getDefaultSideItems().dashboard)
            menuItems.uploads       = this._renderItem_PM(this._getDefaultSideItems().uploads)
            menuItems.properties    = this._renderDefaultItem(this._getDefaultSideItems().properties)

            menuItems.administration= this._renderItem_PM(this._getDefaultSideItems().administration)
        }else if(role === "AM"){
            menuItems.dashboard     = this._renderItem_AM(this._getDefaultSideItems().dashboard)
            menuItems.management    = this._renderItem_AM(this._getDefaultSideItems().management)
            menuItems.properties    = this._renderItem_AM(this._getDefaultSideItems().properties)

            menuItems.administration= this._renderItem_PM(this._getDefaultSideItems().administration)
        }else if(role === "ADMIN"){
            menuItems.management    = this._renderItem_AM(this._getDefaultSideItems().management)
            menuItems.management    = this._renderItem_Admin(menuItems.management)

            menuItems.administration= this._renderItem_PM(this._getDefaultSideItems().administration)
        }else if(role === "SUPERUSER"){
            menuItems.management    = this._renderItem_AM(this._getDefaultSideItems().management)
            menuItems.administration= this._renderItem_SUPERUSER(this._getDefaultSideItems().administration)
        }else if(role === "API"){
            // let settingsItem = this._getDefaultSideItems().settings;
            settingsItems.items.push({ path: "keys",         name: "My Keys",         icon: "fas fa-key", css: "navitem" } );
            // menuItems.settings      = this._renderDefaultItem(this._getDefaultSideItems().settings)
            // menuItems.settings
        }
        // menuItems.support       = this._renderDefaultItem(this._getDefaultSideItems().support)
        menuItems.settings          = this._renderDefaultItem(settingsItems)
        menuItems.logout            = this._renderDefaultItem(this._getDefaultSideItems().logout)

        this.sortMenu = function(sidebarItems){
            console.log(sidebarItems)

            function compare(a, b){
                if( a[1].position < b[1].position ){
                    return -1
                } else if( a[1].position > b[1].position ){
                    return 1;
                }
                return 0;
            }
            let sorted = Object.fromEntries(Object.entries(sidebarItems).sort(compare));

            return sorted;
        }

        // menuItems.dashboard = this._getDefaultSideItems().dashboard;
        console.log(menuItems)

        return this.sortMenu(menuItems);
    }


    return this.render(role);
}