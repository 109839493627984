import React, {Component} from "react";

class ESGTileMap extends Component{
    constructor(props) {
        super(props);
        this.state = {
            title:"",
            settings :"",
            data:{}
        }
        Object.assign(this.state.settings, JSON.parse(JSON.stringify(props.settings)))
        if(this.props.title!=undefined)
            this.state.title = this.props.title;
    }

    render(){
        return(
            <div className={"w3-col "+this.props.colSetup}>
                <div className={"w3-white w3-card "}>
                    {
                        this.generateTitle()
                    }

                    {
                        this.generateContent()
                    }
                </div>
            </div>
        )
    }
    content(){
        return <p>Overwrite the content() method when implementing a new extension of ESGTileMap.</p>
    }
    generateTitle() {
        if(this.state.title !="")
            return <div className = {"w3-panel w3-border-bottom"} style={{marginBottom:"0px"}}>
                <h4>{this.state.title}</h4>
            </div>
        return <div></div>
    }
    generateContent() {
        return <div className = {""} style={{minHeight:"100px"}}>
            {this.content()}
        </div>
    }
}
export default ESGTileMap;