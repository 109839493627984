//TODO: Extract this function to own File
import {convertDate, getCurrentDate} from "../../dashboard/utils/GlobalUtils";
import Select from "react-select";
import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";

import {FilterDiv, FilterPeriodDiv, getSelectStyling} from "./Filter.styles";
import FixRequiredSelect from "../FixRequiredSelect";
import BaseSelect from "react-select";

export function FilterPeriod(props) {

    const [loading, setLoading] = useState(null);

    const setFilter = (filter) => {
        props.setSelectedPeriod(filter.value);
    }
    const getOptions = (data) => {

        data = data.sort().reverse()
        let options = [];
        // if(props.defaultSelectCurrentPeriod) {
            options.push({value: "none", label: "All"})

            if (!data.includes(getCurrentDate())) options.push({
                value: getCurrentDate(),
                label: convertDate(getCurrentDate())
            })
        // }
        options = [].concat(options, data.map(date => {
            return {value: date, label: convertDate(date)}
        }))
        return options;
    }
    const getPeriodOptionOf = (period) => {
        console.log(period, props.availablePeriods)
        let options = getOptions(props.availablePeriods);
        for(let option of options){
            console.log(option.value, period)
            if(option.value === period) {
                console.log("Return", option)
                return option;
            }
        }
        return null;
    }

    useMemo(() => {
        // props.availablePeriods.push(getCurrentDate())
        // if(props.selectedPeriod){
        //     console.log("SelectedPeriod", props.selectedPeriod)
        //     console.log("DefaultSelectCurrentPeriod", props.defaultSelectCurrentPeriod)
        // }
    }, [])

    useEffect(() => {
        if(props.availablePeriods && loading){
            console.log("Available Periods has changed")
            setLoading(false)
        }else{
            setLoading(true)
        }
    }, [props.availablePeriods])

    // let optionsWithCurrentDate = [...props.availablePeriods];
    // optionsWithCurrentDate.push(getCurrentDate());

    let periodOptions = getOptions(props.availablePeriods);
    // console.log(periodOptions)

    if(props.style){
        return(
            <>
                <PeriodSelect
                    styles={props.style}
                    options={periodOptions}
                    // defaultValue={periodOptions[1]}
                    // defaultValue={(props.selectedPeriod) ? periodOptions[1] : null}
                    defaultValue={(props.defaultSelectCurrentPeriod) ? periodOptions[1] : null}
                    onChange={(target) => setFilter(target)}
                    placeholder={(loading === null || loading) ? "Loading available Periods" : (props.availablePeriods.length > 0) ? "Select period" : "No periods for export available" }
                    isLoading={(props.availablePeriods.length === 0)}
                    isDisabled={(props.availablePeriods.length === 0)}
                    name="periodSelection"
                    id="periodSelection"
                    required
                />
            </>
        )
    }else{
        return (
            <FilterDiv>
                <FilterPeriodDiv>
                    <label>Period</label>
                    <Select
                        // menuPortalTarget={document.body}
                        styles={getSelectStyling(props.availablePeriods)}
                        options={periodOptions}
                        defaultValue={getPeriodOptionOf(props.selectedPeriod)}
                        value={getPeriodOptionOf(props.selectedPeriod)}
                        // defaultValue={getPeriodOptionOf(props.selectedPeriod)}
                        // defaultValue={(props.defaultSelectCurrentPeriod) ? periodOptions[1] : null}
                        onChange={(target) => setFilter(target)}
                        placeholder={(props.availablePeriods.length === 0) ? "Loading available Periods..." : "Select a Date"}
                        isLoading={(props.availablePeriods.length === 0)}
                    />
                </FilterPeriodDiv>
            </FilterDiv>
        )
    }
}

FilterPeriod.propTypes = {
    selectedPeriod: PropTypes.string,
    availablePeriods: PropTypes.array,
    setSelectedPeriod: PropTypes.func,
    defaultSelectCurrentPeriod: PropTypes.bool,
    noStyle: PropTypes.bool

}

const PeriodSelect = props => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);
