import React from "react";
import {convertDate, getCurrentDate} from "../../../utils/GlobalUtils";
import Select from "react-select";
import {FilterDiv, FilterPeriodDiv, FilterSelectionDiv} from "../../../../uiElements/filter/Filter.styles";

/**
 * @deprecated
 */
export class Filter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            typeFilter: [...new Set(this.props.properties.map(property => {
                return property.type
            }))],
            propertyFilter: [...new Set(this.props.properties.map(property => {
                return property.property.property_id_receiver
            }))],
            datesFilter: [...new Set(this.props.properties.map(property => {
                return property.period
            }))],
            statusFilter: [...new Set(this.props.properties.map(property => {
                return property.status
            }))],
            overdueFilter: [...new Set(this.props.properties.map(property => {
                return property.statusOverdue
            }))],

            typesToFilter: {
                TYPE: "none",
                PROPERTY: "none",
                PERIOD: "none",
                STATUS: "none",
                OVERDUE: "none"
            },
        }
        console.log(this.props.properties)
    }

    setPropertiesToFilter = () => {
        let filteredProps = this.props.properties;

        for (let typeOfFilter in this.state.typesToFilter) {
            let filterValue = this.state.typesToFilter[typeOfFilter];
            console.log(typeOfFilter, filterValue);
            if (typeOfFilter === "PROPERTY" && filterValue !== "none") {
                console.log("Filter after PROPERTY");
                filteredProps = filteredProps.filter(property => {
                    return property.property.property_id_receiver === filterValue
                });
            }
            if (typeOfFilter === "TYPE" && filterValue !== "none") {
                console.log("Filter after TYPE");
                filteredProps = filteredProps.filter(property => {
                    return property.type === filterValue
                });
            }

            if (typeOfFilter === "PERIOD" && filterValue !== "none") {
                console.log("Filter after PERIOD");
                filteredProps = filteredProps.filter(property => {
                    return property.period === filterValue
                });
                console.log("After Filter PERIOD", filteredProps)
            }

            if (typeOfFilter === "STATUS" && filterValue !== "none") {
                console.log("Filter after STATUS");
                filteredProps = filteredProps.filter(property => {
                    return property.status === filterValue
                });
            }
            if (typeOfFilter === "OVERDUE" && filterValue !== "none") {
                console.log("Filter after OVERDUE");
                filteredProps = filteredProps.filter(property => {
                    return property.statusOverdue === filterValue
                });
            }
        }
        this.props.setFilteredProperties(filteredProps)
    }

    setFilter = (filter, filterType) => {
		console.log("Set Filter", filterType, filter.target.value)
        let state = this.state;
        state.typesToFilter[filterType] = filter.target.value;
		console.log(state)
        this.setState(state);
        // typesToFilter[filterType] = filter.target.value;
        // setTypesToFilter(typesToFilter)
        this.setPropertiesToFilter()
    }

    setDefaultFilter = (filter, filterType, filterValue) => {
        console.log(filter)
        let state = this.state;
        filter.value = filterValue;
        state.typesToFilter[filterType] = filter.value;
        this.setState(state);
        this.setPropertiesToFilter()

    }

    getCurrentDate = () => {
        let today = new Date();
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = yyyy + mm;
        return today;
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // console.log("Filter should update", this.props.properties, nextProps.properties, this.state, nextState)
        if (this.props.properties.length !== nextProps.properties.length) {
        // if (this.props.selectedTab !== nextProps.selectedTab) {

            console.log("TAB has changed", this.props.properties, nextProps.properties)
			console.log(this.props.selectedTab, nextProps.selectedTab)

            nextState.typeFilter = [...new Set(nextProps.properties.map(property => {
                return property.type
            }))];
            nextState.propertyFilter = [...new Set(nextProps.properties.map(property => {
                return property.property.property_id_receiver
            }))];
            nextState.datesFilter = [...new Set(nextProps.properties.map(property => {
                return property.period
            }))];
            nextState.statusFilter = [...new Set(nextProps.properties.map(property => {
                return property.status
            }))];
            nextState.overdueFilter = [...new Set(nextProps.properties.map(property => {
                return property.statusOverdue
            }))];

			// if(this.props.selectedTab !== nextProps.selectedTab){
				nextState.typesToFilter = {
					TYPE: "none",
					PROPERTY: "none",
					PERIOD: "none",
					STATUS: "none",
					OVERDUE: "none"
				}
				if (this.props.filterRef) {
					for (let select of this.props.filterRef.current.getElementsByTagName("select")) {
						// console.log(select.)
						select.selectedIndex = 0;
					}
					// console.log(this.props.filterRef.current.getElementsByTagName("select")["filter_period"])
				}
			// }




            // this.setDefaultFilter(this.props.filterRef.current.getElementsByTagName("select")["filter_period"], "PERIOD", this.getCurrentDate())

        }
        // console.log(this.props, nextProps)
        return true;
    }

    render() {

        let {propertyFilter, typeFilter, typesToFilter, datesFilter, statusFilter, overdueFilter} = this.state;

		console.log("TYPE-FILTER: ", typeFilter, typesToFilter)
        return (
            <FilterDiv
                ref={this.props.filterRef}
                style={{visibility: "hidden"}}
            >
                {/*<h3>Filter</h3>*/}

                <hr/>
                <FilterSelectionDiv>
                    <div>
                        <label>Type</label>
                        <select
                            defaultValue={"default"}
                            disabled={(typeFilter.length === 0)}
                            onChange={(target) => this.setFilter(target, "TYPE")}
							// value={typesToFilter["TYPE"]}
                        >
                            <option disabled hidden value="default">Filter Type</option>
                            <option value="none">All</option>
                            {
                                (typeFilter.length === 0) ? <option disabled>No Props available</option> :
                                    typeFilter.map((property, key) => {
                                        return <option value={property} key={key}>{property}</option>
                                    })
                            }
                        </select>
                    </div>
                    <div>
                        <label>Property</label>
                        <select
                            defaultValue={"default"}
                            disabled={(propertyFilter.length === 0)}
                            onChange={(target) => this.setFilter(target, "PROPERTY")}
                        >
                            <option disabled hidden value="default">Filter Property</option>
                            <option value="none">All</option>
                            {
                                (propertyFilter.length === 0) ? <option disabled>No Props available</option> :
                                    propertyFilter.map((property, key) => {
                                        return <option value={property} key={key}>{property}</option>
                                    })
                            }
                        </select>
                    </div>
                    {/*<div>*/}
                    {/*    <label>Period</label>*/}
                    {/*    <select id="filter_period" disabled={(datesFilter.length === 0)} onChange={(target) => this.setFilter(target, "PERIOD")} defaultValue={this.getCurrentDate()}>*/}
                    {/*        /!*<option disabled hidden>Filter Period</option>*!/*/}
                    {/*        <option value="none">All</option>*/}
                    {/*        <option value={this.getCurrentDate()} selected>{this.getCurrentDate()}</option>*/}

                    {/*        {*/}
                    {/*            (datesFilter.length === 0) ? <option disabled>No Periods available</option> :*/}
                    {/*                datesFilter.map(date => {*/}
                    {/*                    return <option value={date}>{date}</option>*/}
                    {/*                })*/}
                    {/*        }*/}
                    {/*    </select>*/}
                    {/*</div>*/}
                    <div>
                        <label>Status</label>
                        <select
                            defaultValue={"default"}
                            disabled={(statusFilter.length === 0)}
                            onChange={(target) => this.setFilter(target, "STATUS")}
                        >
                            <option disabled hidden value="default">Filter Status</option>
                            <option value="none">All</option>
                            {
                                (statusFilter.length === 0) ? <option disabled>No Status available</option> :
                                    statusFilter.map((status, key) => {
                                        return <option value={status} key={key}>{status}</option>
                                    })
                            }
                        </select>
                    </div>
                    {
                        (this.props.selectedTab === "not_uploaded") ?
                            <div>
                                <label>Overdue</label>
                                <select
                                    defaultValue={"default"}
                                    disabled={(overdueFilter.length === 0)}
                                    onChange={(target) => this.setFilter(target, "OVERDUE")}
                                >
                                    <option disabled hidden value="default">Filter Status</option>
                                    <option value="none">All</option>
                                    {
                                        (overdueFilter.length === 0) ?
                                            <option disabled>No Overdue Props available</option> :
                                            overdueFilter.map((status, key) => {
                                                return (
                                                    <option value={status} key={key}>
                                                        {
                                                            (status === "NOT_OVERDUE") ? "On Time" : "Overdue"
                                                        }
                                                    </option>
                                                )
                                            })
                                    }
                                </select>
                            </div>
                            :
                            <div></div>
                    }

                </FilterSelectionDiv>
            </FilterDiv>
        )
    }
}

/**
 * @deprecated
 */
export class FilterPeriod extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            datesFilter: this.props.availablePeriods,

            // datesFilter : [...new Set(this.props.properties.map(property => {return property.period}))],

            typesToFilter: {
                PERIOD: getCurrentDate(),
                STATUS: "none"
            },
        }
        console.log(this.props, "props")
        console.log(this.state, "state")
    }

    setPropertiesToFilter = () => {
        console.log("SetPRopsToFilter")
        let filteredProps = this.props.properties;

        for (let typeOfFilter in this.state.typesToFilter) {
            let filterValue = this.state.typesToFilter[typeOfFilter];
            console.log(typeOfFilter, filterValue);
            if (typeOfFilter === "PERIOD" && filterValue != "none") {
                console.log("Filter after PERIOD");
                filteredProps = filteredProps.filter(property => {
                    return property.period === filterValue
                });
                console.log("After Filter PERIOD", filteredProps)
            }
        }
        this.props.setFilteredProperties(filteredProps)
    }

    setFilter = (filter, filterType) => {
        console.log("Filter: ", filter)
        let state = this.state;
        state.typesToFilter[filterType] = filter.value;
        this.setState(state);
        this.props.getPropertiesWithPeriod(filter.value);
        this.setPropertiesToFilter()
    }

    // setDefaultFilter = (filter, filterType, filterValue) => {
    //     console.log("DefaultFilter: ", filter)
    //     let state = this.state;
    //     filter.value = filterValue;
    //     state.typesToFilter[filterType] = filter.value;
    //     this.setState(state);
    //     this.setPropertiesToFilter()
    //
    // }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // console.log("Filter should update", nextProps)
        nextState.datesFilter = nextProps.availablePeriods;

        if (this.props.properties.length !== nextProps.properties.length) {
            // nextState.datesFilter = nextProps.availablePeriods;
            nextState.typesToFilter = {
                PERIOD: "none",
            }

            // if(this.props.filterRef){
            //     for(let select of this.props.filterRef.current.getElementsByTagName("select")) {
            //         // console.log(select.)
            //         select.selectedIndex = 0;
            //     }
            //     console.log(this.props.filterRef.current.getElementsByTagName("select")["filter_period"])
            // }


            // this.setDefaultFilter(this.props.filterRef.current.getElementsByTagName("select")["filter_period"], "PERIOD", this.getCurrentDate())

        }
        return true;
    }

    getOptions = (data) => {
        data = data.sort().reverse()
        let options = [];
        options.push({value: "none", label: "All"})

        if (!data.includes(getCurrentDate()))
            options.push({value: getCurrentDate(), label: convertDate(getCurrentDate())})

        options = [].concat(options, data.map(d => {
            return {value: d, label: convertDate(d)}
        }))
        return options;
    }

    getSelectStyle = () => {
        return (
            {
                control: styles => ({
                    ...styles,
                    backgroundColor: 'transparent',
                    border: 'unset',
                    color: 'black'
                    // width: '100%',
                }),
                singleValue: styles => ({
                    ...styles,
                    color: 'white'
                }),
                option: (styles, {data, isDisabled, isFocused, isSelected}) => {
                    // const color = chroma(data.color);
                    return {
                        ...styles,
                        // backgroundColor: isDisabled ? 'lightgrey' : "white",
                        // color: '#000',
                        // cursor: isDisabled ? 'not-allowed' : 'default',

                    }
                },

            }
        )
    }

    render() {

        let {datesFilter} = this.state;

        let selectOptions = this.getOptions(datesFilter);
        // console.log("Options:", selectOptions, this.state);
        return (
            <FilterDiv
                // ref={this.props.filterRef}
                // style={{visibility: "hidden"}}
            >
                {/*<h3>Filter</h3>*/}

                <hr/>
                <FilterPeriodDiv>
                    <label>Period</label>

                    <Select
                        className="selectPeriod"
                        value={selectOptions.value}
                        options={selectOptions}
                        defaultValue={selectOptions[1]}
                        // menuIsOpen={true}
                        styles={this.getSelectStyle()}
                        onChange={(target) => this.setFilter(target, "PERIOD")}
                    />

                    {/*</Select>*/}

                    {/*<select id="filter_period" disabled={(datesFilter.length === 0)} onChange={(target) => this.setFilter(target, "PERIOD")} defaultValue={this.getCurrentDate()}>*/}
                    {/*    /!*<option disabled hidden>Filter Period</option>*!/*/}
                    {/*    <option value="none">All</option>*/}
                    {/*    <option value={this.getCurrentDate()} selected>{this.getCurrentDate()}</option>*/}

                    {/*    {*/}
                    {/*        (datesFilter.length === 0) ? <option disabled>No Periods available</option> :*/}
                    {/*            datesFilter.map(date => {*/}
                    {/*                return <option value={date}>{date}</option>*/}
                    {/*            })*/}
                    {/*    }*/}
                    {/*</select>*/}
                </FilterPeriodDiv>
            </FilterDiv>
        )
    }
}