import styled from "styled-components";

export const LoginMain = styled.div`
  width: 100%;
  box-sizing: inherit !important;
  min-height: calc(100vh);
  background-image:url("../images/background_3-progressive-min.jpeg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
`;

export const VersionContent = styled.div`
  position: absolute;
  right: 10px;
  bottom: 0;
  color: grey;
`;

export const LoginContent = styled.div`
  
  background-color: white;
  width: 450px;
  align-items: center;
  //padding: 0.01em 16px;
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  
  border-radius: 10px;
  border: 2px solid #0E5780;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);

`;

export const LoginHeaderDiv = styled.div`
  background-color: #0E5780;
  text-align: center;
  color: white;

  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position:relative;
  top: -1px;
  
  img{
    width: 85%;
    height: 100%;
    padding: 20px;
    image-rendering: -webkit-optimize-contrast;
  }
`;

export const LoginBodyDiv = styled.div`
  //padding: 10px;
  //padding: 0.01em 16px;
  position: relative;
  margin: 0.01em 16px;
  
  height: 300px;
  
  Form{
    //margin: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    
    input{
      margin: 10px 0px 10px 0px;

      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
    span{
      padding-left: 10px;
    }
  }
`;

export const LoginButtonDiv = styled.div`
  position: absolute;
  bottom: 0;
  margin-bottom: 6px;
  width: 100%;
  text-align: center;
  
  input{
    align-self: center;
    width: 50%;
    background-color: #2196F3!important;
    color: white;
  }
`;

export const ErrorDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  
  height: 50px;
  
  p{
    color: lightcoral;
  }
`;

export const TwoFactorAuthDiv = styled.div`
  
  display: flex;
  
  flex-direction: row;
  
  width: inherit;
  
  gap: 10px;
  
  input{
    width: calc(100%/6);
    text-align: center;
  }
    
`;
