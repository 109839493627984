import styled from "styled-components";

export const SideBarDiv = styled.div`
  
    img{
      width: 100%;
      object-fit: contain;
      padding: 10px;
      image-rendering: -webkit-optimize-contrast;
    }
  
`;

export const NavItemBottom = styled.div`
    position: sticky;
    display: flex;
    flex-direction: column;
    top: 100%;
`;
export const LogoFooter = styled.div`
    display: flex;
    bottom: 0;
    width: 100%;
    color: white !important;
    justify-content: center;
    align-items: center;
    padding: 0 10px 10px 10px;
    
    a:hover{
      background-color: unset !important;
    }
    
    img{
      width: 150px;
    }
`;