import React, {Component} from "react";
import API from "../../../api/api";
import styled from "styled-components";
import moment from "moment";
import {useLocation, useNavigate} from "react-router-dom";

const QualityPage = styled.div`
  width: 100%;
  text-align: center;
  padding: 8px;
  min-height: calc(100vh - 50px);
  p{
    color: grey;
  }
`
const RuleDashboardContainer = styled.div`
  .mapping-bar-element{
    border: 0;
    border-radius: 0px;

  }
  .mapping-bar-element.selected{
    border-bottom: 1px solid;
  }
`
const RuleTableContainer = styled.div`
  min-height:200px;
  padding:16px;
  background-color:#eee;  
  margin:16px;
  table, th, td {
    border: 1px solid white;
  }
`
const RuleButton = styled.span`
  background-color: green;
  color:white;
  border-radius: 16px;
  padding: 4px 8px;
  margin: 4px auto;
  opacity: 0.5;
  transition: all 0.5s ease-out;
  &:hover{
    opacity: 1;
  }
  span{
    opacity: 1;
  }
`


function RuleDashboardOld(){

    const navigate = useNavigate();
    const location = useLocation();
    console.log("asdf")
    return(
        <RuleTable navigate={navigate}/>
    )

}
class RuleTableRow extends Component{
    render(){
        function showButtonData(event){
            let button = event.target.closest(".dataButton");
            let data = button.getAttribute("data");
            alert(data);
        }
        if(!this.props.data) return null;
        let data = this.props.data;
        if(!data.historical_rules) data.historical_rules = [];
        if(!data.locical_rules) data.locical_rules = [];
        if(!data.smart_rules) data.smart_rules = {};
        let showPlus = data.historical_rules.length == 0 || data.locical_rules.length == 0 || Object.keys(data.smart_rules).length == 0;
        let ruleButtons = <React.Fragment>
            <RuleButton className={"dataButton"} onClick={showButtonData} data = {JSON.stringify(data.historical_rules)} style ={{backgroundColor: "brown", display:data.historical_rules.length == 0?"none":"block"}}><span>historical</span></RuleButton>
            <RuleButton className={"dataButton"} onClick={showButtonData} data = {JSON.stringify(data.locical_rules)} style ={{backgroundColor: "darkblue", display:data.locical_rules.length == 0?"none":"block"}}><span>logical</span></RuleButton>
            <RuleButton className={"dataButton"} onClick={showButtonData} data = {JSON.stringify(data.smart_rules)} style ={{backgroundColor: "teal", display:Object.keys(data.smart_rules).length == 0?"none":"block"}}><span>smart</span></RuleButton>
            <RuleButton style ={{backgroundColor: "grey", display:showPlus?"block":"none", textAlign: "center", width:"28px"}}><span><i className={"fa fa-plus"}></i></span></RuleButton>
        </React.Fragment>
        return(
            <tr>
                <td className={"w3-display-container"}>
                    {data.table_name}
                    <div className={"w3-display-topright w3-text-grey"}><i className={"fa fa-pen"}></i></div>
                </td>
                <td className={"w3-display-container"}>
                    {data.field_name}
                    <div className={"w3-display-topright w3-text-grey"}><i className={"fa fa-pen"}></i></div>
                </td>
                <td className={"w3-display-container"}>
                    {data.description}
                    <div className={"w3-display-topright w3-text-grey"}><i className={"fa fa-pen"}></i></div>
                </td>
                <td className={"w3-display-container"}>
                    {data.mandatory?"Yes":"No"}
                    <div className={"w3-display-topright w3-text-grey"}><i className={"fa fa-pen"}></i></div>
                </td>
                <td className={"w3-display-container"}>
                    {data.datatype}
                    <div className={"w3-display-topright w3-text-grey"}><i className={"fa fa-pen"}></i></div>
                </td>
                <td className={"w3-display-container"}>
                    {data.list}
                    <div className={"w3-display-topright w3-text-grey"}><i className={"fa fa-pen"}></i></div>
                </td>
                <td className={"w3-display-container"}>
                    {ruleButtons}
                </td>
            </tr>
        )
    }
}
class RuleTableHead extends Component{

    render(){
        function filterColumn(col, element){
            let showValues = element.parentElement.parentElement.querySelectorAll("div.filter-bar input[type='checkbox']:checked").map(x=>x.parentElement.querySelector("label").innerText)

            element.closest("table").querySelector("tbody").querySelectorAll("tr").map((x,i)=>{
                let cell = x.querySelectorAll("td")[col];
                if(showValues.includes(cell.innerText)){
                    x.style.display = "table-row";
                }
                else{
                    x.style.display = "none";
                }

            })
        }
        function filterClickSelectAll(event){
            let checked = event.target.checked;
            let col = event.target.closest("th").cellIndex;

            if(checked){
                event.target.parentElement.parentElement.querySelectorAll("div.filter-bar input[type='checkbox']").map(x=>x.checked=true)
            }
            else{
                event.target.parentElement.parentElement.querySelectorAll("div.filter-bar input[type='checkbox']").map(x=>x.checked=false)
            }
            filterColumn(col, event.target);
        }
        function filterClick(event){
            let col = event.target.closest("th").cellIndex;
            let totalChecks = event.target.parentElement.parentElement.querySelectorAll("div.filter-bar input[type='checkbox']").length;
            let checkedChecks = event.target.parentElement.parentElement.querySelectorAll("div.filter-bar input[type='checkbox']:checked").length;
            let selectAllCheck = event.target.parentElement.parentElement.querySelector("input.selectAllCheck");
            if(checkedChecks == totalChecks){
                selectAllCheck.checked = true;
                selectAllCheck.indeterminate = false;

            }
            else if(checkedChecks == 0){
                selectAllCheck.checked = false;
                selectAllCheck.indeterminate = false;

            }
            else{
                selectAllCheck.indeterminate = true;
            }
            filterColumn(col, event.target);


        }
        let filter = <></>
        if(this.props.json_key)
            filter = <React.Fragment>
                <i onClick={(e)=>{
                    document.querySelectorAll(".w3-dropdown-content").filter(x=>x!=e.target.nextSibling).map(x=>x.style.display="none")
                    e.target.nextSibling.style.display=="none"?
                        e.target.nextSibling.style.display="block":
                        e.target.nextSibling.style.display="none";
                    e.target.nextSibling.querySelector("input[type='text']").value=""
                    document.querySelectorAll("filter-bar").map(x=>x.style.display="block")
                }} style={{cursor:"pointer"}} className="fa fa-filter"></i>
                <div style={{display:"none"}} className="w3-dropdown-content w3-bar-block w3-border">
                    <div className="w3-bar-item"><input type={"text"} placeholder={"🔍"} onInput={(e)=>{
                        e.target.parentElement.parentElement.children.filter(x=>x!=e.target.parentElement).map(x=>x.style.display="none")
                        e.target.parentElement.parentElement.children.filter(x=>x!=e.target.parentElement).filter(x=>x.querySelector("label").innerText.toLowerCase()=="select all"||x.querySelector("label").innerText.toLowerCase().includes(e.target.value.toLowerCase())).map(x=>x.style.display="block")
                    }}/></div>
                    <div className="w3-bar-item w3-border-bottom"><input className={"selectAllCheck"} onClick={filterClickSelectAll} defaultChecked={true} type={"checkbox"}/> <label>Select All</label></div>
                    {[...new Set(this.props.data.map(d=>d[this.props.json_key]))].map(d=>
                        <div className="w3-bar-item filter-bar"><input type={"checkbox"} onClick={filterClick} defaultChecked={true}/> <label>{d}</label></div>
                    )}
                </div>
            </React.Fragment>
        if(this.props.sort !== undefined)
            return <th style={{whiteSpace: "nowrap"}} ><i onClick={()=>{this.props.sort(this.props.json_key, 1)}} className={"fa "+(this.props.last_sorted==this.props.json_key?(this.props.ascending>0?"fa-caret-down":"fa-caret-up"):"fa-caret-down")} style={{cursor:"pointer"}}></i> {this.props.children} {filter}</th>
        return <th style={{whiteSpace: "nowrap"}}> {this.props.children} {filter}
        </th>
    }
}

class RuleTable extends Component{
    constructor(props) {
        super(props);

        this.state = {
            data:[],
            last_sorted:"",
            ascending:1,
        }

        this.sort = this.sort.bind(this)

    }

    sort(key){
        let asc = this.state.ascending

        if(key == this.state.last_sorted){
            asc = asc*-1
            this.setState({ascending:asc})
            console.log("a")
        }
        else{
            asc = 1
            this.setState({last_sorted:key, ascending:asc})
        }
        //let key = this.props.key
        console.log("asc", asc)
        this.setState({data:this.state.data.sort((a,b)=>{
                if(typeof a[key] == "string")
                    return a[key].localeCompare(b[key])*asc
                if(typeof a[key] == "number")
                    return (a[key]-b[key])*asc

                return 0
        })})
    }


    componentDidMount() {
        let api=new API()
        api.rules.getRules.get(api.getCookie("jwt"), {}).then((response)=>{
            this.setState({data:response.data.map(d=>{d.show=true;return d;})})
        })
    }

    render(){
        return(
            <RuleDashboardContainer >
                <RuleTableContainer>
                    <table className={"w3-table w3-small"}>
                        <thead>
                        <tr>
                            <RuleTableHead data = {this.state.data} last_sorted={this.state.last_sorted} ascending = {this.state.ascending} sort={this.sort} json_key={"table_name"}>Entity Name</RuleTableHead>
                            <RuleTableHead data = {this.state.data} last_sorted={this.state.last_sorted} ascending = {this.state.ascending} sort={this.sort} json_key={"field_name"}>Field Name</RuleTableHead>
                            <RuleTableHead data = {this.state.data} last_sorted={this.state.last_sorted} ascending = {this.state.ascending} sort={this.sort} json_key={"description"}>Description</RuleTableHead>
                            <RuleTableHead data = {this.state.data} last_sorted={this.state.last_sorted} ascending = {this.state.ascending} sort={this.sort} json_key={"mandatory"}>Mandatory</RuleTableHead>
                            <RuleTableHead data = {this.state.data} last_sorted={this.state.last_sorted} ascending = {this.state.ascending} sort={this.sort} json_key={"datatype"}>DataType</RuleTableHead>
                            <RuleTableHead data = {this.state.data} last_sorted={this.state.last_sorted} ascending = {this.state.ascending} sort={this.sort} json_key={"list"}>Enumeration Name</RuleTableHead>
                            <RuleTableHead data = {this.state.data}>Rules</RuleTableHead>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.data.map(d=><RuleTableRow data={d}></RuleTableRow>)}
                        </tbody>
                    </table>
                </RuleTableContainer>
            </RuleDashboardContainer>
        )
    }

}

export default RuleDashboardOld;