/* eslint-disable */

import API from "../../../../api/api";
import React from "react";
import ESGTile from "./ESGTile";
import moment from "moment";
import {getColorByType, sameObjectArrayById} from "../functions";
import Chart from "chart.js/auto";


class ESGTrendLine extends ESGTile{
    constructor(props) {
        super(props);
        this.state = {
            consumptionTypes:[],
            selectedConsumptionType1:"",
            selectedConsumptionType2:""
        }
        this.getTrendData = this.getTrendData.bind(this);
        this.rollingAvgCheck = this.rollingAvgCheck.bind(this);
        this.setConsumptionType = this.setConsumptionType.bind(this);
        this.api = new API();
        window.setTimeout(()=>{this.getTrendData()},100)
        this.showRollingAverage = false;
    }
    setConsumptionType(i,e){
        if(i == 1)
            this.setState({selectedConsumptionType1:e.target.value})
        if(i ==2)
            this.setState({selectedConsumptionType2:e.target.value})
        return false;

    }
    setTimeframe(timetype,e){
        console.log(e.target.value)
        if(timetype == "from")
            this.setState({from:moment(e.target.value)})
        if(timetype == "until")
            this.setState({until:moment(e.target.value)})
        return false;

    }
    getTrendData(settings, t1, t2){
        if(t1 == "" || t2 == "")
            return 0
        if(this.state.selectedConsumptionType1 === undefined || this.state.selectedConsumptionType2 === undefined)
            return 0
        let d = {
            consumptionTypes: [t1,t2],
            from: moment.utc(document.getElementById(this.props.id+"dateselect1").value).subtract(1,"years").startOf("month").format(),
            selectedBuildings: document.getElementsByClassName("buildingSelectCheck").filter(x=>x.checked).map(x=>x.id),
            until: moment.utc(document.getElementById(this.props.id+"dateselect2").value).endOf("month").format(),
            timestamp:settings.selectedTimestamp,
            role:settings.role
        }
        console.log(this.props.settings)
        //console.log(api.esg.getConsumption.get(api.getCookie("jwt"),{from:this.state.settings.from,until:this.state.settings.from,selectedBuildings: (this.state.settings.selectedBuildings == undefined)?[]:this.state.settings.selectedBuildings.map(b=>b.id)}))
        if(this.props.settings.selectedTimestamp && d.selectedBuildings.length > 0)
            this.api.esg.getTrendData.get(this.api.getCookie("jwt"),d).then(response=>{
                this.drawLine(response.data,d,[document.getElementById(this.props.id+"select1").value.indexOf("_PRODUCTION")>-1,document.getElementById(this.props.id+"select2").value.indexOf("_PRODUCTION")>-1])
            })

    }
    componentWillUpdate(nextProps, nextState, nextContext) {
        if (!sameObjectArrayById(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings)){
            this.setState({settings:  JSON.parse(JSON.stringify(nextProps.settings))})
            this.getTrendData()


        }
    }

    drawLine_old(data, meta,isProductionArray){
        let dateStart = moment.utc(meta.from).add(1,'year');
        let dateEnd = moment.utc(meta.until);
        let timeValues = {};

        while (dateEnd > dateStart || dateStart.format('Y-MM') === dateEnd.format('Y-MM')) {
            timeValues[dateStart.format('YYYY-MM')] = {from:moment.utc(dateStart), until:moment.utc(dateStart).endOf('month')}
            dateStart.add(1,'month');
        }
        var legendClickHandler = function (e, legendItem, legend){
            let chart = legend.chart
            let hiddenAxis = "";
            let visibleAxis = "";

            chart.data.datasets = chart.data.datasets.map(d=>{
                if(d.label.includes(legendItem.text)){
                    hiddenAxis = d.yAxisID
                    d.hidden = !legendItem.hidden
                }
                else {
                    if(!legendItem.hidden){
                        d.hidden = legendItem.hidden
                    }
                    visibleAxis = d.yAxisID

                }
                return d
            })
            chart.options.scales._proxy[hiddenAxis].grid.drawOnChartArea = true
            chart.options.scales._proxy[visibleAxis].grid.drawOnChartArea = false

            chart.update()

        }
        let config =  {
            type: 'line',
            data: null,
            options: {

                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                    legend:{
                        onClick: legendClickHandler,
                        labels:{
                            filter: function (legendItem, data) {
                                return !legendItem.text.includes("Average")
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Energy Consumption Mwh"
                        },
                        ticks:{}


                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Energy Consumption Mwh"
                        },

                        // grid line settings
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        ticks:{
                            callback: function(value) {if (value % 1 === 0) {return value + "°C";}}
                        }
                    },

                }
            }
        }
        let datasets = meta.consumptionTypes.map((type,i)=>{
            let consumptionData =[]
            data.filter(x=>x.consumption_type === type && (isProductionArray[i]?x.meter_type ==="PRODUCTION":x.meter_type ==="CONSUMPTION")).forEach(d=>{
                Object.keys(timeValues).forEach((t,j)=>{

                    if(moment.utc(d.measurement_date).get("month")===timeValues[t].from.get("month") && moment.utc(d.measurement_date).get("year")===timeValues[t].until.get("year")){
                        if(consumptionData[j]== undefined)
                            consumptionData[j] = 0
                        consumptionData[j] += d.consumption_since_last_measure
                        config.options.scales[(i%2)?'y1':'y'].ticks.callback =  function(value) {if (value % 1 === 0) {return value + " "+d.measure_unit;}}
                        config.options.scales[(i%2)?'y1':'y'].title.text = d.consumption_type + " " + d.meter_type
                    }
                })
            })
            console.log({consumptionData})
            return{
                label: type + " " + (isProductionArray[i]?"Production":"Consumption"),
                label_original: type,
                data:consumptionData,
                backgroundColor: getColorByType(type + " " + (isProductionArray[i]?"Production":"Consumption")),
                yAxisID: (i%2)?'y1':'y',
                borderColor: getColorByType(type + " " + (isProductionArray[i]?"Production":"Consumption")),
                borderWidth: 2,


            }
        })
        let ds = []
//        Object.assign(ds,datasets)
        ds = JSON.parse(JSON.stringify(datasets))
        /*
        datasets = Object.keys(timeValues).map((x,i)=>
            (datasets[i] == undefined)?
                0 :datasets[i]

        )*/
        datasets = datasets.concat(meta.consumptionTypes.map((type,i)=>{

            let total_data = ds.filter(dsx=>dsx.label===type + " " + (isProductionArray[i]?"Production":"Consumption"))[0].data
            ///////////////////////////////////////////////////// CHANGE HERE
            total_data = [null,null,null,null,null,null,null,null,null,null,null,null].concat(total_data)
            let consumptionData = total_data.map((td,k,a)=>{
                k=k+1
                if(k<=12)
                    return undefined

                return a.slice(k-12,k).reduce((p,c)=>p+c,0)/ a.slice(k-12,k).reduce((p,c)=>(c==null)?p+0:p+1,0)
            })
            consumptionData = consumptionData.splice(12)


            //consumptionData = consumptionData.map(x=>x/12)
            return{
                label: type + " " + (isProductionArray[i]?"Production":"Consumption") + " Rolling Average",
                data:consumptionData,
                hidden: !this.showRollingAverage,
                backgroundColor: getColorByType(type + " " + (isProductionArray[i]?"Production":"Consumption")),
                yAxisID: (i%2)?'y1':'y',

                borderColor: getColorByType(type + " " + (isProductionArray[i]?"Production":"Consumption")),
                borderWidth: 1.5,
                borderDash: [5, 5],
                pointRadius: 0,

            }
        }))

        let chartData ={
            labels: Object.keys(timeValues),
            datasets: datasets
        }
        let emptyLength = 0;
        chartData.labels.every((l,i)=>{
            let empty = true;
            datasets.forEach(ds=>{
                if(ds.data[i]!=undefined && !isNaN(ds.data[i]))
                    empty = false;
                // else{
                // }
            })
            if(empty){
                emptyLength = i;
                return true;
            }
            else{
                return false;
            }
        })
        chartData.labels.splice(0,emptyLength)
        datasets.forEach(ds=>{
            ds.data.splice(0,emptyLength)

        })
        config.data = chartData
        if(document.getElementById(this.props.id+"line") === undefined || document.getElementById(this.props.id+"line") === null)
            return 0

        this[this.props.id+"ctx"] = document.getElementById(this.props.id+"line").getContext('2d');


        if(this[this.props.id+"trendline"] === undefined){
            this[this.props.id+"trendline"] = new Chart(this[this.props.id+"ctx"], config);
        }
        else{
            this[this.props.id+"trendline"].config._config =config
            this[this.props.id+"trendline"].update()
        }
    }

    drawLine(data, meta,isProductionArray){
        let dateStart = moment.utc(meta.from).add(1,'year');
        let dateEnd = moment.utc(meta.until);
        let timeValues = {};

        while (dateEnd > dateStart || dateStart.format('Y-MM') === dateEnd.format('Y-MM')) {
            timeValues[dateStart.format('YYYY-MM')] = {from:moment.utc(dateStart), until:moment.utc(dateStart).endOf('month')}
            dateStart.add(1,'month');
        }
        var legendClickHandler = function (e, legendItem, legend){
            let chart = legend.chart
            let hiddenAxis = "";
            let visibleAxis = "";

            chart.data.datasets = chart.data.datasets.map(d=>{
                if(d.label.includes(legendItem.text)){
                    hiddenAxis = d.yAxisID
                    d.hidden = !legendItem.hidden
                }
                else {
                    if(!legendItem.hidden){
                        d.hidden = legendItem.hidden
                    }
                    visibleAxis = d.yAxisID

                }
                return d
            })
            chart.options.scales._proxy[hiddenAxis].grid.drawOnChartArea = true
            chart.options.scales._proxy[visibleAxis].grid.drawOnChartArea = false

            chart.update()

        }
        let config =  {
            type: 'line',
            data: null,
            options: {

                plugins: {
                    title: {
                        display: false,
                        text: ''
                    },
                    legend:{
                        onClick: legendClickHandler,
                        labels:{
                            filter: function (legendItem, data) {
                                return !legendItem.text.includes("Average")
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Energy Consumption Mwh"
                        },
                        ticks:{}


                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        beginAtZero: true,
                        title: {
                            display: true,
                            text: "Energy Consumption Mwh"
                        },

                        // grid line settings
                        grid: {
                            drawOnChartArea: false, // only want the grid lines for one axis to show up
                        },
                        ticks:{
                            callback: function(value) {if (value % 1 === 0) {return value + "°C";}}
                        }
                    },

                }
            }
        }
        let datasets = meta.consumptionTypes.map((type,i)=>{
            console.log(data, type,Object.keys(timeValues))

            let consumptionData = Object.keys(timeValues).map(ts=>{
                if(data[type][ts] == undefined)
                    return undefined
                return data[type][ts].value
                }
            )
            config.options.scales[(i%2)?'y1':'y'].ticks.callback =  function(value) {if (value % 1 === 0) {return value + " "+data[type].measure_unit;}}
            config.options.scales[(i%2)?'y1':'y'].title.text = type

            console.log({consumptionData})
            return{
                label: type + " " + (isProductionArray[i]?"Production":"Consumption"),
                label_original: type,
                data:consumptionData,
                backgroundColor: getColorByType(type + " " + (isProductionArray[i]?"Production":"Consumption")),
                yAxisID: (i%2)?'y1':'y',
                borderColor: getColorByType(type + " " + (isProductionArray[i]?"Production":"Consumption")),
                borderWidth: 2,


            }
        })
        let ds = []
//        Object.assign(ds,datasets)
        ds = JSON.parse(JSON.stringify(datasets))
        /*
        datasets = Object.keys(timeValues).map((x,i)=>
            (datasets[i] == undefined)?
                0 :datasets[i]

        )*/
        datasets = datasets.concat(meta.consumptionTypes.map((type,i)=>{

            let consumptionData = Object.keys(timeValues).map(ts=>{
                    if(data[type][ts] == undefined)
                        return undefined
                    return data[type][ts].average
                }
            )
            //consumptionData = consumptionData.map(x=>x/12)
            return{
                label: type + " " + (isProductionArray[i]?"Production":"Consumption") + " Rolling Average",
                data:consumptionData,
                hidden: !this.showRollingAverage,
                backgroundColor: getColorByType(type + " " + (isProductionArray[i]?"Production":"Consumption")),
                yAxisID: (i%2)?'y1':'y',

                borderColor: getColorByType(type + " " + (isProductionArray[i]?"Production":"Consumption")),
                borderWidth: 1.5,
                borderDash: [5, 5],
                pointRadius: 0,

            }
        }))

        let chartData ={
            labels: Object.keys(timeValues),
            datasets: datasets
        }
        let emptyLength = 0;
        chartData.labels.every((l,i)=>{
            let empty = true;
            datasets.forEach(ds=>{
                if(ds.data[i]!=undefined && !isNaN(ds.data[i]))
                    empty = false;
                // else{
                // }
            })
            if(empty){
                emptyLength = i;
                return true;
            }
            else{
                return false;
            }
        })
        chartData.labels.splice(0,emptyLength)
        datasets.forEach(ds=>{
            ds.data.splice(0,emptyLength)

        })
        config.data = chartData
        if(document.getElementById(this.props.id+"line") === undefined || document.getElementById(this.props.id+"line") === null)
            return 0

        this[this.props.id+"ctx"] = document.getElementById(this.props.id+"line").getContext('2d');


        if(this[this.props.id+"trendline"] === undefined){
            this[this.props.id+"trendline"] = new Chart(this[this.props.id+"ctx"], config);
        }
        else{
            this[this.props.id+"trendline"].config._config =config
            this[this.props.id+"trendline"].update()
        }
    }
    componentWillUnmount() {
        console.log("unmounting")
        if(this[this.props.id+"trendline"])
            this[this.props.id+"trendline"].destroy()
    }
    content(){
        return <div id="" style={{"height":"440px"}} className={"w3-container"}>
            <div className="w3-row-padding">
                <div className={"w3-col"} style={{width:"70px"}}>
                    <p>Line 1:</p>
                </div>
                <div className="w3-quarter">
                    <div className="w3-container">



                        <select value={this.state.selectedConsumptionType1} onChange={(e)=>{this.setConsumptionType(1,e)}} className=""
                                id={this.props.id + "select1"}>
                            {this.state.consumptionTypes.map((ct,i)=>{
                                return<option  value={ct}>{ct}</option>
                            })}

                        </select>
                    </div>
                </div>
                <div className={"w3-col"} style={{width:"70px"}}>
                    <p>From:</p>
                </div>
                <div className="w3-quarter">
                    <div className="w3-container">
                        <input id={this.props.id + "dateselect1"}  type={"month"} onChange={(e)=>{this.setTimeframe("from",e)}} defaultValue={moment.utc(this.props.settings.selectedTimestamp).subtract(12,"months").startOf("month").format("YYYY-MM")}></input>
                    </div>
                </div>
            </div>
            <div className={"w3-row-padding"}>
                <div className={"w3-col"} style={{width:"70px"}}>
                    <p>Line 2:</p>
                </div>
                <div className="w3-quarter">
                    <div className="w3-container">

                        <select value={this.state.selectedConsumptionType2} onChange={(e)=>{this.setConsumptionType(2,e)}} className=""
                                id={this.props.id + "select2"}>
                            {this.state.consumptionTypes.map((ct,i)=>{
                                return<option  value={ct}>{ct}</option>
                            })}

                        </select>
                    </div>
                </div>
                <div className={"w3-col"} style={{width:"70px"}}>
                    <p>Until:</p>
                </div>
                <div className="w3-quarter">
                    <div className="w3-container">

                        <input id={this.props.id + "dateselect2"}  onChange={(e)=>{this.setTimeframe("until",e)}}  type={"month"}  defaultValue = {moment.utc(this.props.settings.selectedTimestamp).endOf("month").format("YYYY-MM")}></input>
                    </div>
                </div>

                <div className="w3-quarter">
                    <div className="w3-container">

                        <input className={""} onClick={this.rollingAvgCheck} defaultChecked={this.showRollingAverage}
                               type={"checkbox"} id={this.props.id + "rollingAvgCheck"}></input>
                        <label style={{marginLeft: "15px"}} htmlFor={this.props.id + "rollingAvgCheck"}> Rolling
                            average</label>
                    </div>
                </div>

            </div>

            <div style={{height:"300px", marginTop:"24px"}} className="w3-padding">
                <canvas id={this.props.id + "line"} style={{height:"210px", width:"700px"}}></canvas>
            </div>
        </div>

    }
    rollingAvgCheck(e){
        this.showRollingAverage = e.target.checked
        let chart = this[this.props.id+"trendline"]
        chart.data.datasets = chart.data.datasets.map(d=>{
            if(d.label.includes("Rolling Average")){
                d.hidden = !e.target.checked
            }
            return d
        })
        chart.update();
        //this.getTrendData()
    }


    getConsumptionTypes(){
        if(this.props.settings == undefined)
            return 0
        if(this.props.settings.buildings == undefined)
        return


        let d = {
            selectedBuildings: this.props.settings.buildings.map(x=>x.propertyId),
            timestamp:this.props.settings.selectedTimestamp,
            role:this.props.settings.role
        }
        this.api.esg.getConsumptionTypes.get(this.api.getCookie("jwt"),d).then(response=>{
            let types = response.data
            this.setState({consumptionTypes: types, selectedConsumptionType1: types[0],selectedConsumptionType2:types[1]})
        })
    }
    componentDidMount() {

        if(this.props.settings == undefined)
            return 0
        if(this.state.consumptionTypes.length === 0)
            this.getConsumptionTypes()
        this.getTrendData(this.props.settings,this.state.selectedConsumptionType1, this.state.selectedConsumptionType2)

    }
    componentWillUpdate(nextProps, nextState, nextContext) {
        console.log(nextProps, nextState)
        if (this.state.settings!== undefined)
            if(nextProps.settings == undefined)
            return 0
        if(this.state.consumptionTypes.length === 0)
            this.getConsumptionTypes()


        if (this.state.settings == undefined ||
            !sameObjectArrayById(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings) ||
            this.state.selectedConsumptionType1 !== nextState.selectedConsumptionType1 ||
            this.state.selectedConsumptionType2 !== nextState.selectedConsumptionType2||
            this.state.from !== nextState.from||
            this.state.until !== nextState.until
        ){
            this.setState( {settings:nextProps.settings})
            this.getTrendData(nextProps.settings,nextState.selectedConsumptionType1, nextState.selectedConsumptionType2)
        }

    }
}

export default ESGTrendLine;