import {DataTable, QualityPage, SelectContainer} from "./quality.styles";
import {useAPI} from "../../../../api/v2/apiV2";
import {useUserService} from "../../../../services/userService/UserServiceProvider";
import React, {useEffect, useState} from "react";
import moment from "moment/moment";
import Chart from "chart.js/auto";

function QualityMonitor(){

    const api = useAPI();
    const {activeRole} = useUserService();
    // const navigate = useNavigate();
    // const location = useLocation();

    const [options, setOptions] = useState({
        "deliveries":[],
        "types":[]
    });

    const [rawData, setRawData] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [activeTable, setActiveTable] = useState();

    /**
     * Returns the Filter-Settings
     * @returns {{date: string, types: *[], deliveries: *[]}}
     */
    const getSettings = () => {
        let deliveries = document.querySelectorAll("input[name=deliveries]").filter(x=>x.checked).map(x=>x.value)
        let types = document.querySelectorAll("input[name=types]").filter(x=>x.checked).map(x=>x.value)
        let dateElem = document.getElementById("date-setting")
        let date = moment().format("YYYYMM")
        if(dateElem !== null && dateElem !== undefined)
            date = dateElem.value

        return {
            deliveries:deliveries,
            types:types,
            date:date,
        }
        // return settings;
    }

    const loadSettings = (date) => {
        api.inspect.uploadedProperties({period: date, role: activeRole.role}).then((response) => {
            let data = response.message;

            const allTypes = data.map(x=>{return {label:x.type,value:x.type};}).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.label === value.label && t.value === value.value
                    ))
            )
            const allDeliveries = data.map(x=>{return {label:x.property.delivery.fond_name,value:x.property.delivery.id};}).filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        t.label === value.label && t.value === value.value
                    ))
            )

            setOptions({...options, deliveries: allDeliveries, types: allTypes});
            setRawData(response.message)

            if(mapData(response.message).filter(x=>x.mapped_statusOverdue === "OVERDUE").length > 0) {
                setActiveTable("OVERDUE");
            }else{
                setActiveTable("NOT_OVERDUE");
            }

            // drawAll(mapData(response.message))

        }).catch((error) => {
            console.error(error)
        })
    }

    const setSelectOptions = (id) => {

        if(options.deliveries.length === 0 && options.types.length === 0){
            return <></>
        }
        let option = options[id];
        let head = document.getElementById(id+"-select-container")

        let selectedLength = getSettings()[id].length;
        if(selectedLength === option.length){
            head.querySelector("span").innerText = "All";
        }else{
            head.querySelector("span").innerText = selectedLength+" selected";
        }

        return option.map(o => {
            return(
                <div style={{margin: "4px 2px 0px 2px"}}>
                    <input onChange={()=>{changedSelect(o)}} name = {id} type={"checkbox"} value={o.value} id={"check-"+o.value} defaultChecked={true} /> <label htmlFor={"check-"+o.value}>{o.label}</label>
                </div>
            )
        })
    }

    const changedSelect = (id) => {

        // console.log(id)
        // console.log(selectedData)
        // console.log(options)
        // console.log(getSettings())
        // console.log(rawData)
        // let data = getFilteredData(rawData)
        // console.log("FilteredData", data)

        drawAll(mapData(rawData));
        drawDataTable()
    }
    const changeDate = (elem) => {
        let chosenDate = elem.target.value;
        loadSettings(chosenDate.replace("-", ""))
    }

    /**
     * Maps Status and Category for Charts
     * @param data
     * @returns {*}
     */
    const mapData = (data) => {
        function map_statusOverdue(statusOverdue){
            if(statusOverdue === "NOT_OVERDUE")
                return "NOT_OVERDUE"
            if(statusOverdue === "OVERDUE" || statusOverdue === "OVERDUE_NOT_UPLOADED" || statusOverdue === "OVERDUE_IN_PROGRESS")
                return "OVERDUE"
            return statusOverdue
        }
        function map_statusCategory(statusCategory){
            if(statusCategory === "APPROVED" ||statusCategory === "APPROVED_BY_USER" ||statusCategory === "APPROVED_BY_SYSTEM" )
                return "APPROVED"
            if(statusCategory === "REJECTED" || statusCategory === "REJECTED_BY_SYSTEM" ||statusCategory === "REJECTED_BY_USER" )
                return "REJECTED"
            if(statusCategory === "NOT_UPLOADED")
                return "NOT_UPLOADED"
            if(statusCategory === "AWAIT_EXCEPTION_APPROVAL")
                return "IN_REVIEW"
            return statusCategory
        }
        return data.map(x=>{
            x.mapped_statusOverdue = map_statusOverdue(x.statusOverdue);
            x.mapped_statusCategory = map_statusCategory(x.status);
            return x
        })
    }

    const getFilteredData = (data) => {

        return data.filter(
            x => getSettings().deliveries.indexOf(x.property.delivery.id) >= 0)
            .filter(
                x => getSettings().types.indexOf(x.type) >= 0
            )
    }
    const drawAll = (data) => {
        data = getFilteredData(data);
        setSelectedData(data);

        if(data.length > 0){
            drawOverdueDonut(data)
            drawCategoryDonut(data)
        }
    }

    const drawOverdueDonut =  (data) => {
        const chartData = {
            labels: [
                'OVERDUE',
                'NOT_OVERDUE',
            ],
            datasets: [{
                label: 'Overdue Status',
                data: [data.filter(x => x.mapped_statusOverdue === "OVERDUE").length, data.filter(x => x.mapped_statusOverdue === "NOT_OVERDUE").length],
                backgroundColor: [
                    'rgb(208, 50, 64)',
                    'rgb(29, 157, 97)'
                ],
                hoverOffset: 4
            }]
        };
        const config = {
            type: 'doughnut',
            data: chartData,
            options:{
                cutout: "60%",
                plugins:{
                    legend:{
                        position:"bottom"
                    }
                },
                onClick:(evt)=>{
                    const points = window.overdueDonut.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

                    if (points.length) {
                        const firstPoint = points[0];
                        const label = window.overdueDonut.data.labels[firstPoint.index];
                        // const value = window.overdueDonut.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
                        // console.log(label)
                        setActiveTable(label)
                    }
                }
            }
        };

        let ctx = document.getElementById('overdueDonutCanvas').getContext('2d');
        if(window.overdueDonut !== undefined)
            window.overdueDonut.destroy();
        // console.log(config)
        window.overdueDonut = new Chart(ctx, config);

    }

    const drawCategoryDonut = (data) => {
        const chartData = {
            labels: [
                'REJECTED',
                'IN_REVIEW',
                'NOT_UPLOADED',
                'APPROVED'
            ],
            datasets: [{
                label: 'Overdue Status',
                data: [
                    data.filter(x => x.mapped_statusCategory === "REJECTED").length,
                    data.filter(x => x.mapped_statusCategory === "IN_REVIEW").length,
                    data.filter(x => x.mapped_statusCategory === "NOT_UPLOADED").length,
                    data.filter(x => x.mapped_statusCategory === "APPROVED").length
                ],
                backgroundColor: [
                    'rgb(208, 50, 64)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                    'rgb(29, 157, 97)'
                ],
                hoverOffset: 4
            }]
        };
        const config = {
            type: 'doughnut',
            data: chartData,
            options:{
                cutout: "60%",
                plugins:{
                    legend:{
                        position:"bottom"
                    }
                },
                onClick:(evt)=>{
                    const points = window.categoryDonut.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

                    if (points.length) {
                        const firstPoint = points[0];
                        const label = window.categoryDonut.data.labels[firstPoint.index];
                        // const value = window.categoryDonut.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
                        // console.log(label)
                        setActiveTable(label)
                    }
                }
            }
        };

        let ctx = document.getElementById('categoryDonutCanvas').getContext('2d');
        if(window.categoryDonut !== undefined)
            window.categoryDonut.destroy();
        // console.log(config)
        window.categoryDonut = new Chart(ctx, config);
    }

    const calculateDaysOverdue = (delivery_deadline, dateOfApproval) => {

        if(dateOfApproval){
            let deadline = new Date(delivery_deadline);
            return moment(dateOfApproval).diff(deadline, 'days')
        }else{
            let deadline = new Date(delivery_deadline);
            let now = new Date();
            return moment(now).diff(deadline, 'days')
        }

    }

    const drawDataTable = () => {
        if(activeTable === undefined)
            return <div></div>
        let data = mapData(getFilteredData(rawData))

        function getCategoryTypeIcon (type){

            switch(type){
                case "RENTROLL": return (<i className="fas fa-home"></i>)
                case "FINANCIAL": return (<i className="fas fa-coins"></i>)
                case "ESG": return (<i className="fas fa-leaf"></i>)
            }
        }
        switch (activeTable) {
            case "OVERDUE":
                data = data.filter(x=>x.mapped_statusOverdue === "OVERDUE")
                break;
            case "NOT_OVERDUE":
                data = data.filter(x=>x.mapped_statusOverdue === "NOT_OVERDUE")
                break;
            case "REJECTED":
                data = data.filter(x=>x.mapped_statusCategory === "REJECTED")
                break;
            case "IN_REVIEW":
                data = data.filter(x=>x.mapped_statusCategory === "IN_REVIEW")
                break;
            case "NOT_UPLOADED":
                data = data.filter(x=>x.mapped_statusCategory === "NOT_UPLOADED")
                break;
            case "APPROVED":
                data = data.filter(x=>x.mapped_statusCategory === "APPROVED")
                break;

        }

        //
        let overdueTable = <DataTable>
            <table className={"w3-table w3-striped w3-bordered"}>
                <tr>
                    <th colSpan={7} className={"w3-center"}><b>PROPERTIES OVERDUE</b></th>
                </tr>
                <tr>
                    <th><b>Type</b></th>
                    <th><b>Property</b></th>
                    <th><b>Status</b></th>
                    <th><b>Last Upload</b></th>
                    <th><b>Delivery Deadline</b></th>
                    <th><b>Days Overdue</b></th>
                    {/*<th><b>Action</b></th>*/}
                </tr>
                {data.map(x=>{
                    let lastUploadText = ""
                    if(x.history_of_property.length>0 && x.history_of_property[x.history_of_property.length-1].reportUploadCommunication!==null){
                        let historyDate = moment(x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.timestamp)
                        lastUploadText = <div><a title={ moment().diff(historyDate,'days')+" days ago"}>{historyDate.format("YYYY/MM/DD")}</a> by user {x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.user.email}</div>
                    }
                    let deadlineDays = ""
                    let deadlineText = ""
                    if(x.delivery_deadline !== undefined || x.delivery_deadline !== null){
                        deadlineText = moment(x.delivery_deadline).format("YYYY/MM/DD")
                        deadlineDays = (x.mapped_statusCategory === "APPROVED")
                            ? calculateDaysOverdue(x.delivery_deadline, x.history_of_property[x.history_of_property.length-1].timestamp)
                            : deadlineDays = moment().diff(moment(x.delivery_deadline),'days')

                    }
                    // console.log("overdue table inner ", x)
                    return <tr>
                        <td>{getCategoryTypeIcon(x.type)} {x.type}</td>
                        <td>{x.property.property_name} ({x.property.property_id_sender})</td>
                        <td>{x.mapped_statusCategory}</td>
                        <td>{lastUploadText}</td>
                        <td>{deadlineText}</td>
                        <td>{deadlineDays}</td>
                        {/*<td><a style={{textDecoration: "underline"}} onClick={() => {navigate("/dashboard/inspect")}}>Last Error Report</a> / <a style={{textDecoration: "underline"}}>Review (AM)</a></td>*/}
                    </tr>
                })}
            </table>
        </DataTable>

        let notOverdueTable = <DataTable>
            <table className={"w3-table w3-striped w3-bordered"}>
                <tr>
                    <th colSpan={6} className={"w3-center"}><b>PROPERTIES NOT OVERDUE</b></th>
                </tr>
                <tr>
                    <th><b>Type</b></th>
                    <th><b>Property</b></th>
                    <th><b>Status</b></th>
                    <th><b>Last Upload</b></th>
                    <th><b>Delivery Deadline</b></th>
                    {/*<th><b>Action</b></th>*/}
                </tr>
                {data.map(x=>{
                    let lastUploadText = ""
                    if(x.history_of_property.length>0 && x.history_of_property[x.history_of_property.length-1].reportUploadCommunication!==null){
                        let historyDate = moment(x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.timestamp)
                        lastUploadText = <div><a title={ moment().diff(historyDate,'days')+" days ago"}>{historyDate.format("YYYY/MM/DD")}</a> by user {x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.user.email}</div>
                    }
                    // let deadlineDays = ""
                    let deadlineText = ""
                    if(x.delivery_deadline !== undefined || x.delivery_deadline !== null){
                        deadlineText = moment(x.delivery_deadline).format("YYYY/MM/DD")
                        // deadlineDays = moment().diff(moment(x.delivery_deadline),'days')
                    }
                    return <tr>
                        <td>{getCategoryTypeIcon(x.type)} {x.type}</td>
                        <td>{x.property.property_name} ({x.property.property_id_sender})</td>
                        <td>{x.mapped_statusCategory}</td>
                        <td>{lastUploadText}</td>
                        <td>{deadlineText}</td>
                        {/*<td><a style={{textDecoration: "underline"}} onClick={() => {navigate("/dashboard/inspect")}}>Last Error Report</a> / <a style={{textDecoration: "underline"}}>Review (AM)</a></td>*/}
                    </tr>
                })}
            </table>
        </DataTable>

        let rejectedTable = <DataTable>
            <table className={"w3-table w3-striped w3-bordered"}>
                <tr>
                    <th colSpan={6} className={"w3-center"}><b>PROPERTIES IN STATUS REJECTED</b></th>
                </tr>
                <tr>
                    <th><b>Type</b></th>
                    <th><b>Property</b></th>
                    <th><b>Status</b></th>
                    <th><b>Delivery Deadline</b></th>
                    <th><b>Overdue Status</b></th>
                    {/*<th><b>Action</b></th>*/}
                    <th><b>Undo</b></th>
                </tr>
                {data.map(x=>{
                    let lastUploadText = ""
                    if(x.history_of_property.length>0 && x.history_of_property[x.history_of_property.length-1].reportUploadCommunication!==null){
                        let historyDate = moment(x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.timestamp)
                        lastUploadText = <div><a title={ moment().diff(historyDate,'days')+" days ago"}>{historyDate.format("YYYY/MM/DD")}</a> by user {x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.user.email}</div>
                    }
                    let deadlineDays = ""
                    let deadlineText = ""
                    if(x.delivery_deadline !== undefined || x.delivery_deadline !== null){
                        deadlineText = moment(x.delivery_deadline).format("YYYY/MM/DD")
                        deadlineDays = moment().diff(moment(x.delivery_deadline),'days')

                    }
                    return <tr>
                        <td>{getCategoryTypeIcon(x.type)} {x.type}</td>
                        <td>{x.property.property_name} ({x.property.property_id_sender})</td>
                        <td>{lastUploadText}</td>
                        <td>{deadlineText}</td>
                        <td>{x.mapped_statusOverdue === "NOT_OVERDUE"?"On Time": deadlineDays+" days late"}</td>
                        {/*<td><a style={{textDecoration: "underline"}} onClick={() => {navigate("/dashboard/inspect")}}>Last Error Report</a> / <a style={{textDecoration: "underline"}}>Review (AM)</a></td>*/}
                        <td>undo</td>
                    </tr>
                })}
            </table>
        </DataTable>

        let inReviewTable = <DataTable>
            <table className={"w3-table w3-striped w3-bordered"}>
                <tr>
                    <th colSpan={6} className={"w3-center"}><b>PROPERTIES IN STATUS IN REVIEW</b></th>
                </tr>
                <tr>
                    <th><b>Type</b></th>
                    <th><b>Property</b></th>
                    <th><b>Status</b></th>
                    <th><b>In Review since</b></th>
                    <th><b>Delivery Deadline</b></th>
                    <th><b>Overdue Status</b></th>
                    {/*<th><b>Action</b></th>*/}
                </tr>
                {data.map(x=>{
                    let lastUploadText = ""
                    let inReviewSinceText = ""
                    if(x.history_of_property.length>0 && x.history_of_property[x.history_of_property.length-1].reportUploadCommunication!==null){
                        inReviewSinceText = moment(x.history_of_property[x.history_of_property.length-1].timestamp).format("YYYY/MM/DD")
                        let historyDate = moment(x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.timestamp)
                        lastUploadText = <div><a title={ moment().diff(historyDate,'days')+" days ago"}>{historyDate.format("YYYY/MM/DD")}</a> by user {x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.user.email}</div>
                    }
                    let deadlineDays = ""
                    let deadlineText = ""
                    if(x.delivery_deadline !== undefined || x.delivery_deadline !== null){
                        deadlineText = moment(x.delivery_deadline).format("YYYY/MM/DD")
                        deadlineDays = moment().diff(moment(x.delivery_deadline),'days')

                    }
                    return <tr>
                        <td>{getCategoryTypeIcon(x.type)} {x.type}</td>
                        <td>{x.property.property_name} ({x.property.property_id_sender})</td>
                        <td>{lastUploadText}</td>
                        <td>{inReviewSinceText}</td>
                        <td>{deadlineText}</td>
                        <td>{x.mapped_statusOverdue === "NOT_OVERDUE"?"On Time": deadlineDays+" days late"}</td>
                        {/*<td><a style={{textDecoration: "underline"}} onClick={() => {navigate("/dashboard/inspect")}}>Last Error Report</a> / <a style={{textDecoration: "underline"}}>Review (AM)</a></td>*/}
                    </tr>
                })}
            </table>
        </DataTable>

        let notUploadedTable = <DataTable>
            <table className={"w3-table w3-striped w3-bordered"}>
                <tr>
                    <th colSpan={6} className={"w3-center"}><b>PROPERTIES IN STATUS NOT UPLOADED</b></th>
                </tr>
                <tr>
                    <th><b>Type</b></th>
                    <th><b>Property</b></th>
                    <th><b>Deadline</b></th>
                    <th><b>Overdue Status</b></th>
                    {/*<th><b>Action</b></th>*/}
                </tr>
                {data.map(x=>{
                    // let lastUploadText = ""
                    // if(x.history_of_property.length>0 && x.history_of_property[x.history_of_property.length-1].reportUploadCommunication!==null){
                    //     let historyDate = moment(x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.timestamp)
                    //     lastUploadText = <div><a title={ moment().diff(historyDate,'days')+" days ago"}>{historyDate.format("YYYY/MM/DD")}</a> by user {x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.user.email}</div>
                    // }
                    let deadlineDays = ""
                    let deadlineText = ""
                    if(x.delivery_deadline !== undefined || x.delivery_deadline !== null){
                        deadlineText = moment(x.delivery_deadline).format("YYYY/MM/DD")
                        deadlineDays = moment().diff(moment(x.delivery_deadline),'days')

                    }
                    return <tr>
                        <td>{getCategoryTypeIcon(x.type)} {x.type}</td>
                        <td>{x.property.property_name} ({x.property.property_id_sender})</td>
                        <td>{deadlineText}</td>
                        <td>{x.mapped_statusOverdue === "NOT_OVERDUE"?"On Time": deadlineDays+" days late"}</td>
                        {/*<td><a style={{textDecoration: "underline"}} onClick={() => {navigate("/dashboard/inspect")}}>Last Error Report</a> / <a style={{textDecoration: "underline"}}>Review (AM)</a></td>*/}
                    </tr>
                })}
            </table>
        </DataTable>

        //
        let approvedTable = <DataTable>
            <table className={"w3-table w3-striped w3-bordered"}>
                <tr>
                    <th colSpan={6} className={"w3-center"}><b>PROPERTIES IN STATUS APPROVED</b></th>
                </tr>
                <tr>
                    <th><b>Type</b></th>
                    <th><b>Property</b></th>
                    <th><b>Status</b></th>
                    <th><b>Overdue Status</b></th>
                    {/*<th><b>Action</b></th>*/}
                </tr>
                {data.map(x=>{
                    let lastUploadText = ""
                    if(x.history_of_property.length>0 && x.history_of_property[x.history_of_property.length-1].reportUploadCommunication!==null){
                        let historyDate = moment(x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.timestamp)
                        lastUploadText = <div><a title={ moment().diff(historyDate,'days')+" days ago"}>{historyDate.format("YYYY/MM/DD")}</a> by user {x.history_of_property[x.history_of_property.length-1].reportUploadCommunication.user.email}</div>
                    }
                    let deadlineDays = ""
                    // let deadlineText = ""
                    if(x.delivery_deadline !== undefined || x.delivery_deadline !== null){
                        // deadlineText = moment(x.delivery_deadline).format("YYYY/MM/DD")
                        // deadlineDays = moment().diff(moment(x.delivery_deadline),'days')
                        deadlineDays = calculateDaysOverdue(x.delivery_deadline, x.history_of_property[x.history_of_property.length-1].timestamp)

                    }
                    return <tr>
                        <td>{getCategoryTypeIcon(x.type)} {x.type}</td>
                        <td>{x.property.property_name} ({x.property.property_id_sender})</td>
                        <td>{lastUploadText}</td>
                        <td>{x.mapped_statusOverdue === "NOT_OVERDUE"?"On Time": deadlineDays+" days late"}</td>
                        {/*<td><a style={{textDecoration: "underline"}} onClick={() => {navigate("/dashboard/inspect")}} >Last Error Report</a> / <a style={{textDecoration: "underline"}}>Review (AM)</a></td>*/}
                    </tr>
                })}
            </table>
        </DataTable>


        switch (activeTable) {
            case "OVERDUE":
                return overdueTable;
            case "NOT_OVERDUE":
                return notOverdueTable;

            case "REJECTED":
                return rejectedTable;
            case "IN_REVIEW":
                return inReviewTable;
            case "NOT_UPLOADED":
                return notUploadedTable;
            case "APPROVED":
                return approvedTable;

        }
        return <div></div>

    }

    const dataDashboard = () => {
        return(
            <div className={"w3-row-padding"}>
                <div className={"w3-col w3-center"}  style={{"padding":"16px 32px", maxWidth:" 350px"}}>
                    <h2>Overdue</h2>
                    <hr/>
                    <div style={{maxWidth:"260px", "margin":"auto"}}><canvas id={"overdueDonutCanvas"}></canvas></div>

                    <h2>Categories</h2>
                    <hr/>
                    <div style={{maxWidth:"450px", "margin":"auto"}}><canvas id={"categoryDonutCanvas"}></canvas></div>

                </div>
                <div className = "w3-rest">
                    {drawDataTable()}
                </div>
            </div>
        )
    }

    /**
     * Initial call
     */
    useEffect(() => {
        loadSettings(getSettings().date.replace("-", ""));
    }, [])

    useEffect(() => {

        if(options.deliveries.length !== 0 && options.types.length !== 0){
            drawAll(mapData(rawData))
        }

    }, [options])

    // useEffect(() => {
    //
    //     console.log("RawData changed", rawData)
    //
    // }, [rawData])

    let dataAvailable = getFilteredData(rawData).length > 0;
    let dataSelected = selectedData.length > 0;
    let showDataAvailable = dataAvailable && dataSelected;

    return(
        <QualityPage>
            <div className={"w3-bar"} style={{"backgroundColor": "#b0bcc7"}}>

                <div className={"w3-bar-item w3-text-white"} style={{marginRight: "16px"}}>
                    <i className="fas fa-filter"></i>
                </div>
                <div className={"w3-bar-item w3-text-white"}>
                    Period
                </div>
                <div className={"w3-bar-item"} style={{padding: "6px 16px"}}>
                    <input onChange={changeDate} id={"date-setting"} type={"month"} max={moment().format("YYYY-MM")}
                           defaultValue={moment().format("YYYY-MM")}></input>
                </div>

                <div className={"w3-bar-item w3-text-white"}>
                    Data Type
                </div>

                <div className={"w3-bar-item"} style={{padding: "6px 16px"}}>
                    <div className="w3-dropdown-hover">

                        <SelectContainer id={"types-select-container"}>
                            <span></span>
                            <i className={"fas fa-caret-down"}></i>
                        </SelectContainer>
                        <div id={"types-select"} className="w3-dropdown-content w3-left-align w3-bar-block w3-border"
                             style={{"padding": "4px"}}>
                            {
                                setSelectOptions("types")
                            }

                        </div>
                    </div>
                </div>

                <div className={"w3-bar-item w3-text-white"}>
                    Data Delivery Company
                </div>
                <div className={"w3-bar-item"} style={{padding: "6px 16px"}}>
                    <div className="w3-dropdown-hover">

                        <SelectContainer id={"deliveries-select-container"}>
                            <span></span>
                            <i className={"fas fa-caret-down"}></i>
                        </SelectContainer>
                        <div id={"deliveries-select"}
                             className="w3-dropdown-content w3-left-align w3-bar-block w3-border"
                             style={{"padding": "4px"}}>
                            {setSelectOptions("deliveries")}

                        </div>
                    </div>
                </div>

            </div>
            <div
                style={{paddingTop: "20vh", display: (!showDataAvailable ? "block" : "none")}}
                className={"w3-container w3-center"}
            >
                <p>{(dataAvailable && dataSelected ? "No Data selected" : "No Data available")}</p>
            </div>
            <div
                style={{display: (showDataAvailable ? "block" : "none")}}
            >
                {dataDashboard()}
            </div>

        </QualityPage>
    )

}

export default QualityMonitor;