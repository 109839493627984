import {useAPI} from "../../../../api/v2/apiV2";
import {useUserService} from "../../../../services/userService/UserServiceProvider";
import {Button, TableCell, TableRow} from "@mui/material";
import {renderErrorTypeIcon} from "../../inspect_report/ErrorReport";
import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import {ExceptionActionButtons} from "../styles/PropertiesOverview.styles";
import Modal from "../../../modal/Modal";

/**
 * ExceptionContent
 */
function ExceptionContent(props){

    const EXCEPTION_STATUS = {
        PENDING:  "PENDING",
        ACCEPTED: "ACCEPTED",
        REJECTED: "REJECTED",
    }
    const ERROR_STATUS = {
        APPROVE: "APPROVE",
        REJECT: "REJECT",
        REJECT_WITH_EXCEPTION: "REJECT_WITH_EXCEPTION"
    }

    const api = useAPI();
    const {activeRole} = useUserService();

    const [showExceptionModal, setShowExceptionModal] = useState(false);

    const error = props.error; //When using useState got problems with Pagination
    const [exceptionNoteAM, setExceptionNoteAM] = useState( "")

    useMemo(() => {
        // console.log("Initial", error.error_id, error.exception.exception_status)
        // console.log("Note AM: ", props.error.exception.exception_note_am)
        // console.log("Note PM: ", props.error.exception.exception_note_pm)
        setExceptionNoteAM(props.error.exception.exception_note_am ? props.error.exception.exception_note_am : "")
    }, [props.error])

    /**
     * APPROVE, REJECT, REJECT_WITH_EXCEPTION
     * @param status
     */
    const handleErrorAction = (status) => {
        if(status === ERROR_STATUS.APPROVE){
            approveException(error);
        }else if(status === ERROR_STATUS.REJECT_WITH_EXCEPTION){
            rejectException(error);
        }else if(status === ERROR_STATUS.REJECT){
            //console.log("Update Rejected")
            setShowExceptionModal(true);
        }else{
            console.error("Error on Error-Action: Non ErrorStatus");
        }
    }

    /**
     * Helper-Function to create a ExceptionObject for BusinessLogic-Model of Exception
     */
    const getExceptionObject = (error, exceptionNoteAM) => {
        return {
            property_id: error.property.id,
            property_type: error.property.type,
            property_period: error.property.period,
            property_status: error.property.status,
            exception_status: error.exception.exception_status,
            error_id: error.error_id,
            exception_note_am: exceptionNoteAM
        }
    }

    /**
     * Calls API ApproveException
     * @param error
     */
    const approveException = (error) => {
        let exceptionObject = getExceptionObject(error, "approved");
        //console.log(exceptionObject)
        api.exceptions.approveException(exceptionObject).then((response) => {
            if(response.status === 200){
                props.updateError(response.message)
            }
        })
    }

    /**
     * Calls API RejectException
     * @param error
     */
    const rejectException = (error) => {
        let exceptionObject = getExceptionObject(error, exceptionNoteAM);
        api.exceptions.rejectException(exceptionObject).then((response) => {
            if(response.status === 200){
                setShowExceptionModal(false);
                props.updateError(response.message)
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    const renderExceptionActionButtons = (exception_status) => {
        // console.log("Exception_Status", exception_status)
        if(exception_status === EXCEPTION_STATUS.PENDING){
            return(
                <ExceptionActionButtons>
                    <button className="approve exception" onClick={() => handleErrorAction(ERROR_STATUS.APPROVE)}>
                        <i className="fas fa-check-square fa-2x" aria-hidden="true"></i>
                    </button>
                    <button className="reject exception" onClick={() => handleErrorAction(ERROR_STATUS.REJECT)}>
                        <i className="fas fa-window-close fa-2x" aria-hidden="true"></i>
                    </button>
                </ExceptionActionButtons>
            )
        }else if(exception_status === EXCEPTION_STATUS.ACCEPTED){
            return(
                <ExceptionActionButtons>
                    <button className="approve exception">
                        <i className="fas fa-check-square fa-2x" aria-hidden="true"></i>
                    </button>
                    <button className="reject exception disable" onClick={() => handleErrorAction(ERROR_STATUS.REJECT)}>
                        <i className="fas fa-window-close fa-2x" aria-hidden="true"></i>
                    </button>
                </ExceptionActionButtons>
            )
        }else if(exception_status === EXCEPTION_STATUS.REJECTED){
            return(
                <ExceptionActionButtons>
                    <button className="approve exception disable" onClick={() => window.confirm("Do you really want to approve this exception?") ? handleErrorAction(ERROR_STATUS.APPROVE) : ""}>
                        <i className="fas fa-check-square fa-2x" aria-hidden="true"></i>
                    </button>
                    <button className="reject exception" onClick={() => handleErrorAction(ERROR_STATUS.REJECT)}>
                        <i className="fas fa-window-close fa-2x" aria-hidden="true"></i>
                    </button>
                </ExceptionActionButtons>
            )
        }
    }

    const setExceptionMessage = (textarea) => {
        // let exception = Object.assign({}, modifiedError.exception);
        // exception.exception_note_am = textarea.target.value;
        // setModifiedError({...modifiedError, exception: exception});
        setExceptionNoteAM(textarea.target.value)
    }

    // console.log(error.exception.exception_note_am)

    return(
        <TableRow key={error.id}>
            <TableCell component="th" scope="row" align="left" style={{width: 50}}>
                {renderErrorTypeIcon(error.error_type)}
            </TableCell>
            <TableCell>{error.table_name}</TableCell>
            <TableCell>{error.field_name}</TableCell>
            <TableCell>{error.line_number}</TableCell>
            <TableCell>{error.error_description}</TableCell>
            <TableCell>{error.exception.exception_writer_pm.email}</TableCell>
            <TableCell>{error.exception.exception_note_pm}</TableCell>
            {
                (activeRole.role === "AM")
                    ?
                    <TableCell>
                        {
                            renderExceptionActionButtons(error.exception.exception_status)
                        }
                    </TableCell>
                    :
                    <TableCell/>
            }

            <TableCell style={{padding: 0}}>
                <Modal
                    show={showExceptionModal}
                    handleClose={() => setShowExceptionModal(!showExceptionModal)}
                    showCloseButton={true}
                    headerText="Exception-Note for Property Manager"
                    customStyle={"customModal"}
                >
                    <div className="exceptionModal">
                        <label>Exception ID</label>     <span>{error.error_id}</span>
                        <label>Error type</label>       {renderErrorTypeIcon(error.error_type)}
                        <label>Table</label>            <span>{error.table_name}</span>
                        <label>Field</label>            <span>{error.field_name}</span>
                        <label>Line</label>             <span>{error.line_number}</span>
                        <label>Description</label>      <span style={{color: "red"}}>{error.error_description}</span>
                        <label>Table</label>            <span>{error.table_name}</span>
                        <label>Exception Note</label>   <span>{error.exception.exception_note_pm}</span>
                        {/*<label>Current Status</label>   <span>{(error.exception.exception_status === "ACCEPTED") ? "ACCEPTED -> REJECTED": "REJECTED -> ACCEPTED"}</span>*/}
                        <textarea
                            placeholder="Note for the PM why the error is being rejected"
                            name="exceptionMessage"
                            onChange={setExceptionMessage}
                            value={exceptionNoteAM}
                        />

                        {
                            (error.exception.exception_status === EXCEPTION_STATUS.PENDING)
                                ?
                                <button className="reject" onClick={() => handleErrorAction(ERROR_STATUS.REJECT_WITH_EXCEPTION) }>
                                    Reject
                                </button>
                                :
                                <button className="reject" onClick={() => handleErrorAction(ERROR_STATUS.REJECT_WITH_EXCEPTION) }>
                                    Update Exception
                                </button>

                        }

                    </div>
                </Modal>
            </TableCell>

        </TableRow>
    )
}

ExceptionContent.propType = {
    error: PropTypes.object
}

export default ExceptionContent;