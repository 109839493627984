import React from 'react';
import styled, {useTheme} from "styled-components";

import "./Sidebar.css";

import {Link, useLocation, useNavigate, withRouter} from "react-router-dom";
import {ItemTitles} from "../../SidebarItemsConfig";
import {LogoFooter, NavItemBottom, SideBarDiv} from "./Sidebar.styles";
import {useAPI} from "../../../api/v2/apiV2";
import {useUserService} from "../../../services/userService/UserServiceProvider";



function Sidebar(props){

    const navigate = useNavigate();
    const location = useLocation();

    const theme = useTheme();
    const api = useAPI();
    const userService = useUserService();

    // console.log(props)

    // console.log("SIDEBAR", props)
    return (
        <SideNav
            navigate={navigate}
            location={location}
            selectedRole={userService.activeRole}
            roleColor={theme.palette.primary}
            menuItems={props.menuItems}
            logout={api.logout}
        ></SideNav>
    )
}

class SideNav extends React.Component {
    constructor(props) {
        super(props);

        // console.log(props, props.location)

        this.state = {

            logout: this.props.logout,

            selectedRole: this.props.selectedRole,
            roleColor: this.props.roleColor,
            activePath: props.location.pathname,

            menuItems: this.props.menuItems,
        }

        // console.log(this.state)



    }

    componentDidMount() {
        if(this.props.selectedRole.role === "PM"){
            this.onItemClick("upload")
        }
        else if(this.props.selectedRole.role === "AM" || this.props.selectedRole.role === "ADMIN"){
            this.onItemClick("masterdata")
        }
    }

    onItemClick = (path) => {
        this.setState({ activePath: path }); /* Sets activePath which causes rerender which causes CSS to change */
    }

    onImageClick = () => {
        // window.open("https://www.ementalist.ai")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("ShouldUpdate", prevProps.menuItems, this.props.menuItems, prevState.menuItems);
        // if(prevState.menuItems !== this.props.menuItems){
        //     this.setState({
        //         menuitems: this.props.menuItems,
        //         roleColor: this.props.roleColor
        //     })
        //     if(this.props.selectedRole.role === "PM"){
        //         this.onItemClick("/upload")
        //         // this.props.history.push("/upload")
        //         this.props.navigate("upload");
        //     }
        //     else if(this.props.selectedRole.role === "AM" || this.props.selectedRole.role === "ADMIN"){
        //         this.onItemClick("/masterdata")
        //         // this.props.history.push("/masterdata")
        //         this.props.navigate("masterdata");
        //     }
        //
        // }

        //TODO: onItemClick is used to set the current page in the Sidebar but should consider to do somewhere else
        // console.log(prevProps, prevState, snapshot)
        if(prevState.selectedRole !== prevProps.selectedRole){
            this.setState({selectedRole: prevProps.selectedRole})
            if(this.props.selectedRole.role === "PM"){
                this.onItemClick("upload")
                // this.props.history.push("/upload")
                this.props.navigate("upload");
            }
            else if(this.props.selectedRole.role === "AM" || this.props.selectedRole.role === "ADMIN"){
                this.onItemClick("masterdata")
                // this.props.history.push("/masterdata")
                this.props.navigate("masterdata");
            }else if(this.props.selectedRole.role === "API"){
                this.onItemClick("keys")
                this.props.navigate("keys")
            }
        }

    }

    renderNavItem = {

        sideBarNavItem_Dashboard: (menuitem, key, activePath) => {
            return(
                <React.Fragment key={key}>
                    {
                        (menuitem.items.length > 0 && menuitem.heading !== ItemTitles.LOGOUT)
                            ?
                            <div className="title-hr" style={{textAlign: "center" }}>
                                <hr className="floatLeft"/>
                                <span>{menuitem.heading}</span>
                                <hr className="floatRight"/>
                            </div>
                            :
                            <></>
                    }

                    {
                        menuitem.items.map((item, key) => {

                            if(item.path === "/logout") {
                                return (
                                    <NavItem path={item.path} name={item.name} css={item.css}
                                             icon={item.icon} onItemClick={this.state.logout}
                                             active={item.path === activePath} key={key}/>
                                )
                            }else{
                                return  (
                                    <NavItem path={item.path} name={item.name} css={item.css}
                                             icon={item.icon} onItemClick={this.onItemClick}
                                             active={item.path === activePath} key={key}/>
                                )
                            }

                        })
                    }

                </React.Fragment>
            )
        },

        //For Admin-Purpose
        adminNavItem : (menuitem, itemKey, activePath) => {

            if(menuitem.items.length > 0){
                return (

                    // <NavItemBottom key={itemKey}>
                    <div key={itemKey}>
                        <div className="title-hr" style={{textAlign: "center" }}>
                            <hr className="floatLeft"></hr>
                            <span>{menuitem.heading}</span>
                            <hr className="floatRight"></hr>
                        </div>
                        {
                            menuitem.items.map((item, key) => {
                                return  (
                                    <div key={itemKey+key}>
                                        <NavItem path={item.path} name={item.name} css={item.css} icon={item.icon} onItemClick={this.onItemClick} active={item.path === activePath} key={itemKey+key}/>
                                    </div>
                                )
                            })
                        }
                    </div>
                    // </NavItemBottom>
                )
            }else{
                return <></>
            }


        },
        //Default SidebarNavItem
        sideBarNavItem : (menuitem, itemKey, activePath) => {
            return(
                <React.Fragment key={itemKey}>
                    <div className="title-hr" style={{textAlign: "center" }}>
                        <hr className="floatLeft"></hr>
                        <span>{menuitem.heading}</span>
                        <hr className="floatRight"></hr>
                    </div>
                    {
                        menuitem.items.map((item, key) => {
                            if(item.path === "/logout") {
                                return (
                                    <NavItem path={item.path} name={item.name} css={item.css}
                                             icon={item.icon} onItemClick={this.state.logout}
                                             active={item.path === activePath} key={key}/>
                                )
                            }else{
                                return  (
                                    <NavItem path={item.path} name={item.name} css={item.css} icon={item.icon} onItemClick={this.onItemClick} active={item.path === activePath} key={key}/>
                                )
                            }

                        })
                    }
                </React.Fragment>
            )
        }
    }

    render() {
        const { activePath } = this.state;
        const { menuItems } = this.props;

        let logoutItem = Object.values(menuItems).filter(item => item.heading == ItemTitles.LOGOUT)[0];
        return (
            <SideBarDiv className="sidebar" style={{backgroundColor: this.props.roleColor}}>

                <img src="/images/newLogoColored.png" className="center" alt="Logo" onClick={this.onImageClick}/>

                {
                    Object.keys(menuItems).map((sideItem, key) => {
                        if(menuItems[sideItem].heading == ItemTitles.LOGOUT)
                            return;
                        if(sideItem === "administration"){
                            return(this.renderNavItem.adminNavItem(menuItems[sideItem], key, activePath));
                        }else{
                            return(this.renderNavItem.sideBarNavItem_Dashboard(menuItems[sideItem], key, activePath))
                        }
                    })
                }
                <NavItemBottom>
                    <NavItem
                        path={logoutItem.items[0].path}
                        name={logoutItem.items[0].name}
                        css={logoutItem.items[0].css}
                        icon={logoutItem.items[0].icon}
                        onItemClick={this.state.logout}
                        active={logoutItem.items[0].path === activePath}/>
                    <LogoFooter>
                        <span>Powered by</span>
                        <a href="src/components/dashboard/global/sidebar/Sidebar" id="logo_link">
                            <img src="/images/logo.png" id="logo_img" alt="eMentalist_Logo"/>
                        </a>
                    </LogoFooter>
                </NavItemBottom>
            </SideBarDiv>
        );
    }
}
class NavItem extends React.Component {

    handleClick = () => {
        const { path, onItemClick } = this.props;
        onItemClick(path);
    }
    render() {
        const { active } = this.props;
        return (
            <React.Fragment>
                <Link active={+active} className={this.props.css}  onClick={this.handleClick} to={this.props.path}>
                    <i className={this.props.icon}></i>
                    <span style={{paddingLeft: 10}}>{this.props.name}</span>
                </Link>
            </React.Fragment>
        );
    }
}

// const RouterSideNav = withRouter(SideNav);
// const RouterSideNav = withRouter(SideNav);


export default Sidebar;