import React,{Component} from "react";
import API from "../../../api/api";

import moment from 'moment';
import styled from "styled-components";
import TemplateDiv from "../StyledPages";
import "../../global/css/ESG.css";
import ESGConsumptionTile from "./components/ESGConsumptionTile";
import {sameObjectArrayById} from "./functions";




const resourceSettings = {
    "Electricity":{
        "color":"#536878",
        "icon": "fa fa-bolt",
        "unit": "kwh",
    },
    "Water":{
        "color":"#2a9de2",
        "icon": "fas fa-tint",
        "unit": "m³",
    },
    "Waste":{
        "color":"#414400",
        "icon": "fa fa-trash",
        "unit": "kg",
    },
    "Solar":{
        "color":"#f0d117",
        "icon": "fas fa-solar-panel",
        "unit": "kwh",
    },
    "Geothermie":{
        "color":"#bf6b59",
        "icon": "fas fa-water",
        "unit": "kwh",
    },
}


class ESGConsumption extends Component{
    constructor(props) {
        super(props);
        this.api = new API();
        this.state = {
            data : [],
            settings: JSON.parse(JSON.stringify(props.settings)),
            consumptionTileData :{},
        }
        //this.getConsumptionData()
    }



    getConsumptionData(settings){
        let d = {
            selectedBuildings: settings.selectedBuildings.map(x=>x.propertyId),
            timestamp:settings.selectedTimestamp,
            role:settings.role
        }
        //console.log(api.esg.getConsumption.get(api.getCookie("jwt"),{from:this.state.settings.from,until:this.state.settings.from,selectedBuildings: (this.state.settings.selectedBuildings == undefined)?[]:this.state.settings.selectedBuildings.map(b=>b.id)}))
        this.api.esg.getConsumptionData.get(this.api.getCookie("jwt"),d).then(response=>{
            this.setState({data: response.data})
            this.setState({consumptionTileData :response.data})

            //this.groupMeasurementData(response.data, d)

        })

    }

    groupMeasurementData(data, settings){
        let d = {}
        data.map(x=>{if(d[x.consumption_type]== undefined)d[x.consumption_type]={data:[]}; d[x.consumption_type].data.push(x)})
        Object.keys(d).map(t=>{
            d[t].this_month = {}
            let iteration = 0
            while ((d[t].this_month.value === undefined || d[t].this_month.value === 0) && iteration < 12 ) {
                d[t].this_month.data = d[t].data.filter(x =>
                    moment.utc(x.measurement_date) <= moment.utc(this.props.settings.selectedTimestamp).subtract(iteration,"months").endOf("month")
                    &&
                    moment.utc(x.measurement_date) >= moment.utc(this.props.settings.selectedTimestamp).subtract(iteration,"months").startOf("month")
                    &&
                    x.meter_type === "CONSUMPTION"
                )
                d[t].this_month.value = d[t].this_month.data.reduce((y, x) => x.consumption_since_last_measure + y, 0)

                iteration++
            }
            d[t].unit = ""
            if(d[t].data.length>0)
                d[t].unit = d[t].data[0].measure_unit
            d[t].this_month.booking_date = moment.utc(this.props.settings.selectedTimestamp).subtract(iteration-1, "month").startOf("month").format("YYYY-MM")
            this.setState({iteration:iteration})
            d[t].ytd = {}

            d[t].ytd.data = d[t].data.filter(x=>
                moment.utc(x.measurement_date)<=moment.utc(this.props.settings.selectedTimestamp).subtract(1,"years").endOf("year")
                &&
                moment.utc(x.measurement_date)>=moment.utc(settings.until).subtract(1,"years").startOf("year")
                &&
                x.meter_type ==="CONSUMPTION"
            )
            d[t].ytd.value = d[t].ytd.data.reduce((y,x)=>x.consumption_since_last_measure+y,0)


            d[t].lytd = {}

            d[t].lytd.data = d[t].data.filter(x=>
                moment.utc(x.measurement_date)<=moment.utc(settings.until).subtract(1,"years").subtract(1,"year").endOf("year")
                &&
                moment.utc(x.measurement_date)>=moment.utc(settings.until).subtract(1,"years").startOf("year").subtract(1,"year")
                &&
                x.meter_type ==="CONSUMPTION"
            )
            d[t].lytd.value = d[t].lytd.data.reduce((y,x)=>x.consumption_since_last_measure+y,0)

            d[t].ytd_production = {}

            d[t].ytd_production.data = d[t].data.filter(x=>
                moment.utc(x.measurement_date)<=moment.utc(settings.until)
                &&
                moment.utc(x.measurement_date)>=moment.utc(settings.until).startOf("year")
                &&
                x.meter_type ==="PRODUCTION"
            )
            d[t].ytd_production.value = d[t].ytd_production.data.reduce((y,x)=>x.consumption_since_last_measure+y,0)



        })
        console.log("consumptionTileData: ", d)
        this.setState({consumptionTileData :d})

    }
    componentDidMount() {
        if(document.getElementById("tileContainer").innerHTML==""){
            if(this.props.settings.selectedBuildings !== undefined){
                if (this.props.settings.selectedBuildings !== undefined){
                    let settings = JSON.parse(JSON.stringify(this.props.settings))
                    this.setState({settings:  settings})
                    this.getConsumptionData(settings)
                }
            }
        }    }
    componentDidUpdate(nextProps, nextState, nextContext) {
        if(document.getElementById("tileContainer").innerHTML==""|| nextProps.settings.selectedTimestamp !==this.state.settings.selectedTimestamp){
            if(nextProps.settings.selectedBuildings !== undefined){
                if (!sameObjectArrayById(nextProps.settings.selectedBuildings , this.state.settings.selectedBuildings)&& nextProps.settings.selectedBuildings !== undefined|| nextProps.settings.selectedTimestamp !==this.state.settings.selectedTimestamp){
                    let settings = JSON.parse(JSON.stringify(nextProps.settings))
                    this.setState({settings:  settings})
                    this.getConsumptionData(settings)
                }
            }
        }
    }

    render(){
        if(this.props.settings.selectedBuildings !== undefined){
            if (!sameObjectArrayById(this.props.settings.selectedBuildings , this.state.settings.selectedBuildings)&& this.props.settings.selectedBuildings !== undefined){
                let settings = JSON.parse(JSON.stringify(this.props.settings))
                this.setState({settings:  settings})
                this.getConsumptionData(settings)
            }
        }
        return(
            <div className={"w3-row-padding"} id={"tileContainer"} style={{margin:"0px 64px 0px 32px"}}>
                {Object.keys(this.state.consumptionTileData).sort((a,b)=>a>b?1:-1).map((x,i)=>
                    <ESGConsumptionTile iteration = {this.state.iteration} data={this.state.consumptionTileData[x]} id={x} colSetup = {"s12 m6 l4"} title={x} settings = {this.props.settings} seq={i}></ESGConsumptionTile>

                )}
            </div>
        )
    }
}

export default ESGConsumption;