
export class EventComponent{

    static events = null;

    static getInstance() {
        if(!EventComponent.events){
            EventComponent.events = new EventComponent();
        }
        return EventComponent.events;
    }

    Topics = {
        exception: {
            APPROVE_EXCEPTION: 'approveException',
            REJECT_EXCEPTION: 'rejectException',
        },
        changeReport: {
            CHANGE_REPORT_DATA: 'changereport_data'
        },
        filter:{
            PROPERTY_OVERVIEW:'property_overview'
        }

    }
    subscribers = {}

    /**
     * Pub-Sub-Pattern
     * @param eventName
     * @param data
     */
    publish = (eventName, data, callbackFnkt) => {
        // console.log(this.subscribers, eventName)
        if (!Array.isArray(this.subscribers[eventName])) {
            return
        }
        this.subscribers[eventName].forEach((callback) => {
            // console.log("Call subscribers callback")
            if(callbackFnkt)
                callback(data, callbackFnkt)
            else
                callback(data)
        })
    }
    /**
     * Subscribe to a specific Topic to receive Updates
     * @param eventName - Topics
     * @param callback
     */
    subscribe = (eventName, callback) => {
        // console.log("Subscribed to: ", eventName)
        if (!Array.isArray(this.subscribers[eventName])) {
            this.subscribers[eventName] = []
        }

        this.subscribers[eventName].push(callback)
    }

    unsubscribe = (eventName, callback) => {
        if (this.subscribers[eventName]) {
            for (let i = 0; i < this.subscribers[eventName].length; i++) {
                if (this.subscribers[eventName][i] === callback) {
                    this.subscribers[eventName].splice(i, 1);
                    break;
                }
            }
        }
    }
}