import {useUserService} from "../../services/userService/UserServiceProvider";
import {useTheme} from "styled-components";
import {Outlet, Route, Routes} from "react-router-dom";
import Profile from "../pages/profile/Profile";
import Support from "../pages/support/Support";
import Console from "../pages/Console";
import NoMatch from "../pages/NoMatch";
import KeyPage from "../pages/profile/KeyPage";
import PropertiesOverview from "../pages/overviews/PropertiesOverviewV2";
import DataExport from "../pages/data_export/DataExport";
import ESG from "../pages/esg/ESG";
import Upload from "../pages/uploads/Upload";
import QualityMonitor from "../pages/monitor/quality/QualityV2";
import {WrappedInspectReports} from "../pages/inspect_report/InspectReports";
import MasterData from "../pages/masterdata/MasterData";
import MappingDashboard from "../pages/mapping/MappingDashboard";
import RuleDashboard from "../pages/rules/RuleDashboard";
import RuleDashboardOld from "../pages/rules/RuleDashboardOld";
import UploadManual from "../pages/uploads/UploadManual";
import React from "react";
import {useSSE} from "react-hooks-sse";

/**
 * UserPages with Routing
 * @param props
 * @returns {*}
 * @constructor
 */
function UserPages(props) {

    const {activeRole} = useUserService();

    const theme = useTheme();

    const __defaultPages = () => {

        return (
            <>
                <Route index element={<Profile/>}/>

                <Route path="contact" element={<Support/>}/>
                <Route path="myprofile" element={<Profile/>}/>
                <Route path="console" element={<Console/>}/>
                <Route element={<NoMatch/>}/>

            </>
        )
    }

    const __getPages_API = () => {

        return (
            <>
                <Route index element={<KeyPage/>}/>
                <Route path={"keys"} element={<KeyPage/>}/>
                {__defaultPages()}
            </>
        )
    }

    const __getPages_AM = () => {
        return (
            <>
                {/*<Route path={"overviewOld"} element={<PropertiesOverviewOld/>}/>*/}
                <Route path={"overview"}    element={<PropertiesOverview/>}/>
                <Route path={"export"}      element={<DataExport/>}/>
                <Route path={"esg"} element={<Outlet/>}>
                    <Route path={"*"} element={<ESG/>}/>
                </Route>
                {__getPages_Management()}
            </>
        )
    }

    const __getPages_PM = () => {
        return (
            <>
                <Route index element={<Upload/>}/>
                <Route path={"monitor/quality"} element={<QualityMonitor/>}/>
                {/*<Route path={"monitor/quality"} element={<Quality/>}/>*/}
                {<Route path={"uploadManual"} element={<UploadManual/>}/>}

                <Route path={"esg"} element={<Outlet/>}>
                    <Route path={"*"} element={<ESG/>}/>
                </Route>

                <Route path={"upload"} element={<Upload/>}/>
                <Route path={"inspect"} element={<WrappedInspectReports/>}/>
                {/*<Route path={"inspect"} element={<InspectReports/>}/>*/}
                {/*<Route path={"overviewOld"} element={<PropertiesOverviewOld/>}/>*/}
                <Route path={"overview"} element={<PropertiesOverview/>}/>
                {__defaultPages()}
            </>
        )
    }

    const __getPages_Management = () => {
        return (
            <>
                <Route index element={<MasterData/>}/>
                <Route path={"masterdata"} element={<MasterData/>}/>
                <Route path={"mapping"} element={<MappingDashboard roleColor={theme.palette.primary}/>}/>
                <Route path={"rules"} element={<RuleDashboard roleColor={theme.palette.primary}/>}/>
                <Route path={"rules_old"} element={<RuleDashboardOld roleColor={theme.palette.primary}/>}/>
                {__defaultPages()}
            </>
        )
    }

    /**
     * Main rendering for Role-Based Pages
     * @param activeRole
     * @returns {*}
     */
    const getPages = (activeRole) => {
        switch (activeRole.role) {
            case "API":
                return __getPages_API();
            case "AM":
                return __getPages_AM();
            case "PM":
                return __getPages_PM();
            case "ADMIN":
                return __getPages_Management();
            case "SUPERUSER":
                return __getPages_Management();
            default:
                return __defaultPages();
        }
    }

    return (
        <Routes>
            {getPages(activeRole)}
        </Routes>
    )
}

export default UserPages;